import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import PropTypes from "prop-types"
import GlobalState from "contexts/GlobalState"

const Authmiddleware = props => {
  const [state, setState] = useContext(GlobalState)
  if (props.preloader) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    )
  }
  if (state.authUser == null || !localStorage.getItem("session_id")) {
    console.log(localStorage, "localstorage")
    // if (state.authUser == null || !localStorage.getItem("session_id") || !localStorage.getItem("session_id") ) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  if (props.roles != null && props.roles.length > 0) {
    try {
      const user = JSON.parse(state.authUser)
      if (!props.roles.includes(user.role)) {
        return (
          <Navigate
            to={{ pathname: "/main", state: { from: props.location } }}
          />
        )
      }
    } catch (e) {
      console.log("e")
      return (
        <Navigate to={{ pathname: "/main", state: { from: props.location } }} />
      )
    }
  }

  // Special permissions
  if (props.permission != null && props.permission.length > 0) {
    try {
      const obj = JSON.parse(state.authUser)
      if (
        obj.role != "system_admin" &&
        (obj.access_permissions == null ||
          obj.access_permissions[props.permission] != true)
      ) {
        return (
          <Navigate
            to={{ pathname: "/main", state: { from: props.location } }}
          />
        )
      }
    } catch (e) {
      return (
        <Navigate to={{ pathname: "/main", state: { from: props.location } }} />
      )
    }
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  roles: PropTypes.array,
  location: PropTypes.object,
  globalState: PropTypes.any,
  preloader: PropTypes.bool,
}

export default Authmiddleware
