import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import searchAnim from "common/animations/finddata"
import { defaultPageSizeOptions, showToast, stringSorter } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import { clientsTransferGroup, getClientsTable } from "helpers/backend_helper"
import XLSX from "xlsx"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { Table as AntdTable } from "antd"
import ClientDrawer from "./client-drawer"

const ClientsManagementTable = props => {
  const [groups, setGroups] = useState(null)
  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [action, setAction] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])
  const [title, setTitle] = props.t("Clients") //Excel and pdf name
  const [transferModal, setTransferModal] = useState(false)
  const [transferGroup, setTransferGroup] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Clients") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (groups) getClients()
  }, [groups])

  useEffect(() => {
    setSelectedRowKeys([])
    setSelectedRows([])
  }, [groups])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, clients])

  // Handle search function
  const handleSearch = () => {
    let result = clients.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.group?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.address?.toLowerCase().includes(searchText)
    )
    setFilteredClients(result)
  }

  // Get clients for table
  const getClients = () => {
    setLoading(true)
    getClientsTable(abortRef.current, groups)
      .then(result => {
        //console.log("Clients result: ", result)
        if (result.statusCode == 200 && result.entries) {
          //result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          setClients(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setClients([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Move clients to a group
  const moveToGroup = () => {
    if (selectedRowKeys && transferGroup) {
      setAction(true)
      clientsTransferGroup(abortRef.current, selectedRowKeys, transferGroup)
        .then(result => {
          console.log("Move result: ", result)
          if (result.statusCode == 200) {
            setTransferModal(false)
            setSelectedRowKeys([])
            setSelectedRows([])
            getClients()
            showToast(props.t("Success"), "success")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Table structure
  const columns = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return stringSorter(a, b, "name")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "group",
      title: props.t("Group"),
      sorter: (a, b) => {
        return stringSorter(a, b, "group")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "ssn",
      title: props.t("SSN"),
      sorter: (a, b) => {
        return stringSorter(a, b, "ssn")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b) => {
        return stringSorter(a, b, "gsm")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "address",
      title: props.t("Address"),
      sorter: (a, b) => {
        return stringSorter(a, b, "address")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "gatewayKey",
      title: props.t("Gateway"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "trackerKey",
      title: props.t("Tracker"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "carephoneKey",
      title: props.t("Carephone"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      width: "140px",
      ellipsis: true,
    },
  ]

  // Table formatting functions start
  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  // Text with max width and tooltip
  function longTextFormatter(cell, row) {
    return (
      <>
        <span
          id={"TT-" + row.key + "-" + cell}
          className="d-block"
          style={{
            maxWidth: "300px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {cell}
        </span>
        <UncontrolledTooltip
          placement="left"
          target={"TT-" + row.key + "-" + cell}
        >
          {"" + cell}
        </UncontrolledTooltip>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(newSelectedRows)
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = filteredClients.map(elt => [
      elt.name,
      elt.group,
      elt.gsm,
      elt.ssn,
    ])
    console.log(data, "pdf function")

    let content = {
      startY: 50,
      head: [
        [props.t("Name"), props.t("Group"), props.t("GSM"), props.t("SSN")],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message, "success")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [props.t("Name"), props.t("Group"), props.t("GSM"), props.t("SSN")],
    ]
    const data = filteredClients.map(elt => [
      elt.name,
      elt.group,
      elt.gsm,
      elt.ssn,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Clients")} />

          <Row className="mb-4">
            <Col xs="4">
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
            </Col>
            <Col xs="4">
              <SelectGroup
                onChange={setGroups}
                multiple={true}
                selectedTags={selectedTags}
              />
            </Col>
          </Row>

          {!groups && !loading && (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: searchAnim,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          )}

          {groups && (
            <div>
              <Card>
                <CardBody>
                  {clients != null && (
                    <React.Fragment>
                      <Row>
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col>
                          <span className="float-end ms-2">
                            <Tooltip
                              placement="bottom"
                              isOpen={TTdownloadstatstable}
                              target="downloadstatstable"
                              toggle={() => {
                                setTTdownloadstatstable(!TTdownloadstatstable)
                              }}
                            >
                              {props.t("Download statistics")}
                            </Tooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* TODO: Can not choose directory file right now */}
                                <DropdownItem onClick={() => downloadExcel()}>
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                                <DropdownItem onClick={() => exportPDF()}>
                                  {props.t("Download .pdf")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <span className="mt-2 float-end">
                            <p>
                              {filteredClients.length} {props.t("clients")}
                            </p>
                          </span>
                        </Col>
                      </Row>

                      <Collapse isOpen={selectedRowKeys.length > 0}>
                        <span id="selections-text">
                          {selectedRowKeys.length +
                            " " +
                            props.t("selected clients")}
                        </span>
                        <Row className="py-2">
                          <Col>
                            <Button
                              color="primary"
                              disabled={selectedRowKeys.length == 0 || loading}
                              onClick={() => {
                                setTransferModal(true)
                              }}
                            >
                              <i className="mdi mdi-account-group me-2" />
                              {props.t("Move to group")}
                            </Button>
                          </Col>
                        </Row>
                      </Collapse>

                      {!loading && (
                        <>
                          <div className="mt-2">
                            <AntdTable
                              className="striped colored-header"
                              bordered
                              size="small"
                              sticky
                              rowSelection={{
                                selectedRowKeys,
                                onChange: onSelectChange,
                              }}
                              columns={columns}
                              dataSource={filteredClients}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 100,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: "70vh",
                              }}
                            />
                          </div>
                        </>
                      )}
                      {loading && (
                        <Lottie
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: loadingAnim,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            },
                          }}
                          height={100}
                          width={100}
                        ></Lottie>
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </div>
          )}

          <ClientDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getClients={getClients}
          />

          <Modal
            isOpen={transferModal}
            toggle={() => {
              setTransferModal(!transferModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Move to group")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <div className="mb-3">
                  {selectedRows.map((entry, index) => (
                    <span
                      key={index}
                      className="me-1 mb-1 badge badge-soft-primary font-size-12"
                    >
                      {entry.name}
                    </span>
                  ))}
                </div>
                <FormGroup>
                  <SelectGroup
                    onChange={setTransferGroup}
                    multiple={false}
                    clearable={false}
                    //selectedTags={selectedTags}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setTransferModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    moveToGroup()
                  }}
                  disabled={action || !transferGroup || !selectedRowKeys}
                >
                  {props.t("Move")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  )
}

ClientsManagementTable.propTypes = {
  t: PropTypes.any,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(ClientsManagementTable)
