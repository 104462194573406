/***********************
 * NODE BATTERY VOLTAGE CHART
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import { NonceProvider } from "react-select"
import GlobalState from "contexts/GlobalState"

const NodeChartBatteryHistory = props => {
  const [language, setLanguage] = useState("fi")
  const [state, setState] = useContext(GlobalState)
  const [chartDataBatAvg, setChartDataBatAvg] = useState(["", 0])
  const [chartDataBatMin, setChartDataBatMin] = useState(["", 0])
  const [chartDataBatMax, setChartDataBatMax] = useState(["", 0])

  // Loading state
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props.showLoading != undefined) setIsLoading(props.showLoading)
  }, [props.showLoading])

  // empty array
  // const [empty, setEmpty] = useState(false)

  useEffect(() => {
    if (props.data != undefined) {
      setChartDataBatAvg(props.data[0])
      setChartDataBatMin(props.data[1])
      setChartDataBatMax(props.data[2])
    }
    //setIsLoading(false)
  }, [props.data])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setLanguage("fi")
      } else if (currentLanguage.indexOf("sv") == 0) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])
  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-bathis-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-bathis-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-bathis-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Average voltage"),
      type: "line",
      data: chartDataBatAvg,
    },
    // {
    //   name: props.t("Min voltage"),
    //   type: "line",
    //   data: chartDataBatMin,
    // },
    // {
    //   name: props.t("Max voltage"),
    //   type: "line",
    //   data: chartDataBatMax,
    // },
  ]
  const options = {
    chart: {
      id: "node-bathis-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.1,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: [3, 2, 2],
      curve: "smooth",
    },

    colors: ["#186bfc", "#00a0d6", "#f46a6a", "#34c38f", "#00a0d6", "#f46a6a"],
    annotations: {
      position: "front",
      yaxis: [
        {
          y: 2.7,
          borderColor: "#f46a6a",
          strokeDashArray: 4,
          label: {
            borderColor: "#f46a6a",

            style: {
              color: "#fff",
              background: "#f46a6a",
            },
            text: props.t("Weak"),
          },
        },
        {
          y: 2.85,
          borderColor: "#ffd000",
          strokeDashArray: 4,
          label: {
            borderColor: "#ffd000",
            style: {
              color: "#fff",
              background: "#ffd000",
            },
            text: props.t("Moderate"),
          },
        },
        {
          y: 3.0,
          borderColor: "#34c38f",
          strokeDashArray: 4,
          label: {
            borderColor: "#34c38f",
            style: {
              color: "#fff",
              background: "#34c38f",
            },
            text: props.t("Good"),
          },
        },
      ],
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 2.6,
      max: 3.05,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(2) + " " + props.t(" V")
          }
          return y
        },
      },
      x: {
        format: "dd.MM.yyyy",
      },
    },
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          {/* {empty ? (
            <div>
              <div className="text-center my-5">
                <Link to="#" className="text-primary">
                  {props.t("No measurements")}
                </Link>
              </div>
            </div>
          ) : ( */}
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="300"
          />
          {/* )} */}
        </div>
      )}
    </div>
  )
}

NodeChartBatteryHistory.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  avg: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  data: PropTypes.any,
  showLoading: PropTypes.bool,
}

export default withTranslation()(NodeChartBatteryHistory)
