import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { getSystemLogs } from "helpers/backend_helper"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { dateSorter, defaultPageSizeOptions, downloadExcel, exportPDF, stringSorter } from "utils/general"
import { showToast } from "utils/general"
import { Table as AntdTable } from "antd"

const SystemLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "days"),
    moment(),
  ])
  const [logType, setLogType] = useState("script")
  const [maxItems, setMaxItems] = useState("500")
  const [showModal, setShowModal] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [title, setTitle] = useState(props.t("System logs"))
  const [menu, setMenu] = useState(false) //Dropdown button state

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (logType && dateRange) getLogs()
  }, [logType, dateRange, maxItems])

  // Get logs
  const getLogs = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      let rows = isNaN(maxItems) == false ? parseInt(maxItems, 10) : 500
      getSystemLogs(
        abortRef.current,
        logType,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        rows
      )
        .then(data => {
          let counter = 1
          if (data.logs != null) {
            data.logs.forEach(log => {
              log.id = counter++
            })
          }
          setLogs(data.logs)
          setOngoingSearch(false)
        })
        .catch(e => {
          console.log(e)
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.content?.toLowerCase().includes(searchText) ||
        data.user?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  const columns = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["timestamp"], b["timestamp"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "level",
      title: props.t("Level"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "level", sortOrder)
      },
      render: levelFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "content",
      title: props.t("Content"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "content", sortOrder)
      },
      render: contentFormatter,
      width: "400px",
      ellipsis: false,
    },
    {
      dataIndex: "user",
      title: props.t("User"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "user", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "origin",
      title: props.t("Origin"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "origin", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "thread",
      title: props.t("Thread"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "thread", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function contentFormatter(cell, row) {
    return (
      <>
        <Button
          color="clear"
          className="me-2"
          outline
          size="sm"
          onClick={() => {
            setSelectedMessage(row.content)
            setShowModal(true)
          }}
        >
          <i className="mdi mdi-arrow-expand text-primary" />
        </Button>
        <span>{row.content}</span>
      </>
    )
  }

  function levelFormatter(cell, row) {
    if (row.level == "DEBUG") {
      return (
        <span className="text-success">
          <i className="mdi mdi-bug me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "INFO") {
      return (
        <span className="text-primary">
          <i className="mdi mdi-information me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "WARN") {
      return (
        <span className="text-warning">
          <i className="mdi mdi-alert me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "ERROR") {
      return (
        <span className="text-danger">
          <i className="mdi mdi-alert me-1" />
          {row.level}
        </span>
      )
    }
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Time"),
        props.t("Level"),
        props.t("Content"),
        props.t("User"),
        props.t("Origin"),
        props.t("Thread"),
      ],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.level,
      elt.content,
      elt.user,
      elt.origin,
      elt.thread,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("System logs")} />

          <Card>
            <CardBody>
              <Row>
                <Col>
                  {logs != null && (
                    <React.Fragment>
                      <Row className="mb-1">
                        {" "}
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col className="col-auto">
                          <Input
                            type="select"
                            name="logType"
                            value={logType}
                            onChange={v => {
                              setLogType(v.target.value)
                            }}
                          >
                            <option value="script">
                              {props.t("Script")}
                            </option>
                            <option value="application">
                              {props.t("Application")}
                            </option>
                            <option value="security">
                              {props.t("Security")}
                            </option>
                          </Input>
                        </Col>
                        <Col className="col-auto">
                          <Input
                            style={{ width: "80px" }}
                            type="number"
                            name="maxItems"
                            value={maxItems}
                            onChange={v => {
                              setMaxItems(v.target.value)
                            }}
                          />
                        </Col>
                        <Col>
                          <CustomRangePicker
                            value={dateRange}
                            rangeChanged={onDateSelection}
                            showTime={true}
                            allowClear={false}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Button
                            className="square-icon-button-sm"
                            id="calendar-button"
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              setDateRange([dateRange[0], moment()])
                            }}
                          >
                            <UncontrolledTooltip
                              placement="bottom"
                              target="calendar-button"
                            >
                              {props.t("Refresh time")}
                            </UncontrolledTooltip>
                            <i className="mdi mdi-calendar-refresh" />
                          </Button>
                        </Col>
                        <Col>
                          <span className="float-end ms-2">
                            <UncontrolledTooltip
                              placement="bottom"
                              target="downloadstatstable"
                            >
                              {props.t("Download")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* TODO: Can not choose directory file right now */}
                                <DropdownItem
                                  onClick={() => generateExcel()}
                                >
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <div className="mt-2 float-end">
                            <p>
                              {filteredLogs.length}{" "}
                              {props.t("results found")}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          {ongoingSearch == true ? (
                            <div className="py-5 text-center">
                              <p className="text-primary">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                {props.t("Loading")}
                              </p>
                            </div>
                          ) : (
                            <AntdTable
                              className="striped colored-header"
                              bordered
                              size="middle"
                              sticky
                              rowKey={"id"}
                              columns={columns}
                              dataSource={filteredLogs}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: "64vh",
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal)
        }}
        centered
      >
        <ModalBody>
          <Input
            type="textarea"
            rows={20}
            disabled
            value={selectedMessage}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setShowModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

SystemLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SystemLogs)
