import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  Form,
  Input,
  Alert,
  Label,
  FormGroup,
  ButtonGroup,
  Button,
  Modal,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { DatePicker, Switch, Popconfirm } from "antd"
import moment from "moment"
import sanitizeHtml from "sanitize-html"
import { Select, ConfigProvider } from "antd"
const { Option } = Select
import * as Yup from "yup"
import { useFormik } from "formik"
import StatusReportItem from "pages/LandingPage/status-report-item"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import {
  deleteStatusReport,
  getAdminStatusReports,
  insertStatusReport,
  updateStatusReport,
} from "helpers/database_helper"
import { showToast } from "utils/general"

const ErrorBulletinsPage = props => {
  const [bulletins, setBulletins] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selection, setSelection] = useState(null)
  const [example, setExample] = useState(null)
  const [selectedLang, setSelectedLang] = useState(1)
  const [error, setError] = useState(null)
  const [modal, setModal] = useState(false)

  const [rowCount, setRowCount] = useState(100)
  const [targetFilter, setTargetFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("0")
  const [productionDatabase, setProductionDatabase] = useState(true)

  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [ongoingInsert, setOngoingInsert] = useState(false)

  const [initialValues, setInitialValues] = useState({
    id: 0,
    current_status: 1,
    target_groups: [],
    published: false,
    start_time: moment(),
    end_time: moment(0),
    header_fi: "",
    header_en: "",
    header_sv: "",
    description_fi: "",
    description_en: "",
    description_sv: "",
  })

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Error bulletins") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // DB selection changed, clear everything
  useEffect(() => {
    setSelection(null)
    setBulletins([])
    setSelectedIndex(null)
  }, [productionDatabase])

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      guid: initialValues.guid,
      current_status: initialValues.current_status,
      target_groups: initialValues.target_groups,
      published: initialValues.published,
      start_time: initialValues.start_time,
      end_time: initialValues.end_time,
      header_fi: initialValues.header_fi,
      header_en: initialValues.header_en,
      header_sv: initialValues.header_sv,
      description_fi: initialValues.description_fi,
      description_en: initialValues.description_en,
      description_sv: initialValues.description_sv,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)

      let copy = JSON.parse(JSON.stringify(values))
      if (copy.target_groups != null && copy.target_groups.length > 0) {
        copy.target_groups = copy.target_groups.join(",")
      } else {
        copy.target_groups = ""
      }

      const sanitizeSettings = {
        allowedTags: ["b", "i", "em", "strong", "a"],
        allowedAttributes: {
          a: ["href", "target"],
        },
        nonBooleanAttributes: [],
      }

      copy.description_fi = sanitizeHtml(copy.description_fi, sanitizeSettings)
      copy.description_en = sanitizeHtml(copy.description_en, sanitizeSettings)
      copy.description_sv = sanitizeHtml(copy.description_sv, sanitizeSettings)

      console.log("Submit final values: ", copy)

      if (values.id < 0) {
        insertBulletin(copy)
      } else {
        updateBulletin(copy)
      }
    },
  })

  // Form values changed, update example
  useEffect(() => {
    handleExample(validation.values)
  }, [validation.values])

  // Get bulletins
  const fetchBulletins = (clearSelection = true) => {
    if (clearSelection) {
      setSelectedIndex(null)
      setSelection(null)
    }

    // Set filters
    let filters = new Object()
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.current_status = statusNum

    setOngoingSearch(true)
    getAdminStatusReports(
      abortRef.current,
      productionDatabase,
      rowCount,
      filters
    )
      .then(reports => {
        reports.forEach(report => {
          const temp = report.start_time
            ? moment(report.start_time).format("DD.MM.YYYY")
            : ""
          report.label = temp + " " + report.header_fi
          report.value = report.id
        })
        setBulletins(reports)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
      })
  }

  // Insert bulletin
  const insertBulletin = vals => {
    setOngoingInsert(true)
    insertStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchBulletins(true)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Update bulletin
  const updateBulletin = vals => {
    setOngoingInsert(true)
    updateStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchBulletins(false)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Delete bulletin
  const deleteBulletin = () => {
    if (selection && selection.id >= 0) {
      setOngoingDelete(true)
      deleteStatusReport(abortRef.current, productionDatabase, selection.id)
        .then(result => {
          if (result == "OK") {
            showToast(props.t("Deleted"), "success")
            setSelection(null)
            setSelectedIndex(null)
            fetchBulletins(true)
          } else {
            console.log("Delete result: ", result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
        })
    } else {
      setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Handle preview
  const handleExample = bulletin => {
    let niceDate = moment(bulletin.start_time).format("DD.MM.YYYY HH:mm")
    if (bulletin.end_time) {
      var endDate = new Date(bulletin.end_time)
      if (endDate > 0) {
        let endDateNice = moment(bulletin.end_time).format("DD.MM.YYYY HH:mm")
        niceDate = niceDate + " - " + endDateNice
      }
    }

    let temp = new Object()
    temp.id = bulletin.id
    temp.guid = bulletin.guid,
    temp.date = niceDate

    temp.title = bulletin.header_fi
    temp.title_fi = bulletin.header_fi
    temp.title_en = bulletin.header_en
    temp.title_sv = bulletin.header_sv

    temp.content1_fi = bulletin.description_fi
      ? bulletin.description_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = bulletin.description_en
      ? bulletin.description_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = bulletin.description_sv
      ? bulletin.description_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1 = temp.content1_fi

    let targets = []
    bulletin.target_groups.forEach(target => {
      switch (target) {
        case "1":
          targets.push("Suvanto Care app")
          break
        case "2":
          targets.push("Video call")
          break
        case "3":
          targets.push("Group video call")
          break
        case "4":
          targets.push("Carephone")
          break
        case "5":
          targets.push("Medicine reminder")
          break
        case "6":
          targets.push("Suvanto On the Go")
          break
        case "7":
          targets.push("Suvanto Home")
          break
        case "8":
          targets.push("Smartlock")
          break
        case "9":
          targets.push("Suvanto Care webpage")
          break
      }
    })
    temp.targets = targets

    switch (bulletin.current_status) {
      case 1:
      case "1":
        temp.status = "Service outage"
        temp.color = "#f46a6a"
        break
      case 2:
      case "2":
        temp.status = "Service disruption"
        temp.color = "#f1b44c"
        break
      case 3:
      case "3":
        temp.status = "Service operational"
        temp.color = "#34c38f"
        break
    }
    setExample(temp)
  }

  // Create new bulletin draft
  const createBulletin = () => {
    let temp = new Object()
    temp.id = -1
    temp.start_time = moment()
    temp.end_time = null
    temp.published = false
    temp.target_groups = []
    temp.current_status = 1
    temp.header_fi = ""
    temp.header_en = ""
    temp.header_sv = ""
    temp.description_fi = ""
    temp.description_en = ""
    temp.description_sv = ""

    setSelection(temp)
    setInitialValues(temp)
    setSelectedIndex(null)
  }

  // Selected bulletin changed
  const handleChange = newSelection => {
    //console.log(`Option selected:`, newSelection)
    var index = bulletins.findIndex(el => el.id === newSelection)
    if (index >= 0) {
      let copy = JSON.parse(JSON.stringify(bulletins[index]))
      copy.start_time = moment(copy.start_time)
      copy.end_time = moment(copy.end_time)
      if (copy.target_groups != null && copy.target_groups.length > 0)
        copy.target_groups = copy.target_groups.split(",")
      else copy.target_groups = []

      setSelection(copy)
      setSelectedIndex(newSelection)
      setInitialValues(copy)
    } else {
      setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Lottie options
  const animationLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No entries found")}</p>
    </div>
  )

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Error bulletins")} />
          <Row>
            <Col lg={3} xxl={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Search parameters")}
                  </CardTitle>
                  <FormGroup>
                    <Label>{props.t("Status")}</Label>
                    <Input
                      value={statusFilter}
                      type="select"
                      name="statusFilter"
                      onChange={v => {
                        setStatusFilter(v.target.value)
                      }}
                    >
                      <option value="0">{props.t("All")}</option>
                      <option value="1">{props.t("Service outage")}</option>
                      <option value="2">{props.t("Service disruption")}</option>
                      <option value="3">
                        {props.t("Service operational")}
                      </option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>{props.t("Rows")}</Label>
                    <Input
                      name="limit"
                      type="number"
                      onChange={v => {
                        if (v.target.value.length > 0)
                          setRowCount(parseInt(v.target.value, 10))
                      }}
                      defaultValue={rowCount}
                    ></Input>
                  </FormGroup>
                  <FormGroup className="mt-4">
                    <Switch
                      className="me-2"
                      name="productionDb"
                      checked={productionDatabase}
                      onChange={v => {
                        setProductionDatabase(v)
                      }}
                    ></Switch>
                    <Label>{props.t("Production database")}</Label>
                  </FormGroup>
                  <Button
                    color="primary"
                    style={{ width: "100%" }}
                    disabled={ongoingSearch}
                    onClick={() => fetchBulletins(true)}
                    className="mb-2"
                  >
                    {props.t("Search")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col style={{ maxWidth: "1080px" }}>
              <Card>
                <CardBody>
                  <Row>
                    {ongoingSearch && (
                      <Col
                        className="d-inline-flex"
                        style={{ alignItems: "center" }}
                      >
                        <Lottie
                          options={animationLoading}
                          height={36}
                          width={100}
                        />
                      </Col>
                    )}
                    {!ongoingSearch && (
                      <>
                        <Col
                          className="col-auto d-inline-flex"
                          style={{ alignItems: "center" }}
                        >
                          <h5 className="mb-0">
                            {props.t("Results found") + ": " + bulletins.length}
                          </h5>
                        </Col>
                        <Col
                          className="d-inline-flex"
                          style={{ alignItems: "center" }}
                        >
                          <ConfigProvider
                            style={{ width: "100%" }}
                            renderEmpty={customizeRenderEmpty}
                          >
                            <div
                              style={{ width: "100%" }}
                              className="config-provider"
                            >
                              <Select
                                showSearch
                                allowClear
                                loading={ongoingSearch}
                                placeholder={props.t("Select bulletin")}
                                style={{ width: "100%" }}
                                value={selectedIndex}
                                onChange={handleChange}
                                options={bulletins}
                                filterOption={(inputValue, option) => {
                                  return (
                                    option.label
                                      .toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0
                                  )
                                }}
                              />
                            </div>
                          </ConfigProvider>
                        </Col>
                      </>
                    )}
                    <Col className="col-auto">
                      <Button
                        className="ms-2"
                        color="primary"
                        style={{ minWidth: "120px" }}
                        onClick={() => {
                          createBulletin()
                        }}
                      >
                        {props.t("Create new")}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {selection && (
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Bulletin content")}
                      {selection.guid && productionDatabase && (
                        <>
                          <a
                            className="ms-2 font-size-14"
                            href={"/disturbance-notice/" + selection.guid}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {props.t("Public link")}
                          </a>
                        </>
                      )}
                      <Button
                        outline
                        color="primary"
                        size="sm"
                        className="float-end"
                        style={{ borderRadius: "100px" }}
                        onClick={() => {
                          setSelection(null)
                          setSelectedIndex(null)
                        }}
                      >
                        {props.t("Close")}
                      </Button>
                    </CardTitle>
                    <div className="p-1">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : null}
                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Status")}</Label>
                              <Input
                                value={validation.values.current_status + ""}
                                type="select"
                                name="current_status"
                                onChange={validation.handleChange}
                              >
                                <option value="1">
                                  {props.t("Service outage")}
                                </option>
                                <option value="2">
                                  {props.t("Service disruption")}
                                </option>
                                <option value="3">
                                  {props.t("Service operational")}
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Affected services")}</Label>
                              <Select
                                className=""
                                allowClear
                                mode="multiple"
                                placeholder={props.t("Select services")}
                                style={{ width: "100%" }}
                                value={validation.values.target_groups}
                                onChange={v =>
                                  validation.setFieldValue("target_groups", v)
                                }
                                listHeight={400}
                              >
                                <Option value="1">
                                  {props.t("Suvanto Care app")}
                                </Option>
                                <Option value="2">
                                  {props.t("Video call")}
                                </Option>
                                <Option value="3">
                                  {props.t("Group video call")}
                                </Option>
                                <Option value="4">
                                  {props.t("Carephone")}
                                </Option>
                                <Option value="5">
                                  {props.t("Medicine reminder")}
                                </Option>
                                <Option value="6">
                                  {props.t("Suvanto On the Go")}
                                </Option>
                                <Option value="7">
                                  {props.t("Suvanto Home")}
                                </Option>
                                <Option value="8">
                                  {props.t("Smartlock")}
                                </Option>
                                <Option value="9">
                                  {props.t("Suvanto Care webpage")}
                                </Option>
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Start time")}</Label>
                              <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                value={validation.values.start_time}
                                onChange={v =>
                                  validation.setFieldValue("start_time", v)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("End time")}</Label>
                              <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                format="DD.MM.YYYY HH:mm"
                                value={validation.values.end_time}
                                onChange={v =>
                                  validation.setFieldValue("end_time", v)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row xs={1} sm={2} className="mt-2">
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="published"
                                checked={validation.values.published}
                                onChange={v =>
                                  validation.setFieldValue("published", v)
                                }
                              ></Switch>
                              <Label>{props.t("Published")}</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr />

                        <ButtonGroup size="sm" className="mb-4 mt-3">
                          <Button
                            color="primary"
                            active={selectedLang === 1}
                            onClick={() => setSelectedLang(1)}
                          >
                            Suomi
                          </Button>
                          <Button
                            color="primary"
                            active={selectedLang === 2}
                            onClick={() => setSelectedLang(2)}
                          >
                            Englanti
                          </Button>
                          <Button
                            color="primary"
                            active={selectedLang === 3}
                            onClick={() => setSelectedLang(3)}
                          >
                            Ruotsi
                          </Button>
                        </ButtonGroup>

                        {selectedLang === 1 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Title") + " (fi)"}</Label>
                              <Input
                                name="header_fi"
                                placeholder={props.t("Title")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.header_fi || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Description") + " (fi)"}</Label>
                              <Input
                                name="description_fi"
                                placeholder={props.t("Description")}
                                type="textarea"
                                rows={12}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description_fi || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        {selectedLang === 2 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Title") + " (en)"}</Label>
                              <Input
                                name="header_en"
                                placeholder={props.t("Title")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.header_en || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Description") + " (en)"}</Label>
                              <Input
                                name="description_en"
                                placeholder={props.t("Description")}
                                type="textarea"
                                rows={12}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description_en || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        {selectedLang === 3 && (
                          <div>
                            <FormGroup>
                              <Label>{props.t("Title") + " (sv)"}</Label>
                              <Input
                                name="header_sv"
                                placeholder={props.t("Title")}
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.header_sv || ""}
                              ></Input>
                            </FormGroup>

                            <FormGroup>
                              <Label>{props.t("Description") + " (sv)"}</Label>
                              <Input
                                name="description_sv"
                                placeholder={props.t("Description")}
                                type="textarea"
                                rows={12}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description_sv || ""}
                              ></Input>
                            </FormGroup>
                          </div>
                        )}

                        {example && (
                          <section>
                            <hr />
                            <div className="">
                              <ul className="list-group list-group-flush">
                                <li
                                  key="example"
                                  className="list-group-item py-3"
                                >
                                  <StatusReportItem
                                    item={example}
                                    onOpenModal={() => {
                                      setModal(!modal)
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                            <hr />
                          </section>
                        )}

                        <div className="mt-4 mb-2 d-flex justify-content-center">
                          {selection.id >= 0 && (
                            <Popconfirm
                              title={props.t("Confirm action")}
                              open={deleteConfirm}
                              okType="danger"
                              okText={props.t("Delete")}
                              cancelText={props.t("Cancel")}
                              onConfirm={() => {
                                deleteBulletin()
                                setDeleteConfirm(false)
                              }}
                              onCancel={() => {
                                setDeleteConfirm(false)
                              }}
                            >
                              <Button
                                color="danger"
                                disabled={ongoingDelete}
                                style={{ minWidth: "160px" }}
                                onClick={() => {
                                  setDeleteConfirm(true)
                                }}
                              >
                                {props.t("Delete bulletin")}
                              </Button>
                            </Popconfirm>
                          )}
                          <Button
                            color="primary"
                            disabled={ongoingInsert}
                            className="ms-4"
                            style={{ minWidth: "160px" }}
                            onClick={() => {
                              validation.submitForm()
                            }}
                          >
                            {props.t("Save changes")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={() => {
              setModal(!modal)
            }}
            centered
          >
            {example && (
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">{example.title}</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{ __html: example.content1 }}
                  ></p>
                </div>
              </div>
            )}
          </Modal>
        </Container>
      </div>
    </>
  )
}

ErrorBulletinsPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ErrorBulletinsPage)
