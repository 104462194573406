import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Table as AntdTable } from "antd"
import {
  boolSorter,
  dateSorter,
  dateSorter2,
  dateSorterDateObj,
  defaultPageSizeOptions,
  numberSorter,
  stringSorter,
} from "utils/general"

const AlarmServicesTable = props => {
  const [entries, setEntries] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    setEntries(props.data)
  }, [props.data])

  // table columns
  const columns = [
    {
      dataIndex: "status",
      align: "center",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "status", sortOrder)
      },
      render: statusFormatter,
      width: "70px",
    },
    {
      dataIndex: "received_at",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorterDateObj(a["received_at"], b["received_at"], sortOrder)
      },
      render: timeFormatter,
      width: "200px",
    },
    {
      dataIndex: "map_link",
      title: props.t("Location"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "map_link", sortOrder)
      },
      render: locationFormatter,
      width: "200px",
    },
    {
      dataIndex: "alarm_id",
      title: props.t("Type"),
      sorter: (a, b) => {
        return props.t("AlarmTitles." + a.alarm_id) -
          props.t("AlarmTitles." + b.alarm_id)
          ? 1
          : -1
      },
      render: typeFormatter,
      width: "220px",
    },
    {
      dataIndex: "extra_info",
      title: props.t("Info"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "extra_info", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "false_alarm",
      title: props.t("False alarm"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "false_alarm", sortOrder)
      },
      render: falseFormatter,
      width: "130px",
    },
    {
      dataIndex: "comment",
      title: props.t("Comments"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "comment", sortOrder)
      },
      width: "220px",
    },
    {
      dataIndex: "auto_closed",
      align: "center",
      title: props.t("Auto closed"),
      sorter: (a, b) => {
        return a.auto_closed - b.auto_closed ? 1 : -1
      },
      width: "140px",
      render: statusFormatterCheck,
    },
    {
      dataIndex: "acked_by",
      title: props.t("Handler"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "acked_by", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "acked_at",
      title: props.t("Handling time"),
      sorter: (a, b, sortOrder) => {
        return dateSorterDateObj(a["acked_at"], b["acked_at"], sortOrder)
      },
      render: timeFormatter,
      width: "200px",
    },
    {
      dataIndex: "closed_by",
      title: props.t("Closed by"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "closed_by", sortOrder)
      },
      width: "200px",
    },
    {
      dataIndex: "closed_at",
      title: props.t("Closed at"),
      sorter: (a, b, sortOrder) => {
        return dateSorterDateObj(a["closed_at"], b["closed_at"], sortOrder)
      },
      render: timeFormatter,
      width: "200px",
    },
  ]

  // Automatic closure
  // function statusFormatterExport(cell) {
  //   return cell == true ? props.t("x") : ""
  // }
  // Automatic closure table
  function statusFormatterCheck(cell) {
    return cell == true ? (
      <i className="mdi mdi-spellcheck text-success p-0 m-0 font-size-16"></i>
    ) : (
      ""
    )
  }

  // Modified status
  function statusFormatter(cell) {
    if (cell == "CLOSED") {
      return <i className="bx bx-check-circle font-size-20 text-success me-1" />
    } else if (cell == "ASSIGNED") {
      return <i className="bx bxs-calendar-heart font-size-20 text-warning" />
    } else {
      return <i className="bx bxs-calendar-heart font-size-20 text-danger" />
    }
  }

  // False alarm formatter
  function falseFormatter(cell) {
    if (cell == true) {
      return <span>{props.t("Yes")}</span>
    } else {
      /*else if (cell == false) {
      return <span>{props.t("No")}</span>
    }*/
      return <></>
    }
  }

  // Google map link formatter
  function locationFormatter(cell, row) {
    if (row.map_link) {
      return (
        <a href={row.map_link} target="_blank" rel="noopener noreferrer">
          <Button color="secondary" size="sm">
            <i className="mdi mdi-google-maps" />
          </Button>
        </a>
      )
    } else {
      return <></>
    }
  }

  // Converting time from seconds to readable
  function timeFormatter(value) {
    if (value) return moment(value).format("DD.MM.YYYY HH:mm:ss")
    else return ""
  }

  // Can be used to modify type
  function typeFormatter(cell) {
    return props.t("AlarmTitles." + cell)
  }

  const onSelectChange = (newSelectedRowKeys, obj) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys, obj)
    setSelectedRowKeys(newSelectedRowKeys)
    props.setSelectedAlarmsObj(obj)
    props.setSelectedAlarms(newSelectedRowKeys)
  }
  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <span>
        <Row>
          {entries.length > 0 && (
            <Col className="col-12">
              <AntdTable
                className="clear-header"
                bordered={false}
                size="middle"
                loading={props.loading}
                rowSelection={selectRow}
                sticky
                rowKey={"key"}
                columns={columns}
                dataSource={entries}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: 50,
                  pageSizeOptions: defaultPageSizeOptions,
                }}
                tableLayout="auto"
                scroll={{
                  x: "max-content",
                  y: "64vh",
                }}
              />
            </Col>
          )}
        </Row>
      </span>
    </>
  )
}

AlarmServicesTable.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
  data: PropTypes.any,
  selectedAlarms: PropTypes.array,
  setSelectedAlarms: PropTypes.any,
  loading: PropTypes.any,
}

export default withTranslation()(AlarmServicesTable)
