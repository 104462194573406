import React, { useContext, useEffect, useState } from "react"
import { Col } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"

import GlobalState from "contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const MeasureChart = props => {
  const [state, setState] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")

  const [type, setType] = useState()

  const [max, setMax] = useState(props.max)
  const [start, setStart] = useState(props.start)
  const [end, setEnd] = useState(props.end)
  const [seriesData, setSeriesData] = useState([])

  useEffect(() => {
    if (props.data !== undefined && props.data.length !== 0) {
      setSeriesData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if (props.type !== type && props.type !== undefined && props.type !== "") {
      setType(props.type)
      console.log(props.type, "type")
    }
  }, [props.type])

  const options = {
    chart: {
      id: "measurement-chart",
      // locales: [fi, en, se],
      // defaultLocale: language,
      stacked: false,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        // autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    grid: {
      borderColor: "#f8f8fa",
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    stroke: {
      curve: "smooth",
      width: [2, 2, 2],
    },
    fill: {
      type: ["gradient", "gradient", "solid"],
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    colors: ["#00bcf5", "#558ee6", "#ff4c2c"], //"#0080a9""#00bcf5"
    markers: {
      size: [1, 1, 3],
      fillColor: ["#00bcf5", "#558ee6", "#ff4c2c"],
      strokeColor: ["#00bcf5", "#558ee6", "#ff4c2c"],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 2,
      min: start,
      max: end,

      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 0,
      max: max,
      decimalsInFloat: 1,
    },
    tooltip: {
      followCursor: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            console.log(props.type, "type")
            let type = props.type !== 1 ? props.tooltip : ""
            return y + " " + type
          }
          return y
        },
      },
      x: {
        format: "d.M.yyyy HH:mm",
      },
    },
  }

  return (
    <>
      {seriesData.length !== 0 && end !== undefined ? (
        <Col lg="12">
          <ReactApexChart
            options={options}
            series={seriesData}
            type="area"
            height="400"
            width="100%"
          />
        </Col>
      ) : (
        <></>
      )}
    </>
  )
}

MeasureChart.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  type: PropTypes.any,
  tooltip: PropTypes.any,
  max: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withRouter(withTranslation()(MeasureChart))
