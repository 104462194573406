/***********************
 * Forgot password page
 ************************/

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"

import { Link } from "react-router-dom"
import axios from "axios"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Locale
import { withTranslation } from "react-i18next"

const axiosApi = axios.create({})
axiosApi.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded"

const ForgetPasswordPage = props => {
  const [forgetError, setForgetError] = useState(null)
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState(null)
  const [csrfid, setCsrfid] = useState("")

  useEffect(() => {
    document.title = props.t("Forgotten password") + " | Suvanto Care"
    /*axiosApi.get("https://suvanto.care/Thingworx/FormLogin/confirm/Suvanto").then(response => {
      if (response.data.indexOf("x-csrf-id") > -1) {
        let temp = response.data.substring(response.data.indexOf("x-csrf-id"))
        let end = temp.indexOf('">')
        temp = temp.substring(18, end)
        setCsrfid(temp)
      }
    }).catch(err => {
      console.log(err)
    })*/
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please enter your email")),
      username: Yup.string().required(props.t("Please enter your username")),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("Forget password button")
      setForgetError(null)
      setForgetSuccessMsg(null)

      // Trim values
      const keys = Object.keys(values)
      keys.forEach((key, index) => {
        values[key] = values[key].trim()
      })

      axiosApi
        .post(
          "https://suvanto.care/Thingworx/action-confirm-creds",
          new URLSearchParams({
            "x-thingworx-session": true,
            "OrganizationName": "Suvanto",
            "thingworx-form-userid": values.username,
            "thingworx-form-email": values.email,
            "x-csrf-id": csrfid
          }), {
            //withCredentials: true,
            /*headers: {}*/
          }
        )
        // //Cookie: "CSRFID="+csrfid+";",
        .then(response => {
          if (
            response.data.indexOf("check-email-reset-password") > -1
          ) {
            setForgetSuccessMsg("Check your email to reset your password")
            resetForm()
          } else {
            console.log(response)
            setForgetError("Error 500")
          }
        })
        .catch(err => {
          console.log(err)
          setForgetError("Error 500")
        })
    },
  })

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  padding: "30px",
                }}
              >
                <CardBody className="pt-0">
                  <div className="p-3 text-center">
                    <h4 className=" mb-3 mt-2">
                      {props.t("Forgotten password")}
                    </h4>
                    <p className="text-muted mb-3 mt-4 ">
                      {props.t("Password reset description")}
                    </p>
                  </div>

                  <div className="p-1">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.t(forgetError)}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.t(forgetSuccessMsg)}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      {/* Username input */}
                      <div className="mb-3 mt-2 app-input">
                        <div className="position-relative">
                          <span className="bx bx-user text-primary" />
                          <Input
                            name="username"
                            placeholder={props.t("Username")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      {/* Email input */}
                      <div className="mb-4 app-input">
                        <div className="position-relative">
                          <span className="mdi mdi-email-outline text-primary" />
                          <Input
                            name="email"
                            placeholder={props.t("Email")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary w-md "
                          onClick={() => {
                            validation.submitForm()
                          }}
                        >
                          {props.t("Reset password")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/login" className="">
                          {props.t("Go back to") + props.t(" Login Page")}
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-primary">
                  © {new Date().getFullYear()} Suvanto Care
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
  success: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
}

export default withTranslation()(ForgetPasswordPage)
