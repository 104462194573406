import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { withTranslation } from "react-i18next"
import moment from "moment"
import withRouter from "components/Common/withRouter"
import iconVideocall from "assets/images/icons/videocall.svg"
import iconApp from "assets/images/icons/app.svg"
import iconMedicine from "assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "assets/images/icons/groupvideo.png"
import iconWebpageAlt from "assets/images/icons/webIconAlt.svg"
import Lottie from "react-lottie"
import SimpleBar from "simplebar-react"
import ReleaseNotesList from "./release-notes-list"
import ReleaseNotesPreview from "./release-notes-preview"
import loadingAnim from "common/animations/loading-dots-blue"
import {
  deleteReleaseNote,
  getReleaseNotes,
  updateReleaseNote,
} from "helpers/database_helper"
import {
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { Switch, Popconfirm } from "antd"
import { showToast } from "utils/general"
import EmailSendModal from "../email-send-modal"

const ReleaseNotesIndex = props => {
  const [releaseNotes, setReleaseNotes] = useState([])
  const [guid, setGuid] = useState("")
  const [modalType, setModalType] = useState(1)
  const [selection, setSelection] = useState(null)
  const [targetFilter, setTargetFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("0")
  const [rowCount, setRowCount] = useState(100)
  const [productionDatabase, setProductionDatabase] = useState(true)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [count, setCount] = useState(0)
  const [example, setExample] = useState(null)
  const [subscribers, setSubscribers] = useState([])
  const [ongoingInsert, setOngoingInsert] = useState(true)
  // Setting preview
  const [preview, setPreview] = useState(null)
  const [contentLink, setContentLink] = useState()
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [initialValues, setInitialValues] = useState({
    id: 0,
    version: "",
    status: 1,
    type: 1,
    target: 1,
    published: false,
    informed_date: moment(),
    release_date: moment(),
    what_changes_fi: "",
    what_changes_en: "",
    what_changes_sv: "",
    how_effects_fi: "",
    how_effects_en: "",
    how_effects_sv: "",
    how_use_changes_fi: "",
    how_use_changes_en: "",
    how_use_changes_sv: "",
    changes_user_methods: false,
    changes_pro_ui: false,
    changes_enduser_ui: false,
  })

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    setSubscribers(props.subscribers)
  }, [props.subscribers])

  useEffect(() => {
    if (props.update > 0) {
      fetchReleases(true)
    }
  }, [props.update])

  // DB selection changed, clear everything
  useEffect(() => {
    setSelection(null)
    props.setSelection(null)
    setReleaseNotes([])
    setSelectedIndex(null)
  }, [productionDatabase])

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      version: initialValues.version,
      type: initialValues.type,
      status: initialValues.status,
      target: initialValues.target,
      published: initialValues.published,
      informed_date: initialValues.informed_date,
      release_date: initialValues.release_date,
      what_changes_fi: initialValues.what_changes_fi,
      what_changes_en: initialValues.what_changes_en,
      what_changes_sv: initialValues.what_changes_sv,
      how_use_changes_fi: initialValues.how_use_changes_fi,
      how_use_changes_en: initialValues.how_use_changes_en,
      how_use_changes_sv: initialValues.how_use_changes_sv,
      how_effects_fi: initialValues.how_effects_fi,
      how_effects_en: initialValues.how_effects_en,
      how_effects_sv: initialValues.how_effects_sv,
      changes_user_methods: initialValues.changes_user_methods,
      changes_pro_ui: initialValues.changes_pro_ui,
      changes_enduser_ui: initialValues.changes_enduser_ui,
    },
    validationSchema: Yup.object({
      version: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)
      if (values.id < 0) updateRelease(values)
    },
  })

  // Form values changed, update example
  useEffect(() => {
    handleExample(validation.values)
  }, [validation.values])

  // Get release notes
  const fetchReleases = (clearSelection = true) => {
    setCount(count + 1)
    setPreview(null)
    if (clearSelection) {
      setSelectedIndex(null)
      setSelection(null)
      props.setSelection(null)
    }

    // Set filters
    let filters = new Object()
    filters.include_unpublished = true
    const targetNum = parseInt(targetFilter, 10)
    if (targetNum > 0) filters.target = targetNum
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.status = statusNum

    setOngoingSearch(true)
    // console.log(abortRef.current, productionDatabase, rowCount, filters)
    getReleaseNotes(abortRef.current, productionDatabase, rowCount, filters)
      .then(notes => {
        notes.forEach(note => {
          note.label = props.t(getTargetName(note.target)) + " " + note.version
          note.value = note.id
        })
        setReleaseNotes(notes)
        // console.log("notes:", notes)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
      })
  }

  // Update release note
  const updateRelease = vals => {
    setOngoingInsert(true)
    updateReleaseNote(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchReleases(false)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Delete release note
  const deleteRelease = modalId => {
    let id = -1
    if (preview) {
      let item = releaseNotes.filter(el => el.guid == preview)
      // console.log(item)
      id = item[0].id
    } else {
      id = modalId ? modalId : selection.id
    }
    if (id >= 0) {
      setOngoingDelete(true)
      deleteReleaseNote(abortRef.current, productionDatabase, id)
        .then(result => {
          if (result == "OK") {
            showToast(props.t("Deleted"), "success")
            setSelection(null)
            props.setSelection(null)
            setSelectedIndex(null)
            fetchReleases(true)
          } else {
            console.log("Delete result: ", result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
        })
    } else {
      setSelection(null)
      props.setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Lottie options
  const animationLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }

  // Get matching image
  const getImage = id => {
    switch (id) {
      case 1:
        return iconApp
      case 2:
        return iconMedicine
      case 3:
        return iconVideocall
      case 4:
        return iconWebpageAlt
      case 5:
        return iconGroupvideo
      default:
        return null
    }
  }

  // handleChange from preview
  const handleChangePreview = value => {
    let item = releaseNotes.filter(el => el.guid == value)
    handleChange(item[0].id)
  }

  // Selected release changed
  const handleChange = newSelection => {
    // console.log("Option selected:", newSelection)
    var index = releaseNotes.findIndex(el => el.id === newSelection)
    if (index >= 0) {
      let copy = JSON.parse(JSON.stringify(releaseNotes[index]))
      copy.release_date = moment(copy.release_date)
      copy.informed_date = moment(copy.informed_date)
      setSelection(copy)
      props.setSelection(copy)
      setSelectedIndex(newSelection)
      setInitialValues(copy)
    } else {
      setSelection(null)
      props.setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Handle preview
  const handleExample = note => {
    let niceDate = moment(note.informed_date).format("DD.MM.YYYY")
    let temp = new Object()
    temp.id = note.id
    temp.date = niceDate
    temp.title = note.target + " v." + note.version

    if (note.release_date) {
      temp.releaseDate = moment(note.release_date).format("DD.MM.YYYY")
    }

    temp.content1_fi = note.what_changes_fi
      ? note.what_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_fi = note.how_use_changes_fi
      ? note.how_use_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_fi = note.how_effects_fi
      ? note.how_effects_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = note.what_changes_en
      ? note.what_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_en = note.how_use_changes_en
      ? note.how_use_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_en = note.how_effects_en
      ? note.how_effects_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = note.what_changes_sv
      ? note.what_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content2_sv = note.how_use_changes_sv
      ? note.how_use_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content3_sv = note.how_effects_sv
      ? note.how_effects_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""

    temp.changesProUser = note.changes_pro_ui == 1 ? "Yes" : "No"
    temp.changesEndUser = note.changes_enduser_ui == 1 ? "Yes" : "No"
    temp.changesProcedure = note.changes_user_methods == 1 ? "Yes" : "No"
    temp.type = note.type == 1 ? "Hotfix" : "Update"
    temp.version = note.version

    temp.target = getTargetName(parseInt(note.target, 10))
    temp.img = getImage(parseInt(note.target, 10))
    temp.status = parseInt(note.status, 10)

    switch (temp.status) {
      case 1:
        temp.status = "Upcoming"
        break
      case 2:
        temp.status = "Waiting for release"
        break
      case 3:
        temp.status = "Postponed"
        break
      case 4:
        temp.status = "Published"
        break
      default:
        temp.status = "Unknown"
        break
    }

    setExample(temp)
  }

  return (
    <>
      <EmailSendModal
        guid={guid}
        settingSelectedGuid={setGuid}
        modalType={modalType}
        subscribers={subscribers}
        contentLink={contentLink}
      ></EmailSendModal>
      <div className="email-bulletins-col-2">
        <Row className="">
          <Col sm="3">
            <FormGroup>
              <Input
                value={targetFilter}
                type="select"
                name="targetFilter"
                onChange={v => {
                  setTargetFilter(v.target.value)
                }}
              >
                <option value="0">{props.t("All services")}</option>
                <option value="1">{props.t("ReleaseNoteTypes.1")}</option>
                <option value="2">{props.t("ReleaseNoteTypes.2")}</option>
                <option value="3">{props.t("ReleaseNoteTypes.3")}</option>
                <option value="4">{props.t("ReleaseNoteTypes.4")}</option>
                <option value="5">{props.t("ReleaseNoteTypes.5")}</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup>
              <Input
                value={statusFilter}
                type="select"
                name="statusFilter"
                onChange={v => {
                  setStatusFilter(v.target.value)
                }}
              >
                <option value="0">{props.t("All statuses")}</option>
                <option value="1">{props.t("Upcoming")}</option>
                <option value="2">{props.t("Waiting for release")}</option>
                <option value="3">{props.t("Postponed")}</option>
                <option value="4">{props.t("Published")}</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Input
                id="number_limit"
                name="limit"
                type="number"
                onChange={v => {
                  if (v.target.value.length > 0)
                    setRowCount(parseInt(v.target.value, 10))
                }}
                defaultValue={rowCount}
              ></Input>
              <UncontrolledTooltip target="number_limit" placement="right">
                {props.t("Rows")}
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="mt-2">
              <Switch
                id="productionDatabase"
                className="me-2"
                name="productionDb"
                checked={productionDatabase}
                onChange={v => {
                  setProductionDatabase(v)
                }}
              ></Switch>

              <UncontrolledTooltip
                target="productionDatabase"
                placement="right"
              >
                {props.t("Production database")}
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col sm="2">
            <Button
              color="primary"
              disabled={ongoingSearch}
              onClick={() => fetchReleases(true)}
              className="mb-2 float-end px-3 d-flex"
            >
              <i className="bx bx-search-alt-2" />
              <div className="d-none d-md-block ms-2">{props.t("Search")}</div>
            </Button>
          </Col>
        </Row>
        <CardBody className="py-2">
          <Row>
            {ongoingSearch && (
              <Col className="d-inline-flex" style={{ alignItems: "center" }}>
                <Lottie options={animationLoading} height={36} width={100} />
              </Col>
            )}
            {!ongoingSearch && (
              <>
                {releaseNotes?.length > 0 && preview == null ? (
                  <>
                    <h5 className="mt-0 pt-0 pb-1">
                      {props.t("Release notes")}{" "}
                      <span className="pb-2 font-size-14 ms-1 text-muted">
                        {releaseNotes.length}
                      </span>
                    </h5>
                    <SimpleBar className="pe-3" style={{ maxHeight: "55vh" }}>
                      <div className="">
                        {releaseNotes.map(rn => (
                          <ReleaseNotesList
                            settingPreview={setPreview}
                            settingSelectedId={handleChange}
                            key={rn.id}
                            item={rn}
                            subscribers={subscribers}
                            settingSelectedGuid={setGuid}
                            deleteRelease={deleteRelease}
                            setDeleteConfirm={setDeleteConfirm}
                            modalType={modalType}
                            fetchReleases={fetchReleases}
                            settingContentLink={setContentLink}
                          ></ReleaseNotesList>
                        ))}
                      </div>
                    </SimpleBar>
                  </>
                ) : (
                  <>
                    {preview !== null ? (
                      <Row>
                        <Col className="col-auto d-flex flex-column">
                          <Button
                            id="back-btn-1"
                            color="primary"
                            className="btn-rounded btn btn-soft-primary mb-3"
                            onClick={() => setPreview(null)}
                          >
                            <i className="mdi mdi-arrow-left font-size-18"></i>
                          </Button>
                          {/* Set working
                           <Button
                            id="back-btn-5"
                            color="transparent"
                            className=" mb-2"
                            onClick={() => handleChangePreview(preview)}
                          >
                            <i className="bx bx-mail-send font-size-18"></i>
                          </Button> */}
                          <Button
                            id="back-btn-3"
                            color="transparent"
                            className=" mb-2"
                            onClick={() => handleChangePreview(preview)}
                          >
                            <i className="bx bx-edit-alt  font-size-18"></i>
                          </Button>
                          <a
                            id="back-btn-2"
                            type="button"
                            href={"/release-note/" + preview}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            <Button color="transparent" className="mb-2">
                              <i className="bx bx-link-external font-size-18"></i>
                            </Button>
                          </a>
                          <Popconfirm
                            title={props.t("Confirm action")}
                            open={deleteConfirm}
                            okType="danger"
                            okText={props.t("Delete")}
                            cancelText={props.t("Cancel")}
                            onConfirm={() => {
                              deleteRelease()
                              setDeleteConfirm(false)
                            }}
                            onCancel={() => {
                              setDeleteConfirm(false)
                            }}
                          >
                            <Button
                              id="back-btn-4"
                              color="transparent"
                              className=" mb-2"
                              onClick={() => {
                                setDeleteConfirm(true)
                              }}
                            >
                              <i className="bx bx-trash font-size-18"></i>
                            </Button>
                          </Popconfirm>
                          <UncontrolledTooltip
                            target="back-btn-1"
                            placement="right"
                          >
                            {props.t("Move back")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-2"
                            placement="right"
                          >
                            {props.t("Open public link")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-3"
                            placement="right"
                          >
                            {props.t("Modify release note")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-4"
                            placement="right"
                          >
                            {props.t("Delete release note")}
                          </UncontrolledTooltip>
                          {/* <UncontrolledTooltip
                            target="back-btn-5"
                            placement="right"
                          >
                            {props.t("Send release note")}
                          </UncontrolledTooltip> */}
                        </Col>
                        <Col>
                          <ReleaseNotesPreview
                            guid={preview}
                          ></ReleaseNotesPreview>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <div className="text-center">
                          <p className="pt-5 text-muted">
                            {count == 0 ? (
                              <>{props.t("Search for release notes")}</>
                            ) : (
                              <>{props.t("No release notes found")}</>
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        </CardBody>
      </div>
    </>
  )
}

ReleaseNotesIndex.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  releaseNotes: PropTypes.any,
  setSelection: PropTypes.any,
  fetchReleases: PropTypes.any,
  subscribers: PropTypes.any,
  handleSend: PropTypes.any,
}

export default withRouter(withTranslation()(ReleaseNotesIndex))
