import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import * as Yup from "yup"
import { useFormik } from "formik"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  generateAlarmCenterId,
  getClientsAlarmCenterSettings,
  updateClientsAlarmCenterSettings,
} from "helpers/backend_helper"
import { showToast } from "utils/general"
import GlobalState from "contexts/GlobalState"
import loadingAnim from "common/animations/loading-dots-blue"
import Lottie from "react-lottie"
import PubSub from "pubsub-js"

const AlarmCenterConfig = props => {
  const [state, setState] = useContext(GlobalState)
  const [role, setRole] = useState("")
  const [data, setData] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [alarmList, setAlarmList] = useState([])
  const [currentAlarms, setCurrentAlarms] = useState([])
  const [selectedAlarms, setSelectedAlarms] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setRole(role)
    }

    // Populate alarm array
    let alarms = []
    for (var i = 1; i < 100; i++) {
      let prop = "AlarmTitles." + i
      let trans = props.t(prop)
      if (trans != prop) {
        let entry = new Object()
        entry.id = i
        entry.name = trans
        entry.service = ""

        switch (i) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            entry.service = props.t("On the Go")
            break
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
          case 17:
          case 18:
          case 19:
          case 20:
          case 21:
          case 22:
          case 23:
          case 24:
          case 25:
          case 26:
            entry.service = props.t("Home")
            break
          case 27:
          case 28:
          case 29:
            entry.service = props.t("Dose reminder")
            break
          case 30:
          case 31:
            entry.service = props.t("Remote measurement")
            break
          case 32:
          case 33:
          case 34:
          case 39:
          case 48:
            entry.service = props.t("Video")
            break
          case 35:
          case 36:
          case 37:
          case 38:
          case 42:
          case 44:
          case 45:
          case 46:
          case 47:
            entry.service = props.t("Carephone")
            break
          case 51:
          case 52:
          case 53:
            entry.service = props.t("Smartlock")
            break
        }

        alarms.push(entry)
      }
    }
    alarms.sort((a, b) => (a.name > b.name ? 1 : -1))
    setAlarmList(alarms)

    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.suvantoKey) {
      getData()
    }
  }, [props.suvantoKey])

  // Get data
  const getData = () => {
    if (props.suvantoKey && !loading) {
      setLoading(true)
      getClientsAlarmCenterSettings(abortRef.current, props.suvantoKey)
        .then(result => {
          //console.log("DATA: ", result)
          if (result.statusCode == 200) {
            setData(result.data)

            let temp = []
            result.data.alarms.forEach(element => {
              let entry = new Object()
              entry.id = element
              entry.name = props.t("AlarmTitles." + element)
              temp.push(entry)
            })
            setCurrentAlarms(temp)
            setSelectedAlarms(result.data.alarms)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Generate alarm center ID
  const generateId = () => {
    if (props.suvantoKey && !generating) {
      setGenerating(true)
      generateAlarmCenterId(
        abortRef.current,
        props.suvantoKey,
        validation.values.alarmCenter
      )
        .then(result => {
          console.log("GEN: ", result)
          if (result.statusCode == 200 && result.alarmCenterId != null) {
            validation.setFieldValue("alarmCenterId", result.alarmCenterId)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setGenerating(false)
        })
    }
  }

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      alarmCenter: data?.alarmCenter,
      alarmCenterId: data?.alarmCenterId,
      ssn: data?.ssn,
      homeServiceInformation: data?.homeServiceInformation,
      doorInformation: data?.doorInformation,
      alarmCenterProcedure: data?.alarmCenterProcedure,
      alarmCenterNotices: data?.alarmCenterNotices,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      console.log("Selected alarms: ", selectedAlarms)

      let temp = JSON.parse(JSON.stringify(values))
      temp.alarms = selectedAlarms

      setUpdating(true)
      updateClientsAlarmCenterSettings(abortRef.current, props.suvantoKey, temp)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            setEditMode(false)
            getData()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
        })
    },
  })

  // If K2 alarm center selected, set default alarms
  useEffect(() => {
    if (editMode && validation.values.alarmCenter === "K2") {
      setSelectedAlarms([35, 36, 37, 38, 42, 47])
      showToast(props.t("Default alarms selected"), "info")
    }
  }, [validation.values.alarmCenter])

  // table columns
  const columns = [
    {
      dataField: "name",
      text: props.t("Alarm"),
      sort: true,
    },
    {
      dataField: "service",
      text: props.t("Service"),
      sort: true,
    },
  ]

  const columnsPreview = [
    {
      dataField: "name",
      text: props.t("Enabled alarms"),
      sort: true,
    },
  ]

  const pageOptions = {
    custom: true,
    sizePerPageList: [{ text: props.t("All"), value: alarmList.length }],
  }

  const defaultSorted = [
    {
      dataField: "service",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
    selected: selectedAlarms,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedAlarms(prevState => [...prevState, row.id])
      } else {
        setSelectedAlarms(prevState =>
          prevState.filter(prevItem => prevItem !== row.id)
        )
      }
    },
  }

  return (
    <>
      <div>
        {/* TODO: PÄIVITÄ NAPPULA */}
        {/** 
        <Button
          color="clear"
          className="p-0 text-primary"
          disabled={loading}
          onClick={() => {
            getData()
          }}
        >
          {props.t("Refresh")}
          <i className="mdi mdi-refresh ms-2" />
        </Button>
        */}
        {loading && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnim,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          />
        )}
        {data && !loading && (
          <>
            <div className="mt-1">
              <Row xs={1}>
                {/** Alarm center card */}
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <Row>
                          <Col>
                            <i className="bx bxs-info-circle text-truncate align-middle me-2"></i>
                            {props.t("Information") +
                              " & " +
                              props.t("Settings")}
                          </Col>

                          <Col className="col-auto">
                            {!editMode && (
                              <Button
                                color="primary"
                                size="sm"
                                className=""
                                onClick={() => {
                                  setEditMode(true)
                                }}
                              >
                                <i className="me-2 bx bxs-edit text-truncate align-middle"></i>
                                <span className="">{props.t("Edit")}</span>
                              </Button>
                            )}
                            {editMode && (
                              <>
                                <Button
                                  color="primary"
                                  size="sm"
                                  className=""
                                  onClick={() => {
                                    setEditMode(false)
                                    validation.resetForm()
                                    setSelectedAlarms(data.alarms)
                                  }}
                                >
                                  <i className="me-2 bx bxs-x-square text-truncate align-middle"></i>
                                  <span className="">{props.t("Cancel")}</span>
                                </Button>
                                <Button
                                  color="success"
                                  size="sm"
                                  disabled={updating}
                                  className="ms-3"
                                  onClick={() => {
                                    validation.submitForm()
                                  }}
                                >
                                  <i className="me-2 bx bxs-save text-truncate align-middle"></i>
                                  <span className="">
                                    {props.t("Save changes")}
                                  </span>
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardTitle>

                      {!editMode && (
                        <>
                          <Row>
                            <Col>
                              <Row xs={1} sm={2} className="mt-3">
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Alarm center")}:</h6>{" "}
                                    {data?.alarmCenter
                                      ? data.alarmCenter
                                      : props.t("Undefined")}
                                  </div>
                                </Col>
                                {role === "system_admin" && (
                                  <>
                                    <Col>
                                      <div className="text-muted mb-4">
                                        <h6>{props.t("Alarm center ID")}:</h6>{" "}
                                        {data?.alarmCenterId
                                          ? data.alarmCenterId
                                          : props.t("Undefined")}
                                      </div>
                                    </Col>
                                  </>
                                )}
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("SSN")}:</h6>{" "}
                                    {data?.ssn
                                      ? data.ssn
                                      : props.t("Undefined")}
                                  </div>
                                </Col>
                              </Row>
                              <Row xs={1}>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Notices")}:</h6>
                                    <Input
                                      name="alarmCenterNotices"
                                      type="textarea"
                                      rows={5}
                                      disabled
                                      value={data?.alarmCenterNotices}
                                    ></Input>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Home help service")}:</h6>
                                    <Input
                                      name="homeServiceInformation"
                                      type="textarea"
                                      rows={5}
                                      disabled
                                      value={data?.homeServiceInformation}
                                    ></Input>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Opening the door")}:</h6>
                                    <Input
                                      name="doorInfo"
                                      type="textarea"
                                      rows={5}
                                      disabled
                                      value={data?.doorInformation}
                                    ></Input>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-muted mb-4">
                                    <h6>{props.t("Procedure")}:</h6>
                                    <Input
                                      name="alarmCenterProcedure"
                                      type="textarea"
                                      rows={5}
                                      disabled
                                      value={data?.alarmCenterProcedure}
                                    ></Input>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <SimpleBar style={{ maxHeight: "580px" }}>
                                <BootstrapTable
                                  keyField={"id"}
                                  columns={columnsPreview}
                                  data={currentAlarms}
                                  bordered={true}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-start table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                />
                              </SimpleBar>
                            </Col>
                          </Row>
                        </>
                      )}

                      {editMode && (
                        <>
                          <Row>
                            <Col xs={6}>
                              <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <Row xs={1} lg={2}>
                                  {role === "system_admin" && (
                                    <>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Alarm center")}
                                          </Label>
                                          <Input
                                            bsSize={"sm"}
                                            name="alarmCenter"
                                            type="select"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.alarmCenter ||
                                              ""
                                            }
                                          >
                                            <option value="">
                                              {props.t("None")}
                                            </option>
                                            <option value="Test">
                                              {props.t("Test")}
                                            </option>
                                            <option value="K2">{"K2"}</option>
                                            <option value="PNC">
                                              {"PNC AddSecure"}
                                            </option>
                                            <option value="PNC_Essote">
                                              {"PNC Essote"}
                                            </option>
                                            <option value="Avarn">
                                              {"Avarn"}
                                            </option>
                                            <option value="Verifi">
                                              {"Verifi"}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Alarm center ID")}
                                          </Label>
                                          <Row>
                                            <Col>
                                              <Input
                                                bsSize={"sm"}
                                                name="alarmCenterId"
                                                type="text"
                                                onChange={
                                                  validation.handleChange
                                                }
                                                onBlur={validation.handleBlur}
                                                value={
                                                  validation.values
                                                    .alarmCenterId || ""
                                                }
                                              ></Input>
                                            </Col>
                                            <Col className="col-auto">
                                              <Button
                                                id="generate-button"
                                                disabled={
                                                  generating ||
                                                  (validation.values
                                                    .alarmCenter != "PNC" &&
                                                    validation.values
                                                      .alarmCenter !=
                                                      "PNC_Essote")
                                                }
                                                className="p-0"
                                                color="transparent"
                                                outline
                                                size="sm"
                                                onClick={() => {
                                                  generateId()
                                                }}
                                              >
                                                <UncontrolledTooltip
                                                  placement="bottom"
                                                  target="generate-button"
                                                >
                                                  {props.t("Generate ID")}
                                                </UncontrolledTooltip>
                                                <i className="mdi mdi-shield-refresh text-primary font-size-18"></i>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                      </Col>
                                    </>
                                  )}
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("SSN")}</Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="ssn"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ssn || ""}
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row xs={1}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Notices")}</Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="alarmCenterNotices"
                                        type="textarea"
                                        rows={5}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .alarmCenterNotices || ""
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>
                                        {props.t("Home help service")}
                                      </Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="homeServiceInformation"
                                        type="textarea"
                                        rows={5}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .homeServiceInformation || ""
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>
                                        {props.t("Opening the door")}
                                      </Label>
                                      <Input
                                        name="doorInformation"
                                        type="textarea"
                                        rows={5}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.doorInformation ||
                                          ""
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Procedure")}</Label>
                                      <Input
                                        bsSize={"sm"}
                                        name="alarmCenterProcedure"
                                        type="textarea"
                                        rows={5}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values
                                            .alarmCenterProcedure || ""
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                            <Col xs={6}>
                              <SimpleBar style={{ maxHeight: "580px" }}>
                                <PaginationProvider
                                  pagination={paginationFactory(pageOptions)}
                                  keyField="id"
                                  columns={columns}
                                  data={alarmList}
                                >
                                  {({ paginationTableProps }) => (
                                    <ToolkitProvider
                                      keyField="id"
                                      columns={columns}
                                      data={alarmList}
                                    >
                                      {toolkitProps => (
                                        <React.Fragment>
                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive table-scroll-window">
                                                <BootstrapTable
                                                  keyField={"id"}
                                                  responsive
                                                  bordered={true}
                                                  striped={true}
                                                  //pagination={sizePerPageList}
                                                  defaultSorted={defaultSorted}
                                                  selectRow={selectRow}
                                                  classes={
                                                    "table align-start table-nowrap"
                                                  }
                                                  headerWrapperClasses={
                                                    "thead-light"
                                                  }
                                                  {...toolkitProps.baseProps}
                                                  {...paginationTableProps}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      )}
                                    </ToolkitProvider>
                                  )}
                                </PaginationProvider>
                              </SimpleBar>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>

                {/** Security SMS card */}
                {/** 
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Security SMS configurations")}</CardTitle>
                    <CardBody>
                      
                    </CardBody>
                  </CardBody>
                </Card>
              </Col>*/}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  )
}

AlarmCenterConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
}

export default withTranslation()(AlarmCenterConfig)
