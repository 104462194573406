import PropTypes from "prop-types"
import React, { useEffect, useState  } from "react"
import {
  Col,
  Row,
  CardHeader,
} from "reactstrap"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { Avatar } from "antd"

import "./custom-styles.scss"

import pindoraImage from "../../assets/images/devices/pindora.jpeg"
import videoImage from "../../assets/images/devices/video-tablet.jpg"
import trackerImage from "../../assets/images/devices/minifinder-pico.png"
import carephoneImage from "../../assets/images/devices/carephone.png"

const StatusCardHeader = props => {

  const [statusText, setStatusText] = useState("Unknown")
  const [imageSrc, setImageSrc] = useState(null)

  const [greenStatus] = useState({backgroundColor: "#00a352", borderRadius: "40px 0"})
  const [yellowStatus] = useState({backgroundColor: "#f89000", borderRadius: "40px 0"})
  const [redStatus] = useState({backgroundColor: "#eb4f43", borderRadius: "40px 0"})
  const [statusStyle, setStatusStyle] = useState(greenStatus)

  useEffect(() => {
    // Smartlock
    if (props.service == "smartlock") {
      setImageSrc(pindoraImage);
      switch(props.status) {
        case "online": 
          setStatusText("Online")
          setStatusStyle(greenStatus)
          break;
        case "offline": 
          setStatusText("Offline")
          setStatusStyle(redStatus)
          break;
        case "charging": 
          setStatusText("Charging")
          setStatusStyle(greenStatus)
          break;
        default: 
          setStatusText("Unknown device status")
          setStatusStyle(yellowStatus)
          break;
      }
    }
    // Video
    else if (props.service == "video") {
      setImageSrc(videoImage);
      switch(props.status) {
        case "charging": 
          setStatusText("Charging")
          setStatusStyle(greenStatus)
          break;
        case "discharging": 
          setStatusText("Discharging")
          setStatusStyle(yellowStatus)
          break;
        case "shutdown": 
          setStatusText("Shutdown")
          setStatusStyle(redStatus)
          break;
        default: 
          setStatusText("Unknown device status")
          setStatusStyle(yellowStatus)
          break;
      }
    }
    else if (props.service == "tracker") {
      setImageSrc(trackerImage)
      switch(props.status) {
        case 'lowbat': 
          setStatusText("Low battery")
          setStatusStyle(yellowStatus)
          break;
        case 'off': 
          setStatusText("Shutdown")
          setStatusStyle(redStatus)
          break;
        case 'charging': 
          setStatusText("Charging")
          setStatusStyle(greenStatus)
          break;
        case 'idle': 
          setStatusText("Device on")
          setStatusStyle(greenStatus)
          break;
        default: 
          setStatusText("Unknown device status")
          setStatusStyle(yellowStatus)
          break;
      }
    }
    else if (props.service == "carephone") {
      setImageSrc(carephoneImage)
      setStatusText(props.status)
      switch(props.status) {
        case "Online": 
          setStatusStyle(greenStatus)
          break;
        case "Offline": 
          setStatusStyle(redStatus)
          break;
        default: 
          setStatusStyle(yellowStatus)
          break;
      }
    }
  }, [props.service, props.status])

  return (
    <>
      <CardHeader className="py-3" style={statusStyle}>
        <Row>
          <Col>
            <div className="d-flex" style={{backgroundColor: "rgba(255,255,255, 0.15)", borderRadius: "100px", height: "64px"}}>
              <Avatar className="zoom d-none d-md-block" size={64} src={imageSrc} shape="circle" style={{backgroundColor: "#fff"}}/>
              <div className="d-flex mx-4 col" style={{alignItems: "center"}}>
                <h2 className="m-0" style={{color: "white"}}>{props.t(statusText)}</h2>
              </div>
              <div className="d-flex col-auto me-4" style={{alignItems: "center"}}>
                <span className="font-size-16" style={{color: "white"}}>{moment(props.date).format("DD.MM.YYYY HH:mm:ss")}</span>
              </div>
            </div>
          </Col>
          {props.battery != null &&
            <Col className="col-auto">
              <div style={{ width: 64, height: 64 }}>
                <CircularProgressbar value={props.battery} text={props.battery + "%"}
                  strokeWidth={12}
                  styles={buildStyles({
                    textSize: '22px',
                    pathColor: '#fff',
                    textColor: '#fff',
                    trailColor: '#d0d0d0',
                  })}
                />
              </div>
            </Col>
          }
        </Row>
      </CardHeader>
    </>
  )

}

StatusCardHeader.propTypes = {
  t: PropTypes.any,
  service: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.any,
  battery: PropTypes.number
}

export default withTranslation()(StatusCardHeader)
