// Landing page -content
import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const Video = props => {
  return (
    <div>
      <p className="mt-2">
        {props.t(
          "On this page, you can see the support service's contact information and current announcements."
        )}
      </p>
      <p>
        {props.t(
          "You can navigate to other services from the links in the top bar."
        )}
      </p>
    </div>
  )
}
Video.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Video)
