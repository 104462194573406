import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  getHealthMeasurements,
  addManualMeasurement,
} from "helpers/backend_helper"
import { showToast } from "utils/general"
import moment from "moment"
import {
  Alert,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Modal,
  Button,
  Input,
  NavItem,
  NavLink,
  Row,
  FormGroup,
  TabContent,
  TabPane,
  ModalFooter,
  ModalBody,
  UncontrolledTooltip,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"
import MeasureChart from "./components/measurement-chart"
import MeasurementList from "./components/measurement-list"
import classnames from "classnames"
const { RangePicker } = DatePicker
//  antd
import { Switch, DatePicker, InputNumber, ConfigProvider } from "antd"
// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

const MeasurementServices = props => {
  const [updating, setUpdating] = useState(false)
  const [measures, setMeasures] = useState([])

  const [journalKey, setJournalKey] = useState()
  const [refresh, setRefresh] = useState(true)
  // New entry modal
  const [modalMessage, setModalMessage] = useState(false)

  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  // ActiveTab
  const [activeTab, setActiveTab] = useState("1")

  // Search button
  const [ongoingSearch, setOngoingSearch] = useState(false)

  // Date
  const [defaultStartDate, setDefaulStartDate] = useState(
    moment(moment().startOf("day")).add(-14, "days")
  )
  const [defaultEndDate, setDefaultEndDate] = useState(moment())
  const [start, setStart] = useState(defaultStartDate)
  const [end, setEnd] = useState(defaultEndDate)

  // Types of measurements
  const [bloodPressure, setBloodPressure] = useState([])
  const [weight, setWeight] = useState([])
  const [pef, setPef] = useState([])
  const [bgluk, setBgluk] = useState([])
  const [temp, setTemp] = useState([])
  const [spo2, setSpo2] = useState([])
  // Types of measurements list
  const [bloodPressureList, setBloodPressureList] = useState([])
  const [weightList, setWeightList] = useState([])
  const [pefList, setPefList] = useState([])
  const [bglukList, setBglukList] = useState([])
  const [tempList, setTempList] = useState([])
  const [spo2List, setSpo2List] = useState([])
  // Length
  const [bloodPressureL, setBloodPressureL] = useState(0)
  const [weightL, setWeightL] = useState(0)
  const [pefL, setPefL] = useState(0)
  const [bglukL, setBglukL] = useState(0)
  const [tempL, setTempL] = useState(0)
  const [spo2L, setSpo2L] = useState(0)
  // Combined bp
  const [bp, setBp] = useState([])

  // Measurement entry
  const [newType, setNewType] = useState("bloodPressure")
  const [newDate, setNewDate] = useState(moment())
  const [newValue1, setNewValue1] = useState(120)
  const [newValue2, setNewValue2] = useState(80)
  const [newUnit, setNewUnit] = useState("mmHg")
  const [newTitle, setNewTitle] = useState("Systolic and diastolic pressure")
  const [newMin, setNewMin] = useState(0)
  const [newMax, setNewMax] = useState(300)

  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    getMeasures()
    return () => abortRef.current.abort()
  }, [])

  // Get measurements from server
  const getMeasures = newEnd => {
    if (props.suvantoKey && !updating) {
      setUpdating(true)
      /** TYPE LIST - empty or null to get all types
       * bloodSugar
       * perfusion
       * oxygenSaturation
       * temperature
       * diastolicBloodPressure
       * systolicBloodPressure
       * pulse
       * weight
       * peakExpiratoryFlow
       * FEV1
       */
      let endTime = moment()
      if (newEnd !== undefined) {
        endTime = newEnd
      }
      if (newEnd == undefined) {
        endTime = end
      }

      getHealthMeasurements(
        abortRef.current,
        props.suvantoKey,
        "",
        start,
        endTime
      )
        .then(result => {
          console.log("Measures: ", result)
          if (result.statusCode == 200 && result.entries) {
            result.entries.sort((a, b) => (a.date < b.date ? 1 : -1))
            setMeasures(result.entries)
            settingCategories(result.entries)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
          setOngoingSearch(false)
        })
    }
  }
  useEffect(() => {
    if (props.suvantoKey !== undefined) {
      setJournalKey(props.suvantoKey)
    }
  }, [props.suvantoKey])

  const foratDate0 = v => {
    let date = ""
    date = moment(v.date).format("DD.MM.YYYY HH:mm:ss")
    console.log(date)
    return date
  }

  const settingCategories = data => {
    setBloodPressure([])
    setBgluk([])
    setPef([])
    setSpo2([])
    setTemp([])
    setWeight([])

    if (data.length != 0) {
      // RR+p
      let bpp = data.filter(
        d =>
          d.measureType === "systolicBloodPressure" ||
          d.measureType === "diastolicBloodPressure" ||
          d.measureType === "pulse"
      )
      setBp(bpp)
      let bpMeasure = bpp
      let res = Object.values(
        bpMeasure.reduce((acc, cur) => {
          if (!acc[cur.date]) acc[cur.date] = { date: cur.date, data: [] }
          acc[cur.date].data.push(cur)
          return acc
        }, {})
      )
      setBloodPressureList(res)
      setBp(res)
      setBloodPressureL(res.length)
      let bppLength = data.filter(d => d.deviceType === "bloodPressure").length

      let sys = data.filter(
        d => d.measureType === "systolicBloodPressure"
      ).length
      let dia = data.filter(
        d => d.measureType === "diastolicBloodPressure"
      ).length

      // Temperature
      let tempLength = data.filter(d => d.measureType === "temperature").length
      setTempL(tempLength)
      // Weight
      let scaleLength = data.filter(d => d.measureType === "weight").length
      setWeightL(scaleLength)
      // SpO2
      let oxiLength = data.filter(
        d => d.measureType == "oxygenSaturation"
      ).length
      setSpo2L(oxiLength)
      // B-gluk
      let glucLength = data.filter(d => d.measureType === "bloodSugar").length
      setBglukL(glucLength)
      // PEF
      let pefLength = data.filter(
        d => d.measureType === "peakExpiratoryFlow"
      ).length
      setPefL(pefLength)

      // Formatting datas
      if (bppLength > 0) {
        let systolic = data.filter(
          d => d.measureType === "systolicBloodPressure"
        )
        let datas1 = systolic.map(item => [item.date, item.value])

        let diastolic = data.filter(
          d => d.measureType === "diastolicBloodPressure"
        )
        let datas2 = diastolic.map(item => [item.date, item.value])
        let pulse = data.filter(d => d.measureType == "pulse")
        let datas3 = pulse.map(item => [item.date, item.value])

        let set1 = [
          {
            name: props.t("Systolic"),
            data: datas1,
            type: setDiagramType(sys),
          },
        ]
        let set2 = [
          {
            name: props.t("Diastolic"),
            type: setDiagramType(dia),
            data: datas2,
          },
        ]
        let set3 = [
          {
            name: props.t("Pulse"),
            type: "line",
            data: datas3,
          },
        ]
        setBloodPressure([...set1, ...set2, ...set3])
      }
      if (tempLength > 0) {
        let temp = data.filter(d => d.measureType == "temperature")
        setTempList(temp)

        let datas6 = temp.map(item => [item.date, item.value])
        setTemp([
          {
            name: props.t("Temperature"),
            data: datas6,
            type: setDiagramType(tempLength),
          },
        ])
      }
      if (scaleLength > 0) {
        let weight = data.filter(d => d.measureType == "weight")
        setWeightList(weight)

        let datas4 = weight.map(item => [item.date, item.value])
        setWeight([
          {
            name: props.t("Weight"),
            data: datas4,
            type: setDiagramType(scaleLength),
          },
        ])
      }
      if (oxiLength > 0) {
        let spo2 = data.filter(d => d.measureType == "oxygenSaturation")
        setSpo2List(spo2)

        let datas5 = spo2.map(item => [item.date, item.value])
        setSpo2([
          {
            name: props.t("Oxygen saturation"),
            data: datas5,
            type: setDiagramType(oxiLength),
          },
        ])
      }
      if (glucLength > 0) {
        let bgluk = data.filter(d => d.measureType == "bloodSugar")
        setBglukList(bgluk)

        let datas3 = bgluk.map(item => [item.date, item.value])
        setBgluk([
          {
            name: props.t("Blood sugar"),
            data: datas3,
            type: setDiagramType(glucLength),
          },
        ])
      }
      if (pefLength > 0) {
        let pef = data.filter(d => d.measureType == "peakExpiratoryFlow")
        setPefList(pef)

        let datas7 = pef.map(item => [item.date, item.value])
        setPef([
          {
            name: props.t("Peak expiratoty flow"),
            data: datas7,
            type: setDiagramType(pefLength),
          },
        ])
      }
    }
  }

  const setDiagramType = t => {
    if (t > 2) {
      return "area"
    } else {
      return "line"
    }
  }

  const onDateSelection = value => {
    if (value != null) {
      let start = new Date(value[0])
      let startUnix = start.getTime()

      let end = new Date(value[1])
      let endUnix = end.getTime()

      setStart(startUnix)
      setEnd(endUnix)
    }
  }

  const updateLog = () => {
    setOngoingSearch(true)
    getMeasures()
  }

  const load = (
    <div className="text-center py-5 my-3">
      <Link to="#" className="text-primary">
        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        {props.t("Loading")}
      </Link>
    </div>
  )

  // DatePicker language
  //DatePicker language update
  const getLanguage = () => {
    var language = localStorage.getItem("i18nextLng")
    if (language.indexOf("-") > -1) language = language.split("-")[0]
    switch (language) {
      case "en":
        return en
      case "fi":
        return fin
      case "sv":
        return swe
      default:
        return null
    }
  }
  const locale = getLanguage()

  const rangeButtons = {
    [props.t("day")]: [
      moment(moment().startOf("day")).add(-1, "days"),
      moment(),
    ],
    [props.t("7 days")]: [
      moment(moment().startOf("day")).add(-1, "weeks"),
      moment(),
    ],
    [props.t("14 days")]: [
      moment(moment().startOf("day")).add(-2, "weeks"),
      moment(),
    ],
    [props.t("month")]: [
      moment(moment().startOf("day")).add(-1, "months"),
      moment(),
    ],
    [props.t("year")]: [
      moment(moment().startOf("day")).add(-1, "years"),
      moment(),
    ],
  }
  // User can not pick a date further than end of the day
  const disabledDate = current => {
    const from = moment().subtract(moment(), "days")
    return (
      current &&
      (current > moment().endOf("day") || current < from.startOf("day"))
    )
  }

  //  ** START OF MODAL FUNCTIONS ** //

  const settingRefresh = () => {
    setRefresh(!refresh)
  }

  const toggleTab = t => {
    setActiveTab(t)

    switch (t) {
      case "1":
        handleChangeType("bloodPressure")
        break
      case "2":
        handleChangeType("bloodSugar")
        break
      case "3":
        handleChangeType("weight")
        break
      case "4":
        handleChangeType("oxygenSaturation")
        break
      case "5":
        handleChangeType("temperature")
        break
      case "6":
        handleChangeType("peakExpiratoryFlow")
        break
    }
  }

  const settingDate = e => {
    let date = e.valueOf()
    setNewDate(date)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const openMessageModal = () => {
    removeBodyCss()
    setModalMessage(!modalMessage)
  }

  // Manual entry
  const handleChangeType = e => {
    setNewType(e)
    if (e == "bloodPressure") {
      setNewUnit("mmHg")
      setValue1(120)
      setValue2(80)
      setNewTitle("Systolic and diastolic pressure")
      setNewMin(0)
      setNewMax(300)
    }
    if (e == "oxygenSaturation") {
      setNewUnit("%")
      setValue1(100)
      setNewValue2(null)
      setNewTitle("Oxygen saturation")
      setNewMin(0)
      setNewMax(100)
    }
    if (e == "temperature") {
      setNewUnit("°C")
      setValue1(37)
      setNewValue2(null)
      setNewTitle("Temperature")
      setNewMin(0)
      setNewMax(50)
    }
    if (e == "bloodSugar") {
      setNewUnit("mmol/l")
      setValue1(6)
      setNewValue2(null)
      setNewTitle("Blood sugar")
      setNewMin(0)
      setNewMax(50)
    }
    if (e == "weight") {
      setNewUnit("kg")
      setValue1(70)
      setNewValue2(null)
      setNewTitle("Weight")
      setNewMin(0)
      setNewMax(300)
    }
    if (e == "peakExpiratoryFlow") {
      setNewUnit("l/min")
      setValue1(500)
      setNewValue2(null)
      setNewTitle("PEF")
      setNewMin(0)
      setNewMax(1000)
    }
    if (e == "pulse") {
      setNewUnit("bpm")
      setValue1(80)
      setNewValue2(null)
      setNewTitle("Pulse")
      setNewMin(0)
      setNewMax(300)
    }
  }
  const setValue1 = e => {
    setNewValue1(e)
  }
  const setValue2 = e => {
    setNewValue2(e)
  }
  // Reset messages when modal closed
  useEffect(() => {
    setSuccessMessage(null)
    setErrorMessage(null)
  }, [modalMessage])

  // New manual measurement
  const validationMeasurement = useFormik({
    enableReinitialize: true,
    initialValues: {
      newValue1: newValue1,
      newValue2: newValue2,
    },
    validationSchema: Yup.object({
      newValue1: Yup.number().required(props.t("Please enter measurement")),
      newValue2:
        Yup.object().nullable(true) ||
        Yup.object().required(props.t("Please enter measurement")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      // set entry measurement
      addManualMeasurement(
        abortRef.current,
        journalKey,
        newType,
        newDate,
        values.newValue1,
        values.newValue2,
        newUnit
      )
        .then(result => {
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccessMessage("Success")
            openMessageModal()
            if (refresh == true) {
              console.log("refresh", refresh)
              setTimeout(() => {
                setDefaultEndDate(moment())
                getMeasures(moment())
              }, 2000)
            }
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })

  // User can not pick a date further moment
  const disabledDateModal = current => {
    return current && current > moment().startOf("minutes")
  }
  //*** END OF MODAL FUNCTIONS ***//

  return (
    <>
      <div>
        <Card className="mt-1">
          <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                {props.t("Blood pressure and pulse")}
                {bloodPressureL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {bloodPressureL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                {props.t("Blood sugar")}
                {bglukL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {bglukL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                {props.t("Weight")}
                {weightL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">
                    {weightL}
                  </span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "4",
                })}
                onClick={() => {
                  toggleTab("4")
                }}
              >
                {props.t("Oxygen saturation")}
                {spo2L !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{spo2L}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "5",
                })}
                onClick={() => {
                  toggleTab("5")
                }}
              >
                {props.t("Temperature")}
                {tempL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{tempL}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "6",
                })}
                onClick={() => {
                  toggleTab("6")
                }}
              >
                {props.t("PEF")}
                {pefL !== 0 ? (
                  <span className="badge badge-soft-primary ms-1">{pefL}</span>
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
          </ul>
          <CardBody>
            <div className="ms-2">
              <ConfigProvider locale={locale}>
                <RangePicker
                  defaultValue={[defaultStartDate, defaultEndDate]}
                  disabledDate={disabledDate}
                  allowClear={false}
                  onChange={onDateSelection}
                  format="DD.MM.YYYY"
                  ranges={{ ...rangeButtons }}
                />
              </ConfigProvider>
              <span>
                <Button
                  className="ms-2"
                  color="primary"
                  disabled={ongoingSearch}
                  onClick={() => {
                    updateLog()
                  }}
                >
                  <i className="bx bx-search" />
                  <span className="d-none d-sm-inline-block ms-2">
                    {props.t("Search")}
                  </span>
                </Button>
              </span>
              <span className="float-end">
                <Button
                  type="button"
                  color="success"
                  onClick={() => openMessageModal()}
                  className="btn btn-primary mb-2 "
                >
                  <i className="mdi mdi-clipboard-plus-outline" />
                  <span className="d-none d-sm-inline-block ms-2">
                    {props.t("Add measurement")}
                  </span>
                </Button>
              </span>
            </div>
          </CardBody>

          {/* Save remote measurement */}
          <Modal
            isOpen={modalMessage}
            toggle={() => {
              openMessageModal()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {props.t("Add entry")}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalMessage(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <div>
                  <div className="mt-2">
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <h6>{props.t("Measurement type")}</h6>
                          <Input
                            name="type"
                            type="select"
                            defaultValue={newType}
                            onChange={e => handleChangeType(e.target.value)}
                          >
                            <option value={"bloodPressure"}>
                              {props.t("Blood pressure")}
                            </option>
                            <option value={"pulse"}>{props.t("Pulse")}</option>
                            <option value={"oxygenSaturation"}>
                              {props.t("Oxygen saturation")}
                            </option>
                            <option value={"temperature"}>
                              {props.t("Temperature")}
                            </option>
                            <option value={"bloodSugar"}>
                              {props.t("Blood sugar")}
                            </option>
                            <option value={"weight"}>
                              {props.t("Weight")}
                            </option>
                            <option value={"peakExpiratoryFlow"}>
                              {props.t("PEF (Peak expiratory flow)")}
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <h6>{props.t("Time of measurement")}</h6>
                        {/* <ConfigProvider locale={locale}> */}
                        <DatePicker
                          showTime={true}
                          defaultValue={moment(newDate)}
                          format="DD.MM.YYYY HH:mm"
                          style={{ height: "36.5px" }}
                          onChange={settingDate}
                          disabledDate={disabledDateModal}
                          // disabledDate={moment().startOf("minutes")}
                        />
                        {/* </ConfigProvider> */}
                      </Col>
                    </Row>
                  </div>
                  {/* Setting measurements */}
                  {errorMessage ? (
                    <Alert color="danger">{props.t(errorMessage)}</Alert>
                  ) : null}
                  {successMessage ? (
                    <Alert color="success">{props.t(successMessage)}</Alert>
                  ) : null}
                  {newType != "" && (
                    <div className="mt-2">
                      <h6>{props.t(newTitle)}</h6>
                      <div className="d-flex">
                        <InputNumber
                          step={
                            newType == "bloodSugar" ||
                            newType == "weight" ||
                            newType == "temperature"
                              ? 0.1
                              : 1
                          }
                          type="number"
                          name="newValue1"
                          defaultValue={newValue1}
                          style={{ width: "70px" }}
                          size="small"
                          min={newMin}
                          max={newMax}
                          onChange={setValue1}
                          onBlur={validationMeasurement.handleBlur}
                          value={validationMeasurement.values.newValue1 || ""}
                          invalid={
                            validationMeasurement.touched.newValue1 &&
                            validationMeasurement.errors.newValue1
                              ? true
                              : false
                          }
                        ></InputNumber>
                        {validationMeasurement.touched.newValue1 &&
                        validationMeasurement.errors.newValue1 ? (
                          <FormFeedback type="invalid">
                            {validationMeasurement.errors.newValue1}
                          </FormFeedback>
                        ) : null}
                        {newType == "bloodPressure" ? (
                          <>
                            <span className="ms-2 me-2 font-size-16 text-muted">
                              /
                            </span>
                            <InputNumber
                              name="newValue2"
                              defaultValue={newValue2}
                              style={{ width: "70px" }}
                              size="small"
                              min={newMin}
                              max={newMax}
                              onChange={setValue2}
                              onBlur={validationMeasurement.handleBlur}
                              value={
                                validationMeasurement.values.newValue2 || ""
                              }
                              invalid={
                                validationMeasurement.touched.newValue2 &&
                                validationMeasurement.errors.newValue2
                                  ? true
                                  : false
                              }
                            ></InputNumber>
                            {validationMeasurement.touched.newValue2 &&
                            validationMeasurement.errors.newValue2 ? (
                              <FormFeedback type="invalid">
                                {validationMeasurement.errors.newValue2}
                              </FormFeedback>
                            ) : null}
                          </>
                        ) : null}

                        <span className="mt-1 text-muted ms-2">{newUnit}</span>
                      </div>
                    </div>
                  )}
                </div>{" "}
              </ModalBody>
              <ModalFooter className="p-2 mt-4">
                <Col lg={12} className="mt-2 mb-0 text-truncate">
                  <Row>
                    <Col xs={6}>
                      <FormGroup className="ms-2 mt-2">
                        <Switch
                          name="refresh-switch"
                          className="me-1 ms-1"
                          id="refresh-button"
                          checked={refresh}
                          onChange={settingRefresh}
                          checkedChildren={
                            <i className="mdi mdi mdi-sync align-middle font-size-14" />
                          }
                          unCheckedChildren={
                            <i className="mdi mdi-sync-off align-middle font-size-14" />
                          }
                        ></Switch>
                      </FormGroup>
                      <UncontrolledTooltip
                        placement="right"
                        target="refresh-button"
                      >
                        {props.t("Refresh entries on save")}
                      </UncontrolledTooltip>
                    </Col>
                    <Col xs={6}>
                      <span className="float-end">
                        <button
                          type="button"
                          onClick={() => {
                            openMessageModal()
                          }}
                          className="btn btn-outline-primary "
                          data-dismiss="modal"
                        >
                          {props.t("Cancel")}
                        </button>

                        <button
                          disabled={saveDisable}
                          className="btn btn-primary ms-2"
                          onClick={() => {
                            validationMeasurement.submitForm()
                          }}
                        >
                          {props.t("Save entry")}
                        </button>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </ModalFooter>
            </Form>
          </Modal>

          <TabContent className="py-0 px-3" activeTab={activeTab}>
            <TabPane tabId="1">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {bloodPressure.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={bloodPressure}
                            type={activeTab}
                            tooltip={""}
                            max={250}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList
                            bloodPressure={bloodPressureList}
                            type={activeTab}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No blood pressure or pulse measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="2">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {bgluk.length !== 0 ? (
                    <div>
                      <Row className="">
                        <Col lg={8}>
                          <MeasureChart
                            data={bgluk}
                            type={activeTab}
                            tooltip={"mmol/l"}
                            max={20}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList data={bglukList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5  text-center">
                      {props.t("No blood sugar measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="3">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {weight != [] && weight.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={weight}
                            type={activeTab}
                            max={120}
                            tooltip={"kg"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList data={weightList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5  text-center">
                      {props.t("No weight measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="4">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {spo2.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={spo2}
                            type={activeTab}
                            max={100}
                            tooltip={"%"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList data={spo2List} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No oxygen saturation measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="5">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {temp.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={temp}
                            type={activeTab}
                            max={50}
                            tooltip={"°C"}
                            start={start}
                            end={end}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList data={tempList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No temperature measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="6">
              {updating ? (
                <>{load}</>
              ) : (
                <>
                  {pef.length !== 0 ? (
                    <div>
                      <Row>
                        <Col lg={8}>
                          <MeasureChart
                            data={pef}
                            type={activeTab}
                            max={680}
                            start={start}
                            end={end}
                            tooltip={""}
                          />
                        </Col>
                        <Col lg={4}>
                          <MeasurementList data={pefList} type={activeTab} />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Col lg="12" className="text-muted p-5 text-center">
                      {props.t("No PEF measurements")}
                    </Col>
                  )}
                </>
              )}
            </TabPane>
          </TabContent>
        </Card>
      </div>
    </>
  )
}

MeasurementServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  journalKey: PropTypes.string,
}

export default withTranslation()(MeasurementServices)
