/**********************
 * FETCHING GROUP TAGS
 *********************/
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { TreeSelect, ConfigProvider } from "antd"
import { getAvailableTags } from "../../helpers/backend_helper"
const { SHOW_ALL } = TreeSelect
import PubSub from "pubsub-js"

const TagsTree = props => {
  const [treeData, setTreeData] = useState(props.data)
  const [selectedValue, setSelectedValue] = useState(undefined)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()

    // Parent component not providing tree data, load here
    if (props.loadData == true) {
      getTags()
    }

    // Refresh tags
    const token = PubSub.subscribe("tags-refresh", (event, data) => {
      if (props.loadData == true) getTags()
    })

    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Selection from props
  useEffect(() => {
    if (props.data) {
      setTreeData(props.data)
    }
  }, [props.data])

  // Selection from props
  useEffect(() => {
    if (props.selected) {
      console.log(props.selected)
      setSelectedValue(props.selected)
    } else if (props.multiple == true) {
      setSelectedValue([])
    } else {
      setSelectedValue(undefined)
    }
  }, [props.selected])

  // Load tags
  const getTags = () => {
    getAvailableTags(abortRef.current)
      .then(result => {
        if (result.statusCode == 200) {
          result.tags.sort((a, b) => (a.title > b.title ? 1 : -1))
          result.tags.forEach(tag => {
            tag.children.sort((a, b) => (a.title > b.title ? 1 : -1))
          })
          setTreeData(result.tags)
          console.log("Tags", result.tags)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Tree selection changed
  const onChange = (newValue, title) => {
    console.log("onChange ", newValue, title)
    setSelectedValue(newValue)
    if (props.selectionChanged) {
      props.selectionChanged(newValue)
    }
    if (props.selectedTagTitles) {
      props.selectedTagTitles(title)
    }
  }

  // Empty list render
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No tags found")}</p>
    </div>
  )

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <TreeSelect
          // TODO test in different scenarios
          disabled={
            treeData == undefined || treeData == [] || props.disabled == true
          }
          style={{ width: "100%" }}
          value={selectedValue}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={treeData}
          placeholder={props.t("Select tags")}
          allowClear={true}
          treeDefaultExpandAll={props.expanded != null ? props.expanded : false}
          showCheckedStrategy={SHOW_ALL}
          showSearch={false}
          multiple={props.multiple != null ? props.multiple : true}
          onChange={onChange}
        />
      </ConfigProvider>
    </>
  )
}

TagsTree.propTypes = {
  t: PropTypes.any,
  data: PropTypes.array,
  selected: PropTypes.any,
  selectionChanged: PropTypes.func,
  loadData: PropTypes.bool,
  multiple: PropTypes.bool,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedTagTitles: PropTypes.func,
}

export default withTranslation()(TagsTree)
