/*********************************
 * SELECTED USERS
 * USED IN: App Users (services),
 * Admin Users (developer)
 *********************************/

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import { Divider, Space, Tag } from "antd"
import { Button } from "reactstrap"
import { deleteDuplicated } from "utils/general"
import { orderBy } from "lodash"

const AppUsersSelected = props => {
  const [selectedUsersObject, setSelectedUsersObject] = useState([])
  const [wantedLength, setWantedLength] = useState(10)
  const [groups, setGroups] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selectedUsersObject !== undefined) {
      setSelectedUsersObject(props.selectedUsersObject)
      setGroups(formatGroups(props.selectedUsersObject))
    }
  }, [props.selectedUsersObject])

  useEffect(() => {
    console.log(selectedUsersObject, "selected")
    if (selectedUsersObject.length < 0) {
      let sorted = orderBy(selectedUsersObject, "name", "desc")
      setSelectedUsersObject(sorted)
    }
    if (wantedLength < selectedUsersObject.length && wantedLength !== 10) {
      setWantedLength(selectedUsersObject.length)
    }
  }, [selectedUsersObject])

  // Close button, update selectedUsers in parent component
  const preventDefault = user => {
    console.log("Delete select", user)
    // props.setSelectedUsers(prevState =>
    //   prevState.filter(prevItem => prevItem !== user)
    // )
    props.setSelectedUsers(prevState =>
      prevState.filter(prevItem => prevItem.key !== user)
    )
  }

  // Map groups and delete duplicates
  const formatGroups = data => {
    if (data.length > 0) {
      let groups = data.map(g => g.group)
      groups = deleteDuplicated(groups)
      return groups
    } else {
      return []
    }
  }

  // Setting legth for tab render
  const settingLength = () => {
    if (wantedLength == 10) {
      setWantedLength(selectedUsersObject.length)
    } else {
      setWantedLength(10)
    }
  }

  return (
    <>
      {/* Own card on side */}
      {props.showOnSide == true ? (
        <div className="mb-0">
          <Divider
            orientation="right"
            orientationMargin={0}
            className="py-0 mt-0 mb-1"
          >
            <span className="h6 text-muted">
              {selectedUsersObject.length == 1 ? (
                <>
                  {selectedUsersObject.length + " " + props.t("selected user")}
                </>
              ) : (
                <>
                  {selectedUsersObject.length + " " + props.t("selected users")}
                </>
              )}
            </span>
          </Divider>
          {groups.length > 0 && (
            <>
              {groups.map(group => (
                <div key={group + "_group"}>
                  <div className="mt-2 h6">
                    <i className="bx bx-group me-1"></i>
                    {group}
                  </div>
                  <Space size={[0, 8]} wrap>
                    {selectedUsersObject.map(user => (
                      <span key={user.key + "_key"}>
                        {user.group == group && (
                          <Tag
                            style={{ borderRadius: "5px" }}
                            key={user.key + "_tag"}
                            className="py-1 px-2"
                            onClose={() => {
                              preventDefault(user.key)
                            }}
                            closable
                          >
                            {user.name}
                          </Tag>
                        )}
                      </span>
                    ))}
                  </Space>
                </div>
              ))}
            </>
          )}
          {selectedUsersObject.length == 0 && (
            <p className="text-muted pt-4">{props.t("No chosen users")}</p>
          )}
          <Button
            disabled={selectedUsersObject.length == 0}
            onClick={() => {
              props.setSelectedUsers([])
              // props.setSelectedUsersObject([])
            }}
            className="mt-0 mt-2 float-end"
            size="sm"
            color="danger"
            outline
          >
            {props.t("Clear selection")}
          </Button>
        </div>
      ) : (
        <>
          {/* On the same card with table */}
          <div className="mb-3">
            <Divider orientation="left" className="py-0 mt-0 mb-1">
              <span className="h6 text-muted">
                {selectedUsersObject.length == 1 ? (
                  <>
                    {selectedUsersObject.length +
                      " " +
                      props.t("selected user")}
                  </>
                ) : (
                  <>
                    {selectedUsersObject.length +
                      " " +
                      props.t("selected users")}
                  </>
                )}
              </span>
            </Divider>

            <Space size={[0, 8]} wrap>
              {selectedUsersObject.slice(0, wantedLength).map(user => (
                // TODO: ant-tag-close-icon + ant-tag modify in themes
                <Tag
                  style={{ borderRadius: "5px" }}
                  key={user.key + "_tag"}
                  className="py-1 px-2"
                  onClose={() => {
                    preventDefault(user.key)
                  }}
                  closable
                >
                  {user.name}
                </Tag>
              ))}
              {selectedUsersObject.length > 10 && (
                <Button
                  key={"other_tag"}
                  className="py-1 px-2 me-1"
                  onClick={() => {
                    settingLength()
                  }}
                  size="sm"
                  color="primary"
                  outline
                >
                  {wantedLength > 10 ? (
                    <>{props.t("Hide")}</>
                  ) : (
                    <>
                      + {selectedUsersObject.length - wantedLength}{" "}
                      {props.t("others")}
                    </>
                  )}
                </Button>
              )}
              <Button
                onClick={() => {
                  props.setSelectedUsers([])
                  // props.setSelectedUsersObject([])
                }}
                className="me-1"
                size="sm"
                color="danger"
                outline
              >
                {props.t("Clear selection")}
              </Button>
            </Space>
          </div>
        </>
      )}
    </>
  )
}

AppUsersSelected.propTypes = {
  t: PropTypes.any,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.any,
  setSelectedUsersObject: PropTypes.any,
  showOnSide: PropTypes.bool,
}

export default withRouter(withTranslation()(AppUsersSelected))
