import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import RegisterVideoRoomModal from "./register-modal"
import VideoPasswordModal from "./password-modal"
import VideoRoomDrawer from "./room-drawer"
import { getGroupLicenseStats, getVideoRooms } from "helpers/backend_helper"
import "../datatables.scss"
import { DatePicker, Table as AntdTable } from "antd"
import {
  showToast,
  stringSorter,
  downloadExcel,
  isTrue,
  dateFormatter,
  numberSorter,
  dateSorter,
  defaultPageSizeOptions,
} from "utils/general"
import SelectGroupFilters from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import jsPDF from "jspdf"

const VideoRooms = props => {
  const [rooms, setRooms] = useState([])
  const [filteredRooms, setFilteredRooms] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [createModal, setCreateModal] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [hostModal, setHostModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")

  const [platformFilter, setPlatformFilter] = useState("0")
  const [stageFilter, setStageFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("")
  const [roleFilter, setRoleFilter] = useState("")

  const [statsDate, setStatsDate] = useState(moment())
  const [newRooms, setNewRooms] = useState(null)
  const [multilinks, setMultilinks] = useState([])
  const [statistics, setStatistics] = useState({
    total: 0,
  })
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [licenseMenu, setLicenseMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Video numbers"))
  const [selectedTags, setSelectedTags] = useState([])
  const [groups, setGroups] = useState([])
  const [loadingLicenses, setLoadingLicenses] = useState(false)
  const [licenseData, setLicenseData] = useState({guests: 0, hosts: 0, entries: []})

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    // Get search text from URL param
    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    getRooms()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let source = rooms
    let demoRooms = source.filter(data => data.roomName.indexOf("7299") == 0)
    let prodRooms = source.filter(data => data.roomName.indexOf("7254") == 0)

    let temp = new Object()
    temp.total = source.length
    temp.demo = demoRooms.length
    temp.production = prodRooms.length

    temp.linked = source.filter(data => data.links.length > 0).length
    temp.linked_prod = prodRooms.filter(data => data.links.length > 0).length
    temp.linked_demo = demoRooms.filter(data => data.links.length > 0).length

    temp.prod = source.filter(data => data.stage === "prod").length
    temp.prod_prod = prodRooms.filter(data => data.stage === "prod").length
    temp.prod_demo = demoRooms.filter(data => data.stage === "prod").length
    temp.idle = source.filter(data => data.stage === "idle").length
    temp.idle_prod = prodRooms.filter(data => data.stage === "idle").length
    temp.idle_demo = demoRooms.filter(data => data.stage === "idle").length
    temp.test = source.filter(data => data.stage === "test").length
    temp.test_prod = prodRooms.filter(data => data.stage === "test").length
    temp.test_demo = demoRooms.filter(data => data.stage === "test").length

    temp.callback = source.filter(data => data.callbackEnabled == true).length
    temp.callback_prod = prodRooms.filter(
      data => data.callbackEnabled == true
    ).length
    temp.callback_demo = demoRooms.filter(
      data => data.callbackEnabled == true
    ).length
    temp.guests = source.filter(data => data.groupCallGuest == true).length
    temp.guests_prod = prodRooms.filter(
      data => data.groupCallGuest == true
    ).length
    temp.guests_demo = demoRooms.filter(
      data => data.groupCallGuest == true
    ).length
    temp.hosts = source.filter(data => data.groupCallHost == true).length
    temp.hosts_prod = prodRooms.filter(
      data => data.groupCallHost == true
    ).length
    temp.hosts_demo = demoRooms.filter(
      data => data.groupCallHost == true
    ).length

    temp.offline = source.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.offline_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.offline_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.online = source.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.online_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.online_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.call = source.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.call_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.call_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.groupcall = source.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length
    temp.groupcall_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length
    temp.groupcall_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length

    temp.prodVideos = 0
    temp.prodGroups = 0
    temp.prodHealth = 0

    let at = statsDate.valueOf()
    source.forEach(room => {
      if (room.stage !== "test" && room.roomName.indexOf("7254") >= 0) {
        let vStart =
          room.videoStartingDate != undefined
            ? new Date(room.videoStartingDate)
            : undefined
        let vEnd =
          room.videoEndingDate != undefined
            ? new Date(room.videoEndingDate)
            : undefined
        let gStart =
          room.groupStartingDate != undefined
            ? new Date(room.groupStartingDate)
            : undefined
        let gEnd =
          room.groupEndingDate != undefined
            ? new Date(room.groupEndingDate)
            : undefined
        let hStart =
          room.healthStartingDate != undefined
            ? new Date(room.healthStartingDate)
            : undefined
        let hEnd =
          room.healthEndingDate != undefined
            ? new Date(room.healthEndingDate)
            : undefined

        if (
          vStart != undefined &&
          vStart.getTime() > 0 &&
          vStart.getTime() < at
        ) {
          if (vEnd != undefined && vStart.getTime() < vEnd.getTime()) {
            if (vEnd > at) {
              temp.prodVideos += 1
            }
          } else {
            temp.prodVideos += 1
          }
        }
        if (
          gStart != undefined &&
          gStart.getTime() > 0 &&
          gStart.getTime() < at
        ) {
          if (gEnd != undefined && gStart.getTime() < gEnd.getTime()) {
            if (gEnd > at) {
              temp.prodGroups += 1
            }
          } else {
            temp.prodGroups += 1
          }
        }
        if (
          hStart != undefined &&
          hStart.getTime() > 0 &&
          hStart.getTime() < at
        ) {
          if (hEnd != undefined && hStart.getTime() < hEnd.getTime()) {
            if (hEnd > at) {
              temp.prodHealth += 1
            }
          } else {
            temp.prodHealth += 1
          }
        }
      }
    })

    setStatistics(temp)
  }, [rooms, statsDate])

  // Check multiple links
  useEffect(() => {
    if (rooms) {
      let multis = []
      rooms.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.roomName, name: element.roomName })
        }
      })
      setMultilinks(multis)
    }
  }, [rooms])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0) setAlertsOpen(false)
  }, [multilinks])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, platformFilter, stageFilter, statusFilter, roleFilter, rooms])

  // Handle search function
  const handleSearch = () => {
    let plat = ""
    if (platformFilter == "1") plat = "7254"
    else if (platformFilter == "2") plat = "7299"

    let stag = ""
    if (stageFilter == "1") stag = "idle"
    else if (stageFilter == "2") stag = "test"
    else if (stageFilter == "3") stag = "prod"

    let stat = ""
    if (statusFilter == "1") stat = "Offline"
    else if (statusFilter == "2") stat = "Online"
    else if (statusFilter == "3") stat = "Call"
    else if (statusFilter == "4") stat = "Group"

    let searchTextLowercase = searchText.toLowerCase()
    let result = rooms.filter(
      data =>
        data.roomName?.indexOf(plat) == 0 &&
        data.stage?.indexOf(stag) == 0 &&
        data.statusDescription?.indexOf(stat) == 0 &&
        (roleFilter === "" ||
          (roleFilter === "host" && data.groupCallHost == true) ||
          (roleFilter === "guest" && data.groupCallGuest == true) ||
          (roleFilter === "neither" &&
            !data.groupCallHost &&
            !data.groupCallGuest)) &&
        (data.roomName?.toLowerCase().includes(searchTextLowercase) ||
          data.clientName?.includes(searchText) ||
          data.clientEmail?.toLowerCase().includes(searchTextLowercase) ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.includes(searchText) ||
          data.hostName?.includes(searchText) ||
          data.hostGroups?.includes(searchText))
    )
    setFilteredRooms(result)
    //console.log(result)
  }

  // Get video rooms list
  const getRooms = () => {
    setOngoingSearch(true)
    getVideoRooms(abortRef.current)
      .then(result => {
        if (result.statusCode === 200 && result.entries != null) {
          //console.log(result, "rooms")
          result.entries.forEach(element => {
            let linkedNames = []
            if (element.links) {
              element.links.forEach(link => {
                linkedNames.push(link.name)
              })
            }
            element.linkedNames = linkedNames.join()

            if (element.videoStartingDate)
              element.videoStartingDate = moment(element.videoStartingDate)
            if (element.videoEndingDate)
              element.videoEndingDate = moment(element.videoEndingDate)
            if (element.groupStartingDate)
              element.groupStartingDate = moment(element.groupStartingDate)
            if (element.groupEndingDate)
              element.groupEndingDate = moment(element.groupEndingDate)
            if (element.healthStartingDate)
              element.healthStartingDate = moment(element.healthStartingDate)
            if (element.healthEndingDate)
              element.healthEndingDate = moment(element.healthEndingDate)
          })
          //console.log(result.entries, "rooms")
          setRooms(result.entries)
        } else {
          setRooms([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setRooms([])
        setOngoingSearch(false)
      })
  }

  // Get group licenses for selected groups
  const getLicenses = () => {
    if (!loadingLicenses) {
      setLoadingLicenses(true)
      getGroupLicenseStats(abortRef.current, groups, selectedTags.toString())
        .then(result => {
          if (result.statusCode === 200 && result.entries != null) {
            setLicenseData(result)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingLicenses(false)
        })
    }
  }

  const statsDateChanged = value => {
    setStatsDate(value)
  }

  const roomsCreated = arr => {
    setNewRooms(arr)
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "roomName",
      title: props.t("Number"),
      render: roomFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "roomName", sortOrder)
      },
      fixed: "left",
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      width: "165px",
      ellipsis: true,
    },
    {
      dataIndex: "statusDescription",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "statusDescription", sortOrder)
      },
      render: cell => {
        return cell == "Offline" ? (
          <span style={{ color: "#f46a6a" }}>{cell}</span>
        ) : (
          cell
        )
      },
      width: "90px",
      ellipsis: true,
    },
    {
      dataIndex: "stage",
      title: props.t("Stage"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "stage", sortOrder)
      },
      width: "90px",
      ellipsis: true,
    },
    {
      dataIndex: "clientName",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "clientName", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: linksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "clientEmail",
      title: props.t("Email"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "clientEmail", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "clientPassword",
      title: props.t("Password"),
      align: "center",
      render: passwordFormatter,
      sorter: (a, b) => {
        return a.clientPassword - b.clientPassword
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "groupCallHost",
      title: props.t("Group host"),
      align: "center",
      sorter: (a, b) => {
        return a.groupCallHost - b.groupCallHost
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "groupCallGuest",
      title: props.t("Group guest"),
      align: "center",
      sorter: (a, b) => {
        return a.groupCallGuest - b.groupCallGuest
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "callbackEnabled",
      title: props.t("Callback"),
      align: "center",
      sorter: (a, b) => {
        return a.callbackEnabled - b.callbackEnabled
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "feedbackEnabled",
      title: props.t("Feedback"),
      align: "center",
      sorter: (a, b) => {
        return a.feedbackEnabled - b.feedbackEnabled
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "hostName",
      title: props.t("Host user"),
      render: hostFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "hostName", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "idleDisplayMode",
      title: props.t("Idle display"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["idleDisplayMode"], b["idleDisplayMode"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "callRequestTimeout",
      title: props.t("Timeout"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["callRequestTimeout"], b["callRequestTimeout"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "primaryGroupId",
      title: props.t("Group"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["primaryGroupId"], b["primaryGroupId"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "statusTime",
      title: props.t("Status updated"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["statusTime"], b["statusTime"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "lastOnline",
      title: props.t("Last online"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastOnline"], b["lastOnline"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "lastOffline",
      title: props.t("Last offline"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastOffline"], b["lastOffline"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "videoStartingDate",
      title: props.t("Video start"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["videoStartingDate"], b["videoStartingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "videoEndingDate",
      title: props.t("Video end"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["videoEndingDate"], b["videoEndingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "groupStartingDate",
      title: props.t("Group start"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["groupStartingDate"], b["groupStartingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "groupEndingDate",
      title: props.t("Group end"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["groupEndingDate"], b["groupEndingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "healthStartingDate",
      title: props.t("Health start"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["healthStartingDate"], b["healthStartingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "healthEndingDate",
      title: props.t("Health end"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["healthEndingDate"], b["healthEndingDate"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "120px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function passwordFormatter(cell, row) {
    if (row.clientPassword == true) {
      return (
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setPasswordModal(true)
            setSelectedRoom(row)
          }}
        >
          <i className="mdi mdi-qrcode-scan" />
        </Button>
      )
    } else {
      return <></>
    }
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  function roomFormatter(cell, row) {
    return (
      <>
        <span>{row.roomName}</span>
        {row.groupCallGuest == true && (
          <i className="ms-2 mdi mdi-account-group text-primary" />
        )}
        {row.groupCallHost == true && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  /** 
  function ticketFormatter(cell, row) {
    if (row.offlineTicketNumber && row.offlineTicketNumber.length > 0) {
      return (
        <a
          href={
            "https://support.suvanto.care/scp/tickets.php?number=" +
            row.offlineTicketNumber
          }
          target="_blank"
          rel="noreferrer"
        >
          {row.offlineTicketNumber}
        </a>
      )
    }
  }*/

  function hostFormatter(cell, row) {
    if (row.hostName && row.hostName.length > 0) {
      return (
        <>
          <Button
            className="me-2"
            color="primary"
            outline
            size="sm"
            onClick={() => {
              setHostModal(true)
              setSelectedRow(row)
            }}
          >
            <i className="mdi mdi-account-group" />
          </Button>
          <span>{row.hostName}</span>
        </>
      )
    } else {
      return <></>
    }
  }

  function simpleDateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY")
    return dateString
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Number"),
        props.t("Serial"),
        props.t("Status"),
        //props.t("Ticket"),
        props.t("Stage"),
        props.t("Name"),
        props.t("Links"),
        props.t("Email"),
        props.t("Group host"),
        props.t("Group guest"),
        props.t("Callback"),
        props.t("Idle display"),
        props.t("Timeout"),
        props.t("Host user"),
        props.t("Client status"),
        props.t("Client count"),
        props.t("Status updated"),
        props.t("Last online"),
        props.t("Last offline"),
        props.t("Video start"),
        props.t("Video end"),
        props.t("Group start"),
        props.t("Group end"),
        props.t("Health start"),
        props.t("Health end"),
      ],
    ]
    const data = filteredRooms.map(elt => [
      elt.roomName,
      elt.serial,
      elt.statusDescription,
      //elt.offlineTicketNumber,
      elt.stage,
      elt.clientName,
      elt.linkedNames,
      elt.clientEmail,
      isTrue(elt.groupCallHost),
      isTrue(elt.groupCallGuest),
      isTrue(elt.callbackEnabled),
      elt.idleDisplayMode,
      elt.callRequestTimeout,
      elt.hostName,
      elt.clientStatus,
      elt.activeClientCount,
      dateFormatter(elt.statusTime),
      dateFormatter(elt.lastOnline),
      dateFormatter(elt.lastOffline),
      simpleDateFormatter(elt.videoStartingDate),
      simpleDateFormatter(elt.videoEndingDate),
      simpleDateFormatter(elt.groupStartingDate),
      simpleDateFormatter(elt.groupEndingDate),
      simpleDateFormatter(elt.healthStartingDate),
      simpleDateFormatter(elt.healthEndingDate),
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Generate licenses excel
  const generateLicenseExcel = () => {
    const heading = [
      [
        props.t("Group"),
        props.t("Group hosts"),
        props.t("Group hosts"),
        props.t("Group guests"),
        props.t("Group guests"),
      ],
    ]

    let data = licenseData.entries?.map(entry => [
      entry.group,
      entry.hosts,
      entry.hostAccounts.join(", "),
      entry.guests,
      entry.guestClients.join(", "),
    ])
    if (licenseData?.entries.length > 1) {
      data.unshift([props.t("All"), licenseData?.hosts, licenseData?.hostAccounts?.join(", "), licenseData?.guests, licenseData?.guestClients?.join(", ")])
    }
    downloadExcel(props.t("Group call licenses"), heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Generate licenses PDF
  const exportLicensesPDF = () => {
    const doc = new jsPDF("landscape", "pt", "A4", "grid")
    doc.setFontSize(14)

    let data = licenseData.entries?.map(entry => [
      entry.group,
      entry.hosts,
      entry.hostAccounts.join(", "),
      entry.guests,
      entry.guestClients.join(", "),

    ])
    if (licenseData?.entries.length > 1) {
      data.unshift([props.t("All"), licenseData?.hosts, licenseData?.hostAccounts?.join(", "), licenseData?.guests, licenseData?.guestClients?.join(", ")])
    }

    let content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Group hosts"),
          props.t("Group hosts"),
          props.t("Group guests"),
          props.t("Group guests"),
        ],
      ],
      body: data,
    }

    doc.text(props.t("Group call licenses"), 40, 40)
    doc.autoTable(content)
    doc.save(props.t("Group call licenses") + ".pdf")
    //Show toast
    showToast(props.t("PDF file exported succesfully!"))
  }

  const tagsChanged = (newTag) => {
    if (!newTag) setSelectedTags([])
    else setSelectedTags([newTag])
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Video numbers")} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-flex flex-wrap">
                    <ul
                      className="nav nav-tabs nav-tabs-custom card-header-tabs"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          to="#"
                          active={activeTab === "1"}
                          onClick={() => {
                            setActiveTab("1")
                          }}
                        >
                          {props.t("Table")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "2"}
                          onClick={() => {
                            setActiveTab("2")
                          }}
                        >
                          {props.t("Statistics")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "3"}
                          onClick={() => {
                            setActiveTab("3")
                          }}
                        >
                          {props.t("Group call licenses")}
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  {/** 
                  <Link to="/config/video-devices" className="float-end">devs</Link>
                  */}
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane className="show" tabId="1">
                      {newRooms && newRooms.length > 0 && (
                        <Alert color="info">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Registered numbers")}
                            </h5>
                            <span>{newRooms}</span>
                          </div>
                        </Alert>
                      )}

                      <Accordion
                        flush
                        open={alertsOpen}
                        toggle={id => {
                          id === alertsOpen
                            ? setAlertsOpen("")
                            : setAlertsOpen(id)
                        }}
                      >
                        <AccordionItem>
                          <AccordionBody
                            accordionId="1"
                            className="accordion-zeropadding"
                          >
                            <Alert color="danger">
                              <div>
                                <h5 className="alert-heading">
                                  {props.t("Multiple links")}
                                </h5>
                                {multilinks.map((entry, key) => (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    outline
                                    key={key}
                                    className="me-2 mb-2"
                                    onClick={() => {
                                      setSearchText(entry.key)
                                    }}
                                  >
                                    <span>{entry.name}</span>
                                  </Button>
                                ))}
                              </div>
                            </Alert>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <Row>
                        <Col>
                          {rooms != null && (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          value={searchText}
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value
                                            )
                                          }
                                          placeholder={props.t(
                                            "Search"
                                          )}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    color="primary"
                                    className="round-icon-button"
                                    id="filter-button"
                                    outline
                                    onClick={() => {
                                      filtersOpen.length > 0
                                        ? setFiltersOpen("")
                                        : setFiltersOpen("1")
                                    }}
                                  >
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="filter-button"
                                    >
                                      {props.t("Filters")}
                                    </UncontrolledTooltip>
                                    {filtersOpen ? (
                                      <i className="mdi mdi-filter-minus"></i>
                                    ) : (
                                      <i className="mdi mdi-filter-menu"></i>
                                    )}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      getRooms()
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh data")}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    onClick={() => {
                                      setCreateModal(true)
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-2" />
                                    {props.t("Register numbers")}
                                  </Button>
                                </Col>
                                {(multilinks.length > 0) && (
                                    <Button
                                      color="danger"
                                      className="round-icon-button ms-3"
                                      id="alerts-button"
                                      onClick={() => {
                                        alertsOpen.length > 0
                                          ? setAlertsOpen("")
                                          : setAlertsOpen("1")
                                      }}
                                    >
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="alerts-button"
                                      >
                                        {props.t("Notices")}
                                      </UncontrolledTooltip>
                                      {alertsOpen ? (
                                        <i className="mdi mdi-alert-minus"></i>
                                      ) : (
                                        <i className="mdi mdi-alert-plus"></i>
                                      )}
                                    </Button>
                                  )}
                                <Col>
                                  <span className="float-end ms-2">
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="downloadstatstable"
                                    >
                                      {props.t("Download statistics")}
                                    </UncontrolledTooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {/* TODO: Can not choose directory file right now */}
                                        <DropdownItem
                                          onClick={() =>
                                            generateExcel()
                                          }
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredRooms.length}{" "}
                                      {props.t("found results")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Accordion
                                flush
                                open={filtersOpen}
                                toggle={id => {
                                  id === filtersOpen
                                    ? setFiltersOpen("")
                                    : setFiltersOpen(id)
                                }}
                              >
                                <AccordionItem>
                                  <AccordionBody
                                    accordionId="1"
                                    className="mb-3"
                                    style={{
                                      backgroundColor: "#e8f0fe",
                                      border: "1px solid #ced4da",
                                    }}
                                  >
                                    <Row xs={1} sm={2} md={3} xl={4}>
                                      <Col>
                                        <FormGroup className="mb-0">
                                          <Label>
                                            {props.t("Platform")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="platformFilter"
                                            value={platformFilter}
                                            onChange={v => {
                                              setPlatformFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Production")}
                                            </option>
                                            <option value="2">
                                              {props.t("Demo")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Status")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="statusFilter"
                                            value={statusFilter}
                                            onChange={v => {
                                              setStatusFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Offline")}
                                            </option>
                                            <option value="2">
                                              {props.t("Online")}
                                            </option>
                                            <option value="3">
                                              {props.t("Video call")}
                                            </option>
                                            <option value="4">
                                              {props.t("Group call")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Stage")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="stageFilter"
                                            value={stageFilter}
                                            onChange={v => {
                                              setStageFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Idle")}
                                            </option>
                                            <option value="2">
                                              {props.t("Test")}
                                            </option>
                                            <option value="3">
                                              {props.t("Production")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Group call role")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="roleFilter"
                                            value={roleFilter}
                                            onChange={v => {
                                              setRoleFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="">
                                              {props.t("Undefined")}
                                            </option>
                                            <option value="host">
                                              {props.t("Group host")}
                                            </option>
                                            <option value="guest">
                                              {props.t("Group guest")}
                                            </option>
                                            <option value="neither">
                                              {props.t("Neither")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch == true ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <AntdTable
                                      className="striped colored-header"
                                      bordered
                                      size="middle"
                                      sticky
                                      rowKey={"roomName"}
                                      columns={columns}
                                      dataSource={filteredRooms}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 100,
                                        pageSizeOptions: defaultPageSizeOptions,
                                      }}
                                      tableLayout="auto"
                                      scroll={{
                                        x: "max-content",
                                        y: "64vh",
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane className="show" tabId="2">
                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{props.t("Total")}</th>
                            <th>{props.t("Production platform")}</th>
                            <th>{props.t("Demo platform")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{props.t("Video numbers")}</th>
                            <td>{statistics.total}</td>
                            <td>{statistics.production}</td>
                            <td>{statistics.demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Linked")}</th>
                            <td>{statistics.linked}</td>
                            <td>{statistics.linked_prod}</td>
                            <td>{statistics.linked_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Prod stage")}</th>
                            <td>{statistics.prod}</td>
                            <td>{statistics.prod_prod}</td>
                            <td>{statistics.prod_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Idle stage")}</th>
                            <td>{statistics.idle}</td>
                            <td>{statistics.idle_prod}</td>
                            <td>{statistics.idle_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Test stage")}</th>
                            <td>{statistics.test}</td>
                            <td>{statistics.test_prod}</td>
                            <td>{statistics.test_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Callback enabled")}</th>
                            <td>{statistics.callback}</td>
                            <td>{statistics.callback_prod}</td>
                            <td>{statistics.callback_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Group hosts")}</th>
                            <td>{statistics.hosts}</td>
                            <td>{statistics.hosts_prod}</td>
                            <td>{statistics.hosts_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Group guests")}</th>
                            <td>{statistics.guests}</td>
                            <td>{statistics.guests_prod}</td>
                            <td>{statistics.guests_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Offline")}</th>
                            <td>{statistics.offline}</td>
                            <td>{statistics.offline_prod}</td>
                            <td>{statistics.offline_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Online")}</th>
                            <td>{statistics.online}</td>
                            <td>{statistics.online_prod}</td>
                            <td>{statistics.online_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("In call")}</th>
                            <td>{statistics.call}</td>
                            <td>{statistics.call_prod}</td>
                            <td>{statistics.call_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("In group call")}</th>
                            <td>{statistics.groupcall}</td>
                            <td>{statistics.groupcall_prod}</td>
                            <td>{statistics.groupcall_demo}</td>
                          </tr>
                        </tbody>
                      </Table>

                      <DatePicker
                        className="mt-4 mb-3"
                        defaultValue={moment()}
                        showToday
                        allowClear={false}
                        onChange={statsDateChanged}
                        format="DD.MM.YYYY"
                        style={{
                          width: "100%",
                        }}
                      />

                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th>{props.t("Billable")}</th>
                            <th>{props.t("Count")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{props.t("Video")}</th>
                            <td>{statistics.prodVideos}</td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <th scope="row">{props.t("Group call")}</th>
                            <td>{statistics.prodGroups}</td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <th scope="row">
                              {props.t("Remote measurements")}
                            </th>
                            <td>{statistics.prodHealth}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>

                    <TabPane className="show" tabId="3">
                      <Row className="mb-4">
                        <Col xl={2} sm="12" className="mt-2">
                          <div className="d-inline-block" style={{ width: "100%" }}>
                            <TagsTree
                              loadData={true}
                              expanded={true}
                              multiple={false}
                              selectionChanged={tagsChanged}
                            />
                          </div>
                        </Col>
                        <Col xl={6} sm="12" className="mt-2">
                          <SelectGroupFilters
                            onChange={setGroups}
                            multiple={true}
                            selectedTags={selectedTags}
                          />
                        </Col>
                        <Col className="mt-1 col-auto">
                          <div className="d-flex gap-1 flex-wrap">
                            <div className="align-self-end placeholder-glow">
                              <Button
                                type="button"
                                color="primary"
                                disabled={loadingLicenses}
                                style={{ padding: "9px" }}
                                onClick={() => {
                                  getLicenses()
                                }}
                              >
                                <span className="me-3 ms-3">{props.t("Search")}</span>
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col className="mt-2 col-auto">
                          <span className="float-end">
                            <UncontrolledTooltip
                              placement="top"
                              target="exportLicensesDropdown"
                            >
                              {props.t("Download statistics")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="exportLicensesDropdown"
                              isOpen={licenseMenu}
                              toggle={() => setLicenseMenu(!licenseMenu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="exportLicensesDropdown"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem disabled={!licenseData?.entries} onClick={() => generateLicenseExcel()}>
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                                <DropdownItem onClick={() => exportLicensesPDF()}>
                                  {props.t("Download .pdf")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </Col>
                      </Row>
                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th>{props.t("Group")}</th>
                            <th>{props.t("Group hosts")}</th>
                            <th>{props.t("Group guests")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{props.t("All")}</td>
                            <td>{licenseData?.hosts}</td>
                            <td>{licenseData?.guests}</td>
                          </tr>
                        </tbody>
                        {licenseData.entries?.map((item, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td scope="row">{item.group}</td>
                                <td>{item.hosts}</td>
                                <td>{item.guests}</td>
                              </tr>
                            </tbody>
                          )
                        })}
                      </Table>
                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={hostModal}
            toggle={() => {
              setHostModal(!hostModal)
            }}
            centered
          >
            <ModalHeader>{selectedRow?.hostName}</ModalHeader>
            <ModalBody>
              <p>{selectedRow?.hostGroups}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  setHostModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <VideoRoomDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getRooms={getRooms}
          />
          <RegisterVideoRoomModal
            createModal={createModal}
            setCreateModal={setCreateModal}
            getRooms={getRooms}
            roomsCreated={roomsCreated}
          />
          <VideoPasswordModal
            roomName={selectedRoom?.roomName}
            clientEmail={selectedRoom?.clientEmail}
            passwordModal={passwordModal}
            setPasswordModal={setPasswordModal}
          />
        </Container>
      </div>
    </>
  )
}

VideoRooms.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VideoRooms)
