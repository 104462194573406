/***********************
 * DAYTIME DIAGRAM ACTIVITY
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import door from "assets/images/small/door-orange.png"

//Classnames
import classnames from "classnames"

//Translation
import { withTranslation } from "react-i18next"

// Locale/weekday related imports
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import GlobalState from "contexts/GlobalState"

const DayTimeChart = props => {
  const [state, setState] = useContext(GlobalState)
  const [type, setType] = useState("area")
  const [activityData, setActivityData] = useState([])
  const [chartDataMorning, setChartDataMorning] = useState([])
  const [chartDataNight, setChartDataNight] = useState([])
  const [chartDataDay, setChartDataDay] = useState([])
  const [chartDataEvening, setChartDataEvening] = useState([])
  const [language, setLanguage] = useState("fi")
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props.data != 0 && props.data != undefined) {
      setActivityData(props.data.entries)
    }
    setIsLoading(false)
  }, [props.data])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setLanguage("fi")
      } else if (currentLanguage.indexOf("sv") == 0) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  useEffect(() => {
    if (activityData != null && activityData != undefined) {
      let a = activityData.map(ac => [ac.date, ac.activities[1]])
      let b = activityData.map(ac => [ac.date, ac.activities[2]])
      let c = activityData.map(ac => [ac.date, ac.activities[3]])
      let d = activityData.map(ac => [ac.date, ac.activities[0]])
      setChartDataMorning(a)
      setChartDataDay(b)
      setChartDataEvening(c)
      setChartDataNight(d)
    }
    setIsLoading(false)
  }, [activityData])

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("combined-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("combined-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("combined-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Morning") + " (6-12)",
      data: chartDataMorning,
    },
    {
      name: props.t("Day") + " (12-18)",
      data: chartDataDay,
    },
    {
      name: props.t("Evening") + " (18-24)",
      data: chartDataEvening,
    },
    {
      name: props.t("Night") + " (0-6)",
      data: chartDataNight,
    },
  ]
  const [optionsBar, setOptionsBar] = useState({
    colors: ["#f5d663", "#f5a14a", "#48d0f8", "#3783cc"],
    stroke: { curve: "smooth" },
    chart: {
      stacked: true,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },

      id: "combined-activity-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    grid: {
      borderColor: "#f8f8fa",
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d.M.",
      },
      labels: {
        datetimeUTC: false,
      },
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: val => {
          return val + " min"
        },
      },
    },
    fill: {
      opacity: 1,
      type: "solid",
    },

    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      //   offsetX: 40,
    },
  })

  const [options, setOptions] = useState({
    colors: ["#f5d663", "#f5a14a", "#48d0f8", "#3783cc"],
    stroke: { curve: "smooth" },
    chart: {
      stacked: true,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },

      id: "combined-activity-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    grid: {
      borderColor: "#f8f8fa",
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d.M.",
      },
      labels: {
        datetimeUTC: false,
      },
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: val => {
          return val + " min"
        },
      },
    },
    fill: {
      opacity: 1,
      type: ["gradient", "gradient", "gradient", "gradient"],
      gradient: {
        // inverseColors: !1,
        shade: "light",
        // type: "vertical",
        opacityFrom: 1,
        opacityTo: 0.9,
        // stops: [0, 100, 100, 100],
      },
    },

    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      //   offsetX: 40,
    },
  })

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary py-4">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg="12">
              <div
                className="float-end"
                style={{
                  marginTop: "-40px",
                  marginRight: "60px",
                }}
              >
                <Nav pills className="product-view-nav mb-3" role="tablist">
                  <NavItem>
                    <NavLink
                      style={{ zIndex: 1 }}
                      id="area-chart-link"
                      className={classnames({
                        active: type === "area",
                      })}
                      onClick={() => {
                        setType("area")
                      }}
                    >
                      <i className="mdi mdi-chart-areaspline-variant font-size-18 align-middle" />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"area-chart-link"}
                      >
                        {props.t("Area chart")}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ zIndex: 1 }}
                      id="line-chart-link"
                      className={classnames({
                        active: type === "line",
                      })}
                      onClick={() => {
                        setType("line")
                        console.log("line")
                      }}
                    >
                      <i className="mdi mdi-chart-bell-curve align-middle font-size-18" />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"line-chart-link"}
                      >
                        {props.t("Line chart")}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ zIndex: 1 }}
                      id="bar-chart-link"
                      className={classnames({
                        active: type === "bar",
                      })}
                      onClick={() => {
                        setType("bar")
                      }}
                    >
                      <i className="mdi mdi-chart-bar align-middle font-size-18" />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"bar-chart-link"}
                      >
                        {props.t("Bar chart")}
                      </UncontrolledTooltip>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row>{" "}
          {type == "area" && (
            <div className="mt-3 py-2">
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
              />
            </div>
          )}
          {type == "bar" && (
            <div className="mt-3 py-2">
              <ReactApexChart
                options={optionsBar}
                series={series}
                type="bar"
                height={350}
              />
            </div>
          )}
          {type == "line" && (
            <div className="mt-3 py-2">
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

DayTimeChart.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  time: PropTypes.any,
  doorData: PropTypes.any,
}

export default withTranslation()(DayTimeChart)
