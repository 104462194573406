import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../datatables.scss"
import { queryAuditLogs } from "helpers/backend_helper"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { dateSorter, defaultPageSizeOptions, downloadExcel, showToast, stringSorter } from "utils/general"
import { Select } from "antd"
import { Table as AntdTable } from "antd"

const AuditLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "days"),
    moment(),
  ])

  const [usernameFilter, setUsernameFilter] = useState("")
  const [eventsFilter, setEventsFilter] = useState([])
  const [thingFilter, setThingFilter] = useState([])
  const [detailsFilter, setDetailsFilter] = useState("")
  const [ipFilter, setIpFilter] = useState("")

  const [maxItems, setMaxItems] = useState("500")
  const [showModal, setShowModal] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Audit logs"))

  const [isExpanded, setIsExpanded] = useState([])
  const [selectionData, setSelectionData] = useState([])
  const [selectionColumns, setSelectionColumns] = useState([
    {
      dataField: "react_id",
      text: props.t("ID"),
      sort: true,
      hidden: true,
    },
  ])
  const [expandedKeys, setExpandedKeys] = useState([])

  const [eventOptions, setEventOptions] = useState([
    { label: "CREATE", value: "CREATE" },
    { label: "DELETE", value: "DELETE" },
    { label: "UPDATE", value: "UPDATE" },
    { label: "VIEW", value: "VIEW" },
    { label: "RESET", value: "RESET" },
    { label: "LOGIN", value: "LOGIN" },
    { label: "LOGOUT", value: "LOGOUT" },
  ])

  const [thingOptions, setThingOptions] = useState([
    { label: props.t("ThingDescriptions.Thingworx"), value: "Thingworx" },
    {
      label: props.t("ThingDescriptions.Thingworx / Front"),
      value: "Thingworx / Front",
    },
    {
      label: props.t("ThingDescriptions.SuvantoObjects"),
      value: "SuvantoObjects",
    },
    { label: props.t("ThingDescriptions.Groups"), value: "Groups" },
    { label: props.t("ThingDescriptions.Users"), value: "Users" },
    { label: props.t("ThingDescriptions.SuvantoUsers"), value: "SuvantoUsers" },
    {
      label: props.t("ThingDescriptions.SuvantoGateways"),
      value: "SuvantoGateways",
    },
    { label: props.t("ThingDescriptions.SuvantoNodes"), value: "SuvantoNodes" },
    { label: props.t("ThingDescriptions.VideoDevices"), value: "VideoDevices" },
    { label: props.t("ThingDescriptions.VideoRooms"), value: "VideoRooms" },
    { label: props.t("ThingDescriptions.Carephones"), value: "Carephones" },
    { label: props.t("ThingDescriptions.GpsTrackers"), value: "GpsTrackers" },
    {
      label: props.t("ThingDescriptions.SuvantoOrders"),
      value: "SuvantoOrders",
    },
    {
      label: props.t("ThingDescriptions.SuvantoAlarms"),
      value: "SuvantoAlarms",
    },
    {
      label: props.t("ThingDescriptions.SuvantoJournal"),
      value: "SuvantoJournal",
    },
    {
      label: props.t("ThingDescriptions.SuvantoObjectUserLinks"),
      value: "SuvantoObjectUserLinks",
    },
    {
      label: props.t("ThingDescriptions.SuvantoAdminObjectLinks"),
      value: "SuvantoAdminObjectLinks",
    },
    {
      label: props.t("ThingDescriptions.ServerEventStream"),
      value: "ServerEventStream",
    },
    {
      label: props.t("ThingDescriptions.SuvantoActivityProfiles"),
      value: "SuvantoActivityProfiles",
    },
    {
      label: props.t("ThingDescriptions.ScheduledMessages"),
      value: "ScheduledMessages",
    },
    {
      label: props.t("ThingDescriptions.TrackerLocations, TrackerEvents"),
      value: "TrackerLocations, TrackerEvents",
    },
    { label: props.t("ThingDescriptions.Pindoras"), value: "Pindoras" },
    { label: props.t("ThingDescriptions.DoseCans"), value: "DoseCans" },
    {
      label: props.t("ThingDescriptions.MeasuringDevices"),
      value: "MeasuringDevices",
    },
    {
      label: props.t("ThingDescriptions.SuvantoAlarmSetups"),
      value: "SuvantoAlarmSetups",
    },
  ])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getLogs()
  }, [dateRange])

  // Get logs
  const getLogs = () => {
    if (!ongoingSearch && dateRange) {
      setIsExpanded([])
      setOngoingSearch(true)
      let rows = isNaN(maxItems) == false ? parseInt(maxItems, 10) : 500
      queryAuditLogs(
        abortRef.current,
        rows,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        usernameFilter,
        ipFilter,
        eventsFilter,
        thingFilter,
        detailsFilter
      )
        .then(result => {
          //console.log("Logs: ", result)
          if (result.statusCode == 200) {
            result.entries.forEach(element => {
              element.thingDescription = "ThingDescriptions." + element.thing
            })

            setLogs(result.entries)
          } else showToast(props.t("Error"), "error")
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.details?.toLowerCase().includes(searchText.toLowerCase()) ||
        data.username?.toLowerCase().includes(searchText.toLowerCase()) ||
        data.ipAddress?.toLowerCase().includes(searchText.toLowerCase())
    )
    setFilteredLogs(result)
  }

  const columns = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["timestamp"], b["timestamp"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "username",
      title: props.t("Username"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "username", sortOrder)
      },
      width: "160px",
      ellipsis: true,
      hidden: true
    },
    {
      dataIndex: "event",
      title: props.t("Event"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "event", sortOrder)
      },
      render: eventFormatter,
      width: "130px",
      ellipsis: true,
    },
    {
      dataIndex: "thing",
      title: props.t("Target"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "thing", sortOrder)
      },
      render: thingFormatter,
      width: "150px",
      ellipsis: true,
    },
    {
      dataIndex: "details",
      title: props.t("Content"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "details", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "ipAddress",
      title: props.t("IP address"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "ipAddress", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "comments",
      title: props.t("Comments"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "comments", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function linkFormatter(cell, row) {
    return (
      <a href={"/clients/" + cell} target="_blank" rel="noreferrer">
        {cell}
      </a>
    )
  }

  function thingFormatter(cell, row) {
    return <span>{props.t(row.thingDescription)}</span>
  }

  function eventFormatter(cell, row) {
    if (row.event == "CREATE") {
      return (
        <span className="text-success">
          <i className="mdi mdi-database-plus me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "VIEW") {
      return (
        <span className="text-muted">
          <i className="mdi mdi-eye me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "LOGIN") {
      return (
        <span className="text-info">
          <i className="mdi mdi-login me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "LOGOUT") {
      return (
        <span className="text-tertiary">
          <i className="mdi mdi-logout me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "UPDATE") {
      return (
        <span className="text-primary">
          <i className="mdi mdi-database-edit me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "RESET") {
      return (
        <span className="text-danger">
          <i className="mdi mdi-database-edit me-1" />
          {row.event}
        </span>
      )
    } else if (row.event == "DELETE") {
      return (
        <span className="text-danger">
          <i className="mdi mdi-delete me-1" />
          {row.event}
        </span>
      )
    } else return <span>{row.event}</span>
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Event changed
  const eventChange = newSelection => {
    setEventsFilter(newSelection)
  }

  const selectVisible = visible => {
    if (visible == false) getLogs()
  }

  // Thing changed
  const thingChange = newSelection => {
    setThingFilter(newSelection)
  }

  // Key pressed - enter
  const keyUp = event => {
    if (event.key === "Enter") {
      getLogs()
    }
  }

  // Row expanded - format table
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    //console.log("handleOnExpand: ", isExpand, row, rowIndex)
    setIsExpanded(isExpand)
    let cols = [
      { dataField: "react_id", text: props.t("ID"), sort: true, hidden: true },
    ]
    let data = []
    if (isExpand) {
      setIsExpanded([row.id])
      try {
        let json = JSON.parse(row.details)
        data = json
        for (var field in json[0]) {
          let fld = "" + field
          if (
            (row.event == "VIEW" || row.event == "CREATE") &&
            row.thing == "SuvantoObjects" &&
            fld == "key"
          )
            cols.push({
              dataField: fld,
              text: fld,
              sort: true,
              formatter: linkFormatter,
            })
          else if (fld == "suvantoKey")
            cols.push({
              dataField: fld,
              text: fld,
              sort: true,
              formatter: linkFormatter,
            })
          else cols.push({ dataField: fld, text: fld, sort: true })
        }
      } catch (err) {}
    } else {
      setIsExpanded([])
    }

    setSelectionColumns(cols)
    setSelectionData(data)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Time"),
        props.t("Username"),
        props.t("Event"),
        props.t("Target"),
        props.t("Content"),
        props.t("IP address"),
        props.t("Comments"),
      ],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.username,
      elt.event,
      elt.thing,
      elt.details,
      elt.ipAddress,
      elt.comments,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Audit logs")} />

          <Card>
            <CardBody>
              <Row>
                <Col>
                  {logs != null && (
                    <React.Fragment>
                      <Row className="mb-1">
                        {" "}
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(event.target.value)
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col className="col-auto">
                          <Input
                            style={{ width: "80px" }}
                            type="number"
                            name="maxItems"
                            value={maxItems}
                            onKeyUp={v => keyUp(v)}
                            onChange={v => {
                              setMaxItems(v.target.value)
                            }}
                          />
                        </Col>
                        <Col>
                          <CustomRangePicker
                            value={dateRange}
                            rangeChanged={onDateSelection}
                            showTime={true}
                            allowClear={false}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Button
                            className="square-icon-button-sm"
                            id="calendar-button"
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              setDateRange([dateRange[0], moment()])
                            }}
                          >
                            <UncontrolledTooltip
                              placement="bottom"
                              target="calendar-button"
                            >
                              {props.t("Refresh time")}
                            </UncontrolledTooltip>
                            <i className="mdi mdi-calendar-refresh" />
                          </Button>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            className="square-icon-button-sm"
                            id="search-button"
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              getLogs()
                            }}
                          >
                            <UncontrolledTooltip
                              placement="bottom"
                              target="search-button"
                            >
                              {props.t("Search")}
                            </UncontrolledTooltip>
                            <i className="mdi mdi-database-search" />
                          </Button>
                        </Col>
                        <Col>
                          <span className="float-end ms-2">
                            <UncontrolledTooltip
                              placement="bottom"
                              target="downloadstatstable"
                            >
                              {props.t("Download")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* TODO: Can not choose directory file right now */}
                                <DropdownItem
                                  onClick={() => generateExcel()}
                                >
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <div className="mt-2 float-end">
                            <p>
                              {filteredLogs.length}{" "}
                              {props.t("results found")}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="">
                          <Select
                            mode={"multiple"}
                            disabled={ongoingSearch}
                            allowClear={true}
                            value={eventsFilter}
                            placeholder={props.t("Select event")}
                            onChange={eventChange}
                            onDropdownVisibleChange={selectVisible}
                            //onClear={() => {getLogs(); setEventsFilter([])}}
                            options={eventOptions}
                            style={{ width: 100 + "%" }}
                          />
                        </Col>
                        <Col className="">
                          <Select
                            mode={"multiple"}
                            disabled={ongoingSearch}
                            allowClear={true}
                            value={thingFilter}
                            placeholder={props.t("Select target")}
                            onChange={thingChange}
                            onDropdownVisibleChange={selectVisible}
                            //onClear={() => {getLogs(); setThingFilter([])}}
                            options={thingOptions}
                            style={{ width: 100 + "%" }}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Input
                            type="text"
                            name="usernameFilter"
                            disabled={ongoingSearch}
                            placeholder={props.t("Username")}
                            value={usernameFilter}
                            onKeyUp={v => keyUp(v)}
                            onChange={v => {
                              setUsernameFilter(v.target.value)
                            }}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Input
                            type="text"
                            name="detailsFilter"
                            disabled={ongoingSearch}
                            placeholder={props.t("Content")}
                            value={detailsFilter}
                            onKeyUp={v => keyUp(v)}
                            onChange={v => {
                              setDetailsFilter(v.target.value)
                            }}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Input
                            type="text"
                            name="ipFilter"
                            disabled={ongoingSearch}
                            placeholder={props.t("IP address")}
                            value={ipFilter}
                            onKeyUp={v => keyUp(v)}
                            onChange={v => {
                              setIpFilter(v.target.value)
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          {ongoingSearch == true ? (
                            <div className="py-5 text-center">
                              <p className="text-primary">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                {props.t("Loading")}
                              </p>
                            </div>
                          ) : (
                            <AntdTable
                              className="striped colored-header"
                              bordered
                              size="middle"
                              sticky
                              rowKey={"id"}
                              columns={columns}
                              dataSource={filteredLogs}
                              expandable={{
                                showExpandColumn: true,
                                expandRowByClick: true,
                                expandedRowKeys: expandedKeys,
                                onExpand: (expanded, record) => {
                                  let keys = []
                                  if (expanded) {
                                    keys.push(record.id)
                                    handleOnExpand(record, true, 0, null)
                                  }
                                  setExpandedKeys(keys)
                                },
                                rowExpandable: (record) => record.details?.length > 2,
                                expandedRowRender: (record) => (
                                  <>
                                    {selectionColumns && selectionColumns.length > 1 && (
                                      <>
                                        <ToolkitProvider
                                          keyField="react_id"
                                          columns={selectionColumns}
                                          data={selectionData}
                                        >
                                          {toolkitProps => (
                                            <React.Fragment>
                                              <div
                                                className="table-responsive"
                                                style={{ maxWidth: "80vw", backgroundColor: "white" }}
                                              >
                                                <BootstrapTable
                                                  id="selection-table"
                                                  keyField={"react_id"}
                                                  responsive
                                                  classes={"table align-middle table-nowrap"}
                                                  headerWrapperClasses={"thead-light"}
                                                  {...toolkitProps.baseProps}
                                                />
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </ToolkitProvider>
                                      </>
                                    )}
                                  </>
                                ),
                              }}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: "64vh",
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal)
        }}
        centered
      >
        <ModalBody>
          <Input
            type="textarea"
            rows={20}
            disabled
            value={selectedMessage}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setShowModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

AuditLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AuditLogs)
