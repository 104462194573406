import {
  deleteTrackerHistory,
  getTrackerData,
  getTrackerEvents,
  getTrackerLocations,
  locateTracker,
  queryTrackerStatus,
  setTrackerFall,
  setTrackerGeofence,
} from "helpers/backend_helper"
import TrackerSmsTab from "./sms-tab"
//
import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Collapse,
  CardHeader,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  Accordion,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap"
import moment from "moment"
import "react-circular-progressbar/dist/styles.css"
import classnames from "classnames"
import { getLottieLoadingOptions, showToast } from "utils/general"
import Lottie from "react-lottie"
import nodataAnim from "common/animations/nodata"
import StatusCardHeader from "../status-card-header"
import GlobalState from "contexts/GlobalState"
import PubSub from "pubsub-js"
import loadingAnim from "common/animations/loading-dots-blue"

//  Importing Google Map
import Map from "./map/map"
//import "assets/scss/custom.scss"

// Toast
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Popconfirm } from "antd"

const OnTheGoServices = props => {
  const abortRef = useRef(null)
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [tracker, setTracker] = useState(null)
  const [locations, setLocations] = useState(null)
  const [events, setEvents] = useState([])
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [geofenceModal, setGeofenceModal] = useState(false)
  const [geofenceRadius, setGeofenceRadius] = useState("1000")

  const [lat, setLat] = useState()
  const [lng, setLng] = useState()

  const [dataLoading, setDataLoading] = useState(false)
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [locationsNone, setLocationsNone] = useState(false)
  const [eventsLoading, setEventsLoading] = useState(false)
  const [eventsNone, setEventsNone] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [ongoingAction, setOngoingAction] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const [open, setOpen] = useState("")
  const [accordionText, setAccordionText] = useState("Show all device info")
  const toggleInfo = id => {
    open === id
      ? (setOpen(), setAccordionText("Show all device info"))
      : (setOpen(id), setAccordionText("Hide info"))
  }

  const [updateCounter, setUpdateCounter] = useState(0)
  const [intervalId, setIntervalId] = useState(0)

  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
      getLocations()
      getEvents()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
      if (intervalId) {
        console.log("Close page, clear inteval!!!")
        clearInterval(intervalId)
        setIntervalId(0)
      }
    }
  }, [])

  useEffect(() => {
    //console.log("OnTheGoServices init " + props.trackerKey);
    if (props.trackerKey != undefined && props.trackerKey.length > 0) {
      getData()
    } else {
      setTracker(null)
    }
  }, [props.trackerKey, updateCounter])

  useEffect(() => {
    if (props.trackerKey != undefined && props.trackerKey.length > 0) {
      getLocations()
      getEvents()
    }
  }, [props.trackerKey])

  // Update map location
  useEffect(() => {
    if (selectedLocation != null) {
      //console.log("selected location:", selectedLocation)
      setLat(selectedLocation.trackerLocation.latitude)
      setLng(selectedLocation.trackerLocation.longitude)
    }
  }, [selectedLocation])

  // Get tracker data
  const getData = () => {
    if (!dataLoading) {
      setDataLoading(true)
      getTrackerData(abortRef.current, props.trackerKey)
        .then(data => {
          //console.log("TRACKER: ", data)
          if (data.key) {
            switch (data.fallDetectionEnabled) {
              case true:
                data.fallStatus = "Enabled"
                break
              default:
                data.fallStatus = "Disabled"
                break
            }

            switch (data.geofenceEnabled) {
              case true:
                data.geoStatus = "Enabled"
                break
              default:
                data.geoStatus = "Disabled"
                break
            }

            if (
              tracker != null &&
              tracker.trackingEventStarted > 0 &&
              data.trackingEventStarted == 0
            ) {
              toastr.options = {
                positionClass: "toast-top-center",
                timeOut: 10000,
                extendedTimeOut: 1000,
                closeButton: true,
                progressBar: true,
                showEasing: "swing",
                hideEasing: "linear",
                showMethod: "fadeIn",
                hideMethod: "fadeOut",
                showDuration: 300,
                hideDuration: 1000,
              }
              toastr.success(props.t("Location query has ended"))
            }

            /*if (data.trackingEventStarted == 0 && intervalId) {
            console.log("CLEARING INTERVAL")
            clearInterval(intervalId)
            setIntervalId(0)
            getLocations()
            getEvents()
          }*/
            //console.log("Tracker: ", data)
            setTracker(data)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDataLoading(false)
        })
    }
  }

  // Get locations
  const getLocations = () => {
    if (!locationsLoading) {
      setLocationsLoading(true)
      setLocationsNone(false)
      getTrackerLocations(abortRef.current, props.trackerKey)
        .then(data => {
          //console.log("Locations: ", data)
          if (data.length > 0) {
            if (data.length > 10) data = data.slice(0, 10)
            let key = 0
            data.forEach(element => {
              element.key = key++
            })
            setLocations(data)
            setLocationsNone(false)
            setLat(data[0].trackerLocation.latitude)
            setLng(data[0].trackerLocation.longitude)
            // console.log(data[0].trackerLocation.latitude, "kokeilu")
          } else {
            setLocations(null)
            setLocationsNone(true)
          }
        })
        .catch(e => {
          console.log(e)
          setLocations(null)
        })
        .finally(() => {
          setLocationsLoading(false)
        })
    }
  }

  // Get events
  const getEvents = () => {
    if (!eventsLoading) {
      setEventsLoading(true)
      getTrackerEvents(abortRef.current, props.trackerKey)
        .then(data => {
          //console.log("EVENTS: ", data);
          //let temp = JSON.parse(data[0].result)
          //if (data.length > 10) temp = temp.slice(0, 10)
          let key = 0
          data.entries.forEach(element => {
            element.key = key++
          })
          setEvents(data.entries)
          setEventsLoading(false)
          setEventsNone(data.entries.length == 0)
          //console.log("Events:", data[0].result);
        })
        .catch(e => {
          console.log(e)
          setEventsLoading(false)
          setEventsNone(true)
        })
    }
  }

  // Start locating tracker
  const queryLocation = () => {
    if (props.trackerKey && !ongoingAction) {
      setOngoingAction(true)
      locateTracker(abortRef.current, props.trackerKey)
        .then(result => {
          console.log("locate result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getData()
            getEvents()

            /*console.log("First update")
            setUpdateCounter(prevCount => prevCount + 1)

            if (intervalId) {
              clearInterval(intervalId)
              setIntervalId(0)
            }

            const newIntervalId = setInterval(() => {
              console.log("Interval counter")
              setUpdateCounter(prevCount => prevCount + 1)
            }, 15000)
            setIntervalId(newIntervalId)
            getEvents()*/
          } else {
            console.log("Unable to start query")
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Query tracker status
  const queryStatus = () => {
    if (props.trackerKey && !ongoingAction) {
      setOngoingAction(true)
      queryTrackerStatus(abortRef.current, props.trackerKey)
        .then(result => {
          console.log("Query result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getData()
            getEvents()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Set fall detection
  const setFallDetection = () => {
    if (props.trackerKey && tracker && !ongoingAction) {
      setOngoingAction(true)
      setTrackerFall(
        abortRef.current,
        props.trackerKey,
        !tracker.fallDetectionEnabled
      )
        .then(result => {
          console.log("Fall result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getData()
            getEvents()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Set geofence
  const setGeofence = () => {
    if (props.trackerKey && tracker && !ongoingAction) {
      setOngoingAction(true)
      let radius = geofenceRadius.length > 0 ? parseInt(geofenceRadius, 10) : 0
      setTrackerGeofence(abortRef.current, props.trackerKey, radius)
        .then(result => {
          console.log("Geo result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setGeofenceModal(false)
            getData()
            getEvents()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Delete history
  const deleteHistory = () => {
    if (props.trackerKey && !ongoingAction) {
      setOngoingAction(true)
      deleteTrackerHistory(abortRef.current, props.trackerKey)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            getData()
            getLocations()
            getEvents()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Open selected location in new tab
  const openGoogleMap = () => {
    const coords =
      selectedLocation.trackerLocation.latitude +
      "," +
      selectedLocation.trackerLocation.longitude
    const win = window.open(
      "https://www.google.com/maps/place/" + coords,
      "_blank"
    )
    if (win != null) {
      win.focus()
    }
  }

  const refresh = () => {}

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const lottieNoneOpts = {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <>
      <div>
        <span>
          {/*<span className="h4">
            {props.t("Navigation" + ".Suvanto OnTheGo")}
          </span>*/}
          {/** 
          <Button color="clear" className="p-0 text-primary" disabled={eventsLoading || dataLoading || locationsLoading} onClick={() => {getData(); getEvents(); getLocations()}}>
            {props.t("Refresh")}
            <i className="mdi mdi-refresh ms-2" />
          </Button>*/}
          {/*settingsOpen == false && (
            <span
              type="button"
              onClick={() => {
                setSettingsOpen(!settingsOpen)
              }}
              className="ms-2 h5 "
            >
              <i id="cog-button" className="bx bx-cog"></i>{" "}
              <UncontrolledTooltip placement="bottom" target="cog-button">
                {props.t("Open settings")}
              </UncontrolledTooltip>
            </span>
            )*/}

          {/*
          <span
            type="button"
            onClick={() => {
              refresh()
            }}
            className="ms-2 h5 float-end"
          >
            <i id="refresh-button" className="mdi mdi-refresh"></i>{" "}
            <UncontrolledTooltip placement="bottom" target="refresh-button">
              {props.t("Refresh")}
            </UncontrolledTooltip>
          </span>
          */}

          {settingsOpen && (
            <span
              type="button"
              onClick={() => {
                setSettingsOpen(!settingsOpen)
              }}
              className="ms-2 h5 "
            >
              <i id="back-button" className="bx bx-arrow-right" />
              <span className="font-size-12">{props.t("mene takaisin")}</span>
            </span>
          )}
        </span>
        {/* Settings */}
        <Collapse isOpen={settingsOpen}></Collapse>

        {/* Normal view */}
        {settingsOpen == false && tracker && (
          <div className="mt-1">
            <Card style={{ borderRadius: "40px 0 0 0" }}>
              <StatusCardHeader
                service="tracker"
                status={tracker.trackerStatus}
                battery={tracker.lastBatteryStatus}
                date={tracker.lastBatteryStatusTime}
              />
              <CardBody style={{ padding: "0px" }}>
                <Accordion flush open={open} toggle={toggleInfo}>
                  <AccordionItem>
                    <AccordionBody
                      accordionId="1"
                      className="pt-0"
                      style={{ paddingTop: "0px" }}
                    >
                      <Row xs={1} sm={2} lg={3} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Gsm")}:</h6>{" "}
                            {tracker.trackerGsmNumber
                              ? tracker.trackerGsmNumber
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("IMEI")}:</h6>{" "}
                            {tracker.IMEI ? tracker.IMEI : props.t("Undefined")}
                          </div>
                        </Col>
                        {/*
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("IMSI")}:</h6> {tracker.IMSI}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Serial")}:</h6> {tracker.serial}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Device ID")}:</h6> {tracker.deviceId}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Home location")}:</h6> {""+tracker.homeLocation}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Device type")}:</h6> {tracker.trackerType}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Firmware")}:</h6> {tracker.firmwareVersion}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Batch")}:</h6> {tracker.batch}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("SIM status")}:</h6> {props.t(tracker.simStatus)}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Group")}:</h6> {tracker.groupName}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Description")}:</h6> {tracker.trackerLabel}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Order number")}:</h6> {tracker.orderNumber}
                          </div>
                        </Col>
                        */}

                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Fall detection")}:</h6>{" "}
                            {props.t(tracker.fallStatus)}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Geofence")}:</h6>{" "}
                            {tracker?.geofenceEnabled
                              ? props.t(tracker.geoStatus) +
                                ", " +
                                tracker.geofenceRadius +
                                "m"
                              : props.t(tracker.geoStatus)}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Last location query")}:</h6>{" "}
                            {moment(tracker.lastLocateTime).format(
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Data retention")}:</h6>{" "}
                            {!tracker.storeDataPeriod
                              ? props.t("Forever")
                              : tracker.storeDataPeriod + " " + props.t("days")}
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                    <AccordionHeader targetId="1">
                      {props.t(accordionText)}
                    </AccordionHeader>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="bg-transparent border-bottom">
                <div className="d-flex flex-wrap">
                  <ul
                    className="nav nav-tabs nav-tabs-custom card-header-tabs"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1")
                        }}
                      >
                        {props.t("Locations")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2")
                        }}
                      >
                        {props.t("Events")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggle("3")
                        }}
                      >
                        {props.t("Actions")}
                      </NavLink>
                    </NavItem>
                    {/*userRole === "system_admin" && (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => {
                            toggle("4")
                          }}
                        >
                          {props.t("SMS logs")}
                        </NavLink>
                      </NavItem>
                        )*/}
                  </ul>
                </div>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={activeTab} className="py-2">
                  {/** LOCATIONS */}
                  <TabPane className="show" tabId="1">
                    {locationsLoading && (
                      <Lottie
                        options={getLottieLoadingOptions()}
                        height={100}
                        width={100}
                      />
                    )}
                    {locationsNone && (
                      <div className="text-center mb-4">
                        <Lottie
                          options={lottieNoneOpts}
                          height={150}
                          width={150}
                        />
                        <h5 className="text-muted mt-2">
                          {props.t("No locations found")}
                        </h5>
                      </div>
                    )}
                    {locations && (
                      <Row xs={1} xl={2}>
                        <Col className="order-2 order-xl-1">
                          <ul className="verti-timeline list-unstyled mt-2">
                            {locations.map(entry => (
                              <li
                                key={entry.key}
                                className="event-list"
                                type="button"
                                onClick={() => {
                                  setSelectedLocation(entry)
                                }}
                              >
                                <div className="event-timeline-dot">
                                  <i className="bx bx-map-alt text-primary"></i>
                                </div>
                                <Row>
                                  <Col className="location-list">
                                    <h6>{entry.locationAddress}</h6>
                                    {/*<div className="text-muted">{entry.trackerLocation.latitude + ", " + entry.trackerLocation.longitude}</div>*/}
                                    <span className="text-muted">
                                      {moment(entry.locationDate).format(
                                        "DD.MM.YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </li>
                            ))}
                          </ul>
                        </Col>
                        <Col className="order-1 order-xl-2">
                          <Card
                            className="overflow-none"
                            style={{ height: "30vh" }}
                          >
                            {/* > */}
                            {/* <CardBody> */}
                            {/* <h4>Kartta</h4> */}
                            {lat != undefined && lng != undefined && (
                              <Map zoom={16} lat={lat} lng={lng} />
                            )}

                            {/* </CardBody> */}
                          </Card>
                          <Row className="mb-4">
                            <Col>
                              {selectedLocation && (
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    openGoogleMap()
                                  }}
                                >
                                  {props.t("Open in Google Maps")}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </TabPane>

                  {/** EVENTS */}
                  <TabPane className="show" tabId="2">
                    {eventsLoading && (
                      <Lottie
                        options={getLottieLoadingOptions()}
                        height={100}
                        width={100}
                      />
                    )}
                    {eventsNone && (
                      <div className="text-center mb-4">
                        <Lottie
                          options={lottieNoneOpts}
                          height={150}
                          width={150}
                        />
                        <h5 className="text-muted mt-2">
                          {props.t("No events found")}
                        </h5>
                      </div>
                    )}
                    {events && !eventsLoading && (
                      <ul className="verti-timeline list-unstyled mt-2">
                        {events.map(entry => (
                          <li key={entry.key} className="event-list">
                            <div className="event-timeline-dot">
                              {entry.sourceUser != null && (
                                <i className="bx bx-upvote text-success"></i>
                              )}
                              {!entry.sourceUser && (
                                <i className="bx bx-downvote text-primary"></i>
                              )}
                            </div>
                            <Row className="event-content py-2">
                              <Col>
                                <div>
                                  <h6>
                                    {props.t("TrackerEvents." + entry.type)}
                                  </h6>
                                  <span className="text-muted">
                                    {moment(entry.date).format(
                                      "DD.MM.YYYY HH:mm:ss"
                                    )}
                                  </span>
                                </div>
                              </Col>
                              {entry.battery != null && (
                                <Col className="col-auto">{entry.battery}%</Col>
                              )}
                              {entry.sourceUser && (
                                <Col className="col-auto">
                                  {entry.sourceUser}
                                </Col>
                              )}
                            </Row>
                          </li>
                        ))}
                      </ul>
                    )}
                  </TabPane>

                  {/** ACTIONS */}
                  <TabPane className="show" tabId="3">
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="py-4">
                        <Row>
                          <Col>
                            <div>
                              <h5>{props.t("Locate")}</h5>
                              <span className="text-muted">
                                {props.t("Locate description")}
                              </span>
                            </div>
                          </Col>
                          {!tracker.trackingEventStarted && (
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                disabled={
                                  ongoingAction ||
                                  tracker?.trackingEventStarted > 0
                                }
                                onClick={() => {
                                  queryLocation()
                                }}
                              >
                                {props.t("Send")}
                              </Button>
                            </Col>
                          )}
                          {tracker.trackingEventStarted > 0 && (
                            <Col className="col-auto">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: loadingAnim,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                                height={46}
                                width={120}
                              />
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="py-4">
                        <Row>
                          <Col>
                            <div>
                              <h5>{props.t("Status query")}</h5>
                              <span className="text-muted">
                                {props.t("Status query description")}
                              </span>
                            </div>
                          </Col>
                          {!tracker.statusQueryEventStarted && (
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                disabled={
                                  ongoingAction ||
                                  tracker?.statusQueryEventStarted > 0
                                }
                                onClick={() => {
                                  queryStatus()
                                }}
                              >
                                {props.t("Send")}
                              </Button>
                            </Col>
                          )}
                          {tracker.statusQueryEventStarted > 0 && (
                            <Col className="col-auto">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: loadingAnim,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                                height={46}
                                width={120}
                              />
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h5>{props.t("Fall detection")}</h5>
                              <span className="text-muted">
                                {props.t(tracker.fallStatus)}
                              </span>
                            </div>
                          </Col>
                          {!tracker.fallDetectionEventStarted && (
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                disabled={
                                  ongoingAction ||
                                  tracker?.fallDetectionEventStarted > 0
                                }
                                onClick={() => {
                                  setFallDetection()
                                }}
                              >
                                {tracker.fallDetectionEnabled == true
                                  ? props.t("Disable")
                                  : props.t("Enable")}
                              </Button>
                            </Col>
                          )}
                          {tracker.fallDetectionEventStarted > 0 && (
                            <Col className="col-auto">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: loadingAnim,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                                height={46}
                                width={120}
                              />
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="py-4">
                        <Row>
                          <Col>
                            <div>
                              <h5>{props.t("Geofence")}</h5>
                              <span className="text-muted">
                                {tracker?.geofenceEnabled
                                  ? props.t(tracker.geoStatus) +
                                    ", " +
                                    tracker.geofenceRadius +
                                    "m"
                                  : props.t(tracker.geoStatus)}
                              </span>
                            </div>
                          </Col>
                          {!tracker.geofenceSetupStarted && (
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                disabled={
                                  ongoingAction ||
                                  tracker?.geofenceSetupStarted > 0
                                }
                                onClick={() => {
                                  setGeofenceModal(true)
                                }}
                              >
                                {props.t("Change")}
                              </Button>
                            </Col>
                          )}
                          {tracker.geofenceSetupStarted > 0 && (
                            <Col className="col-auto">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: loadingAnim,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                                height={46}
                                width={120}
                              />
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>

                      {/**userRole === "system_admin" && (
                        <ListGroupItem className="py-4">
                          <Row>
                            <Col>
                              <div>
                                <h5>{props.t("SIM status")}</h5>
                                <span className="text-muted">
                                  {props.t(tracker.simStatus)}
                                </span>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                style={{minWidth: "100px"}}
                                disabled
                                onClick={() => {}}
                              >
                                {props.t("Change")}
                              </Button>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      )*/}

                      <ListGroupItem className="py-4">
                        <Row>
                          <Col>
                            <div>
                              <h5>{props.t("Delete collected data")}</h5>
                              <span className="text-muted">
                                {props.t("Delete tracker data description")}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Popconfirm
                              title={props.t("Confirm action")}
                              open={deleteConfirm}
                              okType="danger"
                              okText={props.t("Delete")}
                              cancelText={props.t("Cancel")}
                              onConfirm={() => {
                                deleteHistory()
                                setDeleteConfirm(false)
                              }}
                              onCancel={() => {
                                setDeleteConfirm(false)
                              }}
                            >
                              <Button
                                color="danger"
                                style={{ minWidth: "120px" }}
                                disabled={ongoingAction}
                                onClick={() => {
                                  setDeleteConfirm(true)
                                }}
                              >
                                {props.t("Delete")}
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </TabPane>

                  {/** SMS LOGS */}
                  {/*userRole === "system_admin" && (
                    <TabPane className="show" tabId="4">
                      <TrackerSmsTab
                        gsm={tracker?.trackerGsmNumber}
                        serverNumber={tracker?.serverNumber}
                      ></TrackerSmsTab>
                    </TabPane>
                  )*/}
                </TabContent>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <Modal
        isOpen={geofenceModal}
        toggle={() => {
          setGeofenceModal(!geofenceModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Geofence")}</ModalHeader>
        <ModalBody>
          <Alert color="info">{props.t("Geofence info")}</Alert>
          <FormGroup>
            <Label>{props.t("Geofence radius")}</Label>
            <Input
              name="radius"
              type="number"
              value={geofenceRadius}
              onChange={v => {
                setGeofenceRadius(v.target.value)
              }}
            ></Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setGeofenceModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingAction}
            color="primary"
            onClick={() => {
              setGeofence()
            }}
          >
            {props.t("Set")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

OnTheGoServices.propTypes = {
  t: PropTypes.any,
  trackerKey: PropTypes.string,
}

export default withTranslation()(OnTheGoServices)
