import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
// import UserRoleDropdown from "../CommonForBoth/TopbarDropdown/UserRoleDropdown"
import ThemeDropdown from "../CommonForBoth/TopbarDropdown/ThemeDropdown"

//Settings
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import suvantoLogoFull from "../../assets/images/suvanto-logo-long.png"
import suvantoLogo from "../../assets/images/suvanto-logo-small.png"

//i18n
import { withTranslation } from "react-i18next"
import Calendar from "components/CommonForBoth/TopbarDropdown/Calendar"

const Header = props => {
  const [isTestField, setIsTestField] = useState(
    window.location.href.includes("test.suvanto.care") ||
      window.location.href.includes("beta.suvanto.care")
  )

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={
          isTestField
            ? { border: "1px", borderColor: "#8dd169", borderStyle: "solid" }
            : {}
        }
      >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={suvantoLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={suvantoLogoFull} alt="" height="25" />
                </span>{" "}
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={suvantoLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={suvantoLogoFull} alt="" height="25" />
                </span>
              </Link>
              {/* Style 1 logo */}
              <Link to="/" className="logo logo-1">
                <span className="logo-sm">
                  <img src={suvantoLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={suvantoLogoFull} alt="" height="25" />
                </span>
              </Link>
              {/* Style 2 logo */}
              <Link to="/" className="logo logo-2">
                <span className="logo-sm">
                  <img src={suvantoLogo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={suvantoLogoFull} alt="" height="25" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          {isTestField ? (
            <div className="text-door h4 text-uppercase mt-2 pt-1">
              {props.t("Test environment")}
            </div>
          ) : (
            <></>
          )}

          <div className="d-flex">
            <LanguageDropdown />
            <ThemeDropdown />
            {/* <NotificationDropdown /> */}
            {/* <Calendar /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

export default withTranslation()(Header)
