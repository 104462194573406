import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  Alert,
  FormFeedback,
  Input,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { Divider, Switch } from "antd"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import { sendVideoBulkMessage } from "helpers/backend_helper"
import withRouter from "components/Common/withRouter"
import { showToast } from "utils/general"
import { Chip } from "@material-ui/core"

const VideoMessageModal = props => {
  const abortRef = useRef(null)
  const [keys, setKeys] = useState(props.keys)
  const [clientNames, setClientNames] = useState([])
  const [showAll, setShowAll] = useState(false)
  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)
  // Video message entry
  //const [newMessageVideo, setNewMessageVideo] = useState("")
  const [newResponseOption, setNewResponseOption] = useState(1)
  const [newExpirationTime, setNewExpirationTime] = useState(15)
  const [newAlarmExpiration, setNewAlarmExpiration] = useState(false)
  const [audioAlert, setAudioAlert] = useState(true)
  // Modal
  const [modal, setModal] = useState(props.modal)
  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  //Chips
  const [displayedNames, setDisplayedNames] = useState([])
  const [remainingNames, setRemainingNames] = useState([])
  // Slider expiration time
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 45,
      label: "45",
    },
    {
      value: 60,
      label: "60",
    },
  ]

  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.keys) {
      const keysString = props.keys.join(", ")
      setKeys(keysString)
    }
  }, [props.keys])

  useEffect(() => {
    setModal(props.modal)
  }, [props.modal])

  useEffect(() => {
    if (props.names) {
      setClientNames(props.names)
    }
  }, [props.names])

  useEffect(() => {
    if (clientNames.length > 0) {
      let names = showAll ? clientNames : clientNames.slice(0, 5)
      setDisplayedNames(names)
      let remaining = clientNames.length - 10
      setRemainingNames(remaining)
    }
  }, [showAll, clientNames])

  const handleResponseOptions = e => {
    setNewResponseOption(e)
  }
  // Setting alarm
  const settingExAlarm = () => {
    setNewAlarmExpiration(!newAlarmExpiration)
  }

  // Setting audio alarm
  const settingAudioAlarm = () => {
    setAudioAlert(!audioAlert)
  }

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  // format slider
  const handleSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setNewExpirationTime(newValue)
    }
  }

  // Formatting minutes
  const formatMinutes = value => {
    let Hours = Math.floor(value / 60)
    let minutes = value % 60

    let resultH = ""
    let resultM = ""

    if (Hours > 0) {
      resultH = Hours + "h"
    }
    if (Hours == 0) {
      resultH = ""
    }
    if (minutes > 0) {
      resultM = minutes + "min"
    }
    if (minutes == 0) {
      resultM = ""
    }
    if (minutes == 0 && Hours == 0) {
      resultM = minutes + "min"
    }

    return resultH + " " + resultM
  }

  //  ** START OF MODAL FUNCTIONS ** //
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  
  const openMessageModal = () => {
    removeBodyCss()
    //setNewMessageVideo("")
    props.settingModal(!modal)
  }

  // New video message
  const validationVideoMessage = useFormik({
    enableReinitialize: true,
    initialValues: {
      newMessageVideo: "",
    },
    validationSchema: Yup.object({
      newMessageVideo: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(50, props.t("Message is too long")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      sendVideoBulkMessage(
        abortRef.current,
        keys,
        values.newMessageVideo,
        newResponseOption,
        newExpirationTime,
        newAlarmExpiration,
        audioAlert
      )
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            //setSuccessMessage("Success")
            showToast(props.t("Message sent"), "success")
            openMessageModal()
            props.updateLogs()
            // setEndDate(moment())

            // Set to formik
            validationVideoMessage.resetForm()
            //setNewMessageVideo("")
            setNewResponseOption(1)
            setNewExpirationTime(15)
            setNewAlarmExpiration(false)
            setAudioAlert(true)
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => {
          props.settingModal(!modal)
        }}
        centered
      >
        <ModalHeader>{props.t("Send a message to video devices")}</ModalHeader>
        <Form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <ModalBody>
            <div className="mt-1">
              {errorMessage ? (
                <Alert color="danger">{props.t(errorMessage)}</Alert>
              ) : null}
              <Label>{props.t("Message")}</Label>
              <Input
                type="text"
                maxLength="50"
                name="newMessageVideo"
                onChange={validationVideoMessage.handleChange}
                onBlur={validationVideoMessage.handleBlur}
                value={validationVideoMessage.values.newMessageVideo || ""}
                invalid={
                  validationVideoMessage.touched.newMessageVideo &&
                  validationVideoMessage.errors.newMessageVideo
                    ? true
                    : false
                }
                className="form-control"
                placeholder={props.t("max. 50 characters")}
              />
              {validationVideoMessage.touched.newMessageVideo &&
              validationVideoMessage.errors.newMessageVideo ? (
                <FormFeedback type="invalid">
                  {validationVideoMessage.errors.newMessageVideo}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mt-4">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{props.t("Response options")}</Label>
                    <Input
                      name="stage"
                      type="select"
                      value={newResponseOption}
                      onChange={e => handleResponseOptions(e.target.value)}
                    >
                      <option value={1}>{props.t("Ok")}</option>
                      <option value={2}>{props.t("Yes / No")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              <Label>
                {props.t("Message expiration time")}{" "}
                {formatMinutes(newExpirationTime)}
              </Label>
              <div className="p-2">
                <Box sx={{ width: "100%" }}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    defaultValue={30}
                    getAriaValueText={formatMinutes}
                    valueLabelFormat={formatMinutes}
                    step={1}
                    onChange={handleSlider}
                    marks={marks}
                    min={0}
                    value={newExpirationTime}
                    max={60}
                  />
                </Box>
              </div>
            </div>
            <Row>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Expiration alarm")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={newAlarmExpiration}
                      onChange={settingExAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                  </FormGroup>
                </div>
              </Col>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Message tone")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={audioAlert}
                      onChange={settingAudioAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                    <div id="info-circle" className=" d-md-inline-block">
                      <Button
                        className="pz-2 py-0"
                        size="sm"
                        style={{ cursor: "default" }}
                        color="tranparent"
                        onClick={() => {}}
                      >
                        <i className="bx bx-info-circle font-size-10 " />
                      </Button>
                    </div>
                    <UncontrolledTooltip placement="right" target="info-circle">
                      {props.t("Video device message tone on/off")}
                    </UncontrolledTooltip>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Divider
              orientation="left"
              className="font-size-13 mt-1"
              orientationMargin="0"
            >
              {props.t("Receivers")}
            </Divider>
            <div>
              {displayedNames.map((name, index) => (
                <Chip
                  size="small"
                  key={index}
                  className="mt-0 mb-2 font-size-12 me-1"
                  label={name}
                ></Chip>
              ))}
              {remainingNames > 0 && !showAll && (
                <Chip
                  size="small"
                  className="mt-0 mb-2 font-size-12 me-1"
                  label={`+ ${remainingNames} ${props.t("others")}`}
                />
              )}
              {remainingNames > 0 && (
                <button onClick={toggleShowAll} className="btn btn-link btn-sm">
                  {showAll ? props.t("Hide") : props.t("Show all")}
                </button>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="">
            <div className="float-end">
              <button
                type="button"
                onClick={() => {
                  openMessageModal()
                }}
                className="btn btn-outline-primary "
                data-dismiss="modal"
              >
                {props.t("Cancel")}
              </button>

              <button
                disabled={saveDisable}
                className="btn btn-primary ms-2"
                onClick={() => {
                  validationVideoMessage.submitForm()
                }}
              >
                {props.t("Send")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

VideoMessageModal.propTypes = {
  t: PropTypes.any,
  clientId: PropTypes.any,
  modal: PropTypes.any,
  names: PropTypes.any,
  settingMOdal: PropTypes.any,
  updateLogs: PropTypes.func,
}

export default withRouter(withTranslation()(VideoMessageModal))
