import React, { useEffect, useRef, useState } from "react"
import {
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Switch } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { registerVideoClients } from "helpers/backend_helper"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"
import VideoGroupTree from "components/DropdownList/VideoGroupTree"

const RegisterVideoRoomModal = props => {
  const [loading, setLoading] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newClientCount: 1,
      production: true,
      clientEmail: "e164@suvanto.video",
      clientPassword: "",
      groupCallHost: false,
      groupCallGuest: false,
      groupId: undefined,
    },
    validationSchema: Yup.object({
      newClientCount: Yup.number()
        .min(1, "Min 1")
        .max(10, "Max 10")
        .required(props.t("Required field")),
      clientPassword: Yup.string().min(8, props.t("Password is too short")),
      clientEmail: Yup.string()
        .email(props.t("Not a valid email"))
        .required("Please enter email"),
    }),
    onSubmit: values => {
      setLoading(true)
      //console.log("Register: ", values)
      registerVideoClients(
        abortRef.current,
        values.newClientCount,
        values.clientEmail,
        values.clientPassword,
        values.groupCallHost,
        values.groupCallGuest,
        values.groupId,
        values.production
      )
        .then(result => {
          console.log(result)
          setLoading(false)
          if (result.statusCode == 200) {
            props.setCreateModal(false)
            let message =
              result.roomList.length + " " + props.t("rooms registered")
            if (props.roomsCreated)
              props.roomsCreated(result.roomList.join(", "))
            showToast(message, "success")
            props.getRooms()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          showToast(props.t("Error"), "error")
        })
    },
  })

  // Reset validation form when modal closed
  useEffect(() => {
    validation.resetForm()
  }, [props.createModal])

  // Clear group selection
  useEffect(() => {
    validation.setFieldValue("groupId", undefined)
  }, [validation.values.production])

  // Group tree selection changed
  const onChange = (newValue) => {
    console.log("Tree value: ", newValue)
    if (newValue == null)
      validation.setFieldValue("groupId", undefined)
    else
      validation.setFieldValue("groupId", parseInt(newValue, 10))
  }

  return (
    <>
      <Modal
        isOpen={props.createModal}
        toggle={() => {
          props.setCreateModal(!props.createModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Register numbers")}</ModalHeader>
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <ModalBody>

            <FormGroup className="">
              <Switch
                className="me-2"
                name="production"
                checked={validation.values.production}
                onChange={v => validation.setFieldValue("production", v)}
              ></Switch>
              <Label>{props.t("Production platform")}</Label>
            </FormGroup>

            <FormGroup>
              <Label>{props.t("Count")}</Label>
              <Input
                name="newClientCount"
                type="number"
                onChange={v => {
                  v.target.value.length > 0
                    ? validation.setFieldValue(
                        "newClientCount",
                        parseInt(v.target.value, 10)
                      )
                    : 1
                }}
                onBlur={validation.handleBlur}
                value={validation.values.newClientCount}
                invalid={
                  validation.touched.newClientCount &&
                  validation.errors.newClientCount
                    ? true
                    : false
                }
              />
              {validation.touched.newClientCount &&
              validation.errors.newClientCount ? (
                <FormFeedback type="invalid">
                  {validation.errors.newClientCount}
                </FormFeedback>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>{props.t("Email")}</Label>
              <Input
                name="clientEmail"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.clientEmail || ""}
                invalid={
                  validation.touched.clientEmail &&
                  validation.errors.clientEmail
                    ? true
                    : false
                }
              />
              {validation.touched.clientEmail &&
              validation.errors.clientEmail ? (
                <FormFeedback type="invalid">
                  {validation.errors.clientEmail}
                </FormFeedback>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label>{props.t("Password")}</Label>
              <Input
                name="clientPassword"
                type="text"
                placeholder={props.t("Optional")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.clientPassword || ""}
                invalid={
                  validation.touched.clientPassword &&
                  validation.errors.clientPassword
                    ? true
                    : false
                }
              />
              {validation.touched.clientPassword &&
              validation.errors.clientPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.clientPassword}
                </FormFeedback>
              ) : null}
            </FormGroup>

            <Row xs={2} className="mt-4">
              <Col>
                <FormGroup>
                  <Switch
                    className="me-2"
                    name="groupCallHost"
                    checked={validation.values.groupCallHost}
                    onChange={v => validation.setFieldValue("groupCallHost", v)}
                  ></Switch>
                  <Label>{props.t("Group host")}</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Switch
                    className="me-2"
                    name="groupCallGuest"
                    checked={validation.values.groupCallGuest}
                    onChange={v => validation.setFieldValue("groupCallGuest", v)}
                  ></Switch>
                  <Label>{props.t("Group guest")}</Label>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>{"AC " + props.t("Group")}</Label>
              <VideoGroupTree 
                selected={validation.values.groupId}
                selectionChanged={onChange}
                expanded={true}
                production={validation.values.production}
              />
            </FormGroup>

          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => {
                props.setCreateModal(false)
              }}
            >
              {props.t("Cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {validation.submitForm()}}
            >
              {props.t("Register")}
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

RegisterVideoRoomModal.propTypes = {
  t: PropTypes.any,
  createModal: PropTypes.bool,
  setCreateModal: PropTypes.any,
  getRooms: PropTypes.func,
  roomsCreated: PropTypes.func,
}

export default withRouter(withTranslation()(RegisterVideoRoomModal))
