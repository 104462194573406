import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Collapse,
  Button,
  Card,
  CardBody,
  CardTitle,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer, Steps, Select } from "antd"
//import "antd/dist/antd.css" // Aiheuttaa compile varoituksia 
import "antd/dist/antd.min.css"
import {
  getClientData,
  getVideoCallToken,
  resolveAlarms,
  ackAlarms,
} from "../../../helpers/backend_helper"
import { formatDateFull, showToast } from "utils/general"
import { Link } from "react-router-dom"
import GlobalState from "contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const AlarmMonitoringDrawer = props => {
  const [state, setState] = useContext(GlobalState)
  const [propsTemp, setPropsTemp] = useState()
  const [status, setStatus] = useState(0)
  const [showAlarmStatus, setShowAlarmStatus] = useState(false)
  const [manageAlarmCollapse, setManageAlarmCollapse] = useState(false)
  const [alert, setAlert] = useState(false)
  const [ongoingAction, setOngoingAction] = useState(false)

  // Alert settings content
  const [alarmKey, setAlarmKey] = useState()
  const [newMessage, setNewMessage] = useState("")
  const [cause, setCause] = useState()
  const [actions, setActions] = useState()
  const [falseAlarm, setFalseAlarm] = useState(false)
  const [continueAlarm, setContinueAlarm] = useState(false)

  // Selected client states
  const [selectedClient, setSelectedClient] = useState()
  const [clientInfo, setClientInfo] = useState([])
  const [showInfo, setShowInfo] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selectedRow != undefined && props.selectedRow != propsTemp) {
      // console.log(props.selectedRow, props.showDrawer, "selectedRow")
      setAlarmKey(props.selectedRow.key)
      setSelectedClient(props.selectedRow.client_key)
      setPropsTemp(props.selectedRow)
      setManageAlarmCollapse(false)
      setAlert(false)
      if (props.showDrawer == true) {
        // console.log(props)
        setShowAlarmStatus(true)

        let username
        if (state.authUser != null) {
          const obj = JSON.parse(state.authUser)
          username = obj.username
        }

        if (props.selectedRow.status == "UNASSIGNED") {
          setStatus(0)
        }
        if (props.selectedRow.status == "ASSIGNED") {
          setStatus(1)
          if (
            props.selectedRow.lockedToUser == false ||
            (props.selectedRow.lockedToUser == true &&
              props.selectedRow.acked_by_username == username)
          ) {
            setManageAlarmCollapse(true)
          }
        }
        if (props.selectedRow.status == "CLOSED") {
          setStatus(2)
        }
        if (props.selectedRow.status == false) {
          setStatus(2)
        }
      }
    }
  }, [props.selectedRow])

  // Fetch client data
  useEffect(() => {
    setShowInfo(false)
    if (selectedClient !== undefined) {
      getClientData(abortRef.current, selectedClient, false)
        .then(result => {
          console.log(result)
          setClientInfo(result)
          setShowInfo(true)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }
  }, [selectedClient])

  const settingFalseAlarm = e => {
    e.persist()
    setFalseAlarm(prevState => !prevState)
  }

  const settingContinueAlarm = e => {
    e.persist()
    setContinueAlarm(prevState => !prevState)
  }

  // Handling the alarm
  const manageAlarm = () => {
    setOngoingAction(true)
    let alarms = [alarmKey]
    ackAlarms(abortRef.current, alarms)
      .then(result => {
        console.log(result)
        if (result.length == 1 && result[0].result == "OK") {
          setManageAlarmCollapse(true)
          setStatus(1)
          props.setUpdateRequired(true)
        } else {
          showToast(props.t("Error"), "error", 8000)
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error", 8000)
      })
      .finally(() => {
        setOngoingAction(false)
      })
  }

  // Closing alarm
  const closeAlarm = () => {
    setOngoingAction(true)
    let alarms = [alarmKey]
    resolveAlarms(
      abortRef.current,
      alarms,
      newMessage,
      falseAlarm,
      cause,
      actions,
      continueAlarm
    )
      .then(result => {
        console.log(result)
        if (result.length == 1 && result[0].result == "OK") {
          setAlert(true)
          // TODO: DELETE
          setStatus(2)
          setManageAlarmCollapse(false)
          props.setUpdateRequired(true)
        } else {
          showToast(props.t("Error"), "error", 8000)
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error", 8000)
      })
      .finally(() => {
        setOngoingAction(false)
      })
  }

  const onChange = value => {
    // onChange does not work, but has to be included in checkbox
    // console.log(value, "checkbox is alive")
  }

  // Setting alarm options
  const settingCause = c => {
    setCause(c)
  }
  // Setting actions
  const settingActions = a => {
    setActions(a)
  }

  // Start video call
  const startVideoCall = key => {
    getVideoCallToken(abortRef.current, key)
      .then(result => {
        if (result.link != null && result.link.length > 0) {
          window.open(result.link, "_blank", "noreferrer")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Answer options
  const reason = [
    {
      id: "reasonEmpty",
      value: "",
      label: "",
    },
    {
      id: "reason0",
      value: "R120",
      label: props.t("AlarmReasons." + "R120"),
    },
    {
      id: "reason1",
      value: "R090",
      label: props.t("AlarmReasons." + "R090"),
    },
    {
      id: "reason2",
      value: "R030",
      label: props.t("AlarmReasons." + "R030"),
    },
    {
      id: "reason3",
      value: "R040",
      label: props.t("AlarmReasons." + "R040"),
    },
    {
      id: "reason4",
      value: "R100",
      label: props.t("AlarmReasons." + "R100"),
    },
    {
      id: "reason5",
      value: "R050",
      label: props.t("AlarmReasons." + "R050"),
    },
    {
      id: "reason6",
      value: "R060",
      label: props.t("AlarmReasons." + "R060"),
    },
    {
      id: "reason7",
      value: "R130",
      label: props.t("AlarmReasons." + "R130"),
    },
    {
      id: "reason8",
      value: "R080",
      label: props.t("AlarmReasons." + "R080"),
    },
    {
      id: "reason9",
      value: "R110",
      label: props.t("AlarmReasons." + "R110"),
    },
    {
      id: "reason10",
      value: "R131",
      label: props.t("AlarmReasons." + "R131"),
    },
    {
      id: "reason11",
      value: "R010",
      label: props.t("AlarmReasons." + "R010"),
    },
    {
      id: "reason12",
      value: "R020",
      label: props.t("AlarmReasons." + "R020"),
    },
    {
      id: "reason13",
      value: "R070",
      label: props.t("AlarmReasons." + "R070"),
    },
    {
      id: "reason14",
      value: "R071",
      label: props.t("AlarmReasons." + "R071"),
    },
    {
      id: "reason15",
      value: "R000",
      label: props.t("AlarmReasons." + "R000"),
    },
  ]

  const action = [
    {
      id: "actionEmpty",
      value: "",
      label: "",
    },
    {
      id: "action0",
      value: "A030",
      label: props.t("AlarmActions." + "A030"),
    },
    {
      id: "action1",
      value: "A033",
      label: props.t("AlarmActions." + "A033"),
    },
    {
      id: "action2",
      value: "A032",
      label: props.t("AlarmActions." + "A032"),
    },
    {
      id: "action3",
      value: "A031",
      label: props.t("AlarmActions." + "A031"),
    },
    {
      id: "action4",
      value: "A020",
      label: props.t("AlarmActions." + "A020"),
    },
    {
      id: "action5",
      value: "A000",
      label: props.t("AlarmActions." + "A000"),
    },
    {
      id: "action6",
      value: "A001",
      label: props.t("AlarmActions." + "A001"),
    },
    {
      id: "action7",
      value: "A010",
      label: props.t("AlarmActions." + "A010"),
    },
  ]

  return (
    <>
      <Drawer
        title="" //{props.t("Alarm")}
        width="780px"
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
        }}
        open={props.showDrawer}
        zIndex={1003}
        //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <CardTitle>
          <i className="bx bxs-bell-ring text-truncate align-middle me-2"></i>
          {props.t("Alarm status")}
        </CardTitle>
        {showAlarmStatus == true && (
          <Card>
            <CardBody style={{ paddingBottom: "0px" }}>
              <Row>
                <Col sm={7}>
                  <Steps
                    style={{ height: "230px" }}
                    size="small"
                    current={status}
                    direction="vertical"
                    items={[
                      {
                        title: props.t("Alarm received"),
                        icon: <i className="mdi mdi-arrow-collapse-down"></i>,
                        subTitle: props.selectedRow.received_at ? (
                          <span>
                            {formatDateFull(props.selectedRow.received_at)}
                          </span>
                        ) : (
                          <span></span>
                        ),
                        description: props.selectedRow.acked_by ? (
                          <span></span>
                        ) : (
                          <>
                            {/* TODO: DELETE THIS AND UPDATE PROPS /  */}
                            {manageAlarmCollapse === false && status === 0 && (
                              <button
                                type="button"
                                disabled={ongoingAction}
                                className="btn btn-outline-primary mb-2 mt-1"
                                onClick={() => {
                                  manageAlarm()
                                }}
                              >
                                {props.t("Handle the alarm")}
                              </button>
                            )}
                          </>
                        ),
                      },
                      {
                        title: props.t("Alarm acknowledged"),
                        icon: <i className="bx bx-rotate-right"></i>,
                        subTitle: props.selectedRow.acked_at ? (
                          <span>
                            {formatDateFull(props.selectedRow.acked_at)}
                          </span>
                        ) : (
                          <span></span>
                        ),
                        description: props.selectedRow.acked_by ? (
                          <span>
                            <i className="bx bx-user me-1" />
                            {props.selectedRow.acked_by}
                          </span>
                        ) : (
                          <span></span>
                        ),
                      },
                      {
                        title: props.t("Alarm closed"),
                        icon: <i className="bx bxs-check-circle"></i>,
                        subTitle: props.selectedRow.closed_at ? (
                          <span>
                            {formatDateFull(props.selectedRow.closed_at)}
                          </span>
                        ) : (
                          <span></span>
                        ),
                        description: props.selectedRow.closed_by ? (
                          <span>
                            <i className="bx bx-user me-1" />
                            {props.selectedRow.closed_by}
                          </span>
                        ) : (
                          <span></span>
                        ),
                      },
                    ]}
                  ></Steps>
                </Col>
                <Col sm={5}>
                  <h5 className="mt-1 me-3">
                    {props.t(props.selectedRow.description)}
                  </h5>
                  {/* <p className="text-muted mt-4 me-3"> */}
                  {/* TODO: SET DYNAMIC DESCRIPTION */}
                  {/* "Laitteen akku tulee ladata mahdollisimman pian."
                  </p> */}
                  <div className="mt-4 h6 text-muted">
                    <i className="bx bxs-user-circle me-1"></i>
                    {props.selectedRow.client}
                  </div>
                  <div className="h6 text-muted">
                    <i className="mdi mdi-account-group me-1"></i>
                    {props.selectedRow.group}
                  </div>
                  <div>
                    {status === 0 && (
                      <span className="badge bg-danger font-size-12 p-2">
                        <i className="mdi mdi-alert-circle-outline"></i>{" "}
                        {props.t("Unassigned")}
                      </span>
                    )}
                    {status === 1 && (
                      <span className="badge bg-warning font-size-12 p-2">
                        <i className=" mdi mdi-alert-circle-outline"></i>{" "}
                        {props.t("Assigned")}
                      </span>
                    )}
                    {status === 2 && (
                      <span className="badge bg-success font-size-12 p-2">
                        <i className="bx bx-check-circle"></i>{" "}
                        {props.t("Alarm closed")}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {alert === true && (
          <UncontrolledAlert color="success">
            {props.t("The alarm was closed successfully!")}
          </UncontrolledAlert>
        )}
        <Collapse isOpen={manageAlarmCollapse}>
          <div className="">
            <CardTitle>
              <i className="mdi mdi-bell-check text-truncate align-middle me-2"></i>
              {props.t("Alarm management")}
            </CardTitle>
            <Card>
              <CardBody>
                {/* <hr className="mt-0"></hr> */}
                <Row className="mt-0">
                  <Col sm={6}>
                    <Label>{props.t("Cause of the alarm")}</Label>
                    <div>
                      <Select
                        style={{ width: "100%" }}
                        value={cause}
                        onChange={settingCause}
                        options={reason}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Label>{props.t("Actions")}</Label>
                    <div>
                      <Select
                        style={{ width: "100%" }}
                        value={actions}
                        onChange={settingActions}
                        options={action}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Label className="mt-3">{props.t("Comment")}</Label>
                  </Col>
                  <Col sm={7}>
                    <Input
                      type="textarea"
                      rows="2"
                      maxLength="225"
                      value={newMessage}
                      onChange={e => setNewMessage(e.target.value)}
                      className="form-control"
                      placeholder={props.t("")}
                    />
                  </Col>
                  <Col sm={5}>
                    <div className="mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input checkbox "
                        id="checkbox_0"
                        name="checkboxFalse"
                        checked={falseAlarm}
                        onClick={settingFalseAlarm}
                        onChange={onChange}
                      />
                      <label
                        className="form-check-label ms-1"
                        htmlFor="checkbox_0"
                      >
                        {props.t("False alarm")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="form-check-input checkbox "
                        id="checkbox_1"
                        name="checkboxContinue"
                        checked={continueAlarm}
                        onClick={settingContinueAlarm}
                        onChange={onChange}
                      />
                      <label
                        className="form-check-label ms-1 text-truncate"
                        htmlFor="checkbox_1"
                      >
                        {props.t("Create handover alarm")}
                      </label>
                    </div>
                  </Col>
                </Row>
                <Col lg={12} className="mt-3">
                  <Button
                    color="primary"
                    className="float-end"
                    disabled={ongoingAction}
                    onClick={() => {
                      closeAlarm()
                    }}
                  >
                    {props.t("Close alarm")}
                  </Button>
                </Col>
              </CardBody>
            </Card>{" "}
          </div>
        </Collapse>
        {showInfo ? (
          <>
            {clientInfo ? (
              <>
                <CardTitle>
                  {props.selectedRow != undefined && (
                    <span className="float-end">
                      <Link
                        className="h6"
                        target="_blank"
                        to={`/clients/${props.selectedRow.client_key}`}
                      >
                        {props.t("Move to customer's management")}
                        <i className="bx bx-right-arrow-alt ms-1"></i>
                      </Link>
                    </span>
                  )}

                  <i className="bx bxs-user-circle text-truncate align-middle me-2"></i>
                  {props.t("Customer profile")}
                </CardTitle>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={6} className="mt-1 mb-3">
                        <h6>{props.t("Name")}</h6>
                        <p>{clientInfo.name}</p>
                      </Col>
                      {clientInfo.ssn != "" && clientInfo.ssn == true && (
                        <Col xs={6} className="mt-1  mb-3">
                          <h6>{props.t("SSN")}</h6>
                          <p>{clientInfo.ssn}</p>
                        </Col>
                      )}
                      {clientInfo.address != "" &&
                        clientInfo.address == true && (
                          <Col xs={6} className="mt-1  mb-3">
                            <h6>{props.t("Address")}</h6>
                            <p className="mb-1">{clientInfo.address} </p>
                            {clientInfo.coordinates ? (
                              <div>
                                <i className="mdi mdi-google-maps me-1 text-primary"></i>
                                <a
                                  href={
                                    "https://www.google.com/maps/place/" +
                                    clientInfo.coordinates.latitude +
                                    "," +
                                    clientInfo.coordinates.longitude
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {props.t("Open in Google Maps")}
                                </a>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Col>
                        )}
                      {clientInfo.gsmNumber != "" &&
                        clientInfo.gsmNumber == true && (
                          <Col xs={6} className="mt-1 mb-3">
                            <h6>{props.t("Phone")}</h6>
                            <a href={"tel:" + clientInfo.gsmNumber}>
                              {clientInfo.gsmNumber}
                            </a>
                          </Col>
                        )}

                      {clientInfo.tracker ? (
                        <>
                          {clientInfo.tracker.gsm ? (
                            <Col xs={6} className="mt-1 mb-3">
                              <h6>{props.t("Tracker")}</h6>
                              <a href={"tel:" + clientInfo.tracker.gsm}>
                                {clientInfo.tracker.gsm}
                              </a>
                            </Col>
                          ) : null}
                        </>
                      ) : null}
                      {clientInfo.carephone ? (
                        <>
                          {clientInfo.carephone.gsm ? (
                            <Col xs={6} className="mt-1 mb-3">
                              <h6>{props.t("Safety phone")}</h6>
                              <a href={"tel:" + clientInfo.carephone.gsm}>
                                {clientInfo.carephone.gsm}
                              </a>
                            </Col>
                          ) : null}
                        </>
                      ) : null}
                      {clientInfo.videoCallRoomName ? (
                        <Col xs={6} className="mt-1  mb-3">
                          <h6>{props.t("Video call")}</h6>
                          <div>
                            {/* {clientInfo.videoCallRoomName} */}
                            <Link
                              to="#"
                              onClick={() => {
                                startVideoCall(clientInfo.videoCallRoomName)
                              }}
                            >
                              {props.t("Start video call")}
                            </Link>
                          </div>
                        </Col>
                      ) : null}
                      {clientInfo.alarm_center_notices ? (
                        <Col xs={12} className="mt-1">
                          <h6>{props.t("Notices")}</h6>
                          <p>{clientInfo.alarm_center_notices}</p>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {clientInfo.home_service_info ? (
                        <Col xs={12} className="mt-1  mb-3">
                          <h6>{props.t("Home help service")}</h6>
                          <p>{clientInfo.home_service_info}</p>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {clientInfo.door_info ? (
                        <Col xs={6} className="mt-1  mb-3">
                          <h6>{props.t("Opening the door")}</h6>
                          <p>{clientInfo.door_info}</p>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {clientInfo.alarm_center_procedure ? (
                        <Col xs={6} className="mt-1  mb-3">
                          <h6>{props.t("Procedure")}</h6>
                          <p>{clientInfo.alarm_center_procedure}</p>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </Drawer>
    </>
  )
}

AlarmMonitoringDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getRooms: PropTypes.func,
  setUpdateRequired: PropTypes.func,
}

export default withRouter(withTranslation()(AlarmMonitoringDrawer))
