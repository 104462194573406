import React from "react"
import { Container, Row, Col } from "reactstrap"
import packageInfo from "../../../package.json"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col xs={8}>
              <div className="text-sm d-none d-sm-block">
                {/* Version number + build */}
                {/* {"v." + packageInfo.version + " build " + packageInfo.build} */}
                {/* Only version number (version release) */}
                {"v." + packageInfo.version}
              </div>
            </Col>
            <Col xs={4}>
              <div className="text-sm-end d-none d-sm-block">
                {new Date().getFullYear()} | Suvanto Care
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
