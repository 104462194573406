import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link, useParams } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { checkIfValidUUID } from "utils/general"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import Lottie from "react-lottie"
import { getStatusReport } from "helpers/database_helper"
import moment from "moment"
import suvantoLogo from "assets/images/Suvanto_logo2_transparent.png"
import sanitizeHtml from 'sanitize-html';

const PublicDisturbanceNotice = props => {

  const [bulletin, setBulletin] = useState(null)
  const [loading, setLoading] = useState(true)
  const [targets, setTargets] = useState([])
  const [bodyTexts, setBodyTexts] = useState([])

  const params = useParams()
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Disturbance notice") + " | Suvanto Care"
    abortRef.current = new AbortController()

    // Check if valid params
    if (params && params.id != undefined && checkIfValidUUID(params.id)) {
      getBulletin(params.id)
    }
    else {
      setLoading(false)
    }

    return () => abortRef.current.abort()
  }, [])

  // Get error bulletin
  const getBulletin = (id) => {
    setLoading(true)
    getStatusReport(abortRef.current, id).then(result => {
      if (result.length == 1 && result[0].guid == params.id) {

        // Sanitize html content
        const cleanDescription = sanitizeHtml(result[0].description_fi, {
          allowedTags: [ 'b', 'i', 'em', 'strong', 'a' ],
          allowedAttributes: {
            'a': [ 'href', 'target' ]
          },
          nonBooleanAttributes: [],
        });

        let splitted = cleanDescription.split("\n")
        setBodyTexts(splitted)

        if (result[0].start_time) result[0].informed_date = moment(result[0].start_time).format("DD.MM.YYYY")
        if (result[0].start_time) result[0].start_time = moment(result[0].start_time).format("DD.MM.YYYY HH:mm")
        if (result[0].end_time) result[0].end_time = moment(result[0].end_time).format("DD.MM.YYYY HH:mm")

        let targets = []
        let split = result[0].target_groups.split(",")
        split.forEach(target => {
          switch (target) {
            case "1": targets.push("Suvanto Care app"); break;
            case "2": targets.push("Video call"); break;
            case "3": targets.push("Group video call"); break;
            case "4": targets.push("Carephone"); break;
            case "5":targets.push("Medicine reminder"); break;
            case "6":targets.push("Suvanto On the Go"); break;
            case "7":targets.push("Suvanto Home"); break;
            case "8":targets.push("Smartlock"); break;
            case "9":targets.push("Suvanto Care webpage"); break;
          }
        })
        setTargets(targets)
        setBulletin(result[0])
      }
      //console.log("Bulletin: ", result)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }
  
  return (
    <>
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} xl={10} xxl={9}>

              {loading && <>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingAnim,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={100}
                  width={100}
                />
              </>}

              {!loading && !bulletin && <>
                <Card style={{maxWidth: "1000px"}}>
                  <CardBody>
                    <div className="text-center mb-4">
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: nodataAnim,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        height={150}
                        width={150}
                      />
                      <h5 className="text-muted mt-2">
                        {props.t("No notice found")}
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </>}

              {!loading && bulletin && <>
                <Card style={{maxWidth: "1000px"}}>
                  <CardBody className="p-5">

                    <h3 className="text-primary">{props.t("Disturbance notice")}</h3>
                    <h6>{bulletin.informed_date}</h6>

                    <img
                      className="d-none d-md-block"
                      src={suvantoLogo}
                      style={{
                        position: "absolute",
                        top: "24px",
                        right: "24px",
                        height: "120px",

                      }}
                    ></img>

                    <h6 style={{marginTop: "30px"}} className="">{props.t("Current status") + ": "}{bulletin.current_status == 1 ? props.t("Service outage") : bulletin.current_status == 2 ? props.t("Service disruption") : props.t("Service operational")}</h6>
                    <div className="d-grid">
                      <span>{props.t("Start time") + " " + bulletin.start_time}</span>
                      {bulletin.end_time && 
                        <span className="mt-1">{props.t("End time") + " " + bulletin.end_time}</span>
                      }
                    </div>

                    <h4 style={{marginTop: "30px"}} className="mb-4">{bulletin.header_fi}</h4>
                    {bodyTexts.map((txt, index) => (
                      <p key={index} dangerouslySetInnerHTML={{ __html: txt }}></p>
                    ))}

                    <h5 style={{marginTop: "30px"}} className="">{props.t("Affected services")}</h5>
                    <div>
                      {targets.map((target, index) => (
                        <span key={index} style={{borderRadius: "20px"}} className="badge bg-primary font-size-12 px-2 py-2 me-2 mb-2">
                          {props.t(target)}
                        </span>
                      ))}
                    </div>
                  
                  </CardBody>
                </Card>

                <div className="mt-5 text-center">
                  <a href="https://www.suvantocare.fi/" target="_blank" rel="noreferrer">
                    <p className="text-primary">
                      © {new Date().getFullYear()} Suvanto Care
                    </p>
                  </a>
                </div>

              </>}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

PublicDisturbanceNotice.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
}

export default withTranslation()(PublicDisturbanceNotice)