import React, { useRef, useContext, useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import GlobalState from "../../../contexts/GlobalState"
import { Link } from "react-router-dom"
import { Badge } from "antd"
import moment from "moment"
import { getGroupsAppointments } from "helpers/backend_helper"
import { formatDateTime } from "utils/general"

const LanguageDropdown = props => {
  const [state, setState] = useContext(GlobalState)
  const [appointments, setAppointments] = useState([])
  const [hasAppointments, setHasAppointments] = useState(false)
  const [appointmentsData, setAppointmentsData] = useState()
  const [userName, setUserName] = useState("")
  const [menu, setMenu] = useState(false)
  const abortRef = useRef(null)

  useEffect(() => {
    const user = JSON.parse(state.authUser)
    let key = user.username
    setUserName(key)
    abortRef.current = new AbortController()
    getAppointments()
    return () => abortRef.current.abort()
  }, [])

  // Find appointments for user
  useEffect(() => {
    if (appointmentsData != undefined) {
      let groups = appointmentsData.groups
      let id = 0
      groups.map(g => {
        if (g.key == userName) {
          id = g.id
        }
      })
      let appointments = appointmentsData.items
      let filtered = appointments.filter(f => f.group === id)
      setAppointments(filtered)
      // console.log(appointmentsData, filtered, "apps")
    }
  }, [appointmentsData])

  useEffect(() => {
    console.log(appointments, "app")
    appointments.forEach(item => {
      const currentDate = moment()
      const itemDate = moment(item.end_time)

      if (itemDate.isAfter(currentDate)) {
        // Future
        setHasAppointments(true)
      } else if (itemDate.isBefore(currentDate)) {
        // Past
        setHasAppointments(false)
      } else {
        // Now
        setHasAppointments(true)
      }
    })
  }, [appointments])

  // get entries, TODO: set socket.io
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getAppointments()
  //   }, 5000)
  //   return () => clearInterval(interval)
  // }, [])

  // Get appointments
  const getAppointments = () => {
    getGroupsAppointments(
      abortRef.current,
      "DemoCustomer",
      "admins",
      moment().startOf("day"),
      moment().endOf("day")
    )
      .then(result => {
        if (result.statusCode == 200 && result) {
          if (JSON.stringify(result) === JSON.stringify(appointmentsData)) {
            // console.log("same")
          }
          if (JSON.stringify(result) !== JSON.stringify(appointmentsData)) {
            console.log("New results: ", result)
            setAppointmentsData(result)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  const formattingTime = (time, startTime) => {
    // TODO: Translate
    if (time < 0) {
      if (time > -60) {
        let timeFormat = moment(moment(startTime).diff(moment(), "minutes"))
        return (
          props.t("starts in") + " " + timeFormat + " " + props.t("minutes")
        )
      } else {
        let timeFormat = moment(moment(startTime).diff(moment(), "hours"))
        return props.t("starts in") + " " + timeFormat + " " + props.t("hours")
      }
    } else {
      if (time == 0) {
        return props.t("now")
      }
      if (time > 60) {
        return (
          <span>
            {props.t("over")} {Math.floor(time / 60)}{" "}
            {time >= 120 ? props.t("hours") : props.t("hour")} {props.t("ago")}
          </span>
        )
      }

      if (time == 60) {
        return 1 + " " + props.t("hour") + " " + props.t("ago")
      }
      if (time < 60) {
        return time + " " + props.t("minutes") + " " + props.t("ago")
      }
    }
  }

  return (
    <>
      {/* Only button
       <div className="dropdown d-inline-block">
        <button
          id="calendarIcon"
          onClick={e => console.log(e)}
          type="button"
          className="btn header-item noti-icon right-bar-toggle "
        >
          <Badge dot={hasAppointments} color="rgb(45, 183, 245)">
            <i className="bx bx-calendar" />
          </Badge>
        </button>
      </div> */}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="calendarIcon"
        >
          <Badge dot={hasAppointments} color="rgb(45, 183, 245)">
            <i className="bx bx-calendar" />
          </Badge>
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          style={{ minWidth: "300px" }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">
                  {props.t("Today")} {moment().format("D.M.")}
                </h6>
              </Col>
              <div className="col-auto">
                <Link className="float-end font-size-12" to="/appointments">
                  <span key="t-view-more">{props.t("Page")}</span>
                </Link>
              </div>
            </Row>
          </div>
          {appointments?.length > 0 ? (
            <SimpleBar style={{ maxHeight: "300px" }}>
              {appointments.map((app, key) => (
                <Link
                  key={key}
                  to=""
                  style={{ cursor: "default" }}
                  className="text-reset notification-item"
                >
                  <div className="d-flex pt-3 pb-3">
                    <div className="me-3">
                      <div className="avatar-xs">
                        <span
                          className={
                            "bg-" +
                            classnames({
                              gray: moment().isAfter(app.end_time),
                              primary: moment().isBefore(app.end_time),
                            }) +
                            " avatar-title rounded-circle font-size-16"
                          }
                        >
                          <i
                            className={
                              "bx bx-" +
                              classnames({
                                video: app.type == "video_call",
                                // TODO: Add rest of types icons
                                // warning: app.type == "moderate",
                                // success: app.type == "good",
                              })
                            }
                          />
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="h6">
                        {formatDateTime(app.start_time)} -{" "}
                        {formatDateTime(app.end_time)}
                      </span>
                      <small>
                        <i className="mdi mdi-clock-outline ms-1 me-1" />
                        {formattingTime(
                          moment(moment().diff(app.start_time, "minutes")),
                          app.start_time
                        )}
                      </small>
                      <p className="mt-0 mb-0">{app.title}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </SimpleBar>
          ) : (
            <p className="p-3 text-muted">
              {props.t("No scheduled appointments for today")}.
            </p>
          )}
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/appointments"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("Move to the calendar")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
      <UncontrolledTooltip placement="bottom" target="calendarIcon">
        {props.t("My Appointments")}
      </UncontrolledTooltip>
    </>
  )
}

LanguageDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LanguageDropdown)
