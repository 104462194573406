import { getMessagesWithNumber, sendSms } from "helpers/backend_helper"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { withTranslation } from "react-i18next"
import { Col, Row, Form, Input, Button } from "reactstrap"
import { showToast } from "utils/general"
import CustomRangePicker from "components/DatePickers/RangePicker"

const TrackerSmsTab = props => {
  const [entries, setEntries] = useState([])
  const [inputMessage, setInputMessage] = useState("")
  const [refreshing, setRefreshing] = useState(false)
  const [sending, setSending] = useState(false)
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, "days"),
    moment(),
  ])

  const { SearchBar } = Search
  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getMessages()
  }, [props.gsm, dateRange])

  // Get SMS entries
  const getMessages = () => {
    if (props.gsm.length > 0 && dateRange && !refreshing) {
      //console.log("Get SMS: ", dateRange)
      setRefreshing(true)
      getMessagesWithNumber(
        abortRef.current,
        props.gsm,
        dateRange[0].valueOf(),
        dateRange[1].valueOf()
      )
        .then(data => {
          //console.log("SMS: ", data)
          if (data.entries) {
            data.entries.forEach(element => {
              element.statusTimeNice = moment(element.statusTime).format(
                "DD.MM.YYYY HH:mm:ss"
              )
              if (element.sent)
                element.sentNice = moment(element.sent).format(
                  "DD.MM.YYYY HH:mm:ss"
                )
            })
            setEntries(data.entries)
          }
        })
        .catch(e => {
          console.log(e)
          setEntries([])
        })
        .finally(() => {
          setRefreshing(false)
        })
    }
  }

  // Send SMS to tracker
  const sendMessage = () => {
    if (
      inputMessage.length > 0 &&
      props.gsm &&
      props.serverNumber &&
      !sending
    ) {
      setSending(true)
      sendSms(
        abortRef.current,
        inputMessage,
        props.gsm,
        props.serverNumber,
        null,
        null
      )
        .then(response => {
          console.log("Send response: ", response)
          showToast(props.t("Message sent"), "success")
          setDateRange([dateRange[0], moment()])
        })
        .catch(e => {
          showToast(props.t("Error"), "error")
          console.log(e)
        })
        .finally(() => {
          setSending(false)
        })
    }
  }

  // Sort data in table
  const defaultSorted = [
    {
      dataField: "statusTime",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: entries.length },
    ],
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
  ]

  // Table structure
  const columns = [
    {
      dataField: "statusTimeNice",
      text: props.t("Updated"),
      sort: true,
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: true,
    },
    {
      dataField: "message",
      text: props.t("Message"),
      sort: true,
    },
    {
      dataField: "sentNice",
      text: props.t("Sent"),
      sort: true,
    },
    {
      dataField: "sender",
      text: props.t("Sender"),
      sort: true,
    },
    {
      dataField: "recipient",
      text: props.t("Recipient"),
      sort: true,
    },
    {
      dataField: "source",
      text: props.t("Source"),
      sort: true,
    },
  ]

  function rowStyle(row, index) {
    switch (row.status) {
      case "EXPIRED":
      case "FAILED":
        return { backgroundColor: "#FFC0C0" }
      case "SENT":
        return { backgroundColor: "#FFF0C0" }
      default:
        return {}
    }
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  return (
    <>
      <Row className="pb-4 border-bottom">
        <Col>
          <Input
            name="sendMessage"
            id="sendMessage"
            type="text"
            placeholder={props.t("Message")}
            onChange={e => {
              setInputMessage(e.target.value)
            }}
          />
        </Col>
        <Col className="col-auto">
          <Button
            color="primary"
            disabled={
              sending || !props.gsm || !props.serverNumber || !inputMessage
            }
            onClick={() => {
              sendMessage()
            }}
          >
            {props.t("Send")}
          </Button>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col className="col-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            sizePerPage={SizePerPageDropdownStandalone(sizePerPageList)}
            keyField="id"
            columns={columns}
            data={entries}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={entries}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-1">
                      <Col className="col-auto">
                        <Form className="mt-sm-0 d-flex align-items-center">
                          <div className="search-box">
                            <div className="position-relative">
                              <SearchBar
                                placeholder={props.t(" ")}
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search search-icon" />
                            </div>
                          </div>
                        </Form>
                      </Col>

                      <Col>
                        <CustomRangePicker
                          value={dateRange}
                          rangeChanged={onDateSelection}
                          showTime={true}
                          allowClear={false}
                        />
                      </Col>

                      <Col className="col-auto">
                        <Button
                          style={{ borderRadius: "100px" }}
                          color="primary"
                          disabled={refreshing}
                          onClick={() => {
                            setDateRange([dateRange[0], moment()])
                          }}
                        >
                          <i className="mdi mdi-calendar-refresh me-2" />
                          {props.t("Refresh time")}
                        </Button>
                      </Col>

                      <Col>
                        <div className="mt-2 float-end">
                          <p>
                            {entries.length} {props.t("found results")}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            id="services-table"
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            pagination={sizePerPageList}
                            defaultSorted={defaultSorted}
                            rowStyle={rowStyle}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-3">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="">
                          <SizePerPageDropdownStandalone
                            pagination={sizePerPageList}
                            {...paginationProps}
                          />
                        </div>
                        <Col className="pagination justify-content-end inner-custom-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </>
  )
}

TrackerSmsTab.propTypes = {
  t: PropTypes.any,
  gsm: PropTypes.string,
  serverNumber: PropTypes.string,
}

export default withTranslation()(TrackerSmsTab)
