import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link, Route, useParams } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { checkIfValidUUID } from "utils/general"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import Lottie from "react-lottie"
import { getReleaseNote } from "helpers/database_helper"
import moment from "moment"
import suvantoLogo from "assets/images/Suvanto_logo2_transparent.png"

const PublicReleaseNote = props => {

  const [release, setRelease] = useState(null)
  const [loading, setLoading] = useState(true)

  const params = useParams()
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Release note") + " | Suvanto Care"
    abortRef.current = new AbortController()

    // Check if valid params
    if (params && params.id != undefined && checkIfValidUUID(params.id)) {
      getRelease(params.id)
    }
    else {
      setLoading(false)
    }

    return () => abortRef.current.abort()
  }, [])

  // Get release note
  const getRelease = (id) => {
    setLoading(true)
    getReleaseNote(abortRef.current, id).then(result => {
      if (result.length == 1 && result[0].guid == params.id) {

        if (result[0].release_date) result[0].release_date = moment(result[0].release_date).format("DD.MM.YYYY")
        if (result[0].informed_date) result[0].informed_date = moment(result[0].informed_date).format("DD.MM.YYYY")

        setRelease(result[0])
      }
      //console.log("Release: ", result)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  // Get matching target name
  const getTargetName = id => {
    switch(id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5: 
        return ("ReleaseNoteTypes."+id)
      default: 
        return "Unknown"
    }
  }
  
  return (
    <>
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} xl={10} xxl={9}>

              {loading && <>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingAnim,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={100}
                  width={100}
                />
              </>}

              {!loading && !release && <>
                <Card style={{maxWidth: "1000px"}}>
                  <CardBody>
                    <div className="text-center mb-4">
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: nodataAnim,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        height={150}
                        width={150}
                      />
                      <h5 className="text-muted mt-2">
                        {props.t("No notice found")}
                      </h5>
                    </div>
                  </CardBody>
                </Card>
              </>}

              {!loading && release && <>
                <Card style={{}}>
                  <CardBody className="p-5">

                    <h3 className="text-primary">{props.t("Release note")}</h3>
                    <h6>{release.informed_date}</h6>

                    <img
                      className="d-none d-md-block"
                      src={suvantoLogo}
                      style={{
                        position: "absolute",
                        top: "24px",
                        right: "24px",
                        height: "120px",

                      }}
                    ></img>

                    <h5 style={{marginTop: "30px"}} className="">{props.t(getTargetName(release.target))}</h5>
                    <span>{(release.type == 1 ? props.t("Hotfix") : props.t("Update")) + " v." + release.version}</span>

                    <h5 style={{marginTop: "30px"}} className="">{props.t("Changes")}</h5>
                    <span style={{ whiteSpace: "pre-line" }}>{release.what_changes_fi}</span>

                    <h5 style={{marginTop: "30px"}} className="">{props.t("Visible in")}</h5>
                    <span style={{ whiteSpace: "pre-line" }}>{release.how_use_changes_fi}</span>

                    <h5 style={{marginTop: "30px"}} className="">{props.t("Affects")}</h5>
                    <table className="table" style={{maxWidth: "500px"}}>
                      <tbody>
                        <tr>
                          <td>{props.t("User interface for end user")}</td>
                          <td>{release.changes_enduser_ui == 0 ? props.t("No") : props.t("Yes")}</td>
                        </tr>
                        <tr>
                          <td>
                            {props.t("User interface for professional user")}
                          </td>
                          <td>{release.changes_pro_ui == 0 ? props.t("No") : props.t("Yes")}</td>
                        </tr>
                        <tr>
                          <td>{props.t("Procedural changes")}</td>
                          <td>{release.changes_user_methods == 0 ? props.t("No") : props.t("Yes")}</td>
                        </tr>
                      </tbody>
                    </table>
                    <span style={{ whiteSpace: "pre-line" }}>{release.how_effects_fi}</span>

                    <h5 style={{marginTop: "30px"}} className="">{props.t("Release date")}</h5>
                    <span>{release.release_date}</span>
                  </CardBody>
                </Card>

                <div className="mt-5 text-center">
                  <a href="https://www.suvantocare.fi/" target="_blank" rel="noreferrer">
                    <p className="text-primary">
                      © {new Date().getFullYear()} Suvanto Care
                    </p>
                  </a>
                </div>

              </>}
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}

PublicReleaseNote.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
}

export default withTranslation()(PublicReleaseNote)