import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Dropdown,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  FormFeedback,
  FormText,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import "../datatables.scss"
import {
  addVideoDevices,
  getVideoDevices,
  setVideoIgnoreAlarms,
} from "helpers/backend_helper"
import VideoDeviceDrawer from "./device-drawer"
import {
  showToast,
  stringSorter,
  downloadExcel,
  isTrue,
  dateFormatter,
  numberSorter,
  dateSorter,
  defaultPageSizeOptions,
} from "utils/general"
import { Switch, Table as AntdTable } from "antd"
import SelectOrder from "components/DropdownList/SelectOrder"
import * as Yup from "yup"
import { useFormik } from "formik"

const VideoDevices = props => {
  const [devices, setDevices] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [excludeLinks, setExcludeLinks] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [orderSettingModal, setOrderSettingModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [showDrawer, setShowDrawer] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [selectedSerial, setSelectedSerial] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [extraMenu, setExtraMenu] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statusFilter, setStatusFilter] = useState("0")
  const [modelFilter, setModelFilter] = useState("")
  const [roomStatusFilter, setRoomStatusFilter] = useState("")
  const [ignoreAlarmFilter, setIgnoreAlarmFilter] = useState("")
  const [monitorConnectionFilter, setMonitorConnectionFilter] = useState("")
  const [androidVersionFilter, setAndroidVersionFilter] = useState("all")
  const [androidVersions, setAndroidVersions] = useState({})
  const [foundAndroidVersions, setFoundAndroidVersions] = useState([])

  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [statistics, setStatistics] = useState({
    total: 0,
    shutdown: 0,
    charging: 0,
    discharging: 0,
    tabA: 0,
    tabA7: 0,
    tabA8: 0,
    nokiaT21: 0,
    unknownModel: 0,
  })

  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Video devices"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = devices.length
    temp.linked = devices.filter(data => data.links.length > 0).length

    temp.shutdown = devices.filter(data => data.shutdown == true).length
    temp.charging = devices.filter(
      data => data.isCharging == true && !data.shutdown
    ).length
    temp.discharging = devices.filter(
      data => !data.isCharging && !data.shutdown
    ).length

    temp.tabA = devices.filter(
      data => data.deviceModel.indexOf("SM-T515") >= 0
    ).length
    temp.tabA7 = devices.filter(
      data => data.deviceModel.indexOf("SM-T505") >= 0
    ).length
    temp.tabA8 = devices.filter(
      data => data.deviceModel.indexOf("SM-X205") >= 0
    ).length
    temp.nokiaT21 = devices.filter(
      data => data.deviceModel.indexOf("Nokia T21") >= 0
    ).length
    temp.unknownModel = devices.filter(
      data => !data.deviceModel || data.deviceModel.length == 0
    ).length

    setStatistics(temp)
  }, [devices])

  // Check duplicate values
  useEffect(() => {
    if (devices) {
      // ICCID, IMEI, TEAMVIEWER
      let dups = []
      for (let i = 0; i < devices.length - 1; i++) {
        for (let j = i + 1; j < devices.length; j++) {
          if (devices[i].iccid && devices[i].iccid === devices[j].iccid) {
            dups.push({
              key: devices[i].iccid,
              name: "ICCID:" + devices[i].iccid,
            })
          }
          if (devices[i].imei && devices[i].imei === devices[j].imei) {
            dups.push({ key: devices[i].imei, name: "IMEI:" + devices[i].imei })
          }
          if (
            devices[i].teamViewerId &&
            devices[i].teamViewerId === devices[j].teamViewerId
          ) {
            dups.push({
              key: devices[i].teamViewerId,
              name: "TVID:" + devices[i].teamViewerId,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [devices])

  // Check multiple links
  useEffect(() => {
    if (devices) {
      let multis = []
      devices.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.serial, name: element.serial })
        }
      })
      setMultilinks(multis)
    }
  }, [devices])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen(false)
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [
    searchText,
    statusFilter,
    devices,
    roomStatusFilter,
    ignoreAlarmFilter,
    modelFilter,
    monitorConnectionFilter,
    androidVersionFilter,
  ])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = devices.filter(data =>
      (statusFilter === "0" ||
        (statusFilter === "1" && data.isCharging == true && !data.shutdown) ||
        (statusFilter === "2" && !data.isCharging && !data.shutdown) ||
        (statusFilter === "3" && data.shutdown == true) ||
        (statusFilter === "4" && !data.shutdown)) &&
      (monitorConnectionFilter === "" ||
        (monitorConnectionFilter === "connected" &&
          data.lastConnectedDiff <= 30) ||
        (monitorConnectionFilter === "disconnected" &&
          (data.lastConnectedDiff == null || data.lastConnectedDiff > 30))) &&
      (modelFilter === "" ||
        (modelFilter === "undefined" && !data.deviceModel) ||
        data.deviceModel?.indexOf(modelFilter) >= 0) &&
      (ignoreAlarmFilter === "" ||
        (ignoreAlarmFilter === "enabled" && data.ignoreAlarms == true) ||
        (ignoreAlarmFilter === "disabled" && !data.ignoreAlarms)) &&
      (roomStatusFilter === "" ||
        (roomStatusFilter === "Offline" &&
          data.statusDescription === "Offline") ||
        (roomStatusFilter === "Online" &&
          data.statusDescription === "Online") ||
        (roomStatusFilter === "Call" &&
          data.statusDescription?.indexOf("Call") == 0) ||
        (roomStatusFilter === "Group" &&
          data.statusDescription?.indexOf("Group") == 0) ||
        (roomStatusFilter === "Offline15" &&
          data.statusDescription === "Offline" &&
          data.roomConnectedDiff > 15)) &&
      (androidVersionFilter === "all" || (data.androidVersion == androidVersionFilter)) &&
      //data.statusDescription?.indexOf(roomStatusFilter) == 0 &&
      (data.serial?.toLowerCase().includes(searchTextLowercase) ||
        data.imei?.toLowerCase().includes(searchTextLowercase) ||
        data.iccid?.toLowerCase().includes(searchTextLowercase) ||
        data.teamViewerId?.toLowerCase().includes(searchTextLowercase) ||
        data.deviceModel?.toLowerCase().includes(searchTextLowercase) ||
        data.buildNumber?.toLowerCase().includes(searchTextLowercase) ||
        data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
        data.linkedNames?.toLowerCase().includes(searchTextLowercase) ||
        data.roomName?.toLowerCase().includes(searchTextLowercase) ||
        data.videoVersion?.includes(searchTextLowercase))
    )
    setFilteredDevices(result)
  }

  // Get video devices list
  const getDevices = () => {
    setOngoingSearch(true)

    // TEST
    // Capture the start time
    const startTime = new Date()
    // Create a timeout promise
    const timeoutPromise = new Promise(resolve => {
      setTimeout(() => {
        resolve({ timeout: true })
      }, 120000)
    })

    Promise.race([
      getVideoDevices(abortRef.current, excludeLinks),
      timeoutPromise,
    ])
      .then(result => {
        // Duration of call
        const endTime = new Date()
        const durationInSeconds = (endTime - startTime) / 1000
        if (result.timeout) {
          console.log("Timeout: The operation took too long.")
        } else {
          if (result.statusCode === 200 && result.entries != null) {
            console.log(result)
            let now = moment()
            let sysVersions = {}
            let androidv = []
            result.entries.forEach(element => {
              if (!element.statusDescription) element.statusDescription = ""

              if (element.androidVersion) {
                sysVersions[element.androidVersion] = sysVersions[
                  element.androidVersion
                ]
                  ? sysVersions[element.androidVersion] + 1
                  : 1
                // Dynamic android versions sorter
                androidv.includes(element.androidVersion)
                  ? (androidv = androidv)
                  : androidv.push(element.androidVersion)
                androidv.sort(function (a, b) {
                  return a - b
                })
                setFoundAndroidVersions(androidv)
              }

              let linkedNames = []
              if (element.links) {
                element.links.forEach(link => {
                  linkedNames.push(link.name)
                })
              }
              element.linkedNames = linkedNames.join()

              if (element.lastConnection) {
                element.lastConnectedDiff = now.diff(
                  moment(element.lastConnection),
                  "minutes"
                )
              } else {
                element.lastConnectedDiff = 99999
              }

              if (element.roomLastOnline) {
                element.roomConnectedDiff = now.diff(
                  moment(element.roomLastOnline),
                  "minutes"
                )
              } else {
                element.roomConnectedDiff = 99999
              }
            })
            setAndroidVersions(sysVersions)
            setDevices(result.entries)
          } else {
            setDevices([])
          }
        }

        // Duration
        console.log("API call took " + durationInSeconds + " seconds.")
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setDevices([])
        setOngoingSearch(false)
      })
  }

  // Add new devices validation
  const addValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      serialList: "",
      ignoreAlarms: false,
      orderNumber: null,
    },
    validationSchema: Yup.object({
      serialList: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Add submit: ", values)
      if (!ongoingAdd) {
        setOngoingAdd(true)
        addVideoDevices(
          abortRef.current,
          values.serialList,
          values.ignoreAlarms,
          values.orderNumber
        )
          .then(result => {
            if (result.statusCode === 200) {
              setAddModal(false)
              showToast(
                result.addCount + " " + props.t("devices added"),
                "success"
              )
              getDevices()
              addValidation.resetForm()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAdd(false)
          })
      }
    },
  })

  // Set settings by order validation
  const orderSettingsValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ignoreAlarms: false,
      orderNumber: null,
    },
    validationSchema: Yup.object({
      orderNumber: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Settings submit: ", values)
      if (!ongoingAdd) {
        setOngoingAdd(true)
        setVideoIgnoreAlarms(
          abortRef.current,
          values.orderNumber,
          values.ignoreAlarms
        )
          .then(result => {
            console.log("Set ignore result: ", result)
            if (result.statusCode === 200) {
              setOrderSettingModal(false)
              showToast(props.t("Updated"), "success")
              getDevices()
              orderSettingsValidation.resetForm()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAdd(false)
          })
      }
    },
  })

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      fixed: "left",
      width: "165px",
      ellipsis: true,
    },
    {
      dataIndex: "roomName",
      title: props.t("Number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "roomName", sortOrder)
      },
      render: roomFormatter,
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "statusDescription",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "statusDescription", sortOrder)
      },
      render: cell => {
        return cell == "Offline" ? (
          <span style={{ color: "#f46a6a" }}>{cell}</span>
        ) : (
          cell
        )
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "roomLastOnline",
      title: props.t("Last online"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["roomLastOnline"], b["roomLastOnline"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: linksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "lastConnection",
      title: "Monitor " + props.t("connection"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastConnection"], b["lastConnection"], sortOrder)
      },
      render: connectionFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "videoVersion",
      title: "Video ver.",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "videoVersion", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "monitorVersion",
      title: "Monitor ver.",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "monitorVersion", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "teamViewerId",
      title: "TeamViewer ID",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "teamViewerId", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "buildNumber",
      title: props.t("Build"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "buildNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imei", sortOrder)
      },
      render: imeiFormatter,
      width: "145px",
      ellipsis: true,
    },
    {
      dataIndex: "iccid",
      title: props.t("ICCID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "iccid", sortOrder)
      },
      width: "175px",
      ellipsis: true,
    },
    {
      dataIndex: "ignoreAlarms",
      title: props.t("Ignore alarms"),
      align: "center",
      sorter: (a, b) => {
        return a.ignoreAlarms - b.ignoreAlarms
      },
      //render: (cell) => {return String(cell)},
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "shutdown",
      title: props.t("Shutdown"),
      align: "center",
      sorter: (a, b) => {
        return a.shutdown - b.shutdown
      },
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryLevel",
      title: props.t("Battery"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["batteryLevel"], b["batteryLevel"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "isCharging",
      title: props.t("Charging"),
      align: "center",
      sorter: (a, b) => {
        return a.isCharging - b.isCharging
      },
      //render: (cell) => {return String(cell)},
      render: cell => {
        return cell == true ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "chargingSource",
      title: props.t("Charging source"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "chargingSource", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "availableMemory",
      title: props.t("Available memory"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["availableMemory"], b["availableMemory"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "screenBrightness",
      title: props.t("Brightness"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["screenBrightness"], b["screenBrightness"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "connectionType",
      title: props.t("Connection type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "connectionType", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "networkType",
      title: props.t("Network"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "networkType", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "gsmSignalStrength",
      title: props.t("Signal strength"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsmSignalStrength", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "cellInfo",
      title: props.t("Cell info"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "cellInfo", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      dataIndex: "cellSignalStrength",
      title: props.t("Cell strength"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "cellSignalStrength", sortOrder)
      },
      width: "220px",
      ellipsis: true,
    },
    /*{
      dataIndex: "bluetoothCount",
      title: props.t("BT count"),
      sorter: (a, b) => {
        return a.bluetoothCount - b.bluetoothCount
      },
      width: "120px",
      ellipsis: true,
    },*/
    {
      dataIndex: "volumeLevel",
      title: props.t("Volume"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "volumeLevel", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "videoAppConnection",
      title: "Video app " + props.t("connection"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["videoAppConnection"], b["videoAppConnection"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "healthAppConnection",
      title: "Health " + props.t("connection"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["healthAppConnection"], b["healthAppConnection"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "healthVersion",
      title: "Health ver.",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "healthVersion", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "webviewVersion",
      title: "Webview ver.",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "webviewVersion", sortOrder)
      },
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "chromeVersion",
      title: "Chrome ver.",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "chromeVersion", sortOrder)
      },
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "androidVersion",
      title: props.t("Android"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "androidVersion", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceModel",
      title: props.t("Model"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "deviceModel", sortOrder)
      },
      width: "190px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryVoltage",
      title: props.t("Battery voltage"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["batteryVoltage"], b["batteryVoltage"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryCurrentNow",
      title: props.t("Current now"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["batteryCurrentNow"], b["batteryCurrentNow"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryCurrentAverage",
      title: props.t("Current avg"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["batteryCurrentAverage"], b["batteryCurrentAverage"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryCapacityMah",
      title: props.t("Capacity"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["batteryCapacityMah"], b["batteryCapacityMah"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "chargeTime",
      title: props.t("Charge time"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "chargeTime", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceStarted",
      title: props.t("Device started"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["deviceStarted"], b["deviceStarted"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "bluetoothDeviceList",
      title: props.t("Bluetooth devices"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "bluetoothDeviceList", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <div className="d-inline-flex">
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
        <Button
          className="ms-2"
          color="primary"
          outline
          size="sm"
          onClick={() => {
            copyToClipboard(row)
          }}
        >
          <i className="mdi mdi-clipboard-edit" />
        </Button>
      </div>
    )
  }

  function connectionFormatter(value, row) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")

    if (row.lastConnectedDiff == null || row.lastConnectedDiff > 30) {
      return <span style={{ color: "#f46a6a" }}>{dateString}</span>
    } else return dateString
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  function roomFormatter(cell, row) {
    if (row.roomName && row.roomName.length > 0) {
      return (
        <Link
          to={"/config/video-numbers?search=" + row.roomName}
          target="_blank"
        >
          <span>{row.roomName}</span>
        </Link>
      )
    }
  }

  function imeiFormatter(cell, row) {
    if (row.imei && row.serial.indexOf("PP") == 0) {
      return (
        <a
          href={"https://console.hmdenablepro.com/devices?q=" + row.imei.trim()}
          target="_blank"
          rel="noreferrer"
        >
          {row.imei}
        </a>
      )
    } else return cell
  }

  // Copy video info to clipboard
  const copyToClipboard = row => {
    let text = "" + row.serial + " " + row.roomName
    text = text + " Video ver." + row.videoVersion
    text = text + " Health ver." + row.healthVersion
    text = text + " Monitor ver." + row.monitorVersion
    text = text + " Koontiver." + row.buildNumber
    text = text + " Webview ver." + row.webviewVersion
    text = text + " Chrome ver." + row.chromeVersion

    row.links.forEach(element => {
      text = text + " " + element.name
    })

    text =
      text +
      " Video yhteys " +
      moment(row.videoAppConnection).format("DD.MM.YYYY HH:mm:ss")
    text =
      text +
      " Yhteys " +
      moment(row.lastConnection).format("DD.MM.YYYY HH:mm:ss")
    text = text + " Malli " + row.deviceModel
    text = text + " Latauksessa " + row.isCharging
    text = text + " Verkko " + row.networkType
    text = text + " Cell strength " + row.cellSignalStrength

    navigator.clipboard.writeText(text)
    showToast(props.t("Copied to clipboard"), "info")
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Serial"),
        props.t("Number"),
        props.t("Status"),
        props.t("Last online"),
        props.t("Links"),
        "Monitor " + props.t("connection"),
        "Video ver.",
        "Monitor ver.",
        "TeamViewer ID",
        props.t("Build"),
        props.t("Order number"),
        props.t("IMEI"),
        props.t("ICCID"),
        props.t("Ignore alarms"),
        props.t("Shutdown"),
        props.t("Battery"),
        props.t("Charging"),
        props.t("Charging source"),
        props.t("Available memory"),
        props.t("Brightness"),
        props.t("Connection type"),
        props.t("Network"),
        props.t("Signal strength"),
        props.t("Cell info"),
        props.t("Cell strength"),
        //props.t("BT count"),
        props.t("Volume"),
        "Video app " + props.t("connection"),
        "Health " + props.t("connection"),
        "Health ver.",
        "Webview ver.",
        "Chrome ver.",
        props.t("Android"),
        props.t("Model"),
        props.t("Current now"),
        props.t("Current now"),
        props.t("Current avg"),
        props.t("Capacity"),
        props.t("Charge time"),
        props.t("Device started"),
        props.t("Bluetooth devices"),
      ],
    ]
    const data = filteredDevices.map(elt => [
      elt.serial,
      elt.roomName,
      elt.statusDescription,
      dateFormatter(elt.roomLastOnline),
      elt.linkedNames,
      dateFormatter(elt.lastConnection),
      elt.videoVersion,
      elt.monitorVersion,
      elt.teamViewerId,
      elt.buildNumber,
      elt.orderNumber,
      elt.imei,
      elt.iccid,
      isTrue(elt.ignoreAlarms),
      isTrue(elt.shutdown),
      elt.batteryLevel,
      isTrue(elt.isCharging),
      elt.chargingSource,
      elt.availableMemory,
      elt.screenBrightness,
      elt.connectionType,
      elt.networkType,
      elt.gsmSignalStrength,
      elt.cellInfo,
      elt.cellSignalStrength,
      //elt.bluetoothCount,
      elt.volumeLevel,
      dateFormatter(elt.videoAppConnection),
      dateFormatter(elt.healthAppConnection),
      elt.healthVersion,
      elt.webviewVersion,
      elt.chromeVersion,
      elt.androidVersion,
      elt.deviceModel,
      elt.batteryVoltage,
      elt.batteryCurrentNow,
      elt.batteryCurrentAverage,
      elt.batteryCapacityMah,
      elt.chargeTime,
      dateFormatter(elt.deviceStarted),
      elt.bluetoothDeviceList,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Video devices")} />

          {/** 
          <Breadcrumbs
            title={props.t("Video devices")}
            className="breadcrumb m-0 justify-content-center"
            breadcrumbItem={
              <>
                <Row>
                  <Col>
                    <div className="">
                      <Label>{props.t("Fast refresh")}</Label>
                      <Switch
                        className="ms-2"
                        name="excludeLinks"
                        checked={excludeLinks}
                        onChange={v => setExcludeLinks(v)}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            }
          />*/}

          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-flex flex-wrap">
                    <ul
                      className="nav nav-tabs nav-tabs-custom card-header-tabs"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          to="#"
                          active={activeTab === "1"}
                          onClick={() => {
                            setActiveTab("1")
                          }}
                        >
                          {props.t("Table")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "2"}
                          onClick={() => {
                            setActiveTab("2")
                          }}
                        >
                          {props.t("Statistics")}
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane className="show" tabId="1">
                      <Accordion
                        flush
                        open={alertsOpen}
                        toggle={id => {
                          id === alertsOpen
                            ? setAlertsOpen("")
                            : setAlertsOpen(id)
                        }}
                      >
                        <AccordionItem>
                          <AccordionBody
                            accordionId="1"
                            className="accordion-zeropadding"
                          >
                            <Alert color="danger">
                              <div>
                                <h5 className="alert-heading">
                                  {props.t("Duplicate values")}
                                </h5>
                                {duplicates.map((entry, key) => (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    outline
                                    key={key}
                                    className="me-2 mb-2"
                                    onClick={() => {
                                      setSearchText(entry.key)
                                    }}
                                  >
                                    <span>{entry.name}</span>
                                  </Button>
                                ))}
                              </div>
                              <div>
                                <h5 className="alert-heading">
                                  {props.t("Multiple links")}
                                </h5>
                                {multilinks.map((entry, key) => (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    outline
                                    key={key}
                                    className="me-2 mb-2"
                                    onClick={() => {
                                      setSearchText(entry.key)
                                    }}
                                  >
                                    <span>{entry.name}</span>
                                  </Button>
                                ))}
                              </div>
                            </Alert>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                      <Row>
                        <Col>
                          {devices != null && (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          value={searchText}
                                          onChange={event =>
                                            setSearchText(event.target.value)
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    color="primary"
                                    className="round-icon-button"
                                    id="filter-button"
                                    outline
                                    onClick={() => {
                                      filtersOpen.length > 0
                                        ? setFiltersOpen("")
                                        : setFiltersOpen("1")
                                    }}
                                  >
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="filter-button"
                                    >
                                      {props.t("Filters")}
                                    </UncontrolledTooltip>
                                    {filtersOpen ? (
                                      <i className="mdi mdi-filter-minus"></i>
                                    ) : (
                                      <i className="mdi mdi-filter-menu"></i>
                                    )}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      getDevices()
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh data")}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    onClick={() => {
                                      setAddModal(true)
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-2" />
                                    {props.t("Add devices")}
                                  </Button>
                                  <ButtonDropdown
                                    className="ms-3"
                                    isOpen={extraMenu}
                                    toggle={() => {
                                      setExtraMenu(!extraMenu)
                                    }}
                                  >
                                    <DropdownToggle
                                      color="primary"
                                      style={{ borderRadius: "100px" }}
                                      caret
                                    >
                                      <i className="mdi mdi-menu me-2" />
                                      {props.t("Other actions")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setOrderSettingModal(true)
                                        }}
                                      >
                                        {props.t("Set ignore alarms")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                  {(duplicates.length > 0 ||
                                    multilinks.length > 0) && (
                                    <Button
                                      color="danger"
                                      className="round-icon-button ms-3"
                                      id="alerts-button"
                                      onClick={() => {
                                        alertsOpen.length > 0
                                          ? setAlertsOpen("")
                                          : setAlertsOpen("1")
                                      }}
                                    >
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target="alerts-button"
                                      >
                                        {props.t("Notices")}
                                      </UncontrolledTooltip>
                                      {alertsOpen ? (
                                        <i className="mdi mdi-alert-minus"></i>
                                      ) : (
                                        <i className="mdi mdi-alert-plus"></i>
                                      )}
                                    </Button>
                                  )}
                                </Col>
                                <Col>
                                  <span className="float-end ms-2">
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="downloadstatstable"
                                    >
                                      {props.t("Download statistics")}
                                    </UncontrolledTooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {/* TODO: Can not choose directory file right now */}
                                        <DropdownItem
                                          onClick={() => generateExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredDevices.length}{" "}
                                      {props.t("found results")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Accordion
                                flush
                                open={filtersOpen}
                                toggle={id => {
                                  id === filtersOpen
                                    ? setFiltersOpen("")
                                    : setFiltersOpen(id)
                                }}
                              >
                                <AccordionItem>
                                  <AccordionBody
                                    accordionId="1"
                                    className="mb-3"
                                    style={{
                                      backgroundColor: "#e8f0fe",
                                      border: "1px solid #ced4da",
                                    }}
                                  >
                                    <Row xs={1} sm={2} md={3} xl={4}>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Device status")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="statusFilter"
                                            value={statusFilter}
                                            onChange={v => {
                                              setStatusFilter(v.target.value)
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Charging")}
                                            </option>
                                            <option value="2">
                                              {props.t("Discharging")}
                                            </option>
                                            <option value="3">
                                              {props.t("Shutdown")}
                                            </option>
                                            <option value="4">
                                              {props.t("Device on")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Video number status")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="roomStatusFilter"
                                            value={roomStatusFilter}
                                            onChange={v => {
                                              setRoomStatusFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="">
                                              {props.t("All")}
                                            </option>
                                            <option value="Offline">
                                              {props.t("Offline")}
                                            </option>
                                            <option value="Offline15">
                                              {props.t("Offline") +
                                                " (" +
                                                props.t("over") +
                                                " 15min)"}
                                            </option>
                                            <option value="Online">
                                              {props.t("Online")}
                                            </option>
                                            <option value="Call">
                                              {props.t("Video call")}
                                            </option>
                                            <option value="Group">
                                              {props.t("Group call")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>{props.t("Model")}</Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="modelFilter"
                                            value={modelFilter}
                                            onChange={v => {
                                              setModelFilter(v.target.value)
                                            }}
                                          >
                                            <option value="">
                                              {props.t("All")}
                                            </option>
                                            <option value="SM-T515">
                                              {props.t(
                                                "Galaxy Tab A (SM-T515)"
                                              )}
                                            </option>
                                            <option value="SM-T505">
                                              {props.t(
                                                "Galaxy Tab A7 (SM-T505)"
                                              )}
                                            </option>
                                            <option value="SM-X205">
                                              {props.t(
                                                "Galaxy Tab A8 (SM-X205)"
                                              )}
                                            </option>
                                            <option value="Nokia T21">
                                              {props.t("Nokia T21")}
                                            </option>
                                            <option value="undefined">
                                              {props.t("Undefined")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Ignore alarms")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="ignoreAlarmFilter"
                                            value={ignoreAlarmFilter}
                                            onChange={v => {
                                              setIgnoreAlarmFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="">
                                              {props.t("All")}
                                            </option>
                                            <option value="enabled">
                                              {props.t("Enabled")}
                                            </option>
                                            <option value="disabled">
                                              {props.t("Disabled")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {"Monitor " + props.t("connection")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="monitorConnectionFilter"
                                            value={monitorConnectionFilter}
                                            onChange={v => {
                                              setMonitorConnectionFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value="">
                                              {props.t("Undefined")}
                                            </option>
                                            <option value="connected">
                                              {props.t("Connected") +
                                                " (" +
                                                props.t("under") +
                                                " 30min)"}
                                            </option>
                                            <option value="disconnected">
                                              {props.t("Disconnected") +
                                                " (" +
                                                props.t("over") +
                                                " 30min)"}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {"Android " + props.t("version")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="versionAndroidFilter"
                                            value={androidVersionFilter}
                                            onChange={v => {
                                              setAndroidVersionFilter(
                                                v.target.value
                                              )
                                            }}
                                          >
                                            <option value={"all"}>
                                              {props.t("All")}
                                            </option>
                                            <option value="">
                                              {props.t("Undefined")}
                                            </option>
                                            {foundAndroidVersions?.map(
                                              (v, key) => (
                                                <option key={key} value={v}>
                                                  {v}
                                                </option>
                                              )
                                            )}
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch == true ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <AntdTable
                                      className="striped colored-header"
                                      bordered
                                      size="middle"
                                      sticky
                                      rowKey={"serial"}
                                      columns={columns}
                                      dataSource={filteredDevices}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 50,
                                        pageSizeOptions: defaultPageSizeOptions,
                                      }}
                                      tableLayout="auto"
                                      scroll={{
                                        x: "max-content",
                                        y: "64vh",
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane className="show" tabId="2">
                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{props.t("Count")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{props.t("Devices")}</th>
                            <td>{statistics.total}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Linked")}</th>
                            <td>{statistics.linked}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Charging")}</th>
                            <td>{statistics.charging}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Discharging")}</th>
                            <td>{statistics.discharging}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Shutdown")}</th>
                            <td>{statistics.shutdown}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Galaxy Tab A")}</th>
                            <td>{statistics.tabA}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Galaxy Tab A7")}</th>
                            <td>{statistics.tabA7}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Galaxy Tab A8")}</th>
                            <td>{statistics.tabA8}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Nokia T21")}</th>
                            <td>{statistics.nokiaT21}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Unknown model")}</th>
                            <td>{statistics.unknownModel}</td>
                          </tr>
                          {Object.keys(androidVersions).map((entry, key) => (
                            <tr key={key}>
                              <th scope="row">{"Android " + entry}</th>
                              <td>{androidVersions[entry]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/** Device settings drawer */}
          <VideoDeviceDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getDevices={getDevices}
          />

          {/** Import devices modal */}
          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalHeader>{props.t("Add devices")}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("Serials")}</Label>
                  <Input
                    name="serialList"
                    placeholder={props.t("serial1, serial2, serial3...")}
                    type="textarea"
                    rows={5}
                    value={addValidation.values.serialList}
                    onChange={addValidation.handleChange}
                    onBlur={addValidation.handleBlur}
                    invalid={
                      addValidation.touched.serialList &&
                      addValidation.errors.serialList
                        ? true
                        : false
                    }
                  ></Input>
                  {addValidation.touched.serialList &&
                  addValidation.errors.serialList ? (
                    <FormFeedback type="invalid">
                      {addValidation.errors.serialList}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Order number")}</Label>
                  <SelectOrder
                    selection={addValidation.values.orderNumber}
                    setSelectedOrder={v => {
                      addValidation.setFieldValue("orderNumber", v)
                    }}
                  />
                </FormGroup>
                <FormGroup className="mt-4">
                  <Switch
                    className="me-2"
                    name="ignoreAlarms"
                    checked={addValidation.values.ignoreAlarms}
                    onChange={v =>
                      addValidation.setFieldValue("ignoreAlarms", v)
                    }
                  />
                  <Label>{props.t("Ignore alarms")}</Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                    //setSerialList("")
                    addValidation.resetForm()
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  disabled={
                    addValidation.values.serialList.length == 0 || ongoingAdd
                  }
                  color="success"
                  onClick={() => {
                    addValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/** Set settings by order modal */}
          <Modal
            isOpen={orderSettingModal}
            toggle={() => {
              setOrderSettingModal(!orderSettingModal)
            }}
            centered
          >
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalHeader>{props.t("Set ignore alarms")}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("Order number")}</Label>
                  <SelectOrder
                    selection={orderSettingsValidation.values.orderNumber}
                    setSelectedOrder={v => {
                      orderSettingsValidation.setFieldValue("orderNumber", v)
                    }}
                  />
                  {!orderSettingsValidation.values.orderNumber ? (
                    <FormText color="danger">
                      {props.t("Required field")}
                    </FormText>
                  ) : null}
                </FormGroup>
                <FormGroup className="mt-4">
                  <Switch
                    className="me-2"
                    name="ignoreAlarms"
                    checked={orderSettingsValidation.values.ignoreAlarms}
                    onChange={v =>
                      orderSettingsValidation.setFieldValue("ignoreAlarms", v)
                    }
                  />
                  <Label>{props.t("Ignore alarms")}</Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setOrderSettingModal(false)
                    orderSettingsValidation.resetForm()
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  disabled={
                    ongoingAdd || !orderSettingsValidation.values.orderNumber
                  }
                  color="primary"
                  onClick={() => {
                    orderSettingsValidation.submitForm()
                  }}
                >
                  {props.t("Set")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  )
}

VideoDevices.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VideoDevices)
