import React, { useState, useEffect } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Col,
  Row,
  CardText,
  Tooltip,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { Switch } from "antd"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as Yup from "yup"


const AtHomeSettings = props => {

  const generalValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      collectionDays: 14,
      learningPeriod: 14,
      sleepStart: 20,
    },
    validationSchema: Yup.object({

    }),
    onSubmit: values => {
      setError(null)
      //console.log("SubmitValues", values)
      //console.log("FormValues:", formValues)
      // TODO
    },
  })

  const gwFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      epid: 0
    },
    validationSchema: Yup.object({

    }),
    onSubmit: values => {
      setError(null)
      //console.log("SubmitValues", values)
      //console.log("FormValues:", formValues)
      // TODO
    },
  })

  const nodeFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      id: 0,
      type: "",
      tracking: "",
      version: "",
      powerOnLevel: 0,
      configured: false
    },
    validationSchema: Yup.object({

    }),
    onSubmit: values => {
      setError(null)
      //console.log("SubmitValues", values)
      //console.log("FormValues:", formValues)
      // TODO
    },
  })

  return (
    <>
      <div>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("General settings")}</CardTitle>
            <Row>
              <Col>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    generalValidation.handleSubmit()
                    return false
                  }}
                >
                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Tiedonkeruuaika")}</Label>
                        <Input
                          name="collectionDays"
                          type="number"
                          onChange={generalValidation.handleChange}
                          onBlur={generalValidation.handleBlur}
                          value={generalValidation.values.collectionDays || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Oppimisjakso")}</Label>
                        <Input
                          name="learningPeriod"
                          type="number"
                          onChange={generalValidation.handleChange}
                          onBlur={generalValidation.handleBlur}
                          value={generalValidation.values.learningPeriod || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Lepojakson alku")}</Label>
                        <Input
                          name="sleepStart"
                          type="number"
                          onChange={generalValidation.handleChange}
                          onBlur={generalValidation.handleBlur}
                          value={generalValidation.values.sleepStart || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>

                    </Col>
                  </Row>

                  <div className="mt-3 mb-2 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary btn-block" style={{minWidth: "160px"}}>
                      {props.t("Save")}
                    </button>
                  </div>

                </Form>

              </Col>
              <Col xs={3} sm={3}  style={{borderLeft: "1px solid #999"}}>
                <div className="mb-2">
                  <Button size="sm" color="danger" className="me-2" onClick={() => {}}>
                    {props.t("Reset history")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Gateway settings")}</CardTitle>
            <Row>
              <Col>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    gwFormValidation.handleSubmit()
                    return false
                  }}
                >
                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Description")}</Label>
                        <Input
                          name="description"
                          type="text"
                          onChange={gwFormValidation.handleChange}
                          onBlur={gwFormValidation.handleBlur}
                          value={gwFormValidation.values.description || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Zigbee EPID")}</Label>
                        <Input
                          name="epid"
                          type="number"
                          onChange={gwFormValidation.handleChange}
                          onBlur={gwFormValidation.handleBlur}
                          value={gwFormValidation.values.epid || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="mt-3 mb-2 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary btn-block" style={{minWidth: "160px"}}>
                      {props.t("Save")}
                    </button>
                  </div>

                </Form>
              </Col>
              <Col xs={3} sm={3}  style={{borderLeft: "1px solid #999"}}>
                <div className="mb-2">
                  <Button size="sm" color="primary" className="me-2" onClick={() => {}}>
                    {props.t("Reboot via SMS")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Node configurations")}</CardTitle>
            <Row>
              <Col>
                <div className="mb-2">
                  <Button size="sm" color="primary" className="me-2" onClick={() => {}}>
                    {props.t("Add new")}
                  </Button>
                  <Button size="sm" color="danger" className="me-2" onClick={() => {}}>
                    {props.t("Delete")}
                  </Button>
                  <Button size="sm" color="danger" onClick={() => {}}>
                    {props.t("Reset")}
                  </Button>
                </div>
                <p>Node lista / grid / ???</p>
              </Col>
              <Col>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    nodeFormValidation.handleSubmit()
                    return false
                  }}
                >
                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Description")}</Label>
                        <Input
                          name="description"
                          type="text"
                          onChange={nodeFormValidation.handleChange}
                          onBlur={nodeFormValidation.handleBlur}
                          value={nodeFormValidation.values.description || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Node ID")}</Label>
                        <Input
                          name="id"
                          type="number"
                          onChange={nodeFormValidation.handleChange}
                          onBlur={nodeFormValidation.handleBlur}
                          value={nodeFormValidation.values.id || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Type")}</Label>
                        <Input value={nodeFormValidation.values.type + ""} type="select" name="type" onChange={nodeFormValidation.handleChange}>
                          <option value="4">{props.t("Undefined")}</option>
                          <option value="1">{props.t("Activity")}</option>
                          <option value="3">{props.t("Bed")}</option>
                          <option value="2">{props.t("Door")}</option>
                          <option value="5">{props.t("Electric")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Tracking")}</Label>
                        <Input value={nodeFormValidation.values.tracking + ""} type="select" name="tracking" onChange={nodeFormValidation.handleChange}>
                          <option value="4">{props.t("Undefined")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Version")}</Label>
                        <Input
                          name="version"
                          type="text"
                          onChange={nodeFormValidation.handleChange}
                          onBlur={nodeFormValidation.handleBlur}
                          value={nodeFormValidation.values.version || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Power on level")}</Label>
                        <Input
                          name="powerOnLevel"
                          type="number"
                          onChange={nodeFormValidation.handleChange}
                          onBlur={nodeFormValidation.handleBlur}
                          value={nodeFormValidation.values.powerOnLevel || ""}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs={1} sm={2} >
                    <Col>
                      <FormGroup>
                        <Switch className="me-2" name="configured" checked={nodeFormValidation.configured}>
                        </Switch>
                        <Label>{props.t("Configured")}</Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="mt-3 mb-2 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary btn-block" style={{minWidth: "160px"}}>
                      {props.t("Save")}
                    </button>
                  </div>

                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

AtHomeSettings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AtHomeSettings)