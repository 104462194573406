import withRouter from "components/Common/withRouter"
import GlobalState from "contexts/GlobalState"
import { suvantoLogout } from "helpers/backend_helper"
import React, { useEffect, useContext } from "react"
import { Route, useNavigate } from "react-router-dom"

const Logout = props => {
  const [state, setState] = useContext(GlobalState)
  const navigate = useNavigate()

  useEffect(() => {
    console.log("Logout")
    try {
      // Send logout info to server
      if (localStorage.getItem("session_id")) {
        suvantoLogout().catch((err) => {});
      }

      setState({})
      localStorage.removeItem("session_id")
      navigate("/login")
    } catch (error) {
      console.log(error)
    }
  }, [])

  return <></>
}

Logout.propTypes = {
  //history: PropTypes.object,
}

export default withRouter(Logout)
