// DEMO USE

const events = [
  {
    id: 1,
    title: "Brithday Party",
    start: new Date().setDate(new Date().getDate() + 1),
    className: "bg-success text-white",
  },
  {
    id: 2,
    title: "Meeting",
    start: new Date(),
    end: new Date(),
    className: "bg-success text-white",
  },
  {
    id: 3,
    title: "Click For Google",
    start: new Date().setDate(new Date().getDate() + 18),

    className: "bg-dark text-white",
  },
  {
    id: 4,
    title: "All Day Event",
    start: new Date().setDate(new Date().getDate() - 9),
    className: "bg-primary text-white",
  },
  {
    id: 5,
    title: "Repeating Event",
    start: new Date().setDate(new Date().getDate() - 3),
    className: "bg-info text-white",
  },
  {
    id: 6,
    title: "Lunch",
    extendedProps: {
      department: "BioChemistry",
    },
    description: "Lecture",
    start: new Date().setDate(new Date().getDate()),
    end: new Date().setDate(new Date().getHours() + 2),
    className: "bg-danger text-white",
  },
  {
    id: 7,
    title: "Repeating Event",
    start: new Date().setDate(new Date().getDate() + 4),
    className: "bg-primary text-white",
  },
  {
    id: 8,
    title: "Long Event",
    start: new Date().setDate(new Date().getDate() - 5),
    end: new Date().setDate(new Date().getDate() - 3),
    className: "bg-warning text-white",
  },
]

const categories = [
  {
    id: 1,
    title: "Morning event",
    type: "bg-warning",
  },
  {
    id: 2,
    title: "Forenoon event",
    type: "bg-chart-color2",
  },
  {
    id: 3,
    title: "Day event",
    type: "bg-chart-color6",
  },
  {
    id: 4,
    title: "Evening event",
    type: "bg-chart-color4",
  },
  {
    id: 5,
    title: "Create custom event",
    type: "bg-chart-color5",
  },
]

export { categories, events }
