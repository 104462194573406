/***********************
 * CALENDAR COMPONENT (RANGE)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//Formatting date
import moment from "moment"

//Translation
import { withTranslation } from "react-i18next"

//DatePicker ANTD
import { DatePicker } from "antd"
const { RangePicker } = DatePicker

const RangeCalendar = props => {
  const [defaultValueStart, setDefaultValueStart] = useState(
    moment(moment().startOf("day")).add(-14, "days")
  )
  const [defaultValueEnd, setDefaultValueEnd] = useState(moment())
  const [dateAndTime, setDateAndTime] = useState(null)

  useEffect(() => {
    if (dateAndTime == null) {
      props.setStartDate(defaultValueStart)
      props.setEndDate(defaultValueEnd)
    }
  }, [])

  const rangeButtons = {
    [props.t("day")]: [
      moment(moment().startOf("day")).add(-1, "days"),
      moment(),
    ],
    [props.t("7 days")]: [
      moment(moment().startOf("day")).add(-1, "weeks"),
      moment(),
    ],
    [props.t("14 days")]: [
      moment(moment().startOf("day")).add(-2, "weeks"),
      moment(),
    ],
    [props.t("month")]: [
      moment(moment().startOf("day")).add(-1, "months"),
      moment(),
    ],
    [props.t("year")]: [
      moment(moment().startOf("day")).add(-1, "years"),
      moment(),
    ],
  }

  const onDateSelection = value => {
    setDateAndTime(value)
    if (value != null) {
      let start = new Date(value[0])
      let startUnix = start.getTime()
      console.log(
        "Start date: " +
          moment(startUnix).startOf("day").format("DD.MM.YYYY HH:mm:ss")
      )

      let end = new Date(value[1])
      let endUnix = end.getTime()
      console.log(
        "End date: " +
          moment(endUnix).startOf("day").format("DD.MM.YYYY HH:mm:ss")
      )

      props.setStartDate(moment(startUnix).startOf("day"))
      props.setEndDate(moment(endUnix).endOf("day"))
    }
  }

  // User can not pick a date further than end of the day
  const disabledDate = current => {
    const from = moment().subtract(moment(), "days")
    return (
      current &&
      (current > moment().endOf("day") || current < from.startOf("day"))
    )
    // return current && current > moment().endOf("day")
  }

  return (
    <React.Fragment>
      <div>
        <RangePicker
          disabled={props.disabled}
          disabledDate={disabledDate}
          defaultValue={[defaultValueStart, defaultValueEnd]}
          allowClear={false}
          style={{
            width: "100%",
          }}
          onChange={onDateSelection}
          format="DD.MM.YYYY"
          ranges={{ ...rangeButtons }}
        />
      </div>
    </React.Fragment>
  )
}

RangeCalendar.propTypes = {
  t: PropTypes.any,
  getDate: PropTypes.any,
  setDateRange: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  placeholder: PropTypes.any,
  disabled: PropTypes.any,
}
export default withTranslation()(RangeCalendar)
