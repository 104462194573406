import React, { useEffect, useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getClientList } from "helpers/backend_helper"
import { Select, ConfigProvider } from "antd"

const SelectClient = props => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get clients list
  const getClients = () => {
    setLoading(true)
    getClientList(abortRef.current)
      .then(data => {
        console.log(data)
        if (data.array != null) {
          let opts = []
          let counter = 1
          data.array.forEach(group => {
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = group.name
            groupObject.gsm = ""
            groupObject.ssn = ""
            groupObject.address = ""
            groupObject.options = []

            group.clients.sort((a, b) => (a.name > b.name ? 1 : -1))
            group.clients.forEach(client => {
              let clientObject = new Object()
              clientObject.label = client.name
              clientObject.value = client.id
              clientObject.gsm = client.gsm ? client.gsm : ""
              clientObject.ssn = client.ssn ? client.ssn : ""
              clientObject.address = client.address ? client.address : ""

              groupObject.options.push(clientObject)
            })
            opts.push(groupObject)
          })
          setOptions(opts)
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Selection changes
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Dropdown visibility, get data at first
  const visibilityChanged = visible => {
    if (visible && options.length == 0 && !loading) {
      getClients()
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No customers found")}</p>
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            loading={loading}
            onDropdownVisibleChange={visibilityChanged}
            placeholder={props.t("Select client")}
            style={{ width: 100 + "%" }}
            value={props.selection}
            onChange={handleChange}
            options={options}
            filterOption={(inputValue, option) => {
              return (
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.gsm.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.ssn.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.address
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              )
            }}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectClient.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
}

export default withTranslation()(SelectClient)
