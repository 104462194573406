import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import VideoMessageModal from "./video-message-modal"
import {
  formatDateFull,
  formatDateSimple,
} from "utils/general"

const VideoMessageDevicesWindow = props => {
  const [chosen, setChosen] = useState([])

  useEffect(() => {
    setChosen(props.chosen)
  }, [props.chosen])

  // Change chosen from Customers
  const handleChangeChosen = key => {
    //console.log(key, chosen, "choose / unchoose")

    if (chosen.includes(key)) {
      let c = chosen.filter(x => x !== key)
      setChosen(c)
      props.setGroupInviteChosen(c)
    } 
    else {
      let c = chosen.push(key)
      setChosen(c)
      props.setGroupInviteChosen(c)
    }
  }

  return (
    <>
      {props.groups?.map((g, index) => (
        <ul key={index + g} className="list-group list-group-flush">
          {props.groups.length > 1 && (
            <div className="text-muted font-size-12">
              <i className="mdi mdi-account-group me-1" />
              {g}
            </div>
          )}
          {props.data?.map((guest, index) => (
            <div key={guest.key}>
              {g === guest.group && (
                <li className="list-group p-2">
                  <Col lg="12">
                    <Row>
                      {props.groupInvitation ? (
                        <Col>
                          <div className="form-check  ">
                            <input
                              checked={chosen.includes(guest.key)}
                              type="checkbox"
                              className="form-check-input"
                              id={guest.key}
                              value={guest.key}
                              onChange={() => handleChangeChosen(guest.key)}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor={guest.key}
                            >
                              <div>
                                <span className="h6">{guest.name}</span>
                                <span className="text-muted font-size-11">
                                  {guest.statusDescription === "Available" ? (
                                    <div className="text-success">
                                      {props.t(guest.statusDescription)}
                                    </div>
                                  ) : (
                                    <>
                                      {guest.statusDescription ===
                                      "Unavailable" ? (
                                        <div className="text-muted">
                                          {props.t("Last active")}{" "}
                                          {guest.last_online
                                            ? formatDateSimple(
                                                guest.last_online
                                              )
                                            : "-"}
                                        </div>
                                      ) : (
                                        <div className="text-primary">
                                          {props.t(guest.statusDescription)}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            </label>
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col>
                            <span>
                              {guest.statusDescription === "Available" ? (
                                <i className="bx bxs-circle text-success me-2 font-size-10"></i>
                              ) : (
                                <>
                                  {guest.statusDescription === "Unavailable" ? (
                                    <i className="bx bx-circle text-gray me-2 font-size-10"></i>
                                  ) : (
                                    <i className="bx bx-circle text-primary me-2 font-size-10"></i>
                                  )}
                                </>
                              )}

                              <span className="h6">{guest.name}</span>
                              <div
                                style={{
                                  marginInlineStart: "18px",
                                }}
                                className="text-muted font-size-11"
                              >
                                {guest.statusDescription === "Available" ? (
                                  <div className="text-success">
                                    {props.t(guest.statusDescription)}
                                  </div>
                                ) : (
                                  <>
                                    {guest.statusDescription ===
                                    "Unavailable" ? (
                                      <div className="text-muted">
                                        {props.t("Last active")}{" "}
                                        {guest.last_online
                                          ? formatDateSimple(guest.last_online)
                                          : "-"}
                                      </div>
                                    ) : (
                                      <div className="text-primary">
                                        {props.t(guest.statusDescription)}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </span>
                          </Col>
                          <Col
                            className="col-auto d-flex"
                            style={{ alignItems: "center" }}
                          >
                            {guest.status !== "online" ? (
                              <div className="text-muted font-size-10"></div>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col
                            className="col-auto d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <VideoMessageModal
                              t={props.t}
                              data={guest}
                              index={index}
                            ></VideoMessageModal>

                            <TooltipItemVar
                              t={props.t}
                              data={guest}
                              keyId={guest.key}
                              index={index}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </li>
              )}
            </div>
          ))}
        </ul>
      ))}
    </>
  )
}

const TooltipItemVar = props => {
  return (
    <>
      <span id={"info_" + props.index + props.keyId} className="px-2">
        <i className="text-muted bx bx-info-circle"></i>
      </span>
      <UncontrolledTooltip
        placement="right"
        target={"info_" + props.index + props.keyId}
      >
        <div className="d-grid">
          <span>{props.t("Group") + ": " + props.data.group}</span>
          {props.data.statusDescription !== "Available" &&
            props.data.last_online && (
              <span>
                {props.t("Last active") +
                  ": " +
                  formatDateFull(props.data.last_online)}
              </span>
            )}
          {props.data.device_battery != undefined && (
            <span>
              {props.t("Device battery level") +
                ": " +
                props.data.device_battery +
                "%"}
            </span>
          )}
        </div>
      </UncontrolledTooltip>
    </>
  )
}

VideoMessageDevicesWindow.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  index: PropTypes.number,
  title: PropTypes.any,
  groups: PropTypes.any,
  invitationValue: PropTypes.any,
  groupInvitation: PropTypes.bool,
  chosen: PropTypes.any,
}

TooltipItemVar.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  index: PropTypes.number,
  keyId: PropTypes.string,
}

export default withTranslation()(VideoMessageDevicesWindow)
