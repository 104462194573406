// Landing page -content
import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"
const { Panel } = Collapse

const InstructionsLandingPage = props => {
  return (
    <div>
      <p className="mt-2">
        {props.t(
          "On this page, you can see the support service's contact information and current announcements."
        )}
      </p>
      <p>
        {props.t(
          "You can navigate to other services from the links in the top bar."
        )}
      </p>
      {/* TODO: Content
       <Collapse accordion>
        <Panel header={props.t("Manual") + " 1"}>
          <p>{text}</p>
        </Panel>
        <Panel header={props.t("Manual") + " 2"}>
          <p>{text}</p>
        </Panel>
        <Panel header={props.t("Manual") + " 3"}>
          <p>{text}</p>
        </Panel>
      </Collapse> */}
    </div>
  )
}
InstructionsLandingPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(InstructionsLandingPage)
