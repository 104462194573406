import React, { useContext, useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

//i18n
import i18n from "../../../i18n"
import languages from "../../../common/languages"
import GlobalState from "../../../contexts/GlobalState"

const LanguageDropdown = props => {
  const [state, setState] = useContext(GlobalState)
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)
  const [TTlangbottom, setTTlangbottom] = useState(false) // Tooltip states

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("i18nextLng", lang)
    setState(state => ({ ...state, language: lang }))
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <Tooltip
          placement="bottom"
          isOpen={TTlangbottom}
          target="TTlang"
          toggle={() => {
            setTTlangbottom(!TTlangbottom)
          }}
        >
          {props.t("Language")}
        </Tooltip>
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="TTlang"
        >
          <i id="" className="bx bx-globe align-middle" />
        </DropdownToggle>
        <DropdownMenu className="dropdown">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Language"
                className="me-2"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

LanguageDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LanguageDropdown)
