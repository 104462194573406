import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Button,
  Accordion,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { markFeedbackRead, queryFeedback } from "helpers/backend_helper"
import { dateSorter, defaultPageSizeOptions, showToast, stringSorter } from "utils/general"
import { downloadExcel, exportPDF } from "utils/general"
import { Table as AntdTable } from "antd"

const FeedbackLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingAction, setOngoingAction] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filtersOpen, setFiltersOpen] = useState("")
  const [title, setTitle] = useState(props.t("Feedback"))
  const [menu, setMenu] = useState(false) //Dropdown button state

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    getFeedback()
    return () => abortRef.current.abort()
  }, [])

  // Get feedback
  const getFeedback = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      queryFeedback(abortRef.current, 500, false)
        .then(result => {
          if (result.statusCode == 200 && result.entries) {
            //console.log("Logs: ", result.entries)
            setLogs(result.entries)
          } else {
            setLogs([])
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
          setLogs([])
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Ack message; mark as read
  const ackMessage = id => {
    if (!ongoingAction && id) {
      setOngoingAction(true)
      markFeedbackRead(abortRef.current, id)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setTimeout(() => {
              getFeedback()
            }, 1000)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.message?.toLowerCase().includes(searchText) ||
        data.username?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Mark as read"),
      align: "center",
      render: actionFormatter,
      fixed: "left",
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "received",
      title: props.t("Received"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["received"], b["received"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "type",
      title: props.t("Type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "type", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "username",
      title: props.t("Username"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "username", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "email",
      title: props.t("Email"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "message",
      title: props.t("Message"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "message", sortOrder)
      },
      width: "300px",
      ellipsis: true,
    },
    {
      dataIndex: "id",
      title: props.t("ID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "id", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
  ]

  function actionFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          disabled={ongoingAction}
          size="sm"
          onClick={() => {
            ackMessage(row.id)
          }}
        >
          <i className="mdi mdi-check-bold" />
        </Button>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Received"),
        props.t("Type"),
        props.t("Username"),
        props.t("GSM"),
        props.t("Email"),
        props.t("Message"),
        props.t("ID"),
      ],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.received),
      elt.type,
      elt.username,
      elt.gsm,
      elt.email,
      elt.message,
      elt.id,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Feedback")} />

          <Card>
            <CardBody>
              <Accordion
                flush
                open={filtersOpen}
                toggle={id => {
                  id === filtersOpen ? setFiltersOpen("") : setFiltersOpen(id)
                }}
              >
                <AccordionItem>
                  <AccordionBody
                    accordionId="1"
                    className="mb-3"
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <Row>
                      <Col></Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <Row>
                <Col>
                  {logs != null && (
                    <React.Fragment>
                      <Row className="mb-1">
                        {" "}
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            //className="ms-3"
                            style={{ borderRadius: "100px" }}
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              getFeedback()
                            }}
                          >
                            <i className="mdi mdi-refresh me-2" />
                            {props.t("Refresh data")}
                          </Button>
                        </Col>
                        <Col>
                          <span className="float-end ms-2">
                            <UncontrolledTooltip
                              placement="bottom"
                              target="downloadstatstable"
                            >
                              {props.t("Download")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="printButtonServicesStats"
                              isOpen={menu}
                              toggle={() => setMenu(!menu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="downloadstatstable"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* TODO: Can not choose directory file right now */}
                                <DropdownItem
                                  onClick={() => generateExcel()}
                                >
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                          <div className="mt-2 float-end">
                            <p>
                              {filteredLogs.length}{" "}
                              {props.t("results found")}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          {ongoingSearch == true ? (
                            <div className="py-5 text-center">
                              <p className="text-primary">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                {props.t("Loading")}
                              </p>
                            </div>
                          ) : (
                            <AntdTable
                              className="striped colored-header"
                              bordered
                              size="middle"
                              sticky
                              rowKey={"id"}
                              columns={columns}
                              dataSource={filteredLogs}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: "64vh",
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

FeedbackLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FeedbackLogs)
