/**********************
 * FETCHING VIDEO ROOM GROUPS
 *********************/
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { TreeSelect, ConfigProvider } from "antd"
import { getACsGroupsTree } from "../../helpers/backend_helper"
const { SHOW_ALL } = TreeSelect

const VideoGroupsTree = props => {
  const [treeData, setTreeData] = useState([])
  const [selectedValue, setSelectedValue] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(true)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => {
      abortRef.current.abort()
    }
  }, [])

  // Require data load after production change
  useEffect(() => {
    setSelectedValue(undefined)
    setReload(true)
  }, [props.production])

  // Selection from props
  useEffect(() => {
    if (props.selected) {
      console.log(props.selected)
      setSelectedValue(props.selected)
    } else {
      setSelectedValue(undefined)
    }
  }, [props.selected])

  // Load groups
  const getGroups = () => {
    setTreeData([])
    setSelectedValue(null)
    setLoading(true)
    getACsGroupsTree(abortRef.current, props.production)
      .then(result => {
        if (result.statusCode === 200 && result.groups != null) {
          console.log("Tree: ", result.groups)
          setTreeData(result.groups)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
        setReload(false)
      })
  }

  // Tree selection changed
  const onChange = (newValue) => {
    console.log("onChange ", newValue)
    setSelectedValue(newValue)
    if (props.selectionChanged) {
      props.selectionChanged(newValue)
    }
  }

  // Dropdown visibility, load data on first open
  const visibilityChanged = (visible) => {
    if (visible && reload && !loading) {
      getGroups()
    }
  }

  // Empty list render
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
        {loading ? <p>{props.t("Loading")}</p> :
      <p>{props.t("No data")}</p>}
    </div>
  )

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <TreeSelect
          style={{ width: "100%" }}
          value={selectedValue}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          loading={loading}
          onDropdownVisibleChange={visibilityChanged}
          treeData={treeData}
          placeholder={props.t("Optional") + " - Only for Istekki numbers"}
          allowClear={true}
          treeDefaultExpandAll={props.expanded != null ? props.expanded : false}
          showCheckedStrategy={SHOW_ALL}
          showSearch={false}
          onChange={onChange}
        />
      </ConfigProvider>
    </>
  )
}

VideoGroupsTree.propTypes = {
  t: PropTypes.any,
  selected: PropTypes.any,
  selectionChanged: PropTypes.func,
  expanded: PropTypes.bool,
  production: PropTypes.bool,
}

export default withTranslation()(VideoGroupsTree)