import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { getCarephones, syncCarephones } from "helpers/backend_helper"
import CarephoneDrawer from "./carephone-drawer"
import { showToast, downloadExcel, stringSorter, numberSorter, dateSorter, defaultPageSizeOptions } from "utils/general"
import { DatePicker, Table as AntdTable } from "antd"

const CarephoneConfigs = props => {
  const [carephones, setCarephones] = useState([])
  const [filteredCarephones, setFilteredCarephones] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [operationalFilter, setOperationalFilter] = useState("0")
  const [linkedFilter, setLinkedFilter] = useState("0")
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statsDate, setStatsDate] = useState(moment())
  const [statistics, setStatistics] = useState({ total: 0 })
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Carephones"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = carephones.length
    temp.linked = carephones.filter(data => data.links.length > 0).length
    temp.inUse = carephones.filter(
      data => data.operationalStatusValue == 0
    ).length
    temp.notUsed = carephones.filter(
      data => data.operationalStatusValue != 0
    ).length

    let at = statsDate.valueOf()
    temp.prod = 0
    carephones.forEach(item => {
      let start =
        item.prodStart != undefined ? new Date(item.prodStart) : undefined
      let end = item.prodEnd != undefined ? new Date(item.prodEnd) : undefined
      if (start != undefined && start.getTime() > 0 && start.getTime() < at) {
        if (end != undefined && start.getTime() < end.getTime()) {
          if (end > at) {
            temp.prod += 1
          }
        } else {
          temp.prod += 1
        }
      }
    })
    setStatistics(temp)
  }, [carephones, statsDate])

  // Check duplicate values
  useEffect(() => {
    if (carephones) {
      // GSM, ICCID, SERIAL
      let dups = []
      for (let i = 0; i < carephones.length - 1; i++) {
        for (let j = i + 1; j < carephones.length; j++) {
          if (
            carephones[i].iccid &&
            carephones[i].iccid === carephones[j].iccid
          ) {
            dups.push({
              key: carephones[i].iccid,
              name: "ICCID:" + carephones[i].iccid,
            })
          }
          if (carephones[i].gsm && carephones[i].gsm === carephones[j].gsm) {
            dups.push({
              key: carephones[i].gsm,
              name: "GSM:" + carephones[i].gsm,
            })
          }
          if (
            carephones[i].serial &&
            carephones[i].serial === carephones[j].serial
          ) {
            dups.push({
              key: carephones[i].serial,
              name: "SN:" + carephones[i].serial,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [carephones])

  // Check multiple links
  useEffect(() => {
    if (carephones) {
      let multis = []
      carephones.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.key, name: element.serial })
        }
      })
      setMultilinks(multis)
    }
  }, [carephones])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen(false)
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, carephones, operationalFilter, linkedFilter])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = carephones.filter(
      data =>
        (operationalFilter === "0" ||
          (operationalFilter === "1" && data.operationalStatusValue == 0) ||
          (operationalFilter === "2" && data.operationalStatusValue != 0)) &&
        (linkedFilter === "0" ||
          (linkedFilter === "1" && data.links.length > 0) ||
          (linkedFilter === "2" && data.links.length == 0)) &&
        (data.key?.toLowerCase() === searchTextLowercase ||
          data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.iccid?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.accessories?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredCarephones(result)
  }

  // Get carephones
  const getDevices = () => {
    setOngoingSearch(true)
    getCarephones(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          result.entries.forEach(element => {
            let linkedNames = []
            if (element.links) {
              element.links.forEach(link => {
                linkedNames.push(link.name)
              })
            }
            element.linkedNames = linkedNames.join()
          })
          setCarephones(result.entries)
        } else {
          setCarephones([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setCarephones([])
        setOngoingSearch(false)
      })
  }

  // Sync all devices with Neat
  const syncDeviceList = () => {
    setOngoingSync(true)
    syncCarephones(abortRef.current)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      fixed: "left",
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceType",
      title: props.t("Type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "deviceType", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "firmware",
      title: props.t("Firmware"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "firmware", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "callbackTime",
      title: props.t("Callback time"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["callbackTime"], b["callbackTime"], sortOrder)
      },
      width: "170px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: linksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "iccid",
      title: props.t("ICCID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "iccid", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "syncDate",
      title: props.t("Synced"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["syncDate"], b["syncDate"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "operationalStatus",
      title: props.t("Operation status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "operationalStatus", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "globalTechStatus",
      title: props.t("Global status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "globalTechStatus", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "mainsStatus",
      title: props.t("Mains status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "mainsStatus", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "accumulatorStatus",
      title: props.t("Battery status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "accumulatorStatus", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "connectionPortalStatus",
      title: props.t("Connection status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "connectionPortalStatus", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "accessoriesBatteryStatus",
      title: props.t("Accessory battery status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "accessoriesBatteryStatus", sortOrder)
      },
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "operator",
      title: props.t("Operator"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "operator", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "signalStrength",
      title: props.t("Signal strength"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["signalStrength"], b["signalStrength"], sortOrder)
      },
      width: "170px",
      ellipsis: true,
    },
    {
      dataIndex: "lastReboot",
      title: props.t("Rebooted"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastReboot"], b["lastReboot"], sortOrder)
      },
      render: dateFormatter,
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "lastAlarmTime",
      title: props.t("Last alarm time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastAlarmTime"], b["lastAlarmTime"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "prodStart",
      title: props.t("Prod start"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["prodStart"], b["prodStart"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "prodEnd",
      title: props.t("Prod end"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["prodEnd"], b["prodEnd"], sortOrder)
      },
      render: simpleDateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "accessories",
      title: props.t("Accessories"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "accessories", sortOrder)
      },
      width: "180px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b) => {
        return stringSorter(a, b, "key")
      },
      width: "280px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
          {/**props.t("Edit")*/}
        </Button>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function simpleDateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY")
    return dateString
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Serial"),
        props.t("Type"),
        props.t("Firmware"),
        props.t("Callback time"),
        props.t("Order number"),
        props.t("Links"),
        props.t("Gsm"),
        props.t("ICCID"),
        props.t("Synced"),
        props.t("Operation status"),
        props.t("Global status"),
        props.t("Mains status"),
        props.t("Battery status"),
        props.t("Connection status"),
        props.t("Accessory battery status"),
        props.t("Operator"),
        props.t("Signal strength"),
        props.t("Rebooted"),
        props.t("Last alarm time"),
        props.t("Prod start"),
        props.t("Prod end"),
        props.t("Accessories"),
        props.t("GUID"),
      ],
    ]
    const data = filteredCarephones.map(elt => [
      elt.serial,
      elt.deviceType,
      elt.firmware,
      elt.callbackTime,
      elt.orderNumber,
      elt.linkedNames,
      elt.gsm,
      elt.iccid,
      dateFormatter(elt.syncDate),
      elt.operationalStatus,
      elt.globalTechStatus,
      elt.mainsStatus,
      elt.accumulatorStatus,
      elt.connectionPortalStatus,
      elt.accessoriesBatteryStatus,
      elt.operator,
      elt.signalStrength,
      dateFormatter(elt.lastReboot),
      dateFormatter(elt.lastAlarmTime),
      simpleDateFormatter(elt.prodStart),
      simpleDateFormatter(elt.prodEnd),
      elt.accessories,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Carephones")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen
                        ? setAlertsOpen("")
                        : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {carephones != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      value={searchText}
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                className="round-icon-button"
                                id="filter-button"
                                color="primary"
                                outline
                                onClick={() => {
                                  filtersOpen.length > 0
                                    ? setFiltersOpen("")
                                    : setFiltersOpen("1")
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="filter-button"
                                >
                                  {props.t("Filters")}
                                </UncontrolledTooltip>
                                {filtersOpen ? (
                                  <i className="mdi mdi-filter-minus"></i>
                                ) : (
                                  <i className="mdi mdi-filter-menu"></i>
                                )}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSync}
                                onClick={() => {
                                  syncDeviceList()
                                }}
                              >
                                <i className="mdi mdi-cloud-sync me-2" />
                                {props.t("Sync devices")}
                              </Button>
                              {(duplicates.length > 0 ||
                                multilinks.length > 0) && (
                                <Button
                                  color="danger"
                                  className="round-icon-button ms-3"
                                  id="alerts-button"
                                  onClick={() => {
                                    alertsOpen.length > 0
                                      ? setAlertsOpen("")
                                      : setAlertsOpen("1")
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="alerts-button"
                                  >
                                    {props.t("Notices")}
                                  </UncontrolledTooltip>
                                  {alertsOpen ? (
                                    <i className="mdi mdi-alert-minus"></i>
                                  ) : (
                                    <i className="mdi mdi-alert-plus"></i>
                                  )}
                                </Button>
                              )}
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {/* TODO: Can not choose directory file right now */}
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredCarephones.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row xs={1} sm={2} md={3} xl={4}>
                                  <Col>
                                    <FormGroup>
                                      <Label>
                                        {props.t("Operation status")}
                                      </Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="operationalFilter"
                                        value={operationalFilter}
                                        onChange={v => {
                                          setOperationalFilter(
                                            v.target.value
                                          )
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("In use")}
                                        </option>
                                        <option value="2">
                                          {props.t("Not in use")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Links")}</Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="linkedFilter"
                                        value={linkedFilter}
                                        onChange={v => {
                                          setLinkedFilter(
                                            v.target.value
                                          )
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("Linked")}
                                        </option>
                                        <option value="2">
                                          {props.t("Not linked")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              {ongoingSearch == true ? (
                                <div className="py-5 text-center">
                                  <p className="text-primary">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                    {props.t("Loading")}
                                  </p>
                                </div>
                              ) : (
                                <AntdTable
                                  className="striped colored-header"
                                  bordered
                                  size="middle"
                                  sticky
                                  rowKey={"key"}
                                  columns={columns}
                                  dataSource={filteredCarephones}
                                  pagination={{
                                    showSizeChanger: true,
                                    defaultPageSize: 50,
                                    pageSizeOptions: defaultPageSizeOptions,
                                  }}
                                  tableLayout="auto"
                                  scroll={{
                                    x: "max-content",
                                    y: "64vh",
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Operational")}</th>
                        <td>{statistics.inUse}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Not operational")}</th>
                        <td>{statistics.notUsed}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <DatePicker
                    className="mt-4 mb-3"
                    defaultValue={moment()}
                    showToday
                    allowClear={false}
                    onChange={v => {
                      setStatsDate(v)
                    }}
                    format="DD.MM.YYYY"
                    style={{
                      width: "100%",
                    }}
                  />

                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Production")}</th>
                        <td>{statistics.prod}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <CarephoneDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getCarephones={getDevices}
          />
        </Container>
      </div>
    </>
  )
}

CarephoneConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CarephoneConfigs)
