import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Progress,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"

import {
  formatDateSimple,
  formatDate,
  timeFormatter,
  showToast,
} from "utils/general"
import html2canvas from "html2canvas"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

// Alert types for modal
import { action } from "./options-charts/alarm-titles"

//Simple bar
import SimpleBar from "simplebar-react"

//Chart import
import ReactApexChart from "react-apexcharts"

//Translation
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const ChartAlerts = props => {
  const [stats, setStats] = useState({})
  const [list, setList] = useState(props.data.list)
  const [filteredList, setFilteredList] = useState(list)

  // Info
  const [info, setInfo] = useState(false)

  // Subcategories
  const [subcategory, setSubcategory] = useState()
  const [smallSeries, setSmallSeries] = useState()
  const [smallOptions, setSmallOptions] = useState()

  // Customer stats
  const [customersData, setCustomersData] = useState()
  const [groupsData, setGroupsData] = useState()
  const [combinedData, setCombinedData] = useState()

  // Chosen content
  const [subject, setSubject] = useState(props.t("All alerts"))

  // Graph series
  const [series, setSeries] = useState()

  // Card states
  const [total, setTotal] = useState(0)
  const [closeAverage, setCloseAverage] = useState(0)
  const [openAverage, setOpenAverage] = useState(0)
  const [ackAverage, seAckAverage] = useState(0)
  const [ackMedian, setAckMedian] = useState(0)
  const [closeTimeMedian, setCloseTimeMedian] = useState(0)
  const [closeTimeAverage, setCloseTimeAverage] = useState(0)
  const [falseAck, setFalseAck] = useState(0)

  // setting details filtered data (group/customer/type)
  // const [groups, setGroups] = useState([])
  const [customers, setCustomers] = useState([])

  // Filtered stats by alarm types (devices)
  //   Laitteet ja häiriöt
  // -	"1": "Paikantimen akku loppumassa"
  const [a1, setA1] = useState(0)
  // -	"2": "Paikannin sammutettu",
  const [a2, setA2] = useState(0)
  // -	"10": "Keskusyksikkö akkukäytöllä",
  const [a10, setA10] = useState(0)
  // -	"11": "Keskusyksikkö sammunut",
  const [a11, setA11] = useState(0)
  // -	"26": "Keskusyksikön yhteyshäiriö",
  const [a26, setA26] = useState(0)
  // -	"29": "Lääkemuistuttajan yhteysongelma",
  const [a29, setA29] = useState(0)
  // -	"32": "Videopäätelaite sammutettu",
  const [a32, setA32] = useState(0)
  // -	"33": "Videopäätelaite akkukäytöllä",
  const [a33, setA33] = useState(0)
  // -	"44": "Turvapuhelin akkukäytöllä",
  const [a44, setA44] = useState(0)
  // -	"45": "Turvapuhelimen akku loppumassa",
  const [a45, setA45] = useState(0)
  // -	"46": "Turvapuhelimen yhteyshäiriö",
  const [a46, setA46] = useState(0)
  // -	"47": "Turvapuhelimen lisälaitteen akku loppumassa",
  const [a47, setA47] = useState(0)
  // -	"48": "Videopäätelaitteen yhteysongelma",
  const [a48, setA48] = useState(0)
  // -	"51": "Älylukon akku loppumassa",
  const [a51, setA51] = useState(0)
  // -	"52": "Älylukko sammutettu",
  const [a52, setA52] = useState(0)
  // -	"53": "Älylukon yhteysongelma"
  const [a53, setA53] = useState(0)

  // Filtered stats by alarm types (apartment)
  // -	"20": "Matala ilmankosteus",
  const [a20, setA20] = useState(0)
  // -	"21": "Korkea ilmankosteus",
  const [a21, setA21] = useState(0)
  // -	"22": "Ovi jäänyt auki",
  const [a22, setA22] = useState(0)
  // -	"23": "Havaintoyksikön yhteyshäiriö",
  const [a23, setA23] = useState(0)
  // -	"37": "Savuhälytys",
  const [a37, setA37] = useState(0)
  // -	"38": "Ovihälytys",
  const [a38, setA38] = useState(0)
  // - "54": Oven avaus
  const [a54, setA54] = useState(0)

  //Filtered stats by alarm types (safety)
  const [a3, setA3] = useState(0)
  const [a4, setA4] = useState(0)
  const [a42, setA42] = useState(0)
  const [a18, setA18] = useState(0)
  const [a19, setA19] = useState(0)
  const [a27, setA27] = useState(0)
  const [a28, setA28] = useState(0)
  const [a31, setA31] = useState(0)
  const [a35, setA35] = useState(0)
  const [a36, setA36] = useState(0)

  //Filtered stats by alarm types (activity)
  const [a6, setA6] = useState(0)
  const [a12, setA12] = useState(0)
  const [a14, setA14] = useState(0)
  const [a16, setA16] = useState(0)
  const [a5, setA5] = useState(0)
  const [a24, setA24] = useState(0)
  const [a25, setA25] = useState(0)

  //Filtered stats by alarm types (abnormal activity)
  const [a30, setA30] = useState(0)
  const [a17, setA17] = useState(0)
  const [a15, setA15] = useState(0)
  const [a13, setA13] = useState(0)

  // Other
  const [a34, setA34] = useState(0)
  const [a39, setA39] = useState(0)
  const [a43, setA43] = useState(0)

  //Dropdown button state
  const [menuTypes, setMenuTypes] = useState(false)
  const [menu, setMenu] = useState(false)

  // Dates
  const [timeStart, setTimeStart] = useState(props.start)
  const [timeEnd, setTimeEnd] = useState(props.end)

  // Updating states
  useEffect(() => {
    if (
      props.data != undefined &&
      props.data != null &&
      props.data.stats != undefined &&
      props.data.stats != stats &&
      props.data.list != undefined
    ) {
      console.log("Stats: " + JSON.stringify(props.data))
      setStats(props.data.stats)

      //Filter by categories
      filterData(props.data.list)

      console.log("Data table alerts: ", props.data.list)
      if (props.data.list != filteredList) {
        // format alarmtype for search
        props.data.list.forEach(element => {
          formatEntry(element)
        })
        setList(props.data.list)
        setFilteredList(props.data.list)
      }
      handlingCategory(subject, props.data.list, subject)
      // Set given stats
      seAckAverage(props.data.stats.ack_average)
      setAckMedian(props.data.stats.ack_median)
      setCloseTimeAverage(props.data.stats.close_average)
      setCloseTimeMedian(props.data.stats.close_median)
      setTotal(props.data.stats.total)
      setOpenAverage(props.data.stats.open)
      setFalseAck(props.data.stats.false)
      setCloseAverage(
        props.data.stats.total - props.data.stats.open - props.data.stats.false
      )
    }
  }, [props.data])

  const addCustomers = e => {
    let data = e.map(g => g.client_name)

    const withoutDuplicates = [...new Set(data)]
    setCustomers(withoutDuplicates)
  }

  // when list is updated, updates stats
  useEffect(() => {
    if (filteredList != undefined) {
      // Get groups and customers
      addCustomers(filteredList)
    }
  }, [filteredList])

  // Filter data
  const formatEntry = entry => {
    if (typeof entry.alarm_type === "string") {
      return entry
    } else {
      return (entry.alarm_type = props.t("AlarmTitles." + entry.alarm_type))
    }
  }

  //Filtering by alarmtypes
  const filterData = data => {
    //DEVICES
    // "1": "Paikantimen akku loppumassa"
    const at1 = data.filter(
      d => d.alarm_type === 1 || d.alarm_type === props.t("AlarmTitles." + "1")
    ).length
    setA1(at1)
    // "2": "Paikannin sammutettu",
    const at2 = data.filter(
      d => d.alarm_type === 2 || d.alarm_type === props.t("AlarmTitles." + "2")
    ).length
    setA2(at2)
    // "10": "Keskusyksikkö akkukäytöllä",
    const at10 = data.filter(
      d =>
        d.alarm_type === 10 || d.alarm_type === props.t("AlarmTitles." + "10")
    ).length
    setA10(at10)
    // "11": "Keskusyksikkö sammunut",
    const at11 = data.filter(
      d =>
        d.alarm_type === 11 || d.alarm_type === props.t("AlarmTitles." + "11")
    ).length
    setA11(at11)
    // "26": "Keskusyksikön yhteyshäiriö",
    const at26 = data.filter(
      d =>
        d.alarm_type === 26 || d.alarm_type === props.t("AlarmTitles." + "26")
    ).length
    setA26(at26)
    // "29": "Lääkemuistuttajan yhteysongelma",
    const at29 = data.filter(
      d =>
        d.alarm_type === 29 || d.alarm_type === props.t("AlarmTitles." + "29")
    ).length
    setA29(at29)
    // "32": "Videopäätelaite sammutettu",
    const at32 = data.filter(
      d =>
        d.alarm_type === 32 || d.alarm_type === props.t("AlarmTitles." + "32")
    ).length
    setA32(at32)
    // "33": "Videopäätelaite akkukäytöllä",
    const at33 = data.filter(
      d =>
        d.alarm_type === 33 || d.alarm_type === props.t("AlarmTitles." + "33")
    ).length
    setA33(at33)
    // "44": "Turvapuhelin akkukäytöllä",
    const at44 = data.filter(
      d =>
        d.alarm_type === 44 || d.alarm_type === props.t("AlarmTitles." + "44")
    ).length
    setA44(at44)
    // "45": "Turvapuhelimen akku loppumassa",
    const at45 = data.filter(
      d =>
        d.alarm_type === 45 || d.alarm_type === props.t("AlarmTitles." + "45")
    ).length
    setA45(at45)
    // "46": "Turvapuhelimen yhteyshäiriö",
    const at46 = data.filter(
      d =>
        d.alarm_type === 46 || d.alarm_type === props.t("AlarmTitles." + "46")
    ).length
    setA46(at46)
    // "47": "Turvapuhelimen lisälaitteen akku loppumassa",
    const at47 = data.filter(
      d =>
        d.alarm_type === 47 || d.alarm_type === props.t("AlarmTitles." + "47")
    ).length
    setA47(at47)
    // "48": "Videopäätelaitteen yhteysongelma",
    const at48 = data.filter(
      d =>
        d.alarm_type === 48 || d.alarm_type === props.t("AlarmTitles." + "48")
    ).length
    setA48(at48)
    // "51": "Älylukon akku loppumassa",
    const at51 = data.filter(
      d =>
        d.alarm_type === 51 || d.alarm_type === props.t("AlarmTitles." + "51")
    ).length
    setA51(at51)
    // "52": "Älylukko sammutettu",
    const at52 = data.filter(
      d =>
        d.alarm_type === 52 || d.alarm_type === props.t("AlarmTitles." + "52")
    ).length
    setA52(at52)
    // "53": "Älylukon yhteysongelma"
    const at53 = data.filter(
      d =>
        d.alarm_type === 53 || d.alarm_type === props.t("AlarmTitles." + "53")
    ).length
    setA53(at53)
    let devices =
      at1 +
      at2 +
      at10 +
      at11 +
      at26 +
      at29 +
      at32 +
      at33 +
      at44 +
      at45 +
      at46 +
      at47 +
      at48 +
      at51 +
      at52 +
      at53

    //APARTMENT
    // "20": "Matala ilmankosteus",
    const at20 = data.filter(
      d =>
        d.alarm_type === 20 || d.alarm_type === props.t("AlarmTitles." + "20")
    ).length
    setA20(at20)
    // "21": "Korkea ilmankosteus",
    const at21 = data.filter(
      d =>
        d.alarm_type === 21 || d.alarm_type === props.t("AlarmTitles." + "21")
    ).length
    setA21(at21)
    // "22": "Ovi jäänyt auki",
    const at22 = data.filter(
      d =>
        d.alarm_type === 22 || d.alarm_type === props.t("AlarmTitles." + "22")
    ).length
    setA22(at22)
    // "23": "Havaintoyksikön yhteyshäiriö",
    const at23 = data.filter(
      d =>
        d.alarm_type === 23 || d.alarm_type === props.t("AlarmTitles." + "23")
    ).length
    setA23(at23)
    // "37": "Savuhälytys",
    const at37 = data.filter(
      d =>
        d.alarm_type === 37 || d.alarm_type === props.t("AlarmTitles." + "37")
    ).length
    setA37(at37)
    // "38": "Ovihälytys",
    const at38 = data.filter(
      d =>
        d.alarm_type === 38 || d.alarm_type === props.t("AlarmTitles." + "38")
    ).length
    setA38(at38)
    // "18": "Matala lämpötila",
    const at18 = data.filter(
      d =>
        d.alarm_type === 18 || d.alarm_type === props.t("AlarmTitles." + "18")
    ).length
    setA18(at18)
    // "19": "Korkea lämpötila",
    const at19 = data.filter(
      d =>
        d.alarm_type === 19 || d.alarm_type === props.t("AlarmTitles." + "19")
    ).length
    setA19(at19)
    // "54": "Ovi avattu",
    const at54 = data.filter(
      d =>
        d.alarm_type === 54 || d.alarm_type === props.t("AlarmTitles." + "54")
    ).length
    setA54(at54)
    let apartment = at20 + at21 + at22 + at23 + at37 + at38 + at18 + at19 + at54

    //safety
    // "3": "SOS-nappi",
    const at3 = data.filter(
      d => d.alarm_type === 3 || d.alarm_type === props.t("AlarmTitles." + "3")
    ).length
    setA3(at3)
    // "4": "Kaatuminen",
    const at4 = data.filter(
      d => d.alarm_type === 4 || d.alarm_type === props.t("AlarmTitles." + "4")
    ).length
    setA4(at4)
    // "35": "Turvapuhelimen SOS-nappi",
    const at35 = data.filter(
      d =>
        d.alarm_type === 35 || d.alarm_type === props.t("AlarmTitles." + "35")
    ).length
    setA35(at35)
    // "36": "Turvarannekkeen SOS-nappi",
    const at36 = data.filter(
      d =>
        d.alarm_type === 36 || d.alarm_type === props.t("AlarmTitles." + "36")
    ).length
    setA36(at36)
    // "42": "Turvapuhelimen kaatumishälytys",
    const at42 = data.filter(
      d =>
        d.alarm_type === 42 || d.alarm_type === props.t("AlarmTitles." + "42")
    ).length
    setA42(at42)
    // "5": "Turva-alueelta poistuminen",
    const at5 = data.filter(
      d => d.alarm_type === 5 || d.alarm_type === props.t("AlarmTitles." + "5")
    ).length
    setA5(at5)
    let safety = at3 + at4 + at35 + at36 + at42 + at5

    //health
    // "27": "Lääkeannos ottamatta",
    const at27 = data.filter(
      d =>
        d.alarm_type === 27 || d.alarm_type === props.t("AlarmTitles." + "27")
    ).length
    setA27(at27)
    // "28": "Lääkeannokset loppumassa",
    const at28 = data.filter(
      d =>
        d.alarm_type === 28 || d.alarm_type === props.t("AlarmTitles." + "28")
    ).length
    setA28(at28)
    // "30": "Poikkeava etämittaus",
    const at30 = data.filter(
      d =>
        d.alarm_type === 30 || d.alarm_type === props.t("AlarmTitles." + "30")
    ).length
    setA30(at30)
    // "31": "Etämittaus suorittamatta",
    const at31 = data.filter(
      d =>
        d.alarm_type === 31 || d.alarm_type === props.t("AlarmTitles." + "31")
    ).length
    setA31(at31)
    let health = at27 + at28 + at30 + at31

    //ACTIVITY
    // "6": "Turvarannekkeen liikkumattomuus",
    const at6 = data.filter(
      d => d.alarm_type === 6 || d.alarm_type === props.t("AlarmTitles." + "6")
    ).length
    setA6(at6)
    // "12": "Kotoa poistuminen",
    const at12 = data.filter(
      d =>
        d.alarm_type === 12 || d.alarm_type === props.t("AlarmTitles." + "12")
    ).length
    setA12(at12)
    // "14": "Liikkumattomuus",
    const at14 = data.filter(
      d =>
        d.alarm_type === 14 || d.alarm_type === props.t("AlarmTitles." + "14")
    ).length
    setA14(at14)
    // "16": "Lepoajan aktiivisuus",
    const at16 = data.filter(
      d =>
        d.alarm_type === 16 || d.alarm_type === props.t("AlarmTitles." + "16")
    ).length
    setA16(at16)
    // "24": "Aktiivinen aikaväli",
    const at24 = data.filter(
      d =>
        d.alarm_type === 24 || d.alarm_type === props.t("AlarmTitles." + "24")
    ).length
    setA24(at24)
    // "25": "Tilassa viipyminen",
    const at25 = data.filter(
      d =>
        d.alarm_type === 25 || d.alarm_type === props.t("AlarmTitles." + "25")
    ).length
    setA25(at25)
    let activity = at6 + at12 + at14 + at16 + at24 + at25

    //ABNORMAL
    // "17": "Poikkeava käyttöaika",
    const at17 = data.filter(
      d =>
        d.alarm_type === 17 || d.alarm_type === props.t("AlarmTitles." + "17")
    ).length
    setA17(at17)
    // "15": "Pitkittynyt ulkoilu",
    const at15 = data.filter(
      d =>
        d.alarm_type === 15 || d.alarm_type === props.t("AlarmTitles." + "15")
    ).length
    setA15(at15)
    // "13": "Poikkeava käyttömäärä",
    const at13 = data.filter(
      d =>
        d.alarm_type === 13 || d.alarm_type === props.t("AlarmTitles." + "13")
    ).length
    // console.log(at13, "käyttömäärä")
    setA13(at13)
    let abnormal = at17 + at15 + at13

    //OTHER
    // “34”: “Soittopyyntö”,
    const at34 = data.filter(
      d =>
        d.alarm_type === 34 || d.alarm_type === props.t("AlarmTitles." + "34")
    ).length
    setA34(at34)
    // “39”: “Viestiin ei saatu vastausta”,
    const at39 = data.filter(
      d =>
        d.alarm_type === 39 || d.alarm_type === props.t("AlarmTitles." + "39")
    ).length
    setA39(at39)
    // “43”: “Jatkohälytys”,
    const at43 = data.filter(
      d =>
        d.alarm_type === 43 || d.alarm_type === props.t("AlarmTitles." + "43")
    ).length
    setA43(at43)
    let other = at34 + at39 + at43

    // Order must be devices,apartment,safety,activity,health,abnormal,other
    setSeries([devices, apartment, safety, activity, health, abnormal, other])
  }

  // Different categories for small chart
  const seriesDevices = [
    a1,
    a2,
    a10,
    a11,
    a26,
    a29,
    a32,
    a33,
    a44,
    a45,
    a46,
    a47,
    a48,
    a51,
    a52,
    a53,
  ]
  const seriesHealth = [a30, a27, a28, a31]
  const seriesAppartment = [a20, a21, a22, a23, a37, a38, a18, a19, a54]
  const seriesSafety = [a3, a4, a35, a36, a42, a5]
  const seriesActivity = [a6, a12, a14, a16, a24, a25]
  const seriesAbnormal = [a17, a15, a13]
  const seriesOther = [a34, a39, a43]

  const settingSubject = (title, type) => {
    setSubject(title)
    settingFilteredList(title, type)
    // TODO: Set not-chosen if two same in one array
    // setCategory(category => [...category, title])
  }

  // Setting CustomersData
  const handlingCategory = (ty, fl, subject) => {
    let result = []
    let resultGroups = []
    let resultCombined = []
    let cus = []
    let gro = []

    cus = fl.map(g => g.client_name)
    let customers = [...new Set(cus)]

    gro = fl.map(g => g.group)
    let groups = [...new Set(gro)]

    customers.forEach(c => {
      let count = fl.filter(f => f.client_name === c)
      let group = count[0].group
      let customerData = {
        name: c,
        group: group,
        type: subject,
        time1: formatDateSimple(props.start),
        time2: formatDateSimple(props.end),
        amount: count.length,
        data: count,
      }
      result.push(customerData)
    })
    groups.forEach(g => {
      let count = fl.filter(f => f.group === g)
      let groupData = {
        name: g,
        type: subject,
        amount: count.length,
      }
      resultGroups.push(groupData)
    })

    resultGroups.forEach(rg => {
      let rest = result.filter(s => s.group == rg.name)
      let combinedData = {
        group: rg.name,
        amount: rg.amount,
        clients: rest,
      }
      resultCombined.push(combinedData)
    })
    console.log(resultCombined, "Filtered data combined")
    setCombinedData(resultCombined)
    setGroupsData(resultGroups)
    setCustomersData(result)
  }
  const settingFilteredList = (c, type) => {
    if (type === "main") {
      // Large categories
      setSmallDiagram(c)

      let values = []
      let fileteredlist = []

      if (c.includes(props.t("Activity"))) {
        console.log("Activity")
        values = [6, 12, 14, 16, 24, 25]
      }
      if (c.includes(props.t("Abnormal activity"))) {
        console.log("AB")
        values = [17, 15, 13]
      }
      if (c.includes(props.t("Health"))) {
        console.log("Health")
        values = [30, 27, 28, 31]
      }
      if (c.includes(props.t("Devices"))) {
        console.log("Devices")
        values = [1, 2, 10, 11, 26, 29, 32, 33, 44, 45, 46, 47, 48, 51, 52, 53]
      }
      if (c.includes(props.t("Safety"))) {
        console.log("Safety")
        values = [3, 4, 35, 36, 42, 5]
      }
      if (c.includes(props.t("Apartment"))) {
        console.log("Apartment")
        values = [20, 21, 22, 23, 37, 38, 18, 19, 54]
      }
      if (c.includes(props.t("Other"))) {
        console.log("other")
        values = [34, 39, 43]
      }
      let type = "Main"
      if (values != undefined || values != []) {
        for (let i = 0; i <= values.length; i++) {
          let title = props.t("AlarmTitles." + values[i])

          list.filter(data => {
            if (data.alarm_type == title) {
              fileteredlist.push(data)
              setFilteredList(filteredList => [...filteredList, data])
            }
          })
        }
      }
      setFilteredList(fileteredlist)
      handlingCategory(type, fileteredlist, c)
    }
    if (type === "sub") {
      // Subcategories
      setSubcategory(c)

      let cus = customers
      let fileteredlist = []

      if (cus != undefined || (cus != "" && list != undefined)) {
        fileteredlist = list.filter(data => data.alarm_type?.includes(c))
        setFilteredList(fileteredlist)
      }
      handlingCategory(type, fileteredlist, c)
    }
  }

  const options = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "main")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },

    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut",
    labels: [
      props.t("Devices"),
      props.t("Apartment"),
      props.t("Safety"),
      props.t("Activity"),
      props.t("Health"),
      props.t("Abnormal activity"),
      props.t("Other"),
    ],
    colors: [
      "#224465",
      "#48d0f8",
      "#b8d9c1",
      "#8ab469",
      "#f5d663",
      "#f5a14a",
      "#ca7657",
    ],
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "15px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("All alerts"),
              fontSize: "15px",
              color: "#00000063",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
            },
          },
        },
      },
    },
  }
  // Small chart for separated alarms
  const optionsActivity = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "6"),
      props.t("AlarmTitles." + "12"),
      props.t("AlarmTitles." + "14"),
      props.t("AlarmTitles." + "16"),
      props.t("AlarmTitles." + "24"),
      props.t("AlarmTitles." + "25"),
    ],
    colors: [
      "#37482a",
      "#455a34",
      "#526c3f",
      "#607d49",
      "#7ca25e",
      "#8ab469",
      "#adca96",
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Activity"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }
  //Health
  const optionsHealth = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "30"),
      props.t("AlarmTitles." + "27"),
      props.t("AlarmTitles." + "28"),
      props.t("AlarmTitles." + "31"),
    ],
    colors: [
      "#37482a",
      "#455a34",
      "#526c3f",
      "#607d49",
      "#7ca25e",
      "#8ab469",
      "#adca96",
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Activity"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }

  const optionsSafety = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "3"),
      props.t("AlarmTitles." + "4"),
      props.t("AlarmTitles." + "35"),
      props.t("AlarmTitles." + "36"),
      props.t("AlarmTitles." + "42"),
      props.t("AlarmTitles." + "5"),
    ],
    colors: [
      "#5c6c60",
      "#6e8273",
      "#809787",
      "#93ad9a",
      "#a5c3ad",
      "#b8d9c1",
      "#bfdcc7",
      "#c6e0cd",
      "#cde4d3",
      "#dbece0",
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Safety"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }
  const optionsAbnormal = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "17"),
      props.t("AlarmTitles." + "15"),
      props.t("AlarmTitles." + "13"),
    ],
    colors: ["#c4ab4f", "#dcc059", "#f5d663", "#f8e291"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: ["#00000063", "#00000063", "#00000063", "#00000063"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Abnormal activity"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }
  const optionsAppartment = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "20"),
      props.t("AlarmTitles." + "21"),
      props.t("AlarmTitles." + "22"),
      props.t("AlarmTitles." + "23"),
      props.t("AlarmTitles." + "37"),
      props.t("AlarmTitles." + "38"),
      props.t("AlarmTitles." + "18"),
      props.t("AlarmTitles." + "19"),
      props.t("AlarmTitles." + "54"),
    ],
    colors: [
      "#3291ad",
      "#39a6c6",
      "#40bbdf",
      "#48d0f8",
      "#5ad4f8",
      "#a3e7fb",
      "#30454B",
      "#202E32",
      "#0e1517",
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Apartment"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }
  const optionsDevices = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "1"),
      props.t("AlarmTitles." + "2"),
      props.t("AlarmTitles." + "10"),
      props.t("AlarmTitles." + "11"),
      props.t("AlarmTitles." + "26"),
      props.t("AlarmTitles." + "29"),
      props.t("AlarmTitles." + "32"),
      props.t("AlarmTitles." + "33"),
      props.t("AlarmTitles." + "44"),
      props.t("AlarmTitles." + "45"),
      props.t("AlarmTitles." + "46"),
      props.t("AlarmTitles." + "47"),
      props.t("AlarmTitles." + "48"),
      props.t("AlarmTitles." + "51"),
      props.t("AlarmTitles." + "52"),
      props.t("AlarmTitles." + "53"),
    ],
    colors: [
      "#1b3650",
      "#1e3d5a",
      "#224465",
      "#385674",
      "#4e6983",
      "#647c93",
      "#7a8ea2",
      "#7fa3c7",
      "#748ea8",
      "#8b959e",
      "#91a7bd",
      "#b1b6ba",
      "#172f46",
      "#14283c",
    ],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",

        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Devices"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }
  const optionsOther = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "sub")
        },
        // dataPointMouseEnter: function (event) {
        //   event.path[0].style.cursor = "pointer"
        // },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut-small",
    labels: [
      props.t("AlarmTitles." + "34"),
      props.t("AlarmTitles." + "39"),
      props.t("AlarmTitles." + "43"),
    ],
    colors: ["#dc9042", "#f5a14a", "#f8bd80"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "13px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Other"),
              fontSize: "12px",
              color: "#0c0c0c",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
            },
          },
        },
      },
    },
  }

  // TODO: Set category empty, when chosen twice

  const setSmallDiagram = sub => {
    switch (sub) {
      case props.t("Devices"):
        setSmallSeries(seriesDevices)
        setSmallOptions(optionsDevices)
        break

      case props.t("Apartment"):
        setSmallSeries(seriesAppartment)
        setSmallOptions(optionsAppartment)
        break

      case props.t("Safety"):
        setSmallSeries(seriesSafety)
        setSmallOptions(optionsSafety)
        break

      case props.t("Health"):
        setSmallSeries(seriesHealth)
        setSmallOptions(optionsHealth)
        break

      case props.t("Activity"):
        setSmallSeries(seriesActivity)
        setSmallOptions(optionsActivity)
        break

      case props.t("Abnormal activity"):
        setSmallSeries(seriesAbnormal)
        setSmallOptions(optionsAbnormal)
        break

      case props.t("Other"):
        setSmallSeries(seriesOther)
        setSmallOptions(optionsOther)
        break
    }
  }

  // // Stat card values
  const callerStats = [
    {
      id: 0,
      value: openAverage,
      text: props.t("open"),
      series: toPercentage(openAverage),
    },
    {
      id: 1,
      value: closeAverage,
      text: props.t("closed"),
      series: toPercentage(closeAverage),
    },
    {
      id: 3,
      value: falseAck,
      text: props.t("false"),
      series: toPercentage(falseAck),
    },
  ]

  function toPercentage(value) {
    if (value != 0) {
      var result = Math.round((value / total) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  // Calls time
  const timeStats = [
    {
      text: {
        median: props.t("Median"),
        average: props.t("Average"),
      },
      beforeHandle: {
        id: 1,
        title: props.t("Handling time"),
        valueAvg: ackAverage,
        valueMedian: ackMedian,
        formatValueAvg: timeFormatter(ackAverage),
        formatValueMedian: timeFormatter(ackMedian),
      },
      afterHandle: {
        id: 2,
        title: props.t("Handling duration"),
        valueAvg: closeTimeAverage,
        valueMedian: closeTimeMedian,
        formatValueAvg: timeFormatter(closeTimeAverage),
        formatValueMedian: timeFormatter(closeTimeMedian),
      },
    },
  ]

  // when list is updated, updates stats
  useEffect(() => {
    if (filteredList != undefined) {
      // Get groups and customers
      addCustomers(filteredList)
    }
  }, [filteredList])

  // EXPORT
  //Excel and pdf name
  const titleExport = props.t("Alerts")
  // Export PDF table
  const exportPDFTypes = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "striped" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = action.map(elt => [
      props.t(elt.label),
      elt.types.map(t => props.t(t.label)),
    ])
    console.log(data, ".pdf function")

    let content = {
      startY: 50,
      head: [[props.t("Alert type"), props.t("Alert")]],
      body: data,
    }

    doc.text(titleExport, marginLeft, 40)
    doc.autoTable(content)
    doc.save(titleExport + ".pdf")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  const title = props.t("Alerts")
  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Start time"),
        props.t("End time"),
        props.t("Alarm type"),
        props.t("Group"),
        props.t("Name"),
        props.t("Amount"),
      ],
    ]
    const data = customersData.map(elt => [
      elt.time1,
      elt.time2,
      elt.type,
      elt.group,
      elt.name,
      elt.amount,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(
      wb,
      title + " " + formatDate(timeStart) + "-" + formatDate(timeEnd) + ".xlsx"
    )

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  //  Download png
  const downloadPNG = async num => {
    let chartElement = undefined
    if (num === 1) {
      chartElement = document.getElementById("donut-chart1")
    } else {
      chartElement = document.getElementById("donut-chart2")
    }

    if (chartElement) {
      try {
        const canvas = await html2canvas(chartElement)
        const pngUrl = canvas.toDataURL("image/png")
        const a = document.createElement("a")
        a.href = pngUrl
        a.download = title + ".png"
        a.click()
      } catch (error) {
        console.error("Error capturing chart:", error)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={info}
        toggle={() => {
          setInfo(!info)
        }}
        centered
      >
        <ModalHeader>
          <div className="d-flex flex-wrap">
            {props.t("Alarm types")}
            <Dropdown
              id="downloadTypes"
              isOpen={menuTypes}
              toggle={() => setMenuTypes(!menuTypes)}
              className="download-btn text-center ms-2"
              type="button"
            >
              <DropdownToggle
                id="downloadstatstable"
                className="text-muted"
                tag="i"
              >
                <i className="mdi mdi-download font-size-18" />
              </DropdownToggle>
              <DropdownMenu>
                {/* TODO: Can not choose directory file right now */}
                {/* <DropdownItem onClick={() => downloadExcelTypes()}>
                  {props.t("Download .xlsx")}
                </DropdownItem> */}
                <DropdownItem onClick={() => exportPDFTypes()}>
                  {props.t("Download .pdf")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="px-1">
            {action ? (
              <>
                {action.map(a => (
                  <div key={a.label} className="mt-2">
                    <h6>{props.t(a.label)}</h6>
                    {a.types.map(t => (
                      <div key={t.label} className="text-muted">
                        {props.t(t.label)}
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="float-end">
            <Button
              color="primary"
              className="ms-2"
              onClick={() => setInfo(!info)}
            >
              {props.t("Close")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <div id="alerts-chart">
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Alerts")}</h5>
            </span>
          </div>
        </Row>
        <Row>
          <Col lg="7" className="">
            {/* <CardTitle>{props.t("Status")}</CardTitle> */}
            <Row>
              <Col lg="5" className="">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <div className="d-flex ">
                      <div className="avatar-md mt-2 ms-0">
                        <div className="avatar-title bg-light rounded-circle text-primary h1">
                          <i className="bx bxs-bell-ring"></i>
                        </div>
                      </div>
                      <div className="ms-4 mt-2">
                        <p className="text-muted font-size-14 mb-2">
                          {props.t("Total alarms")}
                        </p>
                        <h2 className="mb-0">{total}</h2>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="7">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <CardTitle>{props.t("Status")}</CardTitle>
                    <div className="mt-4 mb-1">
                      <Progress
                        multi
                        className="progress-xl progress font-size-12"
                      >
                        <Progress
                          bar
                          color="primary"
                          className="progress-xl"
                          value={callerStats[0].series}
                        >
                          <span className="">{callerStats[0].series}%</span>
                        </Progress>
                        <Progress
                          bar
                          color="statusbar-suvantoblue"
                          className="progress-xl font-size-12"
                          value={callerStats[1].series}
                        >
                          <span className="">{callerStats[1].series}%</span>
                        </Progress>
                        <Progress
                          bar
                          color="gray"
                          className="progress-xl font-size-12"
                          value={callerStats[2].series}
                        >
                          <span className="text-dark">
                            {callerStats[2].series}%
                          </span>
                        </Progress>
                      </Progress>
                      <Row className="mt-1 font-size-13">
                        <Col xs="4">
                          <i className="fas fa-circle text-primary"></i>
                          <span className="text-muted ms-1 ">
                            {props.t("Closed")} {callerStats[0].value}
                          </span>
                        </Col>
                        <Col xs="4">
                          <i className="fas fa-circle text-statusbar-suvantoblue ms-3"></i>
                          <span className="text-muted ms-1 ">
                            {props.t("Open")} {callerStats[1].value}
                          </span>
                        </Col>
                        <Col xs="4">
                          <i className="fas fa-circle text-gray ms-3"></i>
                          <span className="text-muted ms-1 ">
                            {props.t("False")} {callerStats[2].value}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          {/* Duration of all calls */}
          {timeStats.map(time => (
            <Col lg="5" className="" key={time + "_div"}>
              <Card style={{ height: 127 + "px" }}>
                <CardBody>
                  <CardTitle>{props.t("Time")}</CardTitle>
                  <Row>
                    <Col xs="6" className="">
                      <h6>{time.beforeHandle.title}</h6>
                      <Row>
                        <Col xs="6">
                          <div>{time.text.average}</div>
                          <div className="font-size-14 h5">
                            {time.beforeHandle.formatValueAvg}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div>{time.text.median}</div>
                          <div className="font-size-14 h5">
                            {time.beforeHandle.formatValueMedian}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="6" className="">
                      <h6>{time.afterHandle.title}</h6>
                      <Row>
                        <Col xs="6">
                          <div>{time.text.average}</div>
                          <div className="font-size-14 h5">
                            {time.afterHandle.formatValueAvg}
                          </div>
                        </Col>
                        <Col xs="6">
                          <div>{time.text.median}</div>
                          <div className="font-size-14 h5">
                            {time.afterHandle.formatValueMedian}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {series ? (
          <Card>
            <CardBody>
              <span className="float-end">
                <Dropdown
                  id="downloadxlsx"
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="download-btn text-center"
                  type="button"
                >
                  <DropdownToggle
                    id="downloadstats"
                    className="text-muted"
                    tag="i"
                  >
                    <i className="mdi mdi-download" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => downloadExcel()}>
                      {props.t("Download table")} .xlsx
                    </DropdownItem>

                    <DropdownItem onClick={() => downloadPNG(1)}>
                      {props.t("Download graph as")}
                      {smallOptions ? " 1 " : <></>}
                      .png
                      {/* <i className="mdi mdi-download font-size-14 text-muted " /> */}
                    </DropdownItem>
                    {smallOptions ? (
                      <DropdownItem onClick={() => downloadPNG(2)}>
                        {props.t("Download graph as")} 2 .png
                        {/* <i className="mdi mdi-download font-size-14 text-muted " /> */}
                      </DropdownItem>
                    ) : (
                      <></>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </span>
              <h4 className="card-title">
                {props.t("Alarm types")}
                <Link
                  to="#"
                  name="infoButton"
                  id="infoButton"
                  className="ms-2 font-size-14"
                  onClick={() => setInfo(!info)}
                >
                  <i className="bx bx-info-circle text-muted"></i>
                </Link>
              </h4>
              <Row>
                <Col lg="5" className="mb-3">
                  <Col xl="12">
                    <div id="donut-chart1">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height="auto"
                        className="apex-charts apexcharts-donut"
                      />
                    </div>
                  </Col>
                </Col>
                <Col lg="7">
                  {subject == props.t("All alerts") ? (
                    <Row>
                      <Col xs="3"></Col>
                      <Col xs="6">
                        <h5 className="text-center">{subject}</h5>

                        <SimpleBar style={{ maxHeight: "420px" }}>
                          {combinedData?.map(el => (
                            <div key={el.group + "_div"}>
                              <div className="mt-4">
                                <h6 className="mb-1 mt-3">
                                  {el.group}
                                  <span className="float-end me-1">
                                    {el.amount}
                                  </span>
                                </h6>
                                <div className="mt-2 ">
                                  {el.clients?.map(c => (
                                    <div key={c.name + "_key"}>
                                      <div className="mt-2">
                                        {c.name}
                                        <span className="float-end me-1">
                                          {c.amount}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </SimpleBar>
                      </Col>
                      <Col xs="3"></Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg="12">
                        <h5 className="text-center">{subject} </h5>
                      </Col>
                      <Col lg="7" xs="7">
                        {smallOptions ? (
                          <>
                            <div className="mt-3">
                              <Row>
                                <Col sm="12">
                                  <div id="donut-chart2">
                                    <ReactApexChart
                                      options={smallOptions}
                                      series={smallSeries}
                                      type="donut"
                                      height="auto"
                                      className="apex-charts apexcharts-donut"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : null}
                      </Col>

                      <Col lg="4" xs="4">
                        {/* <div className="h4 font-size-14 mt-4 d-flex flex-wrap">
                          {props.t("Amounts of the alarm")}
                      
                        </div> */}
                        <SimpleBar style={{ maxHeight: "420px" }}>
                          {combinedData?.map(el => (
                            <div key={el.group + "_div"}>
                              <div className="mt-4">
                                <h6 className="mb-1">
                                  {el.group}
                                  <span className="float-end me-1">
                                    {el.amount}
                                  </span>
                                </h6>
                                <div className="mt-2">
                                  {el.clients?.map(c => (
                                    <div
                                      className="mt-2 "
                                      key={c.name + "_key"}
                                    >
                                      <div>
                                        {c.name}
                                        <span className="float-end me-1">
                                          {c.amount}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </SimpleBar>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : null}
      </div>
    </React.Fragment>
  )
}

ChartAlerts.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  customers: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withTranslation()(ChartAlerts)
