/***********************
 * Theme provider
 * Used in ThemeDropDown.js
 ************************/

import React, {
  Suspense,
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react"
const ThemeContext = createContext()

const ThemeProvider = ({ children }) => {
  const initialTheme = () => localStorage.getItem("theme")
  const [theme, setTheme] = useState(initialTheme)

  // Change theme
  const toggleTheme = t => {
    console.log("Change theme to: " + t)
    if (document.body) {
      document.body.setAttribute("data-layout-mode", t)
      localStorage.setItem("theme", t)
      setTheme(t)
    }
  }

  useLayoutEffect(() => {
    console.log("Theme: " + theme)
    if (document.body) {
      if (theme != undefined) {
        document.body.setAttribute("data-layout-mode", theme)
      } else {
        document.body.setAttribute("data-layout-mode", "light")
      }
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <Suspense
        fallback={
          <>
            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
          </>
        }
      >
        {children}
      </Suspense>
    </ThemeContext.Provider>
  )
}
const useTheme = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider")
  }
  return context
}
export { ThemeProvider, useTheme }
