/***********************
 * CALENDAR COMPONENT (RANGE, CUSTOMER SETTINGS)
 ************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

//Formatting date
import moment from "moment"

//Translation
import { withTranslation } from "react-i18next"

//DatePicker ANTD
import { DatePicker, ConfigProvider } from "antd"
const { RangePicker } = DatePicker

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"
import { Col, Row, Button } from "reactstrap"

const TimelineCalendar = props => {
  const [defaultValueStart, setDefaultValueStart] = useState(props.range)
  const [rangePicker, setRangePicker] = useState(true)
  // const [now, setNow] = useState()
  const [startTime, setStartTime] = useState()
  const [defaultValueEnd, setDefaultValueEnd] = useState(moment())
  const [endTime, setEndTime] = useState()
  const [dateAndTime, setDateAndTime] = useState(null)
  const [chosenDate, setChosenDate] = useState(moment())

  useEffect(() => {
    if (dateAndTime == null && defaultValueStart != undefined) {
      let timeStart = new Date(defaultValueStart)
      let timeEnd = new Date(defaultValueEnd)
      let unixStart = timeStart.getTime()
      let unixEnd = timeEnd.getTime()
      props.setCalendarStartDate(unixStart)
      props.setCalendarEndDate(unixEnd)
    }
    if (defaultValueStart == undefined) {
      //   console.log(defaultValueStart)
      onDateSelection(moment())
    }
  }, [])

  useEffect(() => {
    if (props.range == undefined) {
      setRangePicker(false)
    }
  }, [props.range])

  //DatePicker language update
  const getLanguage = () => {
    var language = localStorage.getItem("i18nextLng")
    if (language.indexOf("-") > -1) language = language.split("-")[0]
    switch (language) {
      case "en":
        return en
      case "fi":
        return fin
      case "sv":
        return swe
      default:
        return null
    }
  }
  const locale = getLanguage()

  useEffect(() => {
    // TODO:set counter => 0 when date is changed
    let newDate = moment(chosenDate).add(props.updateCounter[1], "d")
    if (chosenDate !== newDate) {
      onDateSelection(newDate)
    }
  }, [props.updateCounter])

  const onDateSelection = value => {
    setChosenDate(value)
    // console.log(value)
    // setDateAndTime(value)
    if (value != null) {
      let start = new Date(value.startOf("day"))
      let end = new Date(value.endOf("day"))

      //   let startUnix = start.getTime()
      //   let endUnix = end.getTime()
      props.setCalendarStartDate(start)
      props.setCalendarEndDate(end)

      setStartTime(start)
      setEndTime(end)
    }
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <Col className={` ${props.placeholder ? "placeholder" : ""}`}>
          <ConfigProvider locale={locale}>
            <Col className="px-0">
              {/* <Button
                onClick={() => {
                  settingDate("back")
                }}
                size="sm"
                color="clear"
                className="mt-0 py-0"
                style={{ border: "none" }}
              >
                <i className="bx bx-chevron-left mt-1  text-primary font-size-20"></i>
              </Button> */}
              <DatePicker
                disabled={props.disabled}
                style={{ width: "100%" }}
                className="mt-0"
                defaultValue={moment()}
                showToday
                allowClear={false}
                onChange={onDateSelection}
                format="DD.MM.YYYY"
                value={chosenDate}
              />
              {/* <Button
                onClick={() => {
                  settingDate("forward")
                }}
                size="sm"
                color="clear"
                className="mt-0 py-0"
                style={{ border: "none" }}
              >
                <i className="bx bx-chevron-right mt-1 text-primary font-size-20"></i>
              </Button> */}
            </Col>
          </ConfigProvider>
        </Col>
      </div>
    </React.Fragment>
  )
}

TimelineCalendar.propTypes = {
  t: PropTypes.any,
  getDate: PropTypes.any,
  setDateRange: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  placeholder: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  range: PropTypes.any,
  rangePicker: PropTypes.bool,
  startDefault: PropTypes.any,
  now: PropTypes.any,
  updateCounter: PropTypes.any,
  disabled: PropTypes.bool,
}
export default withTranslation()(TimelineCalendar)
