import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import moment from "moment"

// Locale/weekday related imports
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import GlobalState from "contexts/GlobalState"
import callsChart from "./calls-chart"
import { Card, CardBody, Button } from "reactstrap"
import carephone from "pages/Services/carephone/carephone"

const CallsChartLinear = props => {
  const [state, setState] = useContext(GlobalState)
  const [language, setLanguage] = useState("fi")
  const [data, setData] = useState()
  const [chartData, setChartData] = useState({
    video: [],
    groupvideo: [],
    carephone: [],
    tracker: [],
    phone: [],
  })
  const [chartLoading, setChartLoading] = useState(true)
  const [range, setRange] = useState("day")

  useEffect(() => {
    if (props.data.list !== undefined) {
      setChartLoading(true)
      console.log(props.data.list, "linear chart")
      setData(props.data.list)
    }
  }, [props.data])
  useEffect(() => {
    if (data !== undefined) {
      console.log(data, "linear chart")
      getChart(data)
      setChartLoading(false)
    }
  }, [data, range])
  useEffect(() => {
    console.log(chartData, "chartData")
  }, [chartData])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setLanguage("fi")
      } else if (currentLanguage.indexOf("sv") == 0) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  let event = [
    { id: 1, date: 1387271989749 },
    { id: 2, date: 1387271989760 },
  ]

  function groupday(value, index, array) {
    let byday = {}
    let d = new Date(value.date.isSame)
    console.log(d, "d")
    d = Math.floor(d.getTime() / (1000 * 60 * 60 * 24))
    console.log(d, "d2")
    byday[d] = byday[d] || []
    byday[d].push(value)
    return byday
  }

  function groupweek(value, index, array) {
    let byweek = {}
    let d = new Date(value.date)
    console.log(d, "d")
    d = Math.floor(d.getTime() / (1000 * 60 * 60 * 24 * 7))
    console.log(d, "d2")
    byweek[d] = byweek[d] || []
    byweek[d].push(value)
    return byweek
  }

  function groupmonth(value, index, array) {
    let bymonth = {}
    let d = new Date(value.date)
    console.log(d, "d")
    d = (d.getFullYear() - 1970) * 12 + d.getMonth()
    console.log(d, "d2")
    bymonth[d] = bymonth[d] || []
    bymonth[d].push(value)

    return bymonth
  }
  function groupyear(value, index, array) {
    let bymonth = {}
    let d = new Date(value.date)
    console.log(d, "d")
    d = (d.getFullYear() - 1970) / d.getFullYear()
    console.log(d, "d2")
    bymonth[d] = bymonth[d] || []
    bymonth[d].push(value)
    return bymonth
  }

  // Chart
  const getChart = result => {
    // TODO:
    //

    // console.log("grouped by day", result.map(groupday))
    // console.log("grouped by week", result.map(groupweek))
    // console.log("grouped by month", result.map(groupmonth))
    console.log("grouped by day", result.map(groupday))
    console.log("grouped by week", result.map(groupweek))
    console.log("grouped by month", result.map(groupmonth))

    if (range == "day") {
      result = result.map(groupday)
    }
    if (range == "week") {
      result = result.map(groupweek)
    }
    if (range == "month") {
      result = result.map(groupmonth)
    }

    if (range == "year") {
      result = result.map(groupyear)
    }

    // setChartData({
    //   video: [],
    //   groupvideo: [],
    //   carephone: [],
    //   tracker: [],
    //   phone: [],
    // })

    let temp = new Object()

    let video = []
    video = result.filter(r => r.type == "video")
    temp.video = video.map(v => [moment(v.date).startOf("day"), 1])

    let videogroup = []
    videogroup = result.filter(r => r.type == "group")
    temp.videogroup = videogroup.map(v => [moment(v.date).startOf("day"), 1])

    let tracker = []
    tracker = result.filter(r => r.type == "tracker")
    temp.tracker = tracker.map(v => [moment(v.date).startOf("day"), 1])

    let carephone = []
    carephone = result.filter(r => r.type == "carephone")
    temp.carephone = carephone.map(v => [moment(v.date).startOf("day"), 1])

    let phone = result.filter(r => r.type == "phone")
    temp.phone = phone.map(v => [moment(v.date).startOf("day"), 1])

    setChartData(temp)
  }

  const series = [
    {
      name: props.t("Video"),
      type: "column",
      data: chartData.video,
    },
    {
      name: props.t("Group video call"),
      type: "column",
      data: chartData.videogroup,
    },
    {
      name: props.t("Safety phone"),
      type: "column",
      data: chartData.carephone,
    },

    {
      name: props.t("Tracker"),
      type: "column",
      data: chartData.tracker,
    },
    {
      name: props.t("Phone"),
      type: "column",
      data: chartData.phone,
    },
  ]

  const options = {
    chart: {
      id: "node-activity-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      stacked: false,
      selection: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -17,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      //width: [0, 0, 0],
      curve: "stepline",
    },
    colors: ["#224465", "#48d0f8", "#8ab469", "#f5d663", "#f5a14a", "#ca7657"],
    fill: {
      opacity: [1, 1, 1, 1, 1, 1],
    },
    legend: {
      offsetY: 11,
    },
    // xaxis: {
    //   type: "datetime",
    //   labels: {
    //     datetimeUTC: false,
    //   },
    // },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MM/yy",
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 0,
      // max: 1,
      tickAmount: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "dd.MM.yyyy HH:mm",
      },
      /*y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return value
          }
        }*/
    },
    dataLabels: {
      enabled: false,
    },
  }

  return (
    <Card>
      <CardBody>
        {!chartLoading ? (
          <>
            <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
              <Button
                color="light"
                size="sm"
                type="button"
                value="day"
                className={range == "day" ? "active" : ""}
                onClick={e => setRange(e.target.value)}
                // id="one_month"
              >
                day
              </Button>
              <Button
                color="light"
                size="sm"
                type="button"
                value="week"
                className={range == "week" ? "active" : ""}
                onClick={e => setRange(e.target.value)}
                // id="six_months"
              >
                week
              </Button>
              <Button
                color="light"
                size="sm"
                type="button"
                value="month"
                className={range == "month" ? "active" : ""}
                onClick={e => setRange(e.target.value)}
                // id="one_year"
              >
                month
              </Button>
              <Button
                color="light"
                size="sm"
                type="button"
                value="year"
                className={range == "year" ? "active" : ""}
                onClick={e => setRange(e.target.value)}
                // id="all"
              >
                year
              </Button>
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height="220"
            />
          </>
        ) : (
          <>loading</>
        )}
      </CardBody>{" "}
    </Card>
  )
}

CallsChartLinear.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(CallsChartLinear)
