/***********************
 * My profile
 ************************/

import React, { useState, useEffect, useContext, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardTitle,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap"
import {
  changePassword,
  getUserInfo,
  updateUserProperties,
} from "../../helpers/backend_helper"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumb from "../../components/Common/Breadcrumb"
import GlobalState from "contexts/GlobalState"
import { showToast } from "utils/general"
import { Switch } from "antd"

const UserProfile = props => {
  const [state, setState] = useContext(GlobalState)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [role, setRole] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [gsm, setGsm] = useState("")
  const [unit, setUnit] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [editMode, setEditMode] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [eye, seteye] = useState(true)
  const [password, setpassword] = useState("password")
  const [capsLock, setCapsLock] = useState(false)
  const [numLock, setNumLock] = useState(false)
  const [clickedPassword, setClickedPassword] = useState(false)

  const abortRef = useRef(null)
  const divRef = useRef(null)
  useEffect(() => {
    document.title = props.t("My profile") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      setName(obj.username)
      setEmail(obj.email)
      setGsm(obj.gsm)
      setFirstName(obj.firstName)
      setLastName(obj.lastName)
      setUnit(obj.unit)

      switch (obj.role) {
        case "system_admin":
          setRole("System Admin")
          break
        case "organisation_admin":
          setRole("Organisation Admin")
          break
        case "organisation_user":
          setRole("Organisation User")
          break
        case "private_user":
          setRole("Private User")
          break
        case "salesperson":
          setRole("Salesperson")
          break
        case "order_manager":
          setRole("Order Manager")
          break
        default:
          break
      }
    }
  }, [state])

  // Fetch user info
  const getInfo = () => {
    getUserInfo(abortRef.current)
      .then(data => {
        if (data.username != null) {
          setState(state => ({ ...state, authUser: JSON.stringify(data) }))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      gsm: gsm,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, { resetForm }) => {
      // Trim values
      const keys = Object.keys(values)
      keys.forEach((key, index) => {
        values[key] = values[key].trim()
      })
      setUpdating(true)
      updateUserProperties(
        abortRef.current,
        values.firstName,
        values.lastName,
        values.gsm,
        values.email
      )
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            setEditMode(false)
            getInfo()
          } else {
            showToast(props.t("Failed to update information"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Failed to update information"), "error")
        })
        .finally(() => {
          setUpdating(false)
        })
    },
  })

  const pwValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      currentpassword: "",
      newpassword: "",
      // newpasswordconfirmed: "",
    },
    validationSchema: Yup.object({
      currentpassword: Yup.string().required(
        props.t("Please enter current password")
      ),
      newpassword: Yup.string()
        .min(14, props.t("Password is too short"))
        .max(50, props.t("Password is too long"))
        .matches(
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-])[a-zA-Z0-9!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]{14,50}$/, // OLD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{14,})/
          props.t("Password requirements")
        )
        .required(props.t("Please enter new password")),
      // newpasswordconfirmed: Yup.string()
      //   .oneOf([Yup.ref("newpassword"), null], props.t("Mismatching passwords"))
      //   .required(props.t("Please confirm new password")),
    }),
    onSubmit: (values, { resetForm }) => {
      setError(null)
      setSuccess(null)
      changePassword(
        abortRef.current,
        values.currentpassword,
        values.newpassword
      )
        .then(result => {
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccess("Password changed successfully")
            resetForm()
          } else {
            setError("Password change failed")
          }
        })
        .catch(err => {
          console.log(err)
          setError("Password change failed")
        })
    },
  })

  //
  const showPassword = () => {
    if (password == "password") {
      setpassword("text")
      seteye(false)
    } else {
      setpassword("password")
      seteye(true)
    }
  }

  // Detect caps/num lock
  function handleKeyPress(event) {
    const capsLockOn = event.getModifierState("CapsLock")
    const numLockOn = event.getModifierState("NumLock")
    setCapsLock(capsLockOn)
    setNumLock(numLockOn)
  }

  // TEST
  useEffect(() => {
    const handleClickOutside = event => {
      console.log(event)
      if (divRef.current && !divRef.current.contains(event.target)) {
        console.log(event)
        setClickedPassword(false)
      }
    }

    window.addEventListener("click", handleClickOutside)

    return () => {
      window.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleDivClick = () => {
    setClickedPassword(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={props.t("My profile")} />
          <Row>
            <Col lg="6">
              <Card>
                <CardBody className="mb-3">
                  <CardTitle className="mb-4">
                    <Row>
                      <Col className="d-flex" style={{ alignItems: "center" }}>
                        <i className="bx bxs-user-circle text-truncate align-middle me-2"></i>
                        {props.t("User information")}
                      </Col>
                      <Col className="col-auto">
                        {!editMode && (
                          <Button
                            color="primary"
                            size="sm"
                            className=""
                            onClick={() => {
                              setEditMode(true)
                            }}
                          >
                            <i className="me-2 bx bxs-edit text-truncate align-middle"></i>
                            <span className="">{props.t("Edit")}</span>
                          </Button>
                        )}
                        {editMode && (
                          <>
                            <Button
                              color="primary"
                              size="sm"
                              className=""
                              onClick={() => {
                                setEditMode(false)
                                validation.resetForm()
                              }}
                            >
                              <i className="me-2 bx bxs-x-square text-truncate align-middle"></i>
                              <span className="">{props.t("Cancel")}</span>
                            </Button>
                            <Button
                              color="success"
                              size="sm"
                              disabled={updating}
                              className="ms-3"
                              onClick={() => {
                                validation.submitForm()
                              }}
                            >
                              <i className="me-2 bx bxs-save text-truncate align-middle"></i>
                              <span className="">
                                {props.t("Save changes")}
                              </span>
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardTitle>

                  {!editMode && (
                    <>
                      <Row xs={1} sm={2} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Name")}:</h6>{" "}
                            {firstName + " " + lastName}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Username")}:</h6> {name}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("GSM")}:</h6> {gsm}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Email")}:</h6> {email}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Role")}:</h6>{" "}
                            {props.t("UserRoles." + role)}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Unit info")}:</h6>{" "}
                            {unit ? unit : props.t("Undefined")}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  {editMode && (
                    <>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        <Row xs={1} sm={2} className="mt-3">
                          <Col>
                            <FormGroup>
                              <Label>{props.t("First name")}</Label>
                              <Input
                                name="firstName"
                                type="text"
                                value={validation.values.firstName}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Last name")}</Label>
                              <Input
                                name="lastName"
                                type="text"
                                value={validation.values.lastName}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("GSM")}</Label>
                              <Input
                                name="gsm"
                                type="text"
                                value={validation.values.gsm}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Email")}</Label>
                              <Input
                                name="email"
                                type="text"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            {/* Change password */}
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 " style={{ alignItems: "center" }}>
                    <Row>
                      <Col>
                        <i className="bx bxs-lock-open text-truncate align-middle me-2"></i>
                        {props.t("Change password")}
                      </Col>
                      <Col className="col-auto">
                        <Switch
                          id="showChangePassword"
                          size="small"
                          checked={!eye}
                          onChange={showPassword}
                          checkedChildren={<i className="fa fa-eye" />}
                          unCheckedChildren={<i className="fa fa-eye-slash" />}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="showChangePassword"
                        >
                          {eye ? (
                            <>{props.t("Show passwords")}</>
                          ) : (
                            <>{props.t("Hide passwords")}</>
                          )}
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                  </CardTitle>

                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      return false
                    }}
                  >
                    <Alert color="info">
                      {props.t("Password requirements")}
                      <span id="requirements">
                        <Button
                          className="py-0 me-1 px-1 m-0 font-size-10"
                          color="transparent"
                          style={{ cursor: "default" }}
                        >
                          <i className="dripicons-question"></i>
                        </Button>
                      </span>
                      <UncontrolledTooltip
                        placement="right"
                        target="requirements"
                      >
                        {props.t("Acceptable special characters")}:{" "}
                        {"!?#@$€%&*^ˆ+;:,.<>=|/(){}[]-"}
                      </UncontrolledTooltip>
                      <span>{props.t("Password requirements 2")}</span>
                    </Alert>
                    {error ? (
                      <Alert
                        color="danger"
                        className="alert-dismissible fade show"
                      >
                        <i className="mdi mdi-block-helper me-2"></i>
                        {props.t(error)}
                      </Alert>
                    ) : null}
                    {success ? (
                      <Alert
                        color="success"
                        className="alert-dismissible fade show"
                      >
                        <i className="mdi mdi-check me-2"></i>
                        {props.t(success)}
                      </Alert>
                    ) : null}
                    {/* Check if caps lock or num lock is on  */}
                    {capsLock == true && (
                      <Alert
                        color="info"
                        className="mt-0 mb-2 p-2 px-3 font-size-12"
                      >
                        <i className="bx bx-info-circle me-1" />
                        {props.t("Caps Lock is on")}
                      </Alert>
                    )}
                    {numLock == true && (
                      <Alert
                        color="info"
                        className="mt-0 mb-2 p-2 px-3 font-size-12"
                      >
                        <i className="bx bx-info-circle me-1" />
                        {props.t("Num Lock is on")}
                      </Alert>
                    )}
                    <div className="row mb-3">
                      <Label className="col-sm-4 col-form-label">
                        {props.t("Current password")}
                      </Label>
                      {/* TODO: Task 344 */}
                      <Col sm={8}>
                        <Input
                          onKeyUp={e => handleKeyPress(e)}
                          name="currentpassword"
                          className="form-control"
                          type={eye ? "password" : ""}
                          onChange={pwValidation.handleChange}
                          onBlur={pwValidation.handleBlur}
                          value={pwValidation.values.currentpassword || ""}
                          invalid={
                            pwValidation.touched.currentpassword &&
                            pwValidation.errors.currentpassword
                              ? true
                              : false
                          }
                        ></Input>

                        {pwValidation.touched.currentpassword &&
                        pwValidation.errors.currentpassword ? (
                          <FormFeedback type="invalid">
                            {pwValidation.errors.currentpassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div>
                    <div
                      ref={divRef}
                      onClick={handleDivClick}
                      className="row mb-3"
                    >
                      <Label className="col-sm-4 col-form-label">
                        {props.t("New password")}
                      </Label>
                      <Col sm={8}>
                        <Input
                          onKeyUp={e => handleKeyPress(e)}
                          name="newpassword"
                          className="form-control"
                          type={eye ? "password" : ""}
                          onChange={pwValidation.handleChange}
                          onBlur={pwValidation.handleBlur}
                          value={pwValidation.values.newpassword || ""}
                          invalid={
                            pwValidation.touched.newpassword &&
                            pwValidation.errors.newpassword
                              ? true
                              : false
                          }
                        />
                        {/* {pwValidation.touched.newpassword
                          ? setVisibleCriteria(true)
                          : setVisibleCriteria(false)} */}

                        {/* &&
                        pwValidation.errors.newpassword ? (
                          <FormFeedback type="invalid">
                            {pwValidation.errors.newpassword}
                          </FormFeedback>
                        ) : null} */}

                        {clickedPassword && (
                          <div className="mt-2">
                            {/* custom-list css marks checked */}
                            <Alert
                              color="info"
                              style={{}}
                              className="mt-0 mb-0 font-size-12 "
                            >
                              <ul
                                style={{ marginBottom: 0 }}
                                id="custom-list-profile"
                                className="font-size-11"
                              >
                                <Row>
                                  <Col xs="6">
                                    <li
                                      className={
                                        pwValidation.values.newpassword.length >
                                        13
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>
                                        {props.t("at least")} 14{" "}
                                        {props.t("characters")}
                                      </span>
                                    </li>
                                    <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /[a-z?]/
                                        )
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>{props.t("lowercase letter")}</span>
                                    </li>
                                    <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /[A-Z]/
                                        )
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>{props.t("uppercase letter")}</span>
                                    </li>
                                  </Col>

                                  <Col xs="6">
                                    <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /[0-9]/
                                        )
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>{props.t("number")}</span>
                                    </li>
                                    <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]/
                                          // /[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]/
                                          // TODO: Check acceptable special marks
                                          // /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?´`]/
                                        )
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>
                                        {props.t("special character")}
                                      </span>
                                    </li>
                                    {/* <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /[äÄöÖåÅøØæÆ]/
                                        )
                                          ? "uncheched"
                                          : "cheched"
                                      }
                                    >
                                      <span>
                                        <b>{props.t("no")}</b>{" "}
                                        {props.t("nordic letters")}
                                      </span>
                                    </li> */}
                                    <li
                                      className={
                                        pwValidation.values.newpassword.match(
                                          /^([a-zA-Z0-9!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]+)$/
                                        )
                                          ? "cheched"
                                          : "uncheched"
                                      }
                                    >
                                      <span>
                                        {props.t("only allowed characters")}
                                      </span>
                                    </li>
                                  </Col>
                                </Row>
                              </ul>
                            </Alert>
                          </div>
                        )}
                      </Col>
                    </div>
                    {/* <div className="row mb-4">
                      <Label className="col-sm-4 col-form-label">
                        {props.t("Confirm new password")}
                      </Label>
                      <Col sm={8}>
                        <Input
                          onKeyUp={e => handleKeyPress(e)}
                          name="newpasswordconfirmed"
                          className="form-control"
                          type={eye ? "password" : ""}
                          onChange={pwValidation.handleChange}
                          onBlur={pwValidation.handleBlur}
                          value={pwValidation.values.newpasswordconfirmed || ""}
                          invalid={
                            pwValidation.touched.newpasswordconfirmed &&
                            pwValidation.errors.newpasswordconfirmed
                              ? true
                              : false
                          }
                        />
                        {pwValidation.touched.newpasswordconfirmed &&
                        pwValidation.errors.newpasswordconfirmed ? (
                          <FormFeedback type="invalid">
                            {pwValidation.errors.newpasswordconfirmed}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </div> */}

                    <div className="row justify-content-end">
                      <Col sm={8}>
                        <Button
                          className="w-md"
                          color="primary"
                          disabled={
                            pwValidation.values.newpassword.match(
                              /^([a-zA-Z0-9!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]+)$/
                            )
                              ? false
                              : true
                          }
                          onClick={() => {
                            pwValidation.submitForm()
                          }}
                        >
                          {props.t("Change password")}
                        </Button>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>{" "}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UserProfile)
