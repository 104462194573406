import React, { useEffect, useState } from "react"
import { Col, Table } from "reactstrap"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"

//Translation
import { withTranslation } from "react-i18next"
import { formatDateHoursMinutes } from "utils/general"

//Simple bar
import SimpleBar from "simplebar-react"
import withRouter from "components/Common/withRouter"

const MeasureList = props => {
  const [seriesData, setSeriesData] = useState([])
  const [type, setType] = useState()
  const [listBp, setListBp] = useState(false)
  const [bp, setBp] = useState([])

  useEffect(() => {
    if (props.data !== undefined && props.data !== []) {
      setSeriesData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if (props.type !== type && props.type !== undefined && props.type !== "") {
      setType(props.type)
      setListBp(false)

      if (props.type === "1") {
        setListBp(true)
      }
    }
  }, [props.type])

  useEffect(() => {
    if (props.bloodPressure !== undefined && props.bloodPressure.length > 0) {
      setBp(props.bloodPressure)
    }
  }, [props.bloodPressure])

  return (
    <>
      {(seriesData !== [] && seriesData.length !== 0) || bp !== 0 ? (
        <Col lg="12">
          {listBp === true ? (
            <SimpleBar style={{ maxHeight: "370px", marginTop: "-15px" }}>
              <Table
                className="table px-2"
                bordered={false}
                striped={false}
                hover={true}
                // style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="h6 text-muted ">{props.t("Time")}</th>
                    <th className="h6 text-muted ">{props.t("Systolic")}</th>
                    <th className="h6 text-muted ">{props.t("Diastolic")}</th>
                    <th className="h6 text-muted ">{props.t("Pulse")}</th>
                  </tr>
                </thead>
                <tbody>
                  {bp?.map((el, index) => (
                    <tr key={index + el.date}>
                      <td className="h6 ">{formatDateHoursMinutes(el.date)}</td>
                      <td className="h6 ">
                        {el.data.map(v => {
                          if (v.measureType == "systolicBloodPressure") {
                            return v.value
                          }
                        })}
                      </td>
                      <td className="h6 ">
                        {el.data.map(v => {
                          if (v.measureType == "diastolicBloodPressure") {
                            return v.value
                          }
                        })}
                      </td>
                      <td className="h6 ">
                        {el.data.map(v => {
                          if (v.measureType == "pulse") {
                            return v.value
                          }
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          ) : (
            <SimpleBar style={{ maxHeight: "370px", marginTop: "-15px" }}>
              <Table
                className="table px-2"
                bordered={false}
                striped={false}
                hover={true}
                // style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="h6 text-muted ">{props.t("Time")}</th>
                    <th className="h6 text-muted ">{props.t("Value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data?.map((el, index) => (
                    <tr key={index + el.deviceType}>
                      <td className="h6 ">{formatDateHoursMinutes(el.date)}</td>
                      <td className="h6 ">
                        {el.value} {el.unit}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          )}
        </Col>
      ) : (
        <></>
      )}
    </>
  )
}

MeasureList.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  type: PropTypes.any,
  tooltip: PropTypes.any,
  max: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
  bloodPressure: PropTypes.any,
}

export default withRouter(withTranslation()(MeasureList))
