/**********************
 * DEMO
 * Call appoinments
 * Timeline
 *********************/
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  Form,
  Input,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardTitle,
  Badge,
} from "reactstrap"
import {
  dateFormatter,
  formatDateSimple,
  formatDateTime,
  formatDateWithWeekDay,
  showToast,
} from "utils/general"

import {
  getVideoCallToken,
  getGroupsAppointments,
  deleteAppointment,
  addOrUpdateAppointment,
  updateAppointments,
} from "helpers/backend_helper"

//  antd
import { Calendar, Collapse, DatePicker, Radio, Slider, theme } from "antd"
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline"
import React, { useRef, useEffect, useState, useContext } from "react"
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css"
// import moment from "moment/min/moment-with-locales"
import { withTranslation } from "react-i18next"
import { Select, Space, Popover } from "antd" // Formik Validation
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Locale/weekday related imports
import moment from "moment"
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"

import SelectClient from "components/DropdownList/SelectClient"
import TimelineCalendar from "./timeline-calendar"
import SelectGroup from "components/DropdownList/SelectGroup"
import { Link } from "react-router-dom"
import useScrollBlock from "utils/useScrollBlock"
import GlobalState from "contexts/GlobalState"
import { isEqual } from "lodash"
import { CodeSandboxCircleFilled } from "@ant-design/icons"
const TimelineCalendar2 = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState("")
  const [userKey, setUserKey] = useState("")
  const [assignmentsOrig, setAssignmentsOrig] = useState([])
  const [assignments, setAssignments] = useState([])
  const [modifiedAssignments, setModifiedAssignments] = useState([])
  const [professionals, setProfessionals] = useState([])
  const [selectedProfs, setSelectedProfs] = useState([])
  const [profsSelection, setProfsSelection] = useState([])
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [modal, setModal] = useState(false)
  const [modify, setModify] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const abortRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [addNew, toggleAddNew] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [rangeTime, setRangeTime] = useState([8, 12])
  const [findType, setFindType] = useState("admins")
  const [group, setGroup] = useState(null)
  const [updateCounter, setUpdateCounter] = useState([0, 0])
  const [emptyClickAssignment, setEmptyClickAssignments] = useState(false)
  const [customizeAssignment, setCustomizeAssignment] = useState(false)
  const [differences, setDifferences] = useState({})
  const [selectedAssignmentId, setSelectedAssignmentId] = useState()
  const [selectedAssignmentProfId, setSelectedAssignmentProfId] = useState()
  const [tooltipContent, setTooltipContent] = useState(null)
  const [popoverVisible, setPopoverVisible] = useState(true)
  // Custom mouse scroll
  const [hovered, setHovered] = useState(false)
  //Blocking scroll when handleMouseEnter/handleMouseLeave
  const [blockScroll, allowScroll] = useScrollBlock()
  // Can not set function to Select defaultValue
  const [defaultGroupKey, setDefaultGroupKey] = useState("")
  //   FIXES TIMELINE BUG
  const [display, setDislay] = useState("block")

  //
  let types = [{ label: props.t("Video_call"), value: "video_call" }]
  // Marks
  const marks = {
    0: "00:00",
    8: "08:00",
    12: "12:00",
    16: "16:00",
    20: "20:00",
    24: "23:59",
  }

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      let name = obj.username
      // console.log(obj, name, "obj")
      setUserRole(role)
      setUserKey(name)
    }
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  //   FIXES TIMELINE BUG
  useEffect(() => {
    window.setTimeout(() => setDislay({ display: "block" }))
    window.setTimeout(() => window.dispatchEvent(new Event("resize")), 100)
  }, [])

  // Unselect selected when modal is closed
  useEffect(() => {
    if (modal == false) {
      toggleAddNew(false)
      setCustomizeAssignment(false)
      setEmptyClickAssignments(false)
    }
  }, [modal])

  // Fetch appointments when group is picked
  useEffect(() => {
    if (findType == "clients") {
      setProfsSelection([])
    }
    // console.log(group, "new group")
    if (group == undefined || group == null) {
      setAssignments([])
    }
    if (group != undefined) {
      getAppointments()
    }
  }, [group, findType])

  useEffect(() => {
    if (startDate != undefined) {
      setStartTime(moment(startDate).add(8, "hours"))
      setEndTime(moment(startDate).add(12, "hours"))
      getAppointments()
    }
  }, [startDate])

  useEffect(() => {
    // console.log(differences, "diff")
    if (Object.keys(differences).length == 0) {
      setAssignments(assignmentsOrig)
    }
  }, [differences])

  useEffect(() => {
    if (!isEqual(assignments, assignmentsOrig)) {
      // console.log("different", assignments, assignmentsOrig)
      setModify(true)
      const findDifferences = () => {
        const diffs = []

        assignmentsOrig.forEach(obj1 => {
          const found = assignments.find(obj2 => obj2.key === obj1.key)
          // console.log(found, "found")
          if (found) {
            const newAdmin = found.group
            const newTime = found.start_time
            const diffKeys = Object.keys(obj1).filter(
              key => obj1[key] !== found[key]
            )
            if (diffKeys.length > 0) {
              // console.log(diffKeys, obj1, "pushing")
              //  Dont add if only className is different, needs to change if other changes will be acceptable in the future
              if (
                diffKeys.includes("group") ||
                (diffKeys.includes("start_time") &&
                  diffKeys.includes("end_time"))
              ) {
                diffs.push({
                  key: obj1.key,
                  appointmentType: obj1.type,
                  adminUser: findMatch(newAdmin),
                  clientKey: obj1.client_key,
                  startTime: newTime,
                  duration: obj1.duration,
                })
              }
            }
          }
        })
        // console.log(diffs, "differences")
        return diffs
      }
      setDifferences(findDifferences())
    }
    if (isEqual(assignments, assignmentsOrig)) {
      // console.log("same", assignments, assignmentsOrig)
      setModify(false)
    }
  }, [assignments])

  // Prevent user from leaving in modify=true
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (modify) {
        event.preventDefault()
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [modify])
  useEffect(() => {
    window.addEventListener("beforeunload", handlePageExitAttempt)

    return () => {
      window.removeEventListener("beforeunload", handlePageExitAttempt)
    }
  }, [])

  // Get appointments
  const getAppointments = () => {
    if ((group !== null) | (group !== undefined)) {
      setLoading(true)

      getGroupsAppointments(
        abortRef.current,
        group,
        findType,
        startDate,
        endDate
      )
        .then(result => {
          if (result.statusCode == 200 && result) {
            // console.log("orig data", result)

            if (userRole.includes("admin")) {
              settingSelected(result.groups, result.items)
              settingAssignments(result.items)
            } else {
              let group = result.groups.filter(g => g.key == userKey)
              // console.log(group, "gg")

              settingSelected(group, result.items)
              // filteringOnlyUser()

              settingAssignments(result.items, group)
            }
            // console.log(result, result.items)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const deleteAssignment = () => {
    setOngoingDelete(true)
    console.log(abortRef.current, selectedAssignment?.key)
    deleteAppointment(abortRef.current, selectedAssignment.key)
      .then(result => {
        if (result.statusCode == 200) {
          setDeleteModal(false)
          setSelectedAssignment(null)
          setModal(false)
          // setSelectedAssignment({})
          showToast(props.t("Deleted"), "success")
          getAppointments()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingDelete(false)
      })
  }

  // Formatting assignments if necessary
  // TODO: update with selected profs
  const settingAssignments = (v, g) => {
    // Non-admin / only user's appointment
    if (g && v != undefined && startDate != undefined) {
      let filtered = v.filter(a => a.group == g[0].id)
      // console.log(filtered, v, g, "filtered")
      let opts = []
      if (filtered.length > 0) {
        filtered.forEach(p => {
          let groupObject = new Object()
          groupObject.id = p.id
          groupObject.key = p.key
          groupObject.client_key = p.client_key
          groupObject.title = p.title
          groupObject.group = p.group
          groupObject.start_time = moment(p.start_time)
          groupObject.end_time = moment(p.end_time)
          groupObject.duration = p.duration
          // groupObject.className = p.key != null ? "confirm" : "assign"
          groupObject.className = p.group != 0 ? "confirm" : "assign"
          groupObject.type = p.type
          // groupObject.interval = p.interval
          opts.push(groupObject)
        })
        let customers = []
        filtered.forEach(p => {
          let groupObject = new Object()
          groupObject.id = p.key
          groupObject.value = p.title
          groupObject.title = p.title
          groupObject.group = p.id
          customers.push(groupObject)
        })
      }
      console.log(opts, "appointments")
      setAssignmentsOrig(opts)
      setAssignments(opts)
      setModifiedAssignments(opts)

      if (profsSelection.length > 0) {
        handleChange(profsSelection)
      }
    }
    // admin
    else {
      let opts = []
      v.forEach(p => {
        let groupObject = new Object()
        groupObject.id = p.id
        groupObject.key = p.key
        groupObject.client_key = p.client_key
        groupObject.title = p.title
        groupObject.group = p.group
        groupObject.start_time = moment(p.start_time)
        groupObject.end_time = moment(p.end_time)
        groupObject.duration = p.duration
        // groupObject.className = p.key != null ? "confirm" : "assign"
        groupObject.className = p.group != 0 ? "confirm" : "assign"
        groupObject.type = p.type
        // groupObject.interval = p.interval
        opts.push(groupObject)
      })
      let customers = []
      v.forEach(p => {
        let groupObject = new Object()
        groupObject.id = p.key
        groupObject.value = p.title
        groupObject.title = p.title
        groupObject.group = p.id
        customers.push(groupObject)
      })

      console.log(opts, "appointments")
      setAssignmentsOrig(opts)
      setAssignments(opts)
      setModifiedAssignments(opts)

      if (profsSelection.length > 0) {
        handleChange(profsSelection)
      }
    }
  }

  const countingTime = (prof, app) => {
    let startTime = "00:00"
    let minutes = 0

    app.map(a => {
      if (a.group == prof.id) {
        // console.log(a, prof, "check")
        minutes = minutes + a.duration
      }
    })
    let duration = moment(startTime, "HH:mm")
      .add(minutes, "minutes")
      .format("HH:mm")
    return duration
  }

  const unassigned = () => {
    let shown = 0
    let all = 0
    if (assignments.length > 0) {
      assignments.map(a => {
        if (a.group == 0) {
          shown++
        }
      })
    }
    if (assignmentsOrig.length > 0) {
      assignmentsOrig.map(a => {
        if (a.group == 0) {
          all++
        }
      })
    }
    return (
      <>
        <b className={all == 0 && "text-success"}>{all}</b>
        {all != shown && (
          <span>
            <span className="text-gray font-size-13">
              {" "}
              <span className={shown == 0 && "text-success"}>/{shown}</span>
            </span>
          </span>
        )}
      </>
    )
  }

  // Formatting selected professionals if necessary
  const settingSelected = (prof, app) => {
    if (prof != undefined) {
      let opts = []
      // Counting time for professionals using matching id:s (not permanent solution, only for presentation)
      countingTime(prof, app)

      prof.forEach(p => {
        let groupObject = new Object()
        // groupObject.id = counter++
        groupObject.id = p.id
        groupObject.key = p.key
        groupObject.title =
          p.key != "" ? p.title : props.t("Assignments." + p.title)
        groupObject.label =
          p.key != ""
            ? p.title + " " + "(" + p.key + ")"
            : props.t("Assignments." + p.title)
        groupObject.value = p.key
        groupObject.rightTitle = countingTime(p, app)
        opts.push(groupObject)
      })
      let filteredSelected = opts.filter(o => o.rightTitle !== "00:00")
      setSelectedProfs(filteredSelected)
      setProfessionals(opts)
      console.log(opts, "professionals")
    }
  }

  //  Dragging item
  const handleItemMove = (itemId, dragTime) => {
    const updatedAssignments = assignments.map(item => {
      // console.log(
      //   selectedAssignmentId,
      //   selectedAssignmentProfId,
      //   item,
      //   itemId,
      //   dateFormatter(dragTime),
      //   "Moving"
      // )
      if (item.id === selectedAssignmentId) {
        return {
          ...item,
          start_time: moment(dragTime),
          end_time: moment(dragTime + (item.end_time - item.start_time)),
          group: selectedProfs[selectedAssignmentProfId].id,
          className: "moved",
        }
      }
      return item
    })
    if (itemId.itemId == undefined) {
      setTooltipContent(null)
      setPopoverVisible(true)
    }
    setAssignments(updatedAssignments)
  }
  const handleItemDrag = (itemId, dragTime, newGroupOrder) => {
    // console.log(
    //   "moving",
    //   itemId,
    //   itemId.itemId,
    //   itemId.dragTime,
    //   itemId.newGroupOrder
    // )
    const item = assignments.find(item => item.id === itemId.itemId)
    const group = selectedProfs[itemId.newGroupOrder].title
    const time = itemId.time
    setSelectedAssignmentId(itemId.itemId)
    setSelectedAssignmentProfId(itemId.newGroupOrder)
    setTooltipContent({ item, group, time })
    // Sets a bug that item can not be moved after 1 move
    setPopoverVisible(false)
  }
  //  Dragging item end

  const setNewSelection = () => {
    let newEntry = new Object()
    newEntry.key = undefined
    newEntry.type = "video_call"
    newEntry.group = null
    newEntry.start_time = moment(startDate)
    newEntry.duration = 15
    setSelectedAssignment(newEntry)
    toggleAddNew(true)
    setModal(true)
  }

  // Add or update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: selectedAssignment?.type || "video_call",
      group: selectedAssignment?.group || null,
      user_key: selectedAssignment?.user_key || selectedAssignment?.client_key,
      // user_key: selectedAssignment?.user_key || null,
      // repetition: selectedAssignment?.repetition || "once",
      // interval: selectedAssignment?.interval || 1,
      // paused: selectedAssignment?.paused || false,
      start_time: selectedAssignment?.start_time
        ? moment(selectedAssignment?.start_time)
        : moment(startDate),
      duration: selectedAssignment?.duration || 15,
      key: selectedAssignment?.key || undefined,
      // title: selectedAssignment?.title || null,
      // monday: selectedAssignment?.monday || false,
      // tuesday: selectedAssignment?.tuesday || false,
      // wednesday: selectedAssignment?.wednesday || false,
      // thursday: selectedAssignment?.thursday || false,
      // friday: selectedAssignment?.friday || false,
      // saturday: selectedAssignment?.saturday || false,
      // sunday: selectedAssignment?.sunday || false,
    },
    validationSchema: Yup.object({
      duration: Yup.number()
        .required(props.t("Please enter duration"))
        .min(0, props.t("Assignment is too short"))
        .max(1000, props.t("Assignment is too long")),
      user_key: Yup.string().required(props.t("Please select customer")),
    }),
    onSubmit: values => {
      let temp = JSON.parse(JSON.stringify(values))
      console.log("SUBMIT: ", temp)
      setLoading(true)
      addOrUpdateAppointment(
        abortRef.current,
        values.type,
        values.group,
        values.user_key,
        values.start_time,
        values.duration,
        values.key
      )
        .then(result => {
          console.log(result)
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            setSelectedAssignment(null)
            validation.resetForm()
            getAppointments()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setModal(false)
          setLoading(false)
        })
    },
  })

  const submitMultipleChanges = () => {
    setLoading(true)
    updateAppointments(abortRef.current, { array: differences })
      .then(result => {
        console.log(result.statusCode, result.updated, result.failed)
        if (result.statusCode == 200) {
          console.log(result, "result")
          // TODO: translate
          showToast(props.t("Changes saved"), "success")
          getAppointments()
        }
      })
      .catch(err => {
        console.log(err, result.statusCode)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Setting selected from modified list
  const settingSelectedAssignment = item => {
    // console.log(item, !modify, !loading)
    if (!modify && !loading) {
      setCustomizeAssignment(true)
      let select = []
      if (isNaN(item)) {
        // console.log(" clicked from popup")
        select = modifiedAssignments.find(i => i.key === item)
      } else {
        // console.log("clicked from timetable")
        select = modifiedAssignments.find(i => i.id === item)
        // console.log(select)
      }
      setSelectedAssignment({
        key: select.key,
        type: select.type,
        client_key: select.client_key,
        title: select.title,
        group: findMatch(select.group),
        start_time: select.start_time,
        duration: select.duration,
      })
      setDefaultGroupKey(findMatch(select.group))
      setModal(true)
    }
  }

  // Setting selected from click on empty
  const settingAssignmentClick = (groupId, time) => {
    // console.log("setting", groupId, time)

    let select = []
    // console.log("select of", professionals, group, time)
    if (findType == "clients") {
      select = professionals.find(i => i.id === groupId)
      // console.log(select, time, select.client_key, "select clients base")
      setSelectedAssignment({
        title: "",
        start_time: time,
        client_key: select.client_key,
      })
    }
    if (findType == "admins") {
      select = professionals.find(i => i.id === groupId)
      // console.log(select, time, select.id, "select admins base")
      setSelectedAssignment({
        title: props.t("Assign to") + " " + select.label,
        group: select.key,
        start_time: time,
      })
    }

    setDefaultGroupKey("")
    setModal(true)
  }

  // Set selected professionals
  // TODO: this if appointment is moved?
  const handleChange = newSelection => {
    // console.log(newSelection, profsSelection, "profs")
    setProfsSelection(newSelection)
    let filtered = []
    if (newSelection.length != 0) {
      filtered = professionals.filter(p => newSelection.includes(p.key))
      setSelectedProfs(filtered)
    } else {
      let filteredSelected = professionals.filter(o => o.rightTitle !== "00:00")
      setSelectedProfs(filteredSelected)
    }
  }

  // Changing time window on timeline
  const changeTimeRange = time => {
    let start = time[0]
    let end = time[1]

    let a = moment(startDate).add(start, "hours")
    let b = moment(startDate).add(end, "hours")

    setStartTime(a)
    setEndTime(b)
  }

  // Changing time window on timeline from buttons
  const changeTimeRangeButton = value => {
    let a = moment()
    let b = moment()
    let newRange1 = rangeTime[0]
    let newRange2 = rangeTime[1]
    if (value == "backward" && rangeTime[0] > 0) {
      a = moment(startTime).add(-1, "hours")
      b = moment(endTime).add(-1, "hours")

      setStartTime(a)
      setEndTime(b)

      newRange1 = rangeTime[0] - 1
      newRange2 = rangeTime[1] - 1
      setRangeTime([newRange1, newRange2])
    }
    if (value == "forward" && rangeTime[1] < 24) {
      a = moment(startTime).add(1, "hours")
      b = moment(endTime).add(1, "hours")

      setStartTime(a)
      setEndTime(b)

      newRange1 = rangeTime[0] + 1
      newRange2 = rangeTime[1] + 1
      setRangeTime([newRange1, newRange2])
    } else {
      if (value < 0 && rangeTime[1] > 1 && rangeTime[0] < rangeTime[1] - 1) {
        newRange2 = newRange2 - 1
        a = startTime
        b = moment(endTime).add(value, "hours")

        setStartTime(a)
        setEndTime(b)
      }
      if (value > 0 && rangeTime[1] < 24 && rangeTime[0] < rangeTime[1]) {
        newRange2 = newRange2 + 1
        a = startTime
        b = moment(endTime).add(value, "hours")

        setStartTime(a)
        setEndTime(b)
      }

      setRangeTime([newRange1, newRange2])
    }
  }

  const handleEmptyAreaClick = (groupId, time, e) => {
    // if (userRole.includes("admin") && !modify) {
    if (!modify) {
      // console.log("clicked", groupId, time, e)
      setEmptyClickAssignments(true)
      settingAssignmentClick(groupId, time)
    }
  }

  // Start video call
  const startVideoCall = key => {
    if (key) {
      getVideoCallToken(abortRef.current, key)
        .then(result => {
          if (result.link != null && result.link.length > 0) {
            window.open(result.link, "_blank", "noreferrer")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // Popover
  const itemRenderDisabledButtons = ({ item, itemContext, getItemProps }) => {
    // console.log(item, itemContext, getItemProps, "itemit")
    // let color = item.className
    const content = (
      <div>
        <div className="pb-2">
          <div>
            {formatDateTime(item.start_time)} - {formatDateTime(item.end_time)}
          </div>
          <div>{item.duration} min</div>{" "}
          <div>
            {props.t(item.type.charAt(0).toUpperCase() + item.type.slice(1))}
          </div>
        </div>
      </div>
    )
    const title = (
      <div>
        <span>{itemContext.title}</span>
      </div>
    )
    return (
      <>
        <Popover
          content={popoverVisible ? content : null}
          title={popoverVisible ? title : null}
        >
          <div
            className="item-render-style"
            {...getItemProps({
              style: {
                boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                       0 2px 2px 0 rgba(0, 0, 0, 0.14),
                       0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
              },
            })}
          >
            <div className="ripple ripple-style">
              <div className=" ripple-title h6 mt-1 mb-0 me-2">
                {itemContext.title}
              </div>
              <div className="mt-0 ripple-text">
                {formatDateTime(item.start_time)}-
                {formatDateTime(item.end_time)}
              </div>
            </div>
          </div>
        </Popover>
      </>
    )
  }

  const itemRender = ({ item, itemContext, getItemProps }) => {
    const content = (
      <div>
        <div className="pb-2">
          <div>
            {formatDateTime(item.start_time)} - {formatDateTime(item.end_time)}
          </div>
          <div>{item.duration} min</div>{" "}
          <div>
            {props.t(item.type.charAt(0).toUpperCase() + item.type.slice(1))}
          </div>
        </div>

        <Button
          disabled={item.client_key ? false : true}
          color="secondary"
          className="py-1"
          onClick={() => {
            startVideoCall(item.client_key)
          }}
        >
          <i className="bx bxs-video me-1"></i> {props.t("Start video call")}
        </Button>
      </div>
    )
    const title = (
      <div>
        <span
          id={"edit" + item.key}
          style={{
            visibility: userRole.includes("admin") ? "visible" : "hidden",
          }}
          className="float-end"
        >
          <Button
            className="p-1"
            color="transparent"
            onClick={() => settingSelectedAssignment(item.key)}
          >
            <i className="bx bxs-edit text-primary font-size-14"></i>
          </Button>
        </span>
        <UncontrolledTooltip target={"edit" + item.key} placement="right">
          {props.t("Edit appointment")}
        </UncontrolledTooltip>
        <span>{itemContext.title}</span>
      </div>
    )
    return (
      <>
        <Popover
          content={popoverVisible ? content : null}
          title={popoverVisible ? title : null}
        >
          <div
            className="item-render-style"
            {...getItemProps({
              style: {
                boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                       0 2px 2px 0 rgba(0, 0, 0, 0.14),
                       0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
              },
            })}
          >
            <div className="ripple ripple-style">
              <div className=" ripple-title h6 mt-1 mb-0 me-2">
                {itemContext.title}
              </div>
              <div className="mt-0 ripple-text">
                {formatDateTime(item.start_time)}-
                {formatDateTime(item.end_time)}
              </div>
            </div>
          </div>
        </Popover>
      </>
    )
  }

  const checkRenderer = () => {
    let modified = assignments.map(a => a.className === "moved")
    if (modified.includes(true)) {
      return itemRenderDisabledButtons
    } else {
      return itemRender
    }
  }

  // Matching from profs list to modifying assignment
  const findMatch = value => {
    // console.log(value, "value match")
    if (value == 0) {
      return null
    }
    if (value) {
      let match = professionals.filter(p => value == p.id)
      // console.log(match, value, "1match")
      if (match.length > 0) {
        match = match[0].key
      }
      // console.log(match, "match")
      return match
    }
  }

  const handleMouseEnter = () => {
    setHovered(true)
    blockScroll()
  }
  const handleMouseLeave = () => {
    setHovered(false)
    allowScroll()
  }

  // Custom modal does not work now
  // const handleModalResponse = response => {
  //   if (response === "confirm") {
  //     window.removeEventListener("beforeunload", handleBeforeUnload)
  //     window.location.href = "/exit"
  //   } else {
  //     setShowModal(false)
  //   }
  // }
  const handlePageExitAttempt = event => {
    if (modify) {
      event.preventDefault()
      // setShowModal(true)
    }
  }

  // Filtering for non-admin
  // const filteringOnlyUser = () => {
  //   let appointments = []
  // }

  return (
    <>
      <div>
        {/* LEAVE MODAL */}
        {/* <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => handleModalResponse("confirm")}
          onCancel={() => handleModalResponse("cancel")}
        >
          Are you sure you want to leave?
        </Modal> */}
        {/** DELETE CLIENT MODAL */}
        <Modal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(!deleteModal)
          }}
          centered
        >
          <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
          <ModalBody>
            <p>
              {props.t("Are you sure you want to delete") +
                " " +
                selectedAssignment?.title +
                " " +
                props.t("event") +
                "?"}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => {
                setDeleteModal(false)
              }}
            >
              {props.t("Cancel")}
            </Button>
            <Button
              disabled={ongoingDelete}
              color="danger"
              onClick={() => {
                deleteAssignment()
              }}
            >
              {props.t("Delete")}
            </Button>
          </ModalFooter>
        </Modal>
        {assignments && !loading && !modify && (
          <Modal isOpen={modal && !modify} centered>
            {selectedAssignment && addNew == false ? (
              <>
                <ModalHeader>{selectedAssignment.title}</ModalHeader>
              </>
            ) : (
              <ModalHeader>{props.t("Add a new appointment")}</ModalHeader>
            )}
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <Row>
                  {addNew ? (
                    <>
                      <Col xs="12" className="mb-3">
                        <Label>{props.t("Client")}</Label>
                        <SelectClient
                          selectedGroup={group}
                          value={validation.values.user_key || null}
                          onChange={v => {
                            validation.setFieldValue("user_key", v)
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.user_key &&
                            !!validation.errors.user_key
                          }
                        />
                        {validation.touched.user_key &&
                        validation.errors.user_key ? (
                          <FormFeedback type="invalid">
                            {validation.errors.user_key}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col xs="12" className="mb-3">
                        <Label>{props.t("Assign to")}</Label>
                        <Select
                          showSearch
                          // defaultValue={defaultGroupKey}
                          options={professionals}
                          onChange={v => {
                            // console.log(v, "group"),
                            validation.setFieldValue("group", v)
                          }}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      {/* TODO set if admin */}
                      {emptyClickAssignment && findType == "admins" ? (
                        <Col xs="12" className="mb-3">
                          <Label>{props.t("Client")}</Label>
                          <SelectClient
                            selectedGroup={group}
                            value={validation.values.user_key}
                            onChange={v => {
                              validation.setFieldValue("user_key", v)
                            }}
                          />
                        </Col>
                      ) : (
                        <Col xs="12" className="mb-3">
                          <Label>{props.t("Assign to")}</Label>
                          <Select
                            showSearch
                            defaultValue={defaultGroupKey}
                            options={professionals}
                            onChange={v => {
                              validation.setFieldValue("group", v)
                            }}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      )}
                    </>
                  )}

                  {/* NOT IN USE YET <Col className="mb-3" xs="6">
                      <Label>{props.t("Repetition")}</Label>
                      {modifyAssignment ? (
                        <>
                          <Input
                            name="repetitionType"
                            type="select"
                            value={validation.values.repetitionType}
                            onChange={validation.handleChange}
                          >
                            <option value={"once"}>{props.t("Once")}</option>
                            <option value={"interval"}>
                              {props.t("Interval")}
                            </option>
                            <option value={"weekdays"}>
                              {props.t("Weekdays")}
                            </option>
                          </Input>
                        </>
                      ) : (
                        <div className="font-size-14">
                          {selectedAssignment.repetitionType}
                        </div>
                      )}
                    </Col> */}

                  {/* {validation.values.repetition === "weekdays" && (
                        <Col xs="8">
                          <h6>{props.t("Weekdays")}</h6>
                          <div className="text-uppercase mt-3">
                            <FormGroup>
                              <input
                                type="checkbox"
                                id="mon"
                                className="form-check-input"
                                defaultChecked={validation.values.monday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "monday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="mon"
                              >
                                {props.t("Mon")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="tue"
                                autoComplete="off"
                                defaultChecked={validation.values.tuesday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "tuesday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="tue"
                              >
                                {props.t("Tues")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="wed"
                                autoComplete="off"
                                defaultChecked={validation.values.wednesday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "wednesday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="wed"
                              >
                                {props.t("Wed")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="thu"
                                autoComplete="off"
                                defaultChecked={validation.values.thursday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "thursday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="thu"
                              >
                                {props.t("Thurs")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="fri"
                                autoComplete="off"
                                defaultChecked={validation.values.friday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "friday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="fri"
                              >
                                {props.t("Fri")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="sat"
                                autoComplete="off"
                                defaultChecked={validation.values.saturday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "saturday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="sat"
                              >
                                {props.t("Sat")}
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="sun"
                                autoComplete="off"
                                defaultChecked={validation.values.sunday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "sunday",
                                    v.target.checked
                                  )
                                }}
                              />
                              <label
                                className="form-check-label ms-1 me-2"
                                htmlFor="sun"
                              >
                                {props.t("Sun")}
                              </label>
                            </FormGroup>
                          </div>
                        </Col>
                      )}
                      {validation.values.repetition === "interval" && (
                        <Col xs="8">
                          <h6>{props.t("Recurrence interval")}</h6>
                          <InputNumber
                            className="mt-1 p-1 me-1"
                            style={{ height: "36.5px" }}
                            min="1"
                            max="365"
                            onChange={v =>
                              validation.setFieldValue("interval", v)
                            }
                            value={validation.values.interval}
                            invalid={
                              validation.touched.interval &&
                              validation.errors.interval
                                ? "true"
                                : "false"
                            }
                          ></InputNumber>
                          {props.t("vrk")}
                        </Col>
                      )} */}
                  <Col className="mb-3 mt-2" xs="8">
                    {validation.values.repetition === "once" ? (
                      <Label>{props.t("Time of the assignment")}</Label>
                    ) : (
                      <Label>{props.t("Start time")}</Label>
                    )}

                    <DatePicker
                      allowClear={false}
                      showTime={true}
                      value={validation.values.start_time}
                      format="DD.MM.YYYY HH:mm"
                      style={{ height: "36.5px", width: "100%" }}
                      onChange={v => {
                        validation.setFieldValue("start_time", v)
                      }}
                    />
                  </Col>
                  {/*NOT IN USE YET {validation.values.repetition !== "once" && (
                          <Col xs="6">
                            <Label>{props.t("End of repetition")}</Label>
                            <DatePicker
                              showTime={true}
                              value={validation.values.endDate}
                              format="DD.MM.YYYY HH:mm"
                              style={{ height: "36.5px" }}
                              onChange={v => {
                                validation.setFieldValue("endDate", v)
                              }}
                            />
                          </Col>
                        )} */}
                  <Col className="mb-3 mt-2" xs="4">
                    <Label>{props.t("Duration in minutes")}</Label>

                    <Input
                      name="duration"
                      // placeholder={selectedAssignment.duration}
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.duration}
                      min={0}
                      max={1000}
                      invalid={
                        validation.touched.duration &&
                        !!validation.errors.duration
                      }
                    />
                    {validation.touched.duration &&
                    validation.errors.duration ? (
                      <FormFeedback type="invalid">
                        {validation.errors.duration}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col className="mb-3 mt-2" xs="6">
                    <Label>{props.t("Type")}</Label>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      defaultValue={validation.values.type}
                      options={types}
                      onChange={v => {
                        validation.setFieldValue("type", v)
                      }}
                    />
                  </Col>
                  {/* NOT IN USE YET <Col className="mb-3 text-muted" xs="6">
                      <Label>{props.t("Notices")}</Label>
                      <div className="font-size-14">
                        {selectedAssignment.note}
                      </div>
                    </Col> */}
                  <Col xs="12" className="pt-4 pb-3 ">
                    <Row>
                      <Col xs="6">
                        {customizeAssignment && userRole.includes("admin") && (
                          <Button
                            className="text-truncate "
                            color="danger"
                            onClick={() => {
                              setDeleteModal(!deleteModal)
                            }}
                          >
                            {props.t("Delete event")}
                          </Button>
                        )}
                      </Col>
                      <Col xs="6">
                        <Button
                          className="float-end"
                          color="primary"
                          onClick={() => {
                            validation.submitForm()
                          }}
                        >
                          {customizeAssignment
                            ? props.t("Update information")
                            : props.t("Save")}
                        </Button>
                        <Button
                          className="float-end me-2"
                          color="primary"
                          outline
                          onClick={() => {
                            setModal(false), validation.resetForm()
                          }}
                        >
                          {props.t("Cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        )}
        <Row>
          <Col lg="3" className="p-0">
            <Collapse
              className="p-0"
              ghost
              // bordered={false}
              defaultActiveKey={[1]}
              expandIconPosition={"end"}
              style={{ background: "transparent" }}
            >
              <Collapse.Panel
                header={
                  <h5 className="p-0 m-0 font-size-14">
                    <i className="bx bx-calendar text-truncate align-middle me-2"></i>
                    {props.t("Search")}
                  </h5>
                }
                key={1}
              >
                <div className="">
                  <div className="mb-2">
                    <TimelineCalendar
                      disabled={modify}
                      setCalendarStartDate={setStartDate}
                      setCalendarEndDate={setEndDate}
                      updateCounter={updateCounter}
                    />
                  </div>
                  <SelectGroup
                    disabled={modify}
                    onChange={setGroup}
                    multiple={false}
                    // selection={group}
                    // selectedTags={selectedTags}
                  ></SelectGroup>
                  {/* // TODO: Set working: rendering/functions when modifying appointment on "clients" */}
                  {/* <Label className="mt-1 mb-1 pt-1 font-size-12">
                    {props.t("Sort by")}
                  </Label>
                  <div>
                    <Radio.Group
                      name="radioType"
                      className="mt-0 mb-0 pb-0"
                      defaultValue={findType}
                      onChange={e => {
                        setFindType(e.target.value)
                      }}
                    >
                      <Radio value={"admins"}>{props.t("Professional")}</Radio>
                      <Radio value={"clients"}>{props.t("Client")}</Radio>
                    </Radio.Group>
                  </div> */}
                </div>
              </Collapse.Panel>
              {/* {assignments.length > 0 && ( */}
              {group != null && group != undefined && (
                <Collapse.Panel
                  header={
                    <h5 className="p-0 m-0 font-size-14">
                      <i className="dripicons-scale text-truncate align-middle me-2"></i>
                      {props.t("Filter")}
                    </h5>
                  }
                  key={2}
                >
                  <div className="">
                    <Select
                      className="mb-2"
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder={props.t("Assignment type")}
                      options={[
                        {
                          title: props.t("Video call"),
                          value: props.t("Video call"),
                          key: "call_video",
                        },
                      ]}
                    />
                    {professionals.length > 0 && findType == "admins" && (
                      <>
                        <Select
                          className="mb-2"
                          mode="multiple"
                          disabled={professionals.length > 0 ? false : true}
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          // defaultValue={selectedProfs}
                          placeholder={props.t("Professional")}
                          onChange={handleChange}
                          options={professionals}
                        />
                      </>
                    )}
                  </div>
                </Collapse.Panel>
              )}
              <div className="vl-appointments d-none d-sm-inline-block ms-2"></div>
            </Collapse>{" "}
          </Col>
          <Col lg={9}>
            <div className="ms-2">
              <div className="mb-3">
                <Col lg={12}>
                  <span className="float-end">
                    {userRole.includes("admin") && modify && (
                      <>
                        <div className="badge badge-soft-danger p-2 font-size-12 me-3">
                          <span className="">{props.t("Unsaved")}</span>
                        </div>
                        <Button
                          disabled={loading}
                          className="mt-0 me-2"
                          color="danger"
                          onClick={() => {
                            setDifferences({}), setAssignments(assignmentsOrig)
                          }}
                        >
                          <i className="bx bx-x"></i>
                          <div className="d-none d-lg-inline-block ms-2">
                            {props.t("Cancel changes")}
                          </div>
                        </Button>
                        <Button
                          disabled={loading}
                          className="mt-0"
                          color="success"
                          onClick={() => {
                            submitMultipleChanges()
                          }}
                        >
                          <i className="bx bx-check "></i>

                          <div className="d-none d-lg-inline-block ms-2">
                            {props.t("Save changes")}
                          </div>
                        </Button>
                      </>
                    )}
                    {/* {userRole.includes("admin") && !modify && ( */}
                    {!modify && (
                      <Button
                        disabled={group == null || group == undefined || modify}
                        className="mt-0"
                        color="success"
                        onClick={() => {
                          setNewSelection()
                        }}
                      >
                        <i className="bx bx-calendar-plus "></i>
                        <div className="d-none d-lg-inline-block ms-2">
                          {props.t("Add a new appointment")}
                        </div>
                      </Button>
                    )}
                  </span>
                  <div className="h4 d-block pt-2">
                    <Button
                      disabled={modify}
                      onClick={() => {
                        setUpdateCounter([updateCounter - 1, -1])
                      }}
                      // size="sm"
                      color="clear"
                      className="mt-0 py-0"
                      style={{ border: "none" }}
                    >
                      <i className="bx bx-chevron-left mt-1 font-size-22"></i>
                    </Button>
                    {moment(startDate).format("Do MMMM YYYY")}
                    <Button
                      disabled={modify}
                      onClick={() => {
                        setUpdateCounter([updateCounter + 1, 1])
                      }}
                      // size="sm"
                      color="clear"
                      className="mt-0 py-0"
                      style={{ border: "none" }}
                    >
                      <i className="bx bx-chevron-right ms-0 mt-1 font-size-22"></i>
                    </Button>
                    {/* {assignments.length > 0 && <span>, {}</span>} */}{" "}
                    {/* <Button
                      onClick={() => {
                        setUpdateCounter(0)
                      }}
                      // size="sm"
                      color="primary"
                      outline
                      className="ms-3 me-2"
                      // style={{ border: "none" }}
                    >
                      {props.t("Today")}
                    </Button> */}
                  </div>
                </Col>
              </div>
              {/* </Row> */}
              {/* </Col> */}
              {loading ? (
                <CardBody>
                  <div className="text-center p-5">
                    <Link to="#">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      {props.t("Loading")}
                    </Link>
                  </div>
                </CardBody>
              ) : (
                <>
                  {assignments.length == 0 && selectedProfs.length == 0 && (
                    <CardBody>
                      <div className="text-center p-5">
                        {group != undefined || group != null ? (
                          <>
                            {props.t(
                              "No scheduled appointments for chosen date"
                            )}
                          </>
                        ) : (
                          <>{props.t("No selected group")}</>
                        )}
                      </div>
                    </CardBody>
                  )}
                  {(assignments.length > 0 || selectedProfs.length > 0) &&
                    group != null &&
                    group != undefined && (
                      <div className="pt-2">
                        <Col xs="12">
                          <div className="mt-2">
                            <div className="mt-3" style={{ display: "flex" }}>
                              <div
                                style={{ flexGrow: "1" }}
                                className="p-0 ps-2"
                              >
                                <Button
                                  onClick={() => {
                                    changeTimeRangeButton("backward")
                                  }}
                                  color="clear"
                                  className="mt-1 py-0 pe-0 "
                                  style={{ border: "none" }}
                                >
                                  <i className="bx bx-chevrons-left mt-1 text-primary font-size-16"></i>
                                </Button>
                              </div>
                              <div
                                // style={{ width: "90%" }}
                                style={{ flexGrow: "40" }}
                                className="d-inline-block mb-0"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onWheel={e => {
                                  if (hovered && e.shiftKey) {
                                    const delta = e.deltaY > 0 ? -1 : 1
                                    changeTimeRangeButton(delta)
                                  }
                                  if (hovered && e.shiftKey == false) {
                                    const delta = e.deltaY
                                    if (delta > 0) {
                                      changeTimeRangeButton("backward")
                                    } else {
                                      changeTimeRangeButton("forward")
                                    }
                                  }
                                }}
                              >
                                <Slider
                                  className="custom-timeline"
                                  onChange={e => {
                                    changeTimeRange(e), setRangeTime(e)
                                  }}
                                  marks={marks}
                                  range={{
                                    draggableTrack: true,
                                  }}
                                  step={1}
                                  min={0}
                                  max={24}
                                  defaultValue={rangeTime}
                                  value={rangeTime}
                                />
                              </div>
                              <div className="ms-3" style={{ flexGrow: "1" }}>
                                <Button
                                  onClick={() => {
                                    changeTimeRangeButton("forward")
                                  }}
                                  // size="sm"
                                  color="clear"
                                  className="mt-1 py-0 px-0"
                                  style={{ border: "none" }}
                                >
                                  <i className="bx bx-chevrons-right mt-1 text-primary font-size-16"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="pb-2 px-3"
                            display={display}
                            // Styles from scss file does not work if timeline disappears
                            style={{
                              height:
                                assignments.length == 0 &&
                                selectedProfs.length == 0
                                  ? "0px"
                                  : "auto",
                              marginTop: "-20px",
                            }}
                          >
                            <div className="px-3"></div>
                            <div
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onWheel={e => {
                                if (hovered && e.shiftKey) {
                                  const delta = e.deltaY > 0 ? -1 : 1
                                  changeTimeRangeButton(delta)
                                }
                                if (hovered && e.shiftKey == false) {
                                  const delta = e.deltaY
                                  if (delta > 0) {
                                    changeTimeRangeButton("backward")
                                  } else {
                                    changeTimeRangeButton("forward")
                                  }
                                }
                              }}
                              id="timeline-container"
                            >
                              <Timeline
                                // Styles from scss file does not work if timeline disappears
                                style={{
                                  // opacity: assignments.length == 0 ? 0 : 1,
                                  opacity:
                                    selectedProfs.length == 0 &&
                                    assignments.length == 0
                                      ? 0
                                      : 1,
                                }}
                                groups={selectedProfs}
                                items={assignments}
                                visibleTimeStart={startTime.valueOf()}
                                visibleTimeEnd={endTime.valueOf()}
                                lineHeight={50}
                                onItemDoubleClick={settingSelectedAssignment}
                                stackItems
                                rightSidebarWidth={120}
                                rightSidebarContent={props.t("Time")}
                                canResize={false}
                                traditionalZoom={true}
                                canMove={userRole.includes("admin")}
                                canChangeGroup={true}
                                onItemMove={
                                  (e => console.log(e), handleItemMove)
                                }
                                onItemDrag={handleItemDrag}
                                itemRenderer={checkRenderer()}
                                onCanvasClick={handleEmptyAreaClick}
                                // onCanvasDoubleClick={handleEmptyAreaClick}
                              >
                                <TimelineHeaders>
                                  <SidebarHeader>
                                    {({ getRootProps, styles }) => {
                                      const customStyles = {
                                        ...styles,
                                        color: "transparent",
                                        width: "150.25px",
                                        fontWeight: "regular",
                                        fontSize: "13px",
                                        paddingRight: "2px",
                                        padding: "20px 0px 10px 10px",
                                      }
                                      return (
                                        <div
                                          {...getRootProps()}
                                          style={customStyles}
                                        >
                                          {findType == "admins"
                                            ? props.t("Professional")
                                            : props.t("Customer")}
                                        </div>
                                      )
                                    }}
                                  </SidebarHeader>
                                  {/* <DateHeader
                      unit="primaryHeader"
                      className="text-uppercase"
                      disabled="true"
                    /> */}
                                  <DateHeader unit="hour" labelFormat="HH.mm" />
                                </TimelineHeaders>
                                <TimelineMarkers>
                                  <TodayMarker>
                                    {({ styles }) => {
                                      const customStyles = {
                                        ...styles,
                                        backgroundColor: "#f46a6a",
                                        width: "2px",
                                        border: "1px",
                                        borderRadius: "10px",
                                      }
                                      return <div style={customStyles} />
                                    }}
                                  </TodayMarker>
                                </TimelineMarkers>
                              </Timeline>
                            </div>
                            {/* Not in use */}
                            <div>
                              {tooltipContent && (
                                <div
                                  style={{
                                    position: "fixed",
                                    left: 100,
                                    bottom: 100,
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: 10,
                                    transition: "0.3s",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    zIndex: 85,
                                  }}
                                >
                                  <div>
                                    {props.t("Move")}:{" "}
                                    {tooltipContent.item.title}
                                  </div>
                                  <div>
                                    {props.t("Assign to")}:{" "}
                                    {tooltipContent.group}
                                  </div>
                                  <div>
                                    {props.t("Start time")}:{" "}
                                    {formatDateTime(tooltipContent.time)}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <div className="p-3 mt-2">
                          <span className="me-4">
                            {props.t("All appointments")}:{" "}
                            <b>{assignmentsOrig.length}</b>
                          </span>
                          <span className="me-4">
                            {props.t("Shown appointments")}:{" "}
                            <b>{assignments.length}</b>
                          </span>
                          <span>
                            {props.t("Unassigned appointments")}: {unassigned()}
                          </span>
                        </div>
                      </div>
                    )}
                  {/* TODO exportable lists */}
                  {/* {assignments.length > 0 && (
            <>
              <Col lg="12">{assignments.map(a=>(a.))}</Col>
            </>
          )} */}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

TimelineCalendar2.propTypes = {
  t: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
}

export default withTranslation()(TimelineCalendar2)
