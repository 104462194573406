// ALARM FILTER TREE

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

//Translation
import { withTranslation } from "react-i18next"

//  antd
import { TreeSelect, ConfigProvider } from "antd"
const { SHOW_PARENT } = TreeSelect

const AlarmFilter = props => {
  // Filtered data
  const [filteredDataTemp, setFilteredDataTemp] = useState([])

  const treeData = [
    {
      title: props.t("All"),
      value: "all",
      key: "all",
      children: [
        {
          title: props.t("Activity"),
          value: "Activity",
          key: "Activity",
          children: [
            {
              title: props.t("AlarmTitles." + "6"),
              value: "6",
              key: "6",
            },
            {
              title: props.t("AlarmTitles." + "12"),
              value: "12",
              key: "12",
            },
            {
              title: props.t("AlarmTitles." + "14"),
              value: "14",
              key: "14",
            },
            {
              title: props.t("AlarmTitles." + "16"),
              value: "16",
              key: "16",
            },

            {
              title: props.t("AlarmTitles." + "24"),
              value: "24",
              key: "24",
            },
            {
              title: props.t("AlarmTitles." + "25"),
              value: "25",
              key: "25",
            },
          ],
        },
        {
          title: props.t("Abnormal activity"),
          value: "AB",
          key: "AB",
          children: [
            {
              title: props.t("AlarmTitles." + "17"),
              value: "17",
              key: "17",
            },
            {
              title: props.t("AlarmTitles." + "15"),
              value: "15",
              key: "15",
            },
            {
              title: props.t("AlarmTitles." + "13"),
              value: "13",
              key: "13",
            },
          ],
        },

        {
          title: props.t("Health"),
          value: "Health",
          key: "Health",
          children: [
            {
              title: props.t("AlarmTitles." + "30"),
              value: "30",
              key: "30",
            },
            {
              title: props.t("AlarmTitles." + "27"),
              value: "27",
              key: "27",
            },
            {
              title: props.t("AlarmTitles." + "28"),
              value: "28",
              key: "28",
            },
            {
              title: props.t("AlarmTitles." + "31"),
              value: "31",
              key: "31",
            },
          ],
        },
        {
          title: props.t("Safety"),
          value: "Safety",
          key: "Safety",
          children: [
            {
              title: props.t("AlarmTitles." + "3"),
              value: "3",
              key: "3",
            },
            {
              title: props.t("AlarmTitles." + "4"),
              value: "4",
              key: "4",
            },
            {
              title: props.t("AlarmTitles." + "35"),
              value: "35",
              key: "35",
            },
            {
              title: props.t("AlarmTitles." + "36"),
              value: "36",
              key: "36",
            },
            {
              title: props.t("AlarmTitles." + "42"),
              value: "42",
              key: "42",
            },
            {
              title: props.t("AlarmTitles." + "5"),
              value: "5",
              key: "5",
            },
          ],
        },
        {
          title: props.t("Apartment"),
          value: "Apartment",
          key: "Apartment",
          children: [
            {
              title: props.t("AlarmTitles." + "20"),
              value: "20",
              key: "20",
            },
            {
              title: props.t("AlarmTitles." + "21"),
              value: "21",
              key: "21",
            },
            {
              title: props.t("AlarmTitles." + "22"),
              value: "22",
              key: "22",
            },
            {
              title: props.t("AlarmTitles." + "23"),
              value: "23",
              key: "23",
            },
            {
              title: props.t("AlarmTitles." + "37"),
              value: "37",
              key: "37",
            },
            {
              title: props.t("AlarmTitles." + "38"),
              value: "38",
              key: "38",
            },
            {
              title: props.t("AlarmTitles." + "18"),
              value: "18",
              key: "18",
            },
            {
              title: props.t("AlarmTitles." + "19"),
              value: "19",
              key: "19",
            },
            {
              title: props.t("AlarmTitles." + "54"),
              value: "54",
              key: "54",
            },
          ],
        },
        {
          title: props.t("Devices"),
          value: "Devices",
          key: "Devices",
          children: [
            {
              title: props.t("AlarmTitles." + "1"),
              value: "1",
              key: "1",
            },
            {
              title: props.t("AlarmTitles." + "2"),
              value: "2",
              key: "2",
            },
            {
              title: props.t("AlarmTitles." + "10"),
              value: "10",
              key: "10",
            },
            {
              title: props.t("AlarmTitles." + "11"),
              value: "11",
              key: "11",
            },
            {
              title: props.t("AlarmTitles." + "26"),
              value: "26",
              key: "26",
            },
            {
              title: props.t("AlarmTitles." + "29"),
              value: "29",
              key: "29",
            },
            {
              title: props.t("AlarmTitles." + "32"),
              value: "32",
              key: "32",
            },
            {
              title: props.t("AlarmTitles." + "33"),
              value: "33",
              key: "33",
            },
            {
              title: props.t("AlarmTitles." + "44"),
              value: "44",
              key: "44",
            },
            {
              title: props.t("AlarmTitles." + "45"),
              value: "45",
              key: "45",
            },
            {
              title: props.t("AlarmTitles." + "46"),
              value: "46",
              key: "46",
            },
            {
              title: props.t("AlarmTitles." + "47"),
              value: "47",
              key: "47",
            },
            {
              title: props.t("AlarmTitles." + "48"),
              value: "48",
              key: "48",
            },
            {
              title: props.t("AlarmTitles." + "51"),
              value: "51",
              key: "51",
            },
            {
              title: props.t("AlarmTitles." + "52"),
              value: "52",
              key: "52",
            },
            {
              title: props.t("AlarmTitles." + "53"),
              value: "53",
              key: "53",
            },
          ],
        },
        {
          title: props.t("Other"),
          value: "Other",
          key: "Other",
          children: [
            {
              title: props.t("AlarmTitles." + "34"),
              value: "34",
              key: "34",
            },
            {
              title: props.t("AlarmTitles." + "39"),
              value: "39",
              key: "39",
            },
            {
              title: props.t("AlarmTitles." + "43"),
              value: "43",
              key: "43",
            },
          ],
        },
      ],
    },
  ]

  const [typeFilter, setTypeFilter] = useState(["all"])
  const onChange = newValue => {
    console.log("onChange ", newValue)
    setTypeFilter(newValue)
  }
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("Alarm type not found")}</p>
    </div>
  )
  const [customize, setCustomize] = useState(true)
  const tProps = {
    treeData: treeData,
    value: typeFilter,
    onChange,
    allowClear: true,
    treeDefaultExpandAll: true,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: props.t("Filter"),
    style: {
      width: "100%",
    },
  }
  let types = [
    { checked: "true", name: "all", color: "light", type: "all" },
    { checked: "false", name: "calls", color: "primary", type: "call" },
    { checked: "false", name: "alarms", color: "danger", type: "alarm" },
    { checked: "false", name: "dose", color: "chart-color1", type: "dose" },
    { checked: "false", name: "files", color: "chart-color2", type: "file" },
    {
      checked: "false",
      name: "measurements",
      color: "chart-color3",
      type: "measurement",
    },
    {
      checked: "false",
      name: "messages",
      color: "chart-color4",
      type: "message",
    },
    {
      checked: "false",
      name: "messages to client",
      color: "chart-color5",
      type: "message_client",
    },
    {
      checked: "false",
      name: "smartlocks",
      color: "chart-color6",
      type: "smartlock",
    },
    { checked: "false", name: "visits", color: "chart-color7", type: "visit" },
  ]

  //   Filter by selected types
  useEffect(() => {
    if (props.data != undefined && props.data.length > 0) {
      setFilteredDataTemp([])
      if (typeFilter == "all") {
        setFilteredDataTemp(props.data)
      } else {
        let alarms = []
        console.log("Filter is: ", typeFilter)
        typeFilter.forEach(element => {
          switch (element) {
            case "Activity":
              alarms.push(...[6, 12, 14, 16, 24, 25])
              break
            case "AB":
              alarms.push(...[17, 15, 13])
              break
            case "Health":
              alarms.push(...[30, 27, 28, 31])
              break
            case "Devices":
              alarms.push(
                ...[
                  1, 2, 10, 11, 26, 29, 32, 33, 44, 45, 46, 47, 48, 51, 52, 53,
                ]
              )
              break
            case "Safety":
              alarms.push(...[3, 4, 35, 36, 42, 5])
              break
            case "Apartment":
              alarms.push(...[20, 21, 22, 23, 37, 38, 18, 19])
              break
            case "Other":
              alarms.push(...[34, 39, 43])
              break
            default:
              let temp = parseInt(element, 10)
              if (!isNaN(temp)) alarms.push(...[temp])
              break
          }
        })

        console.log("Filter alarms: ", alarms)
        for (let i = 0; i <= alarms.length; i++) {
          props.data.filter(data => {
            if (data.alarm_id == alarms[i]) {
              setFilteredDataTemp(filteredDataTemp => [
                ...filteredDataTemp,
                data,
              ])
            }
          })
        }
      }
    }
    // Not useful
    // if (props.data.length == 0) {
    //   setFilteredDataTemp(props.data)
    // }
  }, [props.data, typeFilter])

  //   Filter by selected types
  useEffect(() => {
    props.setFilteredEntries(filteredDataTemp)
  }, [filteredDataTemp])

  return (
    <>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <TreeSelect style={{ maxHeight: 200 + "px" }} {...tProps} />
      </ConfigProvider>
    </>
  )
}

AlarmFilter.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  setFilteredEntries: PropTypes.any,
}

export default withTranslation()(AlarmFilter)
