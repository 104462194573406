import { DatePicker, Switch } from "antd"
import { useFormik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import { TimePicker } from "antd"
import moment from "moment"
//
import {
  getDoseConfig,
  getJournalEntries,
  updateDoseConfig,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import { formatDate, showToast } from "utils/general"
import { Chip } from "@material-ui/core"
import PubSub from "pubsub-js"

const DoseServices = props => {
  const [configuration, setConfiguration] = useState(null)
  const [events, setEvents] = useState([])
  const [eventsLoading, setEventsLoading] = useState(false)
  const [alarms, setAlarms] = useState([])
  const [editAlarms, setEditAlarms] = useState([])
  const [reminders, setReminders] = useState([])
  const [editReminders, setEditReminders] = useState([])
  const [loadingConfig, setLoadingConfig] = useState(false)
  const [updatingConfig, setUpdatingConfig] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [idCounter, setIdCounter] = useState(10)

  const timeFormat = "HH:mm"
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getConfig()
      getRecentEvents()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Get configuration JSON
  useEffect(() => {
    getConfig()
    getRecentEvents()
  }, [props.configId])

  // Get Dose configurations
  const getConfig = () => {
    if (props.configId && !loadingConfig) {
      setLoadingConfig(true)
      getDoseConfig(abortRef.current, props.suvantoKey)
        .then(result => {
          if (result.statusCode == 200 && result.config) {
            if (result.config?.DosePlan?.Alarms) {
              let counter = 1
              result.config.DosePlan.Alarms.forEach(element => {
                element.id = counter++
                let time =
                  element.TimeOfDay.Hour + ":" + element.TimeOfDay.Minute
                element.Time = moment(time, timeFormat)
                element.TimeNice = element.Time.format("HH:mm")
              })
              setAlarms(result.config.DosePlan.Alarms)
              setEditAlarms(result.config.DosePlan.Alarms)

              result.config.TimePlans.Plans.forEach(element => {
                element.id = counter++
                let time =
                  element.TimeOfDay.Hour + ":" + element.TimeOfDay.Minute
                element.Time = moment(time, timeFormat)
                element.TimeNice = element.Time.format("HH:mm")
                element.Frekvens = element.RepetitionDays.Frekvens
                element.Expiration = element.ExpireTime
                  ? moment(element.ExpireTime)
                  : null
                element.Expired = false
                if (
                  element.Expiration &&
                  moment().diff(element.Expiration, "minutes") >= 0
                ) {
                  element.Expired = true
                }
              })
              setReminders(result.config.TimePlans.Plans)
              setEditReminders(result.config.TimePlans.Plans)
            }

            if (result.config?.DosePlan?.Repetition != null) {
              result.config.DosePlan.RepetitionText =
                result.config.DosePlan.Repetition == 0
                  ? "Daily"
                  : result.config.DosePlan.Repetition == 1
                  ? "Selected days"
                  : "Interval"
            }

            if (result.config?.DosePlan) {
              result.config.DosePlan.Expired = false
              if (result.config.DosePlan.ExpireTime) {
                let expire = moment(result.config.DosePlan.ExpireTime)
                if (moment().diff(expire, "minutes") >= 0) {
                  result.config.DosePlan.Expired = true
                }
              }

              result.config.DosePlan.Monday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(1)
              result.config.DosePlan.Tuesday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(2)
              result.config.DosePlan.Wednesday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(3)
              result.config.DosePlan.Thursday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(4)
              result.config.DosePlan.Friday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(5)
              result.config.DosePlan.Saturday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(6)
              result.config.DosePlan.Sunday =
                result.config.DosePlan.Weekdays == null ||
                result.config.DosePlan.Weekdays.includes(0)
            }

            setConfiguration(result.config)
            console.log("Get Dose config: ", result.config)
          } else {
            setConfiguration(null)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setLoadingConfig(false)
          validation.resetForm()
        })
    }
  }

  // Get recent logs from journal
  const getRecentEvents = () => {
    if (!eventsLoading) {
      let to = new Date()
      let from = moment().subtract(1, "month")
      setEventsLoading(true)
      getJournalEntries(
        abortRef.current,
        props.suvantoKey,
        from,
        to,
        10,
        "dose",
        false
      )
        .then(result => {
          if (result.entries) {
            result.entries.forEach(item => {
              item.dateNice = moment(item.date).format("DD.MM.yyyy HH:mm:ss")
            })
            //console.log("LOGS: ", result.entries)
            setEvents(result.entries)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setEventsLoading(false)
        })
    }
  }

  // Formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      delay: configuration?.ExpireTime || 0,
      sideplanAlarms: configuration?.SideplanAlarms || false,
      paused: configuration?.Paused || false,
      doses: configuration?.InventoryCount || 0,
      doseCounter: configuration?.DosesCountDisabled || false,
      repetition: configuration?.DosePlan?.Repetition || 0,
      interval: configuration?.DosePlan?.RepetitionDays.Frekvens || 1,
      expirationDate: configuration?.DosePlan?.ExpireTime
        ? moment(configuration.DosePlan.ExpireTime)
        : null,
      monday: configuration?.DosePlan?.Monday || false,
      tuesday: configuration?.DosePlan?.Tuesday || false,
      wednesday: configuration?.DosePlan?.Wednesday || false,
      thursday: configuration?.DosePlan?.Thursday || false,
      friday: configuration?.DosePlan?.Friday || false,
      saturday: configuration?.DosePlan?.Saturday || false,
      sunday: configuration?.DosePlan?.Sunday || false,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      //console.log("Submit: ", values)

      let temp = new Object()
      temp.Id = configuration?.Id
      temp.InventoryCount = values.doses
      temp.DosesCountDisabled = values.doseCounter
      temp.CitizenId = configuration?.CitizenId
      temp.ExpireTime = values.delay
      temp.Paused = values.paused
      temp.SideplanAlarms = values.sideplanAlarms

      // DosePlans
      let plans = new Object()
      plans.UseDosePlan = true
      plans.Repetition = parseInt(values.repetition, 10)
      plans.ExpireTime = values.expirationDate
        ? values.expirationDate.toISOString()
        : null
      plans.Weekdays = []
      if (values.sunday == true) plans.Weekdays.push(0)
      if (values.monday == true) plans.Weekdays.push(1)
      if (values.tuesday == true) plans.Weekdays.push(2)
      if (values.wednesday == true) plans.Weekdays.push(3)
      if (values.thursday == true) plans.Weekdays.push(4)
      if (values.friday == true) plans.Weekdays.push(5)
      if (values.saturday == true) plans.Weekdays.push(6)

      let rep = new Object()
      rep.Fase = 0
      rep.Frekvens = parseInt(values.interval, 10)
      plans.RepetitionDays = rep

      let alarmArray = []
      editAlarms.forEach(element => {
        let al = new Object()
        al.PrekvitOn = element.PrekvitOn
        al.SoundOn = element.SoundOn
        al.TimeOfDay = {
          Hour: element.Time.hour(),
          Minute: element.Time.minute(),
        }
        alarmArray.push(al)
      })
      plans.Alarms = alarmArray
      temp.DosePlan = plans

      // TimePlans
      temp.TimePlans = new Object()
      let timePlans = []
      editReminders.forEach(element => {
        let re = new Object()
        re.ExpireTime = element.Expiration
          ? element.Expiration.toISOString()
          : null
        re.Message = element.Message
        re.PrekvitOn = element.PrekvitOn
        re.Repetition = parseInt(element.Repetition, 10)
        re.SoundOn = element.SoundOn
        re.TimeOfDay = {
          Hour: element.Time.hour(),
          Minute: element.Time.minute(),
        }
        re.RepetitionDays = {
          Fase: 0,
          Frekvens: parseInt(element.Frekvens, 10),
        }
        re.Weekdays = element.Weekdays

        timePlans.push(re)
      })
      temp.TimePlans.Plans = timePlans
      temp.TimePlans.UsePlans = true

      //console.log("Final submit: ", temp)

      setUpdatingConfig(true)
      updateDoseConfig(abortRef.current, props.suvantoKey, temp)
        .then(result => {
          //console.log("Update result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            getConfig()
            setEditMode(false)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdatingConfig(false)
        })
    },
  })

  // Add Dose
  const addAlarm = () => {
    if (editAlarms && editAlarms.length < 8) {
      let entry = new Object()
      entry.PrekvitOn = true
      entry.SoundOn = true
      entry.Time = moment("12:00", timeFormat)
      entry.id = idCounter
      setEditAlarms(prevState => [...prevState, entry])
      setIdCounter(prevCount => prevCount + 1)
    }
  }

  // Remove Dose
  const removeAlarm = id => {
    if (editAlarms) {
      setEditAlarms(prevState =>
        prevState.filter(prevItem => prevItem.id !== id)
      )
    }
  }

  // Add reminder
  const addReminder = () => {
    if (editReminders && editReminders.length < 8) {
      let entry = new Object()
      entry.Message = "NO MESSAGE"
      entry.Repetition = 0
      entry.Weekdays = []
      entry.Frekvens = 3
      entry.RepetitionDays = { Frekvens: 3 }
      entry.PrekvitOn = true
      entry.SoundOn = true
      entry.Time = moment("12:00", timeFormat)
      entry.id = idCounter
      setEditReminders(prevState => [...prevState, entry])
      setIdCounter(prevCount => prevCount + 1)
    }
  }

  // Remove reminder
  const removeReminder = id => {
    if (editReminders) {
      setEditReminders(prevState =>
        prevState.filter(prevItem => prevItem.id !== id)
      )
    }
  }

  const lottieLoadingOpts = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lottieNoneOpts = {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const setStatusIcon = s => {
    switch (s) {
      case "dose_untaken":
      case "dose_side_unack":
        return "mdi mdi-alert text-danger"
      case "dose_count":
        return "mdi mdi-pill text-primary"
      case "dose_paused":
        return "mdi mdi-pause text-danger"
      case "dose_unpaused":
        return "mdi mdi-play text-success"
      default:
        return "mdi mdi-check text-success"
    }
  }

  const getEventName = item => {
    switch (item.subType) {
      case "dose_taken":
        return item.additionalData.time + " " + props.t("dose acked")
      case "dose_untaken":
        return item.additionalData.time + " " + props.t("dose missed")
      case "dose_side_ack":
        return (
          item.additionalData.time +
          " " +
          item.additionalData.event +
          " " +
          props.t("acked")
        )
      case "dose_side_unack":
        return (
          item.additionalData.time +
          " " +
          item.additionalData.event +
          " " +
          props.t("missed")
        )
      case "dose_count":
        return props.t("Dose count set to") + " " + item.additionalData.count
      case "dose_paused":
        return props.t("Reminders paused")
      case "dose_unpaused":
        return props.t("Reminders unpaused")
      default:
        return item.message
    }
  }

  const getIntervalText = (repetition, interval, weekdays) => {
    switch (repetition) {
      case 0:
        return props.t("Daily")
      case 1:
        let temp = props.t("Selected days") + ":"
        if (weekdays.includes(1)) temp = temp + " " + props.t("Mon")
        if (weekdays.includes(2)) temp = temp + " " + props.t("Tues")
        if (weekdays.includes(3)) temp = temp + " " + props.t("Wed")
        if (weekdays.includes(4)) temp = temp + " " + props.t("Thurs")
        if (weekdays.includes(5)) temp = temp + " " + props.t("Fri")
        if (weekdays.includes(6)) temp = temp + " " + props.t("Sat")
        if (weekdays.includes(0)) temp = temp + " " + props.t("Sun")
        return temp
      case 2:
        return interval + " " + props.t("days interval")
    }
  }

  const alarmPropertyChanged = (item, prop, value) => {
    setEditAlarms(
      editAlarms.map(alarm =>
        alarm.id === item.id ? { ...alarm, [prop]: value } : alarm
      )
    )
  }

  const reminderPropertyChanged = (item, prop, value) => {
    setEditReminders(
      editReminders.map(reminder =>
        reminder.id === item.id ? { ...reminder, [prop]: value } : reminder
      )
    )
  }

  const reminderWeekdaysChanged = (item, day, value) => {
    editReminders.forEach(element => {
      if (element.id === item.id) {
        let days = [...element.Weekdays]
        if (value == true) {
          if (!days.includes(day)) {
            days.push(day)
            setEditReminders(
              editReminders.map(reminder =>
                reminder.id === item.id
                  ? { ...reminder, Weekdays: days }
                  : reminder
              )
            )
          }
        } else {
          var newDays = _.remove(days, function (n) {
            return n !== day
          })
          setEditReminders(
            editReminders.map(reminder =>
              reminder.id === item.id
                ? { ...reminder, Weekdays: newDays }
                : reminder
            )
          )
        }
      }
    })
  }

  return (
    <>
      <div>
        <span className="h4">
          <Row>
            <Col></Col>
            <Col className="col-auto">
              {!editMode && (
                <Button
                  color="primary"
                  className=""
                  disabled={loadingConfig}
                  onClick={() => {
                    setEditMode(!editMode)
                  }}
                >
                  <i className="bx bxs-edit me-2" />
                  {props.t("Edit")}
                </Button>
              )}
              {editMode && (
                <>
                  <Button
                    color="primary"
                    className=""
                    disabled={loadingConfig}
                    onClick={() => {
                      setEditMode(!editMode)
                      validation.resetForm()
                      setEditAlarms(alarms)
                      setEditReminders(reminders)
                    }}
                  >
                    <i className="bx bxs-x-square me-2" />
                    {props.t("Cancel")}
                  </Button>
                  <Button
                    color="success"
                    className="ms-3"
                    disabled={loadingConfig || updatingConfig}
                    onClick={() => {
                      validation.submitForm()
                    }}
                  >
                    <i className="bx bxs-save me-2" />
                    {props.t("Save changes")}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </span>

        <div className="mt-1">
          {!editMode && (
            <>
              {configuration?.Paused && (
                <>
                  <Alert color="danger">
                    <span>{props.t("Reminders paused")}</span>
                  </Alert>
                </>
              )}

              <Row xs={1} lg={2}>
                <Col>
                  <Card color="success" outline>
                    <CardHeader style={{ backgroundColor: "#34C38F" }}>
                      <div className="d-flex text-white">
                        <i className="mdi mdi-message-text me-2 "></i>
                        <h5 className="mb-0 text-white">
                          {props.t("Dose reminder").toUpperCase()}
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <h6 className="">
                        {getIntervalText(
                          configuration?.DosePlan?.Repetition,
                          configuration?.DosePlan?.RepetitionDays?.Frekvens,
                          configuration?.DosePlan?.Weekdays
                        )}
                      </h6>
                      <ul className="list-group list-group-flush border-top border-bottom mt-3">
                        {alarms && alarms.length > 0 && (
                          <>
                            {alarms.map((item, index) => (
                              <li
                                key={index}
                                className="list-group-item py-3 text-muted"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <Row xs={2} xl={3}>
                                  <Col>
                                    <i className="mdi mdi-clock me-2 text-success"></i>
                                    {item.TimeNice}
                                  </Col>
                                  {item.SoundOn == true && (
                                    <Col>
                                      <i className="mdi mdi-bell-ring me-1 text-success"></i>
                                      {props.t("Alarm sound")}
                                    </Col>
                                  )}
                                  {!item.SoundOn && (
                                    <Col>
                                      <i className="mdi mdi-bell-off me-1 text-danger"></i>
                                      {props.t("No sound")}
                                    </Col>
                                  )}
                                  {item.PrekvitOn == true && (
                                    <Col>
                                      <i className="mdi mdi-reply me-1 text-success"></i>
                                      {props.t("Pre-ack")}
                                    </Col>
                                  )}
                                </Row>
                              </li>
                            ))}
                          </>
                        )}
                        {!alarms ||
                          (alarms.length == 0 && (
                            <>
                              <li
                                key={0}
                                className="list-group-item py-3 text-muted"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <span>{props.t("No reminders")}</span>
                              </li>
                            </>
                          ))}
                      </ul>

                      <Row>
                        {configuration?.DosesCountDisabled == false && (
                          <Col className="col-auto">
                            <div className="d-flex mt-3">
                              <Chip
                                size="medium"
                                key="chip"
                                className="mdi mdi-pill me-2 p-2"
                                style={{
                                  background: "#34C38F",
                                  color: "white",
                                }}
                                label={
                                  configuration?.InventoryCount +
                                  " " +
                                  props.t("doses remaining")
                                }
                              />
                            </div>
                          </Col>
                        )}
                        {configuration?.DosePlan?.ExpireTime && (
                          <Col className="col-auto">
                            <div className="d-flex mt-3">
                              {configuration.DosePlan.Expired && (
                                <Chip
                                  size="medium"
                                  key="chip"
                                  className="mdi mdi-calendar-end me-2 p-2"
                                  style={{
                                    background: "#F46A6A",
                                    color: "white",
                                  }}
                                  label={
                                    props.t("Expired") +
                                    " " +
                                    formatDate(
                                      configuration.DosePlan.ExpireTime
                                    )
                                  }
                                />
                              )}
                              {!configuration.DosePlan.Expired && (
                                <Chip
                                  size="medium"
                                  key="chip"
                                  className="mdi mdi-calendar-end me-2 p-2"
                                  style={{
                                    background: "#34C38F",
                                    color: "white",
                                  }}
                                  label={
                                    props.t("Expires") +
                                    " " +
                                    formatDate(
                                      configuration.DosePlan.ExpireTime
                                    )
                                  }
                                />
                              )}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>

                  {reminders && reminders.length > 0 && (
                    <>
                      {reminders.map((item, index) => (
                        <Card color="primary" outline key={index}>
                          <CardHeader style={{ backgroundColor: "#00A0D6" }}>
                            <div className="d-flex text-white">
                              <i className="mdi mdi-message-text me-2 "></i>
                              <h5 className="mb-0 text-white">
                                {item.Message}
                              </h5>
                            </div>
                          </CardHeader>
                          <CardBody className="">
                            <h6 className="">
                              {getIntervalText(
                                item.Repetition,
                                item.RepetitionDays.Frekvens,
                                item.Weekdays
                              )}
                            </h6>
                            <ul className="list-group list-group-flush border-top border-bottom mt-3">
                              <li
                                key={"1"}
                                className="list-group-item py-3 text-muted"
                              >
                                <Row xs={2} xl={3}>
                                  <Col>
                                    <i className="mdi mdi-clock me-2 text-primary"></i>
                                    {item.TimeNice}
                                  </Col>
                                  {item.SoundOn == true && (
                                    <Col>
                                      <i className="mdi mdi-bell-ring me-1 text-primary"></i>
                                      {props.t("Alarm sound")}
                                    </Col>
                                  )}
                                  {!item.SoundOn && (
                                    <Col>
                                      <i className="mdi mdi-bell-off me-1 text-danger"></i>
                                      {props.t("No sound")}
                                    </Col>
                                  )}
                                  {item.PrekvitOn == true && (
                                    <Col>
                                      <i className="mdi mdi-reply me-1 text-primary"></i>
                                      {props.t("Pre-ack")}
                                    </Col>
                                  )}
                                </Row>
                              </li>
                            </ul>

                            {item.ExpireTime && (
                              <div className="d-flex mt-3">
                                {item.Expired && (
                                  <Chip
                                    size="medium"
                                    key="chip"
                                    className="mdi mdi-calendar-end me-2 p-2"
                                    style={{
                                      background: "#F46A6A",
                                      color: "white",
                                    }}
                                    label={
                                      props.t("Expired") +
                                      " " +
                                      formatDate(item.ExpireTime)
                                    }
                                  />
                                )}
                                {!item.Expired && (
                                  <Chip
                                    size="medium"
                                    key="chip"
                                    className="mdi mdi-calendar-end me-2 p-2"
                                    style={{
                                      background: "#00A0D6",
                                      color: "white",
                                    }}
                                    label={
                                      props.t("Expires") +
                                      " " +
                                      formatDate(item.ExpireTime)
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      ))}
                    </>
                  )}
                </Col>

                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        {props.t("Recent events")}
                      </CardTitle>
                      {eventsLoading && (
                        <Lottie
                          options={lottieLoadingOpts}
                          height={100}
                          width={100}
                        />
                      )}
                      {!eventsLoading && events.length > 0 && (
                        <ul className="list-group list-group-flush">
                          {events.map(item => (
                            <li key={item.key} className="list-group-item py-3">
                              <Row>
                                <Col>
                                  <div className="d-grid">
                                    <h6>{getEventName(item)}</h6>
                                    <span className="text-muted">
                                      {item.dateNice}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className="col-auto d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <i
                                    id="statusIcon"
                                    className={
                                      setStatusIcon(item.subType) +
                                      " font-size-18"
                                    }
                                  />
                                </Col>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      )}
                      {!eventsLoading &&
                        (events == null || events.length == 0) && (
                          <div className="text-center mb-4">
                            <Lottie
                              options={lottieNoneOpts}
                              height={150}
                              width={150}
                            />
                            <h5 className="text-muted mt-2">
                              {props.t("No events found")}
                            </h5>
                          </div>
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          {editMode && (
            <>
              <Card>
                <CardBody style={{ padding: "0px" }}>
                  <UncontrolledAccordion flush defaultOpen={["1"]} stayOpen>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        {props.t("General settings")}
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <Row xs={2}>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Alarm delay") +
                                  ", " +
                                  props.t("minutes")}
                              </Label>
                              <Input
                                bsSize="sm"
                                name="delay"
                                type="number"
                                value={validation.values.delay}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Reminders paused")}</Label>
                              <Switch
                                className="d-block"
                                name="paused"
                                checked={validation.values.paused}
                                onChange={v => {
                                  validation.setFieldValue("paused", v)
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </CardBody>
              </Card>

              <Card>
                <CardBody style={{ padding: "0px" }}>
                  <UncontrolledAccordion flush defaultOpen={["2"]} stayOpen>
                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        {props.t("Dose reminders")}
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Dose count")}</Label>
                              <Input
                                bsSize="sm"
                                name="doses"
                                type="number"
                                value={validation.values.doses}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Dose counter disabled")}</Label>
                              <Switch
                                className="d-block"
                                name="dose_count"
                                checked={validation.values.doseCounter}
                                onChange={v => {
                                  validation.setFieldValue("doseCounter", v)
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Repetition")}</Label>
                              <Input
                                bsSize="sm"
                                value={validation.values.repetition + ""}
                                type="select"
                                name="repetition"
                                onChange={validation.handleChange}
                              >
                                <option value="0">{props.t("Daily")}</option>
                                <option value="1">
                                  {props.t("Selected days")}
                                </option>
                                <option value="2">{props.t("Interval")}</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            {validation.values.repetition == "1" && (
                              <div className="mt-4">
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.monday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "monday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Mon")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.tuesday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "tuesday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Tues")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.wednesday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "wednesday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Wed")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.thursday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "thursday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Thurs")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.friday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "friday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Fri")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.saturday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "saturday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Sat")}</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    defaultChecked={validation.values.sunday}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "sunday",
                                        v.target.checked
                                      )
                                    }}
                                  />
                                  <Label check>{props.t("Sun")}</Label>
                                </FormGroup>
                              </div>
                            )}
                            {validation.values.repetition == "2" && (
                              <FormGroup>
                                <Label>
                                  {props.t("Interval") + ", " + props.t("days")}
                                </Label>
                                <Input
                                  bsSize="sm"
                                  value={validation.values.interval + ""}
                                  type="number"
                                  name="interval"
                                  onChange={validation.handleChange}
                                ></Input>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>

                        <Row xs={1} sm={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Expiration date")}</Label>
                              <DatePicker
                                style={{ width: "100%" }}
                                format="DD.MM.YYYY"
                                value={validation.values.expirationDate}
                                onChange={v =>
                                  validation.setFieldValue("expirationDate", v)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <ul className="list-group list-group-flush border-top mt-2">
                          {editAlarms.map((item, index) => (
                            <li key={index} className="list-group-item pt-3">
                              <>
                                <div className="mb-3">
                                  <Chip
                                    size="small"
                                    key="chip"
                                    className="px-1"
                                    style={{
                                      background: "#00A0D6",
                                      color: "white",
                                    }}
                                    label={
                                      props.t("Alarm").toUpperCase() +
                                      " " +
                                      (index + 1)
                                    }
                                  />
                                  <Button
                                    color="clear"
                                    className="ms-1 py-0 px-2"
                                  >
                                    <i
                                      className="text-danger mdi mdi-close-circle font-size-18"
                                      onClick={() => {
                                        removeAlarm(item.id)
                                      }}
                                    ></i>
                                  </Button>
                                </div>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label className="me-3">
                                        {props.t("Time")}
                                      </Label>
                                      <TimePicker
                                        value={item.Time}
                                        allowClear={false}
                                        format={timeFormat}
                                        onChange={v => {
                                          alarmPropertyChanged(item, "Time", v)
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Switch
                                        className="me-2"
                                        name=""
                                        checked={item.SoundOn}
                                        onChange={v => {
                                          alarmPropertyChanged(
                                            item,
                                            "SoundOn",
                                            v
                                          )
                                        }}
                                      />
                                      <Label>{props.t("Alarm sound")}</Label>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Switch
                                        className="me-2"
                                        name=""
                                        checked={item.PrekvitOn}
                                        onChange={v => {
                                          alarmPropertyChanged(
                                            item,
                                            "PrekvitOn",
                                            v
                                          )
                                        }}
                                      />
                                      <Label>{props.t("Pre-ack")}</Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            </li>
                          ))}
                        </ul>

                        <div className="border-top pb-2 pt-3 d-flex justify-content-center">
                          <Button
                            color="success"
                            style={{ minWidth: "160px" }}
                            disabled={alarms.length >= 8}
                            onClick={() => {
                              addAlarm()
                            }}
                          >
                            {props.t("Add new")}
                          </Button>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </CardBody>
              </Card>

              <Card>
                <CardBody style={{ padding: "0px" }}>
                  <UncontrolledAccordion flush defaultOpen={["3"]} stayOpen>
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        {props.t("Other reminders")}
                      </AccordionHeader>
                      <AccordionBody accordionId="3">
                        <Row className="mt-2">
                          <Col>
                            <FormGroup>
                              <Switch
                                className="me-2"
                                name="sideplanAlarms"
                                checked={validation.values.sideplanAlarms}
                                onChange={v => {
                                  validation.setFieldValue("sideplanAlarms", v)
                                }}
                              />
                              <Label>
                                {props.t("Create alarm for missed reminder")}
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <ul className="list-group list-group-flush border-top">
                          {editReminders.map((item, index) => (
                            <li key={index} className="list-group-item pt-3">
                              <>
                                <div className="mb-3">
                                  <Chip
                                    size="small"
                                    key="chip"
                                    className="px-1"
                                    style={{
                                      background: "#00A0D6",
                                      color: "white",
                                    }}
                                    label={
                                      props.t("Reminder").toUpperCase() +
                                      " " +
                                      (index + 1)
                                    }
                                  />
                                  <Button
                                    color="clear"
                                    className="ms-1 py-0 px-2"
                                  >
                                    <i
                                      className="text-danger mdi mdi-close-circle font-size-18"
                                      onClick={() => {
                                        removeReminder(item.id)
                                      }}
                                    ></i>
                                  </Button>
                                </div>

                                <Row xs={1} sm={2}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Repetition")}</Label>
                                      <Input
                                        bsSize="sm"
                                        value={item.Repetition}
                                        type="select"
                                        name=""
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "Repetition",
                                            v.target.value
                                          )
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("Daily")}
                                        </option>
                                        <option value="1">
                                          {props.t("Selected days")}
                                        </option>
                                        <option value="2">
                                          {props.t("Interval")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  {item.Repetition == "1" && (
                                    <div className="mt-4">
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            1
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              1,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Mon")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            2
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              2,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Tues")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            3
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              3,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Wed")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            4
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              4,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Thurs")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            5
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              5,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Fri")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            6
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              6,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Sat")}</Label>
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          defaultChecked={item.Weekdays.includes(
                                            0
                                          )}
                                          onChange={v => {
                                            reminderWeekdaysChanged(
                                              item,
                                              0,
                                              v.target.checked
                                            )
                                          }}
                                        />
                                        <Label check>{props.t("Sun")}</Label>
                                      </FormGroup>
                                    </div>
                                  )}
                                  {item.Repetition == "2" && (
                                    <FormGroup>
                                      <Label>
                                        {props.t("Interval") +
                                          ", " +
                                          props.t("days")}
                                      </Label>
                                      <Input
                                        bsSize="sm"
                                        value={item.Frekvens + ""}
                                        type="number"
                                        name="interval"
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "Frekvens",
                                            v.target.value
                                          )
                                        }}
                                      ></Input>
                                    </FormGroup>
                                  )}
                                </Row>

                                <Row xs={1} sm={2}>
                                  <Col>
                                    <FormGroup>
                                      <Label>
                                        {props.t("Expiration date")}
                                      </Label>
                                      <DatePicker
                                        style={{ width: "100%" }}
                                        format="DD.MM.YYYY"
                                        value={item.Expiration}
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "Expiration",
                                            v
                                          )
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Message")}</Label>
                                      <Input
                                        bsSize="sm"
                                        name=""
                                        type="text"
                                        value={item.Message}
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "Message",
                                            v.target.value
                                          )
                                        }}
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row className="mt-2">
                                  <Col>
                                    <FormGroup>
                                      <Label className="me-3">
                                        {props.t("Time")}
                                      </Label>
                                      <TimePicker
                                        value={item.Time}
                                        allowClear={false}
                                        format={timeFormat}
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "Time",
                                            v
                                          )
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Switch
                                        className="me-2"
                                        name=""
                                        checked={item.SoundOn}
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "SoundOn",
                                            v
                                          )
                                        }}
                                      ></Switch>
                                      <Label>{props.t("Alarm sound")}</Label>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Switch
                                        className="me-2"
                                        name=""
                                        checked={item.PrekvitOn}
                                        onChange={v => {
                                          reminderPropertyChanged(
                                            item,
                                            "PrekvitOn",
                                            v
                                          )
                                        }}
                                      ></Switch>
                                      <Label>{props.t("Pre-ack")}</Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            </li>
                          ))}
                        </ul>

                        <div className="border-top pb-2 pt-3 d-flex justify-content-center">
                          <Button
                            color="success"
                            style={{ minWidth: "160px" }}
                            disabled={reminders.length >= 8}
                            onClick={() => {
                              addReminder()
                            }}
                          >
                            {props.t("Add new")}
                          </Button>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  )
}

DoseServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  configId: PropTypes.string,
}

export default withTranslation()(DoseServices)
