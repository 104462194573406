import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Select, ConfigProvider } from "antd"
import { acGetVirtualRoomList } from "helpers/backend_helper"

const SelectVirtualRoom = props => {
  const [loading, setLoading] = useState(false)
  const [customize, setCustomize] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selection) setSelectedValue(props.selection)
    else setSelectedValue(null)
  }, [props.selection])

  useEffect(() => {
    setOptions([])
    if (props.data.length > 0) {
      let opts = []
      props.data.forEach(entry => {
        let temp = new Object()
        temp.label = entry.vmr_number + " - " + entry.vmr_name
        temp.value = entry.vmr_id
        opts.push(temp)
      })
      opts.sort((a, b) => (a.label < b.label ? 1 : -1))
      setOptions(opts)
    }
  }, [props.data])

  // Get rooms
  /*const getRooms = () => {
    if (!loading && props.selectedGroupId) {
      setLoading(true)
      setOptions([])
      acGetVirtualRoomList(abortRef.current, props.selectedGroupId, 1, false)
        .then(result => {
          if (result.statusCode == 200 && result.list != null) {
            let opts = []
            result.list.forEach(entry => {
              let temp = new Object()
              temp.label = entry.vmr_number + " - " + entry.vmr_name
              temp.value = entry.vmr_id
              opts.push(temp)
            })
            opts.sort((a, b) => (a.label < b.label ? 1 : -1))
            setOptions(opts)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }*/

  const handleChange = newSelection => {
    setSelectedValue(newSelection)
    if (props.setSelectedRoom != null) {
      props.setSelectedRoom(newSelection)
    }
  }

  // Dropdown visibility
  /*const dropVisible = visible => {
    if (visible && options.length == 0 && !loading) {
      getRooms()
    }
  }*/

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No rooms found")}</p>
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            loading={props.loadingData}
            //onDropdownVisibleChange={dropVisible}
            placeholder={props.t("Select")}
            style={{ width: 100 + "%" }}
            value={selectedValue}
            onChange={handleChange}
            allowClear={true}
            options={options}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectVirtualRoom.propTypes = {
  t: PropTypes.any,
  data: PropTypes.array,
  loadingData: PropTypes.bool,
  selection: PropTypes.string,
  setSelectedRoom: PropTypes.func,
  selectedGroupId: PropTypes.any
}

export default withTranslation()(SelectVirtualRoom)