/***********************
 * ROLE DROPDOWN HEADER
 ************************/

import React, { useEffect, useState, useContext } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap"
import { Route, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import GlobalState from "../../../contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const UserRoleDropdown = props => {
  const [state, setState] = useContext(GlobalState)
  const [selectedRole, setSelectedRole] = useState("")
  const [roles, setRoles] = useState([])
  const [menu, setMenu] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let temp = []
    if (state.authUser) {
      let userObject = JSON.parse(state.authUser)
      if (userObject.role === "system_admin") {
        temp.push({
          label: "UserRoles." + getRoleName("system_admin"),
          value: "system_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_admin"),
          value: "organisation_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_user"),
          value: "organisation_user",
        })
        temp.push({
          label: "UserRoles." + getRoleName("private_user"),
          value: "private_user",
        })
        temp.push({
          label: "UserRoles." + getRoleName("salesperson"),
          value: "salesperson",
        })
        temp.push({
          label: "UserRoles." + getRoleName("order_manager"),
          value: "order_manager",
        })
      } else {
        /*else if (userObject.role === "organisation_admin") {
        temp.push({
          label: "UserRoles." + getRoleName("organisation_admin"),
          value: "organisation_admin",
        })
        temp.push({
          label: "UserRoles." + getRoleName("organisation_user"),
          value: "organisation_user",
        })
      }*/
        temp.push({
          label: "UserRoles." + getRoleName(userObject.role),
          value: userObject.role,
        })
      }

      if (userObject.activeRole) {
        let role = {
          label: "UserRoles." + getRoleName(userObject.activeRole),
          value: userObject.activeRole,
        }
        setSelectedRole(role)
      } else {
        let role = {
          label: "UserRoles." + getRoleName(userObject.role),
          value: userObject.role,
        }
        setSelectedRole(role)
      }
    }
    setRoles(temp)
  }, [])

  const getRoleName = role => {
    switch (role) {
      case "system_admin":
        return "System Admin"
      case "organisation_admin":
        return "Organisation Admin"
      case "organisation_user":
        return "Organisation User"
      case "private_user":
        return "Private User"
      case "salesperson":
        return "Salesperson"
      case "order_manager":
        return "Order Manager"
      default:
        return ""
    }
  }

  const changeRoleAction = role => {
    console.log("New active role is: " + role)
    if (role !== selectedRole.value) {
      if (state.authUser != null) {
        let userObject = JSON.parse(state.authUser)
        userObject.activeRole = role
        setState(state => ({ ...state, authUser: JSON.stringify(userObject) }))
        let newRole = {
          label: "UserRoles." + getRoleName(role),
          value: role,
        }
        props.settingRole(newRole.label)
        setSelectedRole(newRole)
        navigate("/")
      }
    }
  }

  return (
    <>
      {/* <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div>
            <span className="font-size-12 d-lg-inline-block ms-2 me-1">
              {props.t(selectedRole.label)}
            </span>
            <i className="mdi mdi-chevron-down d-sm-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {roles?.map(item => (
            <DropdownItem
              key={item.value + "_dropd"}
              onClick={() => changeRoleAction(item.value)}
              className="dropdown-item"
            >
              {props.t(item.label)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown> */}
      {roles?.map(item => (
        <div
          className="role"
          key={item.value + "_dropd"}
          onClick={() => changeRoleAction(item.value)}
        >
          {props.t(item.label)}
        </div>
      ))}
    </>
  )
}

UserRoleDropdown.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
}

export default withRouter(withTranslation()(UserRoleDropdown))
