import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { DatePicker, Drawer } from "antd"
import {
  deleteSmartlock,
  syncSmartlock,
  updateSmartlock,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import { showToast } from "utils/general"
import moment from "moment"
import withRouter from "components/Common/withRouter"

const PindoraDrawer = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: props.selectedRow?.orderNumber || "",
      description: props.selectedRow?.description || "",
      gsm: props.selectedRow?.gsm || "",
      iccid: props.selectedRow?.iccid || "",
      imei: props.selectedRow?.imei || "",
      prodStart: props.selectedRow?.prodStart
        ? moment(props.selectedRow.prodStart)
        : null,
      prodEnd: props.selectedRow?.prodEnd
        ? moment(props.selectedRow.prodEnd)
        : null,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setSuccess("")
      setError("")
      setUpdate(true)

      let temp = JSON.parse(JSON.stringify(values))
      temp.orderNumber = temp.orderNumber ? temp.orderNumber : ""
      temp.prodStart = values.prodStart ? values.prodStart.valueOf() : 0
      temp.prodEnd = values.prodEnd ? values.prodEnd.valueOf() : 0

      updateSmartlock(abortRef.current, props.selectedRow?.id, temp)
        .then(result => {
          setUpdate(false)
          if (result.statusCode == 200) {
            setSuccess("Updated")
            props.getDevices()
          } else {
            setError("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setError("Error")
        })
    },
  })

  // Sync device
  const syncDevice = () => {
    setOngoingSync(true)
    syncSmartlock(abortRef.current, props.selectedRow?.id)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode == 200) {
          props.getDevices()
          showToast(props.t("Success"), "success")
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Delete device
  const deleteDevice = () => {
    if (props.selectedRow != null && props.selectedRow.id.length > 0) {
      setOngoingDelete(true)
      deleteSmartlock(abortRef.current, props.selectedRow.id)
        .then(result => {
          if (result.statusCode === 200) {
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
            props.getDevices()
            props.setShowDrawer(false)
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
          setOngoingDelete(false)
        })
    }
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  return (
    <>
      <Drawer
        title={props.t("Smartlock") + " " + props.selectedRow?.name}
        width={500}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Settings")}</CardTitle>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row xs={2}>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Description")}</Label>
                    <Input
                      bsSize="sm"
                      name="description"
                      type="text"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("GSM")}</Label>
                    <Input
                      bsSize="sm"
                      name="gsm"
                      type="text"
                      value={validation.values.gsm}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("ICCID")}</Label>
                    <Input
                      bsSize="sm"
                      name="iccid"
                      type="text"
                      value={validation.values.iccid}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("IMEI")}</Label>
                    <Input
                      bsSize="sm"
                      name="imei"
                      type="text"
                      value={validation.values.imei}
                      onChange={validation.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Production start")}</Label>
                    <DatePicker
                      size={"small"}
                      style={{ width: "100%" }}
                      format="DD.MM.YYYY"
                      value={validation.values.prodStart}
                      onChange={v => validation.setFieldValue("prodStart", v)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Production end")}</Label>
                    <DatePicker
                      size={"small"}
                      style={{ width: "100%" }}
                      format="DD.MM.YYYY"
                      value={validation.values.prodEnd}
                      onChange={v => validation.setFieldValue("prodEnd", v)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Order number")}</Label>
                    <SelectOrder
                      selection={validation.values.orderNumber}
                      setSelectedOrder={orderSelectionChanged}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mt-2 mb-3 d-flex justify-content-center">
                <Button
                  color="primary"
                  disabled={update}
                  style={{ minWidth: "150px" }}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Save")}
                </Button>
              </div>
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
            <ListGroup className="list-group-flush">
              <ListGroupItem className="pb-4">
                <Row>
                  <Col>
                    <div>
                      <h6>{props.t("Sync device")}</h6>
                      <span className="text-muted">
                        {props.t("Get latest device data from API")}
                      </span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      color="primary"
                      style={{ minWidth: "100px" }}
                      disabled={ongoingSync}
                      onClick={() => {
                        syncDevice()
                      }}
                    >
                      {props.t("Send")}
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="pb-4">
                <Row>
                  <Col>
                    <div>
                      <h6>{props.t("Deletion")}</h6>
                      <span className="text-muted">
                        {props.t(
                          "The device and all related data will be deleted permanently"
                        )}
                      </span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      color="danger"
                      style={{ minWidth: "100px" }}
                      onClick={() => {
                        setDeleteModal(true)
                      }}
                    >
                      {props.t("Delete")}
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.name +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteDevice()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

PindoraDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getDevices: PropTypes.func,
}

export default withRouter(withTranslation()(PindoraDrawer))
