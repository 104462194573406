import React, { useState, useEffect, Fragment } from "react"
import {
  Row,
  Progress,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Table,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"

//Simple bar
import SimpleBar from "simplebar-react"

// General imports
import { formatDate, showToast, formatDateFull } from "utils/general"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Chart import
import ReactApexChart from "react-apexcharts"

//Translation
import { withTranslation } from "react-i18next"

const ChartCalls = props => {
  const [stats, setStats] = useState()
  const [list, setList] = useState(props.data.list)

  // Graph series
  const [series, setSeries] = useState([])

  // Chosen content
  const [subject, setSubject] = useState(props.t("All calls"))

  // Customer stats
  const [customersData, setCustomersData] = useState()
  const [combinedData, setCombinedData] = useState()

  // Relative vs other callers
  const [callerFamily, setCallerFamily] = useState(0)
  const [callerOther, setCallerOther] = useState(0)

  // Card states
  const [total, setTotal] = useState(0)
  const [durationA, setDurationA] = useState(0)
  const [durationM, setDurationM] = useState(0)

  const [menu, setMenu] = useState(false)
  const [filteredList, setFilteredList] = useState(list)

  // Dates
  const [timeStart, setTimeStart] = useState(props.start)
  const [timeEnd, setTimeEnd] = useState(props.end)

  // Updating states
  useEffect(() => {
    if (
      props.data != null &&
      props.data.stats != null &&
      props.data.stats != stats
    ) {
      console.log("Stats: " + JSON.stringify(props.data))
      setStats(props.data.stats)
      setList(props.data.list)

      if (props.data.list != filteredList) {
        setList(props.data.list)
        setFilteredList(props.data.list)
      }

      setSeries([
        props.data.stats.video,
        props.data.stats.group,
        props.data.stats.tracker,
        props.data.stats.carephone,
        props.data.stats.phone,
      ])
      // filterData(props.data.list)
      handlingCategory(subject, props.data.list, subject)

      // Set given stats
      setDurationA(props.data.stats.duration_average)
      setTotal(props.data.stats.total)
      setDurationM(props.data.stats.duration_median)
      setCallerFamily(props.data.stats.caller_relative)
      setCallerOther(props.data.stats.total - props.data.stats.caller_relative)
    }
  }, [props.data])

  useEffect(() => {
    setTimeStart(props.start)
    setTimeEnd(props.end)
  }, [props.start, props.end])

  // Setting CustomersData
  const handlingCategory = (ty, fl, subject) => {
    let result = []
    let resultGroups = []
    let resultCombined = []
    let cus = []
    let gro = []

    cus = fl.map(g => g.client_name)
    let customers = [...new Set(cus)]

    gro = fl.map(g => g.group)
    let groups = [...new Set(gro)]

    customers.forEach(c => {
      let count = fl.filter(f => f.client_name === c)
      let relative = fl.filter(
        f => f.caller_role == "relative" && f.client_name === c
      )
      let group = count[0].group
      let customerData = {
        name: c,
        group: group,
        type: subject,
        time: formatDateFull(props.start) + " - " + formatDateFull(props.end),
        relativeCalls: relative.length,
        amount: count.length,
        data: count,
      }
      result.push(customerData)
    })
    groups.forEach(g => {
      let count = fl.filter(f => f.group === g)
      let relative = fl.filter(
        f => f.caller_role == "relative" && f.group === g
      ).length
      let groupData = {
        name: g,
        type: subject,
        relativeCalls: relative,
        amount: count.length,
      }
      resultGroups.push(groupData)
    })

    resultGroups.forEach(rg => {
      let rest = result.filter(s => s.group == rg.name)
      let combinedData = {
        group: rg.name,
        relativeCalls: rg.relativeCalls,
        amount: rg.amount,
        clients: rest,
      }
      resultCombined.push(combinedData)
    })
    setCombinedData(resultCombined)
    setCustomersData(result)
  }

  // Stat card values
  const callerStats = [
    {
      id: 0,
      value: callerFamily,
      text: props.t("family"),
      series: toPercentage(callerFamily),
    },
    {
      id: 1,
      value: callerOther,
      text: props.t("professional"),
      series: toPercentage(callerOther),
    },
  ]

  function toPercentage(value) {
    if (value != 0) {
      var result = Math.round((value / total) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  function toPercentage(value) {
    if (value != 0) {
      var result = Math.round((value / total) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  // Function for formatting seconds -> hours, minutes, seconds
  const formatTime = value => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - minutes * 60
    let time = ""

    if (value == 0) {
      time = "-"
    }
    if (value != 0) {
      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }
      time = hours + ":" + minutes + ":" + seconds
    }

    return time
  }

  // Calls time
  const timeStats = [
    {
      id: 0,
      value: durationA,
      text: props.t("Average"),
      series: formatTime(durationA),
    },
    {
      id: 1,
      value: durationM,
      text: props.t("Median"),
      series: formatTime(durationM),
    },
  ]

  const settingFilteredList = (c, type) => {
    console.log(type, c, "filter")
    if (type === "main") {
      let values = ""
      let fileteredlist = []

      if (c.includes(props.t("Video"))) {
        console.log("Video")
        values = "video"
      }
      if (c.includes(props.t("Group video participations"))) {
        console.log("Group video call")
        values = "group"
      }
      if (c.includes(props.t("Tracker"))) {
        values = "tracker"
      }
      if (c.includes(props.t("Safety phone"))) {
        values = "carephone"
      }
      if (c.includes(props.t("Phone"))) {
        values = "phone"
      }
      let type = "Main"
      if (values != undefined || list !== undefined) {
        let title = values
        list.filter(data => {
          if (data.type == title) {
            fileteredlist.push(data)
            setFilteredList(filteredList => [...filteredList, data])
          }
        })
        // }
      }
      setFilteredList(fileteredlist)
      handlingCategory(type, fileteredlist, c)
    }
  }

  const settingSubject = (title, type) => {
    setSubject(title)
    settingFilteredList(title, type)
    // TODO: Set not-chosen if two same in one array
    // setCategory(category => [...category, title])
  }
  // Chart
  const options = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          settingSubject(config.w.config.labels[config.dataPointIndex], "main")
        },
      },
    },

    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "alarms-donut",
    labels: [
      props.t("Video"),
      props.t("Group video call"),
      props.t("Tracker"),
      props.t("Safety phone"),
      props.t("Phone"),
    ],
    colors: ["#224465", "#48d0f8", "#8ab469", "#f5d663", "#f5a14a", "#ca7657"],
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "15px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("All calls"),
              fontSize: "15px",
              color: "#00000063",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
            },
          },
        },
      },
    },
  }

  const title = props.t("Group video calls")
  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Period of time"),
        props.t("Call type"),
        props.t("Group"),
        props.t("Name"),
        props.t("Relative calls"),
        props.t("Professional calls"),
        props.t("Total"),
      ],
    ]
    const data = customersData.map(elt => [
      elt.time,
      elt.type,
      elt.group,
      elt.name,
      elt.relativeCalls,
      elt.amount - elt.relativeCalls,
      elt.amount,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(
      wb,
      title + " " + formatDate(timeStart) + "-" + formatDate(timeEnd) + ".xlsx"
    )

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  const styleTableResult = r => {
    if (r > 0) {
      return r
    } else {
      return ""
    }
  }

  // TEST
  const downloadPNG = chartId => {
    // var chart1 = new ApexCharts(document.querySelector("#chart1"), options1);
    // const chartInstance = window.Apex._chartInstances.find(
    //   chart => chart.id === chartId
    // )
    const chart = window.ApexCharts.getChartByID(options.chart.id)
    const base64 = chartInstance.chart.dataURI()
    return base64

    // const chartInstance = window.Apex._chartInstances.find(
    //   chart => chart.id === chartId
    // )

    // if (datatype === "png") {
    //   chartInstance.chart.exports.exportToPng()
    // } else if (datatype === "svg") {
    //   chartInstance.chart.exports.exportToSVG()
    // }
  }

  return (
    <React.Fragment>
      <div id="calls-chart">
        <Row className=" mt-0 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-0">{props.t("Group video calls")}</h5>
            </span>
          </div>
        </Row>
        {stats != [] && (
          <>
            <Row>
              {/* All calls */}
              <Col lg="5" className="">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <div className="d-flex ">
                      <div className="avatar-md mt-2 ms-0">
                        <div className="avatar-title bg-light rounded-circle text-primary h1">
                          <i className="bx bx-group"></i>
                        </div>
                      </div>
                      <div className="ms-4 mt-2">
                        <p className="text-muted font-size-14 mb-2">
                          {props.t("Total group video calls")}
                        </p>
                        <h2 className="mb-0">{total}</h2>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg="5">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <CardTitle>{props.t("Contact type")}</CardTitle>
                    <div className="mt-3 mb-1">
                      <Progress
                        multi
                        className="progress-xl progress font-size-12"
                      >
                        <Progress
                          bar
                          color="primary"
                          className="progress-xl"
                          value={callerStats[0].series}
                        >
                          <span className="">{callerStats[0].series}%</span>
                        </Progress>
                        <Progress
                          bar
                          color="statusbar-suvantoblue"
                          className="progress-xl font-size-12"
                          value={callerStats[1].series}
                        >
                          <span className="">{callerStats[1].series}%</span>
                        </Progress>
                      </Progress>
                      <Row className="mt-2 font-size-14">
                        <Col xs="6">
                          <i className="fas fa-circle text-primary"></i>
                          <span className="ms-2 ">
                            {props.t("Relatives")} {callerStats[0].value}
                          </span>
                        </Col>
                        <Col xs="6">
                          <i className="fas fa-circle text-statusbar-suvantoblue ms-3"></i>
                          <span className="ms-2 ">
                            {props.t("Professional")} {callerStats[1].value}
                          </span>
                          <span id={callerStats.id + "_info"}>
                            <i className="bx bx-info-circle ms-2 font-size-12 text-muted me-2" />
                          </span>
                          <UncontrolledTooltip
                            placement="right"
                            target={callerStats.id + "_info"}
                          >
                            {props.t("Nurses and other professionals")}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
              {/* Duration of calls */}
              <Col lg="7">
                <Card style={{ height: 127 + "px" }}>
                  <CardBody>
                    <Col lg="12">
                      <Row>
                        <Col xs="2">
                          <div className="avatar-md mt-2 ms-0">
                            <div className="avatar-title bg-light rounded-circle text-primary h1">
                              <i className="bx bx-time"></i>
                            </div>
                          </div>
                        </Col>
                        <Col xs="9">
                          <CardTitle>
                            {props.t("Group call duration")}
                          </CardTitle>
                          <Row>
                            {timeStats.map(time => (
                              <Col xs="6" key={time.id + "_col"}>
                                <div className="mb-2 mt-1 text-muted">
                                  {time.text}
                                </div>
                                <Row>
                                  <Col xs="6">
                                    <div className="font-size-14 h5">
                                      {time.series}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

ChartCalls.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withTranslation()(ChartCalls)
