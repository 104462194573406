/***********************
 * Theme switch
 ************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import themes from "common/themes"
import { get, map } from "lodash"
import { useTheme } from "contexts/ThemeProvider"

const ThemeDropdown = props => {
  const { theme, toggleTheme } = useTheme()
  const [menu, setMenu] = useState(false)
  const [TTthemebottom, setTTthemebottom] = useState(false)

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
        tag="li"
      >
        <Tooltip
          placement="bottom"
          isOpen={TTthemebottom}
          target="TTtheme"
          toggle={() => {
            setTTthemebottom(!TTthemebottom)
          }}
        >
          {props.t("Theme")}
        </Tooltip>
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="TTtheme"
        >
          <i id="" className="bx bx-palette align-middle" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu ">
          {map(Object.keys(themes), key => (
            <DropdownItem
              key={key}
              onClick={() => toggleTheme(key)}
              className={`notify-item ${theme === key ? "active" : "none"}`}
            >
              {/* <img
                src={get(themes, `${key}.icon`)}
                alt="Theme"
                className="me-2"
                height="12"
              /> */}
              {get(themes, `${key}.label`) == "ThemeLight" ? (
                <i className="bx bx-sun ms-0 me-2 font-size-14"></i>
              ) : (
                <i className="bx bx-moon ms-0 me-2 font-size-14"></i>
              )}
              {/* <i className="bx bx-sun ms-0 me-2 font-size-14"></i> */}
              <span className="align-middle">
                {props.t(get(themes, `${key}.label`))}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

ThemeDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ThemeDropdown)
