import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { Link } from "react-router-dom"

//import "./map.css"

const Map = props => {
  const [lat, setLat] = useState(props.lat)
  const [lng, setLng] = useState(props.lng)
  const center = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng])

  useEffect(() => {
    setLat(props.lat)
    setLng(props.lng)
    console.log(props.lat, "lat", props.lng, "lng")
  }, [props.lng, props.lat])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  })
  if (!isLoaded)
    return (
      <div className="text-center py-5 my-3">
        <Link to="#" className="text-primary">
          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        </Link>
      </div>
    )

  // Open selected location in new tab
  const openGoogleMap = () => {
    const win = window.open(
      "https://www.google.com/maps/place/" + lat + "," + lng,
      "_blank"
    )
    if (win != null) {
      win.focus()
    }
  }

  return (
    <GoogleMap
      zoom={props.zoom}
      center={center}
      mapContainerClassName="google-map"
    >
      <Marker
        position={{ lat: lat, lng: lng }}
        onClick={() => {
          openGoogleMap()
        }}
      >
        {/* <InfoWindow
          position={{ lat: lat, lng: lng }}
          options={{ maxWidth: 100 }}
        >
          <div>
            <span></span>
          </div> 
        </InfoWindow>*/}
      </Marker>
    </GoogleMap>
  )
}

Map.propTypes = {
  t: PropTypes.any,
  lat: PropTypes.any,
  lng: PropTypes.any,
  zoom: PropTypes.any,
}

export default Map
