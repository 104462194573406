import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getGroups } from "../../helpers/backend_helper"
import { Select, ConfigProvider } from "antd"

const SelectGroup = props => {
  const [options, setOptions] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    getData()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.updateCounter > 0) {
      getData()
    }
  }, [props.updateCounter])

  // Get groups
  const getData = () => {
    getGroups(abortRef.current)
      .then(result => {
        if (result.statusCode === 200 && result.entries) {
          console.log(result, "groups")
          //let selection = null
          let opts = []
          let counter = 1
          result.entries.forEach(group => {
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = group.name
            opts.push(groupObject)
          })
          setOptions(opts)
        } else {
          setOptions([])
          handleChange(null)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Group selection changed
  const handleChange = newSelection => {
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No groups found")}</p>
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            disabled={props.disabled}
            value={props.selection}
            mode={props.multiple ? "multiple" : null}
            allowClear={props.clearable != null ? props.clearable : true}
            placeholder={
              props.multiple
                ? props.t("Select groups")
                : props.t("Select group")
            }
            style={{ width: 100 + "%" }}
            onChange={handleChange}
            options={options}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectGroup.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  updateCounter: PropTypes.any,
  disabled: PropTypes.bool,
}

export default withTranslation()(SelectGroup)
