import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import {
  addOrUpdateAlarmSetting,
  deleteAlarmSetting,
  getAlarmSettings,
  getNodesList,
} from "helpers/backend_helper"
import { showToast } from "utils/general"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import Lottie from "react-lottie"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Select, Switch } from "antd"
import PubSub from "pubsub-js"

const AlarmSettingsConfig = props => {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [data, setData] = useState([])
  const [nodes, setNodes] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const [nodeOptions, setNodeOptions] = useState([])
  const [selectedSetting, setSelectedSetting] = useState(null)
  const [isMenu, setIsMenu] = useState({})
  const [fields, setFields] = useState({
    hours: false,
    nodes: false,
    measure: false,
    interval: false,
    threshold: false,
    limits: false,
    checkHour: false,
  })
  const [nodeStats, setNodeStats] = useState({
    average: 0,
    previous: 0,
    low: 0,
    high: 0,
  })

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getSettings()
      getNodes()
    })
    formatTypeOptions()
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.suvantoKey) {
      getSettings()
      getNodes()
    }
  }, [props.suvantoKey])

  useEffect(() => {
    if (!createModal) validation.resetForm()
  }, [createModal])

  // Format rule type select options
  const formatTypeOptions = () => {
    let homeOpts = [
      { label: props.t("Prolonged outdoor activity"), value: "HOME_RETURN" },
      { label: props.t("Leaving home"), value: "HOME_LEFT" },
      { label: props.t("Door is left open"), value: "DOOR_OPEN" },
      { label: props.t("AlarmTitles.54"), value: "DOOR_OPENED" },
      { label: props.t("Abnormal usage amount"), value: "NODE_USAGE" },
      { label: props.t("Inactivity"), value: "INACTIVITY" },
      { label: props.t("Rest time activity"), value: "NIGHT_ACTIVITY" },
      { label: props.t("Abnormal humidity"), value: "HUMIDITY" },
      { label: props.t("Abnormal temperature"), value: "TEMPERATURE" },
      { label: props.t("Unusual operating time"), value: "ABNORMAL_USE" },
      {
        label: props.t("Prolonged presence in a space"),
        value: "EXCESSIVE_STAY",
      },
      { label: props.t("Excessive activity"), value: "EXCESSIVE_ACTIVITY" },
    ]
    homeOpts.sort((a, b) => (a.label > b.label ? 1 : -1))

    let measureOpts = [
      { label: props.t("Abnormal measurement"), value: "ABNORMAL_MEASUREMENT" },
      { label: props.t("Missed measurement"), value: "MEASUREMENT_MISSED" },
    ]
    measureOpts.sort((a, b) => (a.label > b.label ? 1 : -1))

    let opts = [
      {
        label: props.t("Home"),
        options: homeOpts,
      },
      {
        label: props.t("Measurements"),
        options: measureOpts,
      },
    ]
    setTypeOptions(opts)
  }

  // Get alarm settings
  const getSettings = () => {
    if (!loading) {
      setLoading(true)
      getAlarmSettings(abortRef.current, props.suvantoKey)
        .then(result => {
          if (result.statusCode == 200 && result.entries) {
            result.entries.forEach(element => {
              element.description = getDescription(element.type)
              element.translated_desc = props.t(element.description)
              if (element.type == "DOOR_OPEN") element.alarmThreshold = 15

              if (element.endingHour == 0) element.endingHour = 24
              if (element.startingHour == undefined) element.startingHour = 0
              if (element.endingHour == undefined) element.endingHour = 24
              element.timePeriod =
                element.startingHour + ":00 - " + element.endingHour + ":00"
              element.time = element.checkHourUTC + ":00"

              // Set node keys into array
              element.nodes = []
              if (element.nodeList) {
                let tempArray = element.nodeList.split(",")
                tempArray.forEach(nodeKey => {
                  if (nodeKey) element.nodes.push(nodeKey)
                })
              }
            })

            result.entries.sort((a, b) =>
              a.translated_desc > b.translated_desc ? 1 : -1
            )
            setData(result.entries)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get nodes
  const getNodes = () => {
    if (props.gatewayKey) {
      getNodesList(abortRef.current, props.gatewayKey).then(result => {
        if (result.statusCode == 200 && result.entries) {
          // Filter unconfigured nodes
          let configured = result.entries.filter(
            data => data.configured == true
          )
          setNodes(configured)
          //console.log("NODES: ", configured)
        }
      })
    }
  }

  // Delete
  const deleteSetting = () => {
    if (selectedSetting && selectedSetting.key) {
      //console.log("Delete: ", selectedSetting.key)
      setDeleting(true)
      deleteAlarmSetting(abortRef.current, selectedSetting.key)
        .then(result => {
          if (result.statusCode == 200) {
            setDeleteModal(false)
            setSelectedSetting(null)
            getSettings()
            showToast(props.t("Deleted"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setDeleting(false)
        })
    }
  }

  // Add or update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      key: selectedSetting?.key,
      type: selectedSetting?.type,
      enabled: selectedSetting?.enabled,
      startingHour: selectedSetting?.startingHour || 0,
      endingHour: selectedSetting?.endingHour || 24,
      checkHourUTC: selectedSetting?.checkHourUTC || 12,
      measureType: selectedSetting?.measureType || "temperature",
      alarmThreshold: selectedSetting?.alarmThreshold || 3,
      lowerThreshold: selectedSetting?.lowerThreshold || 0,
      upperThreshold: selectedSetting?.upperThreshold || 100,
      checkNextDay: selectedSetting?.checkNextDay || false,
      interval: selectedSetting?.interval || 3,
      nodes: selectedSetting?.nodes || [],
      nodeKey: selectedSetting?.nodeKey || null,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      if (values.type) {
        let temp = new Object()
        if (values.key) temp.key = values.key // Add key to update existing entry
        temp.type = values.type
        temp.enabled = values.enabled
        temp.measureType = values.measureType
        temp.alarmThreshold = parseInt(values.alarmThreshold, 10)
        temp.lowerThreshold = parseInt(values.lowerThreshold, 10)
        temp.upperThreshold = parseInt(values.upperThreshold, 10)

        temp.startingHour = parseInt(values.startingHour, 10)
        temp.endingHour = parseInt(values.endingHour, 10)
        temp.checkHourUTC = parseInt(values.checkHourUTC, 10)

        temp.interval = parseInt(values.interval, 10)
        temp.checkNextDay = values.checkNextDay

        if (values.type == "NODE_USAGE") temp.nodeKey = values.nodeKey
        else temp.nodeList = values.nodes.toString()

        setUpdating(true)
        addOrUpdateAlarmSetting(abortRef.current, props.suvantoKey, temp)
          .then(result => {
            if (result.statusCode == 200) {
              getSettings()
              if (temp.key) showToast(props.t("Updated"), "success")
              else showToast(props.t("New alarm rule added"), "success")
              setCreateModal(false)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
            validation.resetForm()
          })
      }
    },
  })

  // Type changed, set visible node options
  useEffect(() => {
    let filtered = []
    switch (validation.values.type) {
      case "INACTIVITY":
      case "EXCESSIVE_STAY":
      case "EXCESSIVE_ACTIVITY":
        filtered = nodes.filter(data => data.deviceType == "Activity")
        break
      case "DOOR_OPEN":
      case "DOOR_OPENED":
        filtered = nodes.filter(data => data.deviceType == "Door")
        break
      case "ABNORMAL_USE":
      case "NODE_USAGE":
        filtered = nodes.filter(
          data =>
            data.deviceType == "Door" ||
            data.deviceType == "Electric" ||
            (data.trackingTarget == "WC" && data.deviceType == "Activity")
        )
        break
      case "TEMPERATURE":
      case "HUMIDITY":
        filtered = nodes.filter(
          data =>
            data.deviceType == "Door" ||
            data.deviceType == "Bed" ||
            data.deviceType == "Activity"
        )
        break
    }

    let opts = []
    filtered.forEach(element => {
      let e = { label: element.description, value: element.key }
      opts.push(e)
    })

    opts.sort((a, b) => (a.label > b.label ? 1 : -1))
    setNodeOptions(opts)
  }, [validation.values.type])

  // Type changed, set initial values for new alarm setup
  useEffect(() => {
    if (!validation.values.key) {
      validation.setFieldValue("nodes", [])
      validation.setFieldValue("nodeKey", null)
      switch (validation.values.type) {
        case "HOME_LEFT":
          validation.setFieldValue("alarmThreshold", 10)
          break
        case "HOME_RETURN":
          validation.setFieldValue("alarmThreshold", 120)
          break
        case "ABNORMAL_MEASUREMENT":
          validation.setFieldValue("alarmThreshold", 3)
          break
        default:
          validation.setFieldValue("alarmThreshold", 60)
          break
      }
    }
  }, [validation.values.type])

  // Type changed, set which fields are visible
  useEffect(() => {
    switch (validation.values.type) {
      case "ABNORMAL_MEASUREMENT":
        setFields({
          hours: false,
          measure: true,
          nodes: false,
          interval: true,
          threshold: true,
          limits: true,
          checkHour: false,
        })
        break
      case "MEASUREMENT_MISSED":
        setFields({
          hours: true,
          measure: true,
          nodes: false,
          interval: false,
          threshold: false,
          limits: false,
          checkHour: false,
        })
        break
      case "TEMPERATURE":
      case "HUMIDITY":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: false,
          limits: true,
          checkHour: false,
        })
        break
      case "HOME_LEFT":
        setFields({
          hours: true,
          measure: false,
          nodes: false,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      case "DOOR_OPEN":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: false,
          limits: false,
          checkHour: false,
        })
        break
      case "DOOR_OPENED":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: false,
          limits: false,
          checkHour: false,
        })
        break
      case "HOME_RETURN":
        setFields({
          hours: true,
          measure: false,
          nodes: false,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      case "NODE_USAGE":
        setFields({
          hours: false,
          measure: false,
          nodes: true,
          interval: false,
          threshold: false,
          limits: true,
          checkHour: true,
        })
        break
      case "ABNORMAL_USE":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: false,
          limits: false,
          checkHour: false,
        })
        break
      case "EXCESSIVE_STAY":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      case "INACTIVITY":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      case "NIGHT_ACTIVITY":
        setFields({
          hours: true,
          measure: false,
          nodes: false,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      case "EXCESSIVE_ACTIVITY":
        setFields({
          hours: true,
          measure: false,
          nodes: true,
          interval: false,
          threshold: true,
          limits: false,
          checkHour: false,
        })
        break
      default:
        setFields({
          hours: false,
          measure: false,
          nodes: false,
          interval: false,
          threshold: false,
          limits: false,
          checkHour: false,
        })
        break
    }
  }, [validation.values.type])

  // Selected node changed (node usage rule), set stats
  useEffect(() => {
    if (validation.values.nodeKey) {
      setNodeStats({ average: 0, previous: 0, low: 0, high: 0 })
      nodes.forEach(element => {
        if (element.key == validation.values.nodeKey) {
          setNodeStats({
            average: element.averageUseCount,
            previous: element.previousUseCount,
            low: element.useCountLow,
            high: element.useCountHigh,
          })
        }
      })
    }
  }, [validation.values.nodeKey])

  // Add new selection
  const addNewSelection = () => {
    var newEntry = new Object()
    newEntry.key = null
    newEntry.type = null
    newEntry.enabled = true
    newEntry.startingHour = 0
    newEntry.endingHour = 24
    newEntry.checkHourUTC = 12
    newEntry.measureType = "temperature"
    newEntry.alarmThreshold = 3
    newEntry.lowerThreshold = 0
    newEntry.upperThreshold = 100
    newEntry.interval = 3
    newEntry.checkNextDay = false
    newEntry.nodes = []
    newEntry.nodeKey = null
    setSelectedSetting(newEntry)
    setCreateModal(true)
  }

  const getDescription = type => {
    switch (type) {
      case "ABNORMAL_MEASUREMENT":
        return "Abnormal measurement"
      case "MEASUREMENT_MISSED":
        return "Missed measurement"
      case "NODE_USAGE":
        return "Abnormal usage amount"
      case "INACTIVITY":
        return "Inactivity"
      case "HOME_LEFT":
        return "Leaving home"
      case "HOME_RETURN":
        return "Prolonged outdoor activity"
      case "NIGHT_ACTIVITY":
        return "Rest time activity"
      case "HUMIDITY":
        return "Abnormal humidity"
      case "TEMPERATURE":
        return "Abnormal temperature"
      case "ABNORMAL_USE":
        return "Unusual operating time"
      case "EXCESSIVE_STAY":
        return "Prolonged presence in a space"
      case "EXCESSIVE_ACTIVITY":
        return "Excessive activity"
      case "DOOR_OPEN":
        return "Door is left open"
      case "DOOR_OPENED":
        return "AlarmTitles.54"
      default:
        return type
    }
  }

  // Toggle dropdown menu
  const toggleMenu = e => {
    setIsMenu(!isMenu)
    if (!isMenu[e]) {
      setIsMenu({ [e]: true })
    } else {
      setIsMenu({})
    }
  }

  return (
    <>
      <div>
        <Row>
          <Col></Col>
          <Col className="col-auto">
            <Button
              color="success"
              disabled={loading}
              onClick={() => {
                addNewSelection()
              }}
            >
              <i className="mdi mdi-plus me-2" />
              {props.t("Create a new rule")}
            </Button>
          </Col>
        </Row>
        <div className="mt-3">
          {loading && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingAnim,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={100}
              width={100}
            />
          )}

          {!loading && data.length == 0 && (
            <Card>
              <CardBody>
                <div className="text-center mb-4">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: nodataAnim,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={150}
                    width={150}
                  />
                  <h5 className="text-muted mt-2">
                    {props.t("No alarm rules found")}
                  </h5>
                </div>
              </CardBody>
            </Card>
          )}

          {!loading && data.length > 0 && (
            <>
              <div className="font-size-12 h6 text-muted ms-3 mt-2 me-3">
                <Row>
                  <Col className="text-uppercase" xs="4">
                    {props.t("Type")}
                  </Col>
                  <Col className="text-uppercase" xs="3">
                    {props.t("Time") + " / " + props.t("Time period")}
                  </Col>
                  <Col className="text-uppercase" xs="3">
                    {props.t("Alarm thresholds")}
                  </Col>
                  <Col xs={2}></Col>
                </Row>
              </div>

              <Row xs={1} lg={1}>
                {data.map((item, index) => (
                  <Col key={index}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={4}>
                            <>
                              <div className="h6 text-muted d-md-flex">
                                <div className="text-primary me-2">
                                  {props.t(item.description)}
                                </div>
                                {!item.enabled && (
                                  <div className="badge badge-soft-danger font-size-12">
                                    {props.t("Disabled")}
                                  </div>
                                )}
                              </div>
                              {(item.type == "MEASUREMENT_MISSED" ||
                                item.type == "ABNORMAL_MEASUREMENT") && (
                                <div>
                                  <i className="mdi mdi-heart-outline me-2"></i>
                                  {props.t(item.measureType)}
                                </div>
                              )}
                              {(item.type == "TEMPERATURE" ||
                                item.type == "HUMIDITY" ||
                                item.type == "NODE_USAGE" ||
                                item.type == "ABNORMAL_USE" ||
                                item.type == "EXCESSIVE_STAY" ||
                                item.type == "DOOR_OPEN" ||
                                item.type == "DOOR_OPENED" ||
                                item.type == "INACTIVITY" ||
                                item.type == "EXCESSIVE_ACTIVITY") && (
                                <div>
                                  <i className="mdi mdi-router-wireless me-2"></i>
                                  {item.nodeNames}
                                </div>
                              )}
                            </>
                          </Col>
                          <Col xs={3}>
                            <>
                              {(item.type == "MEASUREMENT_MISSED" ||
                                item.type == "TEMPERATURE" ||
                                item.type == "HUMIDITY" ||
                                item.type == "HOME_LEFT" ||
                                item.type == "ABNORMAL_USE" ||
                                item.type == "EXCESSIVE_STAY" ||
                                item.type == "HOME_RETURN" ||
                                item.type == "DOOR_OPEN" ||
                                item.type == "DOOR_OPENED" ||
                                item.type == "INACTIVITY" ||
                                item.type == "NIGHT_ACTIVITY" ||
                                item.type == "EXCESSIVE_ACTIVITY") && (
                                <div>
                                  <i className="mdi mdi-clock-outline me-2"></i>
                                  {item.timePeriod}
                                </div>
                              )}
                              {item.type == "NODE_USAGE" && (
                                <div>
                                  <i className="mdi mdi-clock-outline me-2"></i>
                                  {item.time}
                                </div>
                              )}
                              {item.type == "ABNORMAL_MEASUREMENT" && (
                                <div>
                                  <i className="mdi mdi-calendar-end me-2"></i>
                                  {item.interval + " " + props.t("days")}
                                </div>
                              )}
                            </>
                          </Col>
                          <Col xs={3}>
                            <>
                              {item.type == "ABNORMAL_MEASUREMENT" && (
                                <div>
                                  <i className="mdi mdi-counter me-2"></i>
                                  {item.alarmThreshold + " " + props.t("times")}
                                </div>
                              )}
                              {(item.type == "TEMPERATURE" ||
                                item.type == "HUMIDITY" ||
                                item.type == "NODE_USAGE" ||
                                item.type == "ABNORMAL_MEASUREMENT") && (
                                <>
                                  <div>
                                    <i className="mdi mdi-chevron-up-circle-outline me-2"></i>
                                    {props.t("over") +
                                      " " +
                                      item.upperThreshold}
                                  </div>
                                  <div>
                                    <i className="mdi mdi-chevron-down-circle-outline me-2"></i>
                                    {props.t("under") +
                                      " " +
                                      item.lowerThreshold}
                                  </div>
                                </>
                              )}
                              {(item.type == "HOME_LEFT" ||
                                item.type == "EXCESSIVE_STAY" ||
                                item.type == "HOME_RETURN" ||
                                item.type == "DOOR_OPEN" ||
                                item.type == "INACTIVITY" ||
                                item.type == "NIGHT_ACTIVITY" ||
                                item.type == "EXCESSIVE_ACTIVITY") && (
                                <div>
                                  <i className="mdi mdi-timelapse me-2"></i>
                                  {item.alarmThreshold + "min"}
                                </div>
                              )}
                            </>
                          </Col>
                          <Col xs={2}>
                            <div className="float-end">
                              <Dropdown
                                isOpen={isMenu[item.key]}
                                toggle={() => toggleMenu(item.key)}
                              >
                                <DropdownToggle
                                  type="button"
                                  tag="button"
                                  className="btn btn-light"
                                >
                                  <i className="mdi mdi-cog me-1" />
                                  <span className="d-none d-sm-inline-block font-size-12">
                                    <i className="mdi mdi-chevron-down" />
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedSetting(item)
                                      setCreateModal(true)
                                    }}
                                  >
                                    {props.t("Edit")}
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                      setSelectedSetting(item)
                                      setDeleteModal(true)
                                    }}
                                  >
                                    {props.t("Delete")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
        <Modal
          isOpen={createModal}
          toggle={() => {
            setCreateModal(!createModal)
          }}
          centered
        >
          <ModalHeader>
            {validation.values.key
              ? props.t("Modify rule")
              : props.t("Create a new rule")}
          </ModalHeader>
          <ModalBody>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row xs={1}>
                <Col>
                  <FormGroup>
                    <Select
                      defaultValue={validation.values.type}
                      disabled={validation.values.key}
                      placeholder={props.t("Select type")}
                      style={{ width: 100 + "%" }}
                      onChange={v => {
                        validation.setFieldValue("type", v)
                      }}
                      options={typeOptions}
                    />
                  </FormGroup>
                </Col>
                {validation.values.type && (
                  <Col>
                    <Alert color={"primary"}>
                      <Row>
                        <Col
                          className="col-auto"
                          style={{ alignSelf: "center" }}
                        >
                          <i className={"mdi mdi-information font-size-20"}></i>
                        </Col>
                        <Col style={{ alignSelf: "center" }}>
                          <span>
                            {props.t(
                              "AlarmRuleDescriptions." + validation.values.type
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                )}
              </Row>

              {validation.values.type != null && (
                <>
                  {fields.nodes == true &&
                    validation.values.type != "NODE_USAGE" && (
                      <Row xs={1}>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Nodes")}</Label>
                            <Select
                              mode={"multiple"}
                              allowClear
                              placeholder={props.t("Select nodes")}
                              style={{ width: 100 + "%" }}
                              onChange={v => {
                                validation.setFieldValue("nodes", v)
                              }}
                              value={validation.values.nodes}
                              options={nodeOptions}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  {fields.nodes == true &&
                    validation.values.type == "NODE_USAGE" && (
                      <Row xs={1}>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Node")}</Label>
                            <Select
                              allowClear
                              placeholder={props.t("Select node")}
                              style={{ width: 100 + "%" }}
                              onChange={v => {
                                validation.setFieldValue("nodeKey", v)
                              }}
                              value={validation.values.nodeKey}
                              options={nodeOptions}
                            />
                          </FormGroup>
                        </Col>
                        {validation.values.nodeKey && (
                          <Col>
                            <Card>
                              <CardBody>
                                <Row xs={2}>
                                  <Col>
                                    <p>
                                      {props.t("Average") +
                                        ": " +
                                        nodeStats.average}
                                    </p>
                                  </Col>
                                  <Col>
                                    <p>
                                      {props.t("Previous") +
                                        ": " +
                                        nodeStats.previous}
                                    </p>
                                  </Col>
                                  <Col>
                                    <span>
                                      {props.t("Lowest") + ": " + nodeStats.low}
                                    </span>
                                  </Col>
                                  <Col>
                                    <span>
                                      {props.t("Highest") +
                                        ": " +
                                        nodeStats.high}
                                    </span>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                      </Row>
                    )}

                  <Row xs={2}>
                    {fields.hours == true && (
                      <>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Start time")}</Label>
                            <Input
                              bsSize="sm"
                              name="startingHour"
                              type="select"
                              value={validation.values.startingHour}
                              onChange={validation.handleChange}
                            >
                              <option value={0}>{"00:00"}</option>
                              <option value={1}>{"01:00"}</option>
                              <option value={2}>{"02:00"}</option>
                              <option value={3}>{"03:00"}</option>
                              <option value={4}>{"04:00"}</option>
                              <option value={5}>{"05:00"}</option>
                              <option value={6}>{"06:00"}</option>
                              <option value={7}>{"07:00"}</option>
                              <option value={8}>{"08:00"}</option>
                              <option value={9}>{"09:00"}</option>
                              <option value={10}>{"10:00"}</option>
                              <option value={11}>{"11:00"}</option>
                              <option value={12}>{"12:00"}</option>
                              <option value={13}>{"13:00"}</option>
                              <option value={14}>{"14:00"}</option>
                              <option value={15}>{"15:00"}</option>
                              <option value={16}>{"16:00"}</option>
                              <option value={17}>{"17:00"}</option>
                              <option value={18}>{"18:00"}</option>
                              <option value={19}>{"19:00"}</option>
                              <option value={20}>{"20:00"}</option>
                              <option value={21}>{"21:00"}</option>
                              <option value={22}>{"22:00"}</option>
                              <option value={23}>{"23:00"}</option>
                              <option value={24}>{"24:00"}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("End time")}</Label>
                            <Input
                              bsSize="sm"
                              name="endingHour"
                              type="select"
                              value={validation.values.endingHour}
                              onChange={validation.handleChange}
                            >
                              <option value={0}>{"00:00"}</option>
                              <option value={1}>{"01:00"}</option>
                              <option value={2}>{"02:00"}</option>
                              <option value={3}>{"03:00"}</option>
                              <option value={4}>{"04:00"}</option>
                              <option value={5}>{"05:00"}</option>
                              <option value={6}>{"06:00"}</option>
                              <option value={7}>{"07:00"}</option>
                              <option value={8}>{"08:00"}</option>
                              <option value={9}>{"09:00"}</option>
                              <option value={10}>{"10:00"}</option>
                              <option value={11}>{"11:00"}</option>
                              <option value={12}>{"12:00"}</option>
                              <option value={13}>{"13:00"}</option>
                              <option value={14}>{"14:00"}</option>
                              <option value={15}>{"15:00"}</option>
                              <option value={16}>{"16:00"}</option>
                              <option value={17}>{"17:00"}</option>
                              <option value={18}>{"18:00"}</option>
                              <option value={19}>{"19:00"}</option>
                              <option value={20}>{"20:00"}</option>
                              <option value={21}>{"21:00"}</option>
                              <option value={22}>{"22:00"}</option>
                              <option value={23}>{"23:00"}</option>
                              <option value={24}>{"24:00"}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    {fields.measure == true && (
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Measurement type")}</Label>
                          <Input
                            bsSize="sm"
                            name="measureType"
                            type="select"
                            value={validation.values.measureType}
                            onChange={validation.handleChange}
                          >
                            <option value="weight">{props.t("Weight")}</option>
                            <option value="temperature">
                              {props.t("Temperature")}
                            </option>
                            <option value="peakExpiratoryFlow">
                              {props.t("PEF")}
                            </option>
                            <option value="pulse">{props.t("Pulse")}</option>
                            <option value="oxygenSaturation">
                              {props.t("Oxygen saturation")}
                            </option>
                            <option value="bloodSugar">
                              {props.t("Blood sugar")}
                            </option>
                            {validation.values.type == "MEASUREMENT_MISSED" && (
                              <option value="bloodPressure">
                                {props.t("Blood pressure")}
                              </option>
                            )}
                            {validation.values.type ==
                              "ABNORMAL_MEASUREMENT" && (
                              <>
                                <option value="systolicBloodPressure">
                                  {props.t("systolicBloodPressure")}
                                </option>
                                <option value="diastolicBloodPressure">
                                  {props.t("diastolicBloodPressure")}
                                </option>
                              </>
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                    )}

                    {fields.interval == true && (
                      <Col>
                        <FormGroup>
                          <Label>
                            {props.t("Interval") + ", " + props.t("days")}
                          </Label>
                          <Input
                            bsSize="sm"
                            name="interval"
                            type="number"
                            value={validation.values.interval}
                            onChange={validation.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {fields.limits == true && (
                      <>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Lower threshold")}</Label>
                            <Input
                              bsSize="sm"
                              name="lowerThreshold"
                              type="number"
                              value={validation.values.lowerThreshold}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>{props.t("Upper threshold")}</Label>
                            <Input
                              bsSize="sm"
                              name="upperThreshold"
                              type="number"
                              value={validation.values.upperThreshold}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}

                    {fields.threshold == true && (
                      <Col>
                        <FormGroup>
                          {validation.values.type == "HOME_LEFT" ? (
                            <Label>
                              {props.t("Verification interval") +
                                ", " +
                                props.t("minutes")}
                            </Label>
                          ) : validation.values.type ==
                            "ABNORMAL_MEASUREMENT" ? (
                            <Label>
                              {props.t("Number of abnormal measurements")}
                            </Label>
                          ) : (
                            <Label>
                              {props.t("Duration") + ", " + props.t("minutes")}
                            </Label>
                          )}
                          <Input
                            bsSize="sm"
                            name="alarmThreshold"
                            type="number"
                            value={validation.values.alarmThreshold}
                            onChange={validation.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {fields.checkHour == true && (
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Checked at")}</Label>
                          <Input
                            bsSize="sm"
                            name="checkHourUTC"
                            type="select"
                            value={validation.values.checkHourUTC}
                            onChange={validation.handleChange}
                          >
                            <option value={0}>{"00:00"}</option>
                            <option value={1}>{"01:00"}</option>
                            <option value={2}>{"02:00"}</option>
                            <option value={3}>{"03:00"}</option>
                            <option value={4}>{"04:00"}</option>
                            <option value={5}>{"05:00"}</option>
                            <option value={6}>{"06:00"}</option>
                            <option value={7}>{"07:00"}</option>
                            <option value={8}>{"08:00"}</option>
                            <option value={9}>{"09:00"}</option>
                            <option value={10}>{"10:00"}</option>
                            <option value={11}>{"11:00"}</option>
                            <option value={12}>{"12:00"}</option>
                            <option value={13}>{"13:00"}</option>
                            <option value={14}>{"14:00"}</option>
                            <option value={15}>{"15:00"}</option>
                            <option value={16}>{"16:00"}</option>
                            <option value={17}>{"17:00"}</option>
                            <option value={18}>{"18:00"}</option>
                            <option value={19}>{"19:00"}</option>
                            <option value={20}>{"20:00"}</option>
                            <option value={21}>{"21:00"}</option>
                            <option value={22}>{"22:00"}</option>
                            <option value={23}>{"23:00"}</option>
                            <option value={24}>{"24:00"}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                    {validation.values.type == "NODE_USAGE" && (
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Check previous day")}</Label>
                          <Switch
                            className="d-block"
                            name="checkNextDay"
                            checked={validation.values.checkNextDay}
                            onChange={v => {
                              validation.setFieldValue("checkNextDay", v)
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Enabled")}</Label>
                        <Switch
                          className="d-block"
                          name="enabled"
                          checked={validation.values.enabled}
                          onChange={v => {
                            validation.setFieldValue("enabled", v)
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => {
                setCreateModal(false)
              }}
            >
              {props.t("Cancel")}
            </Button>
            <Button
              disabled={updating}
              color="success"
              onClick={() => {
                validation.submitForm()
              }}
            >
              {validation.values.key
                ? props.t("UpdateCommand")
                : props.t("Add")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(!deleteModal)
          }}
          centered
        >
          <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
          <ModalBody>
            <p>
              {props.t(
                "Are you sure you want to delete the selected alarm rule?"
              )}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => {
                setDeleteModal(false)
              }}
            >
              {props.t("Cancel")}
            </Button>
            <Button
              disabled={deleting}
              color="danger"
              onClick={() => {
                deleteSetting()
              }}
            >
              {props.t("Delete")}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

AlarmSettingsConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  gatewayKey: PropTypes.string,
}

export default withTranslation()(AlarmSettingsConfig)
