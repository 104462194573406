import {
  getVideoClients,
  getVideoBulkMessageLogs,
} from "helpers/backend_helper"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Input,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SimpleBar from "simplebar-react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { ConfigProvider, Tree } from "antd"
import VideoMessageStatus from "./video-message-status"
import VideoMessageModal from "./video-message-modal"
import VideoMessageDevicesWindow from "./video-message-devices-window"

const VideoGroupMessage = props => {
  const [title, setTitle] = useState(props.t("Group messages"))
  const [messageStats, setMessageStats] = useState([])
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingStats, setLoadingStats] = useState(false)

  // Status categories
  const [offline, setOffline] = useState([])
  const [online, setOnline] = useState([])
  const [call, setCall] = useState([])
  const [groupCall, setGroupCall] = useState([])

  // Separeted groups
  const [groups, setGroups] = useState([])
  const [groupClients, setGroupClients] = useState([])

  // Video devices
  const [origResult, setOrigResult] = useState([])
  const [treeOptions, setTreeOptions] = useState([])

  // Selected devices
  const [groupMessage, setGroupMessage] = useState(false)
  const [groupClientsChosen, setGroupClientsChosen] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [selectedNames, setSelectedNames] = useState([])
  // Search field
  const [searchInput, setSearchInput] = useState("")

  // Result devices
  const [resultDevices, setResultDevices] = useState([])
  const abortRef = useRef(null)

  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    getClients()
    getLogs()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (groupClients.length > 0 && groups.length > 0) {
      let all = []
      let opts = []
      // Groups
      groups.forEach(g => {
        let clients = groupClients.filter(
          client => client.group === g && client.status === "online"
        ) // Online clients only
        let groupChildrenKey = []
        clients.map(client => {
          groupChildrenKey.push(client.key)
        })
        let groupValue = new Object()
        groupValue.title = g
        // groupValue.key = groupChildrenKey
        // TODO: no null
        groupValue.key = null
        groupValue.children = []

        // Clients
        clients.forEach(client => {
          let clientObject = new Object()
          clientObject.title = client.name
          clientObject.key = client.key
          clientObject.room = client.room
          // TODO set icon to implicate if online
          // clientObject.icon = <CheckCircleTwoTone />
          groupValue.children.push(clientObject)
        })

        opts.push(groupValue)
      })
      let allKeys = []
      groups.map(g => {
        allKeys.push(g.key)
      })
      all = [
        {
          // key: allKeys,
          // TODO: no null
          key: null,
          title: props.t("All"),
          children: opts,
        },
      ]
      setTreeOptions(all)
      setFilteredClients(all)
    }
  }, [groups])

  // Update clientslist
  useEffect(() => {
    if (origResult.length > 0) {
      categorizingResult(resultDevices)
    }
  }, [resultDevices])

  // Filtering clients groups
  useEffect(() => {
    //console.log(searchInput, "treeoptions")
    if (searchInput.length == 0) {
      //console.log("settingOrig", origResult)
      setResultDevices(origResult)
      setGroupClients(origResult)
    }
    if (searchInput !== "") {
      //console.log("searchInput")
      let result = []
      result = origResult.filter(
        data =>
          data.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          data.group?.toLowerCase().includes(searchInput.toLowerCase())
      )
      //console.log(result)
      setResultDevices(result)
      setGroupClients(result)
    }
  }, [searchInput, origResult])

  // Get list of video clients
  const getClients = () => {
    setIsLoading(true)
    getVideoClients(abortRef.current)
      .then(result => {
        if (result.clients) {
          //console.log(result, "result")
          setOrigResult(result.clients)

          let categorized = categorizingResult(result.clients)
          setGroupClients(categorized)
          setResultDevices(categorized)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  // Get recent message logs
  const getLogs = () => {
    setLoadingStats(true)
    getVideoBulkMessageLogs(abortRef.current)
      .then(result => {
        // console.log("bulk stats", result)
        setMessageStats(result.entries)
        setLoadingStats(false)
      })
      .catch(err => {
        console.log(err)
        setLoadingStats(false)
      })
  }

  // Categorize results based on status
  const categorizingResult = result => {
    result.forEach(client => {
      client.statusDescription = "Unknown"
      switch (client.status) {
        case "offline":
          client.statusDescription = "Unavailable"
          break
        case "online":
          client.statusDescription = "Available"
          break
        case "group":
          client.statusDescription = "Ongoing group call"
          break
        case "call":
          client.statusDescription = "Ongoing video call"
          break
      }
    })

    // Getting group names
    let groups = []
    result.map(g => groups.push(g.group))
    const withoutDuplicates = [...new Set(groups)]
    setGroups(withoutDuplicates)

    let online = []
    let offline = []
    let groupcall = []
    let call = []
    online = result.filter(c => c.status === "online")
    offline = result.filter(c => c.status === "offline")
    groupcall = result.filter(c => c.status === "group")
    call = result.filter(c => c.status === "call")
    setOnline(online)
    setOffline(offline)
    setCall(call)
    setGroupCall(groupcall)

    result.sort((a, b) => (a.name < b.name ? 1 : -1))
    result.sort((a, b) => (a.statusDescription > b.statusDescription ? 1 : -1))

    return result
  }

  // Handle search field function
  const handleSearchField = event => {
    if (treeOptions != undefined) {
      setSearchInput(event.target.value)
    }
  }

  // For testing
  const onSelect = (selectedKeys, info) => {
    // console.log("selected", selectedKeys, info)
  }

  const onCheck = (checkedKeys, info) => {
    // console.log("onCheck", checkedKeys, info)
    let keys = info.checkedNodes?.filter(n => n.key !== null)
    console.log(keys)
    let realKeys = []
    let names = []
    keys.map(k => realKeys.push(k.key))
    keys.map(k => names.push(k.title))
    //console.log("Chosen keys:", realKeys)
    setGroupClientsChosen(realKeys)
    setSelectedNames(names)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={title}
            className="breadcrumb m-0 justify-content-center"
          />
          <VideoMessageModal
            modal={modal}
            names={selectedNames}
            keys={groupClientsChosen}
            settingModal={setModal}
            updateLogs={getLogs}
          ></VideoMessageModal>
          <Row xs={1} xl={2}>
            <Col className="order-2 order-xl-1" xl="5">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">
                    {groupMessage ? (
                      <Button
                        className="float-end ms-1"
                        color="primary"
                        size="sm"
                        onClick={() => {
                          setGroupMessage(false)
                          setGroupClientsChosen([])
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    ) : (
                      <>
                        <Button
                          id="messagebutton"
                          className="float-end ms-1 mb-2"
                          color="primary"
                          size="sm"
                          disabled={groupMessage}
                          // onClick={() => setModal(!modal)}

                          onClick={() => setGroupMessage(!modal)}
                        >
                          <i className="bx bx-group me-1"></i>
                          {props.t("Choose")}
                        </Button>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="messagebutton"
                        >
                          {props.t("GroupMessageSelectInfo")}
                        </UncontrolledTooltip>
                      </>
                    )}
                    <div className="search-field px-1 float-end mt-0 pt-0">
                      <div className="box mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            style={{
                              height: "28px",
                              width: "100%",
                              fontSize: "12px",
                            }}
                            // size={"sm"}
                            type="text"
                            onChange={event => handleSearchField(event)}
                            placeholder={props.t("Search")}
                          />
                          {/* <i className="bx bx-search-alt search-icon mt-0 p-0" /> */}
                        </div>
                      </div>
                    </div>
                    {props.t("Video clients")}
                  </CardTitle>
                  {isLoading && (
                    <div className="text-center py-5">
                      <Link to="#" className="text-primary">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                        {props.t("Loading")}
                      </Link>
                    </div>
                  )}
                  <SimpleBar className="pe-3" style={{ maxHeight: "65vh" }}>
                    {!isLoading && groupClients.length > 0 && (
                      <Col lg="12">
                        {groupMessage ? (
                          <Row>
                            <ConfigProvider
                              theme={{
                                components: {
                                  Radio: {
                                    size: "small",
                                  },
                                },
                              }}
                            >
                              <div className="py-3 font-size-10">
                                <Row>
                                  <Col sm="12">
                                    <h6>
                                      <Button
                                        className="float-end ms-2"
                                        size="sm"
                                        disabled={groupClientsChosen.length == 0}
                                        onClick={() => setModal(true)}
                                      >
                                        <i className="mdi mdi-message-text-outline me-2"></i>
                                        {props.t("Write a message")} (
                                        {groupClientsChosen.length})
                                      </Button>
                                      <span className="mt-2">
                                        {props.t("GroupMessageSelectInfo")}
                                      </span>
                                    </h6>
                                  </Col>
                                  <Col sm="4"></Col>
                                </Row>
                              </div>
                            </ConfigProvider>
                            <Row>
                              <Tree
                                checkable
                                defaultExpandAll
                                onSelect={onSelect}
                                onCheck={onCheck}
                                treeData={filteredClients}
                              />
                            </Row>
                          </Row>
                        ) : (
                          <Row>
                            {online.length > 0 && (
                              <Col xs="12">
                                <VideoMessageDevicesWindow
                                  t={props.t}
                                  data={online}
                                  title="Available"
                                  groups={groups}
                                  groupInvitation={groupMessage}
                                  setGroupClientsChosen={setGroupClientsChosen}
                                  chosen={groupClientsChosen}
                                />
                              </Col>
                            )}
                            {offline.length > 0 && (
                              <Col xs="12">
                                <VideoMessageDevicesWindow
                                  t={props.t}
                                  data={offline}
                                  title="Offline"
                                  groups={groups}
                                  groupInvitation={groupMessage}
                                  setGroupClientsChosen={setGroupClientsChosen}
                                  chosen={groupClientsChosen}
                                />
                              </Col>
                            )}
                          </Row>
                        )}
                      </Col>
                    )}
                  </SimpleBar>
                  {!isLoading &&
                    (groupClients == null || groupClients.length == 0) && (
                      <div className="text-center p-4">
                        <p className="text-muted">
                          {props.t("No clients found")}
                        </p>
                      </div>
                    )}
                </CardBody>
              </Card>
            </Col>
            <Col className=" order-1 order-xl-2" xl="7">
              <Card>
                <CardBody className="pt-3 pb-0 mb-1">
                  <CardTitle>
                    <Button
                      color="clear"
                      className="p-0 text-primary float-end"
                      disabled={loadingStats}
                      onClick={() => {
                        getLogs()
                      }}
                    >
                      {props.t("Refresh")}
                      <i className="mdi mdi-refresh ms-2" />
                    </Button>
                    {props.t("Latest sent messages")}
                  </CardTitle>
                  {loadingStats ? (
                    <div className="py-5 text-center">
                      <Link to="#" className="text-primary">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                        {props.t("Loading")}
                      </Link>
                    </div>
                  ) : (
                    <SimpleBar className="pe-3 mt-3" style={{ maxHeight: "65vh" }}>
                      <VideoMessageStatus message={messageStats} />
                    </SimpleBar>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

VideoGroupMessage.propTypes = {
  t: PropTypes.any,
  handleChangeChosen: PropTypes.func,
}

export default withTranslation()(VideoGroupMessage)
