/**********************
 * SELECT IMAGE FOR VIDEO EVENT
 *********************/
import PropTypes from "prop-types"
import {
  FormGroup,
  Label,
  Button,
  Row,
  Col,
  Card,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import Dropzone from "react-dropzone"

const EventImagePicker = props => {
  const [selectedImage, setSelectedImage] = useState("")

  useEffect(() => {
    //console.log("Selected: ", props.selected)
    setSelectedImage(props.selected)
  }, [props.selected])

  // Dropzone files
  function handleAcceptedFiles(files) {
    files.forEach(file => {
      const reader = new FileReader()
      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        let dataUrl = reader.result
        let index = dataUrl.indexOf("base64,")
        if (index >= 0) {
          dataUrl = dataUrl.substring(index + 7)
        }
        setSelectedImage(dataUrl)
        if (props.selectionChanged) {
            props.selectionChanged(dataUrl)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  return (
    <>
      <FormGroup>
        <Label>{props.t("Image") + " (" + props.t("optional") + ")"}</Label>
        {selectedImage != undefined && selectedImage.length > 0 ? 
          <div className="dropzone-previews py-1" id="file-previews">
            <Card
              outline
              className={
                "py-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              }
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img 
                      className="avatar-xl rounded bg-light" 
                      style={{objectFit: "cover"}}
                      src={"data:image/jpeg;base64,"+selectedImage} 
                      alt="Event image"
                    ></img>
                  </Col>
                  <Col>
                    <Button
                      outline
                      color="danger"
                      onClick={() => {
                        setSelectedImage("")
                        if (props.selectionChanged) {
                          props.selectionChanged("")
                        }
                      }}
                    >
                      <i className="bx bx-trash me-2" />
                      <span>{props.t("Remove")}</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        :
          <>
            <Dropzone
              accept={{
                "image/jpeg": [".jpeg", ".jpg"],
              }}
              maxFiles={1}
              multiple={false}
              maxSize={524288} // 0.5MB
              onDropAccepted={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
              onDropRejected={fileRejections => {
                let errMessage = fileRejections[0].errors[0].code
                showToast(errMessage, "error")
              }}
              >
              {({ getRootProps, getInputProps }) => (
                <>
                  <div className="dropzone">
                    <div
                      className="needsclick"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="dz-message needsclick">
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h5>
                        {props.t("Drop file here or click to upload")}
                      </h5>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Dropzone>
            <p className="text-muted mt-2">
              {props.t("VideoEventImageSelectionDescription")}
            </p>
          </>
        }
      </FormGroup>
    </>
  )
}

EventImagePicker.propTypes = {
  t: PropTypes.any,
  selected: PropTypes.any,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(EventImagePicker)