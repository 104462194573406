import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer } from "antd"
import withRouter from "components/Common/withRouter"
import ClientInfo from "pages/CustomerProfile/customer-info"

const ClientDrawer = props => {
  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  return (
    <>
      <Drawer
        title={props.selectedRow?.name}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
        }}
        open={props.showDrawer}
        zIndex={1003}
        //bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        {props.selectedRow?.key && (
          <ClientInfo suvantoKey={props.selectedRow.key} />
        )}
      </Drawer>
    </>
  )
}

ClientDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getClients: PropTypes.func,
}

export default withRouter(withTranslation()(ClientDrawer))
