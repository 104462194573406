import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Chip from "@material-ui/core/Chip"
import { withTranslation } from "react-i18next"
import moment from "moment"
import withRouter from "components/Common/withRouter"
import { Button, Card } from "reactstrap"
import { Popconfirm } from "antd"

const ErrorBulletinsItem = props => {
  const [example, setExample] = useState()
  //Chips
  // const [displayedNames, setDisplayedNames] = useState([])
  // const [remainingNames, setRemainingNames] = useState([])
  const [item, setItem] = useState()

  useEffect(() => {
    if (props.item) {
      setItem(props.item)
      handleExample(props.item)
    }
  }, [props.item])

  // Handle preview
  const handleExample = bulletin => {
    let niceDate = moment(bulletin.start_time).format("DD.MM.YYYY HH:mm")
    if (bulletin.end_time) {
      var endDate = new Date(bulletin.end_time)
      if (endDate > 0) {
        let endDateNice = moment(bulletin.end_time).format("DD.MM.YYYY HH:mm")
        niceDate = niceDate + " - " + endDateNice
      }
    }

    let temp = new Object()
    temp.id = bulletin.id
    temp.date = niceDate

    temp.title = bulletin.header_fi
    temp.title_fi = bulletin.header_fi
    temp.title_en = bulletin.header_en
    temp.title_sv = bulletin.header_sv

    temp.content1_fi = bulletin.description_fi
      ? bulletin.description_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = bulletin.description_en
      ? bulletin.description_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = bulletin.description_sv
      ? bulletin.description_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1 = temp.content1_fi

    let targets = []
    const targetGroupsString = bulletin.target_groups
    const targetGroupsArray = targetGroupsString.split(",")

    targetGroupsArray.forEach(target => {
      switch (target) {
        case "1":
          targets.push("Suvanto Care app")
          break
        case "2":
          targets.push("Video call")
          break
        case "3":
          targets.push("Group video call")
          break
        case "4":
          targets.push("Carephone")
          break
        case "5":
          targets.push("Medicine reminder")
          break
        case "6":
          targets.push("Suvanto On the Go")
          break
        case "7":
          targets.push("Suvanto Home")
          break
        case "8":
          targets.push("Smartlock")
          break
        case "9":
          targets.push("Suvanto Care webpage")
          break
      }
    })
    temp.targets = targets

    switch (bulletin.current_status) {
      case 1:
      case "1":
        temp.status = "Service outage"
        temp.color = "#f46a6a"
        break
      case 2:
      case "2":
        temp.status = "Service disruption"
        temp.color = "#f1b44c"
        break
      case 3:
      case "3":
        temp.status = "Service operational"
        temp.color = "#34c38f"
        break
    }
    setExample(temp)
  }

  const sendValues = () => {
    props.settingPreview(item.guid)
    props.settingContentLink(item.id)
  }

  const sendValuesEmail = () => {
    props.settingSelectedGuid(props.item.guid)
    props.settingContentLink(props.item.id)
  }
  return (
    <>
      {example && (
        <Card className="shadow-none border p-3 mb-2">
          <div className="d-flex-email">
            <div className="align-self-center overflow-hidden me-auto ms-2">
              <div
                className="d-flex-email"
                style={{ cursor: "pointer" }}
                onClick={() => sendValues()}
              >
                <div className="h5 font-size-16 text-truncate mb-1 me-2 ">
                  <span className="h6 font-size-14">
                    {props.t(example.title)}
                  </span>
                </div>
                <div className="text-muted me-3">{example.date}</div>
              </div>
              <div>
                <Chip
                  size="small"
                  key={example.id + "_chip"}
                  className="font-size-11 me-2 mt-2"
                  style={{
                    background: example.color,
                    color: "white",
                  }}
                  //variant="outlined"
                  label={props.t(example.status)}
                />
              </div>
              <div style={{ textOverflow: "ellipsis" }}>
                {example.targets.map(target => (
                  <Chip
                    size="small"
                    key={target + "_chip"}
                    className="font-size-11 me-2 my-2"
                    label={props.t(target)}
                  />
                ))}
              </div>
            </div>
            {/* TODO: subs ? */}
            {/* <div className="pt-1">
              <small className="text-muted">{props.t("Subscribers")}</small>
            </div> */}
            <div className="float-end mt-1 d-md-block ">
              <Button
                size="sm"
                color="transparent"
                className="mx-1"
                onClick={() => sendValuesEmail()}
              >
                <i className="bx bx-mail-send text-primary font-size-14"></i>
              </Button>
              <Button
                size="sm"
                color="transparent"
                className="mx-1"
                onClick={() => props.settingSelectedId(props.item.id)}
              >
                <i className="bx bx-edit-alt text-primary font-size-14"></i>
              </Button>
              <Popconfirm
                title={props.t("Confirm action")}
                open={props.deleteConfirm}
                okType="danger"
                okText={props.t("Delete")}
                cancelText={props.t("Cancel")}
                onConfirm={() => {
                  props.deleteRelease(props.item.id)
                  props.setDeleteConfirm(false)
                }}
                onCancel={() => {
                  props.setDeleteConfirm(false)
                }}
              >
                <Button
                  size="sm"
                  color="transparent"
                  className="mx-1"
                  onClick={() => {
                    props.setDeleteConfirm(true)
                  }}
                >
                  <i className="bx bx-trash text-danger font-size-14"></i>
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Card>
      )}
    </>
  )
}

ErrorBulletinsItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  onOpenModal: PropTypes.any,
  settingPreview: PropTypes.any,
  settingSelectedId: PropTypes.any,
  settingSelectedGuid: PropTypes.any,
  setDeleteConfirm: PropTypes.any,
  deleteRelease: PropTypes.any,
  settingContentLink: PropTypes.any,
}

export default withRouter(withTranslation()(ErrorBulletinsItem))
