import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../Reporting/datatables.scss"

const MonitoringHandoverTable = props => {
  // Sort data in table
  const defaultSorted = [
    {
      dataField: "updated_at",
      order: "desc",
    },
  ]
  // Show selected number of rows
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: props.alarms?.length || 0 },
    ],
  }

  // Table structure
  const columns = [
    {
      dataField: "description",
      text: props.t("Alarm"),
      sort: true,
      formatter: descriptionFormatter,
    },

    {
      dataField: "client",
      text: props.t("Customer"),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: props.t("Time"),
      sort: true,
      formatter: dateFormatter,
    },
  ]

  function dateFormatter(value) {
    let unix = value / 1000
    var dateString = moment.unix(unix).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function descriptionFormatter(cell, row) {
    //return props.t(value)
    if (row.status == "CLOSED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bx-check-circle font-size-20 text-success me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else if (row.status == "ASSIGNED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-warning me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-danger me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {props.alarms && (
            <PaginationProvider pagination={paginationFactory(pageOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="key"
                  columns={columns}
                  data={props.alarms}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              id="alarms-table"
                              keyField={"key"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-3 mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <Col className="pagination justify-content-end inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

MonitoringHandoverTable.propTypes = {
  t: PropTypes.any,
  alarms: PropTypes.any,
}

export default withTranslation()(MonitoringHandoverTable)
