// DEMO USE
import PropTypes from "prop-types"
import React from "react"
import {
  Col,
  Modal,
  ModalBody,
  Row,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap"
import { withTranslation } from "react-i18next"

const DeleteModal = props => {
  return (
    <Modal isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
      <ModalBody className="p-3">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "4em", color: "orange" }}
              />
              <h5 className="py-3">
                {/* TODO Correct sentence */}
                {props.t("Are you sure you want to delete selected entry?")}
              </h5>
              <h5 className="pb-3">{props.t("(selected appointment)")}</h5>
              <p className="pb-3">
                {props.t("The action is final and cannot be revoked")}.
              </p>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={props.onCloseClick}>
          {props.t("Cancel")}
        </Button>
        <Button
          // disabled={deleting}
          color="danger"
          onClick={props.onDeleteClick}
        >
          {props.t("Delete")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(DeleteModal)
