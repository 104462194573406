import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import {
  addTracker,
  getTrackersTableData,
  importTrackers,
} from "helpers/backend_helper"
//import "../datatables.scss"
import TrackerDrawer from "./tracker-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { defaultPageSizeOptions, downloadExcel, numberSorter, showToast, stringSorter } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroup"
import { Table as AntdTable } from "antd"

const TrackerConfigs = props => {
  const [trackers, setTrackers] = useState([])
  const [filteredTrackers, setFilteredTrackers] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [addModal, setAddModal] = useState(false)
  const [importModal, setImportModal] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [ongoingImport, setOngoingImport] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [typeFilter, setTypeFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("0")
  const [searchText, setSearchText] = useState("")
  const [statistics, setStatistics] = useState({ total: 0 })
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Trackers"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    abortRef.current = new AbortController()
    getTrackers()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Check duplicate values
  useEffect(() => {
    if (trackers) {
      // GSM, IMSI, SERIAL, IMEI
      let dups = []
      for (let i = 0; i < trackers.length - 1; i++) {
        for (let j = i + 1; j < trackers.length; j++) {
          if (trackers[i].serial && trackers[i].serial === trackers[j].serial) {
            dups.push({
              key: trackers[i].serial,
              name: "SN:" + trackers[i].serial,
            })
          }
          if (trackers[i].gsm && trackers[i].gsm === trackers[j].gsm) {
            dups.push({ key: trackers[i].gsm, name: "GSM:" + trackers[i].gsm })
          }
          if (trackers[i].imei && trackers[i].imei === trackers[j].imei) {
            dups.push({
              key: trackers[i].imei,
              name: "IMEI:" + trackers[i].imei,
            })
          }
          if (trackers[i].imsi && trackers[i].imsi === trackers[j].imsi) {
            dups.push({
              key: trackers[i].imsi,
              name: "IMSI:" + trackers[i].imsi,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [trackers])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = trackers.length
    temp.linked = trackers.filter(data => data.links.length > 0).length
    temp.minifinder = trackers.filter(data => data.type == "MiniFinder").length
    temp.farnear = trackers.filter(data => data.type == "Farnear").length
    temp.minifinderGprs = trackers.filter(
      data => data.type == "MiniFinderGPRS"
    ).length
    temp.yepzon = trackers.filter(data => data.type == "Yepzon").length
    temp.navigil = trackers.filter(data => data.type == "Navigil").length
    temp.undefinedType = trackers.filter(
      data => data.type == "" || !data.type
    ).length
    setStatistics(temp)
  }, [trackers])

  // Check multiple links
  useEffect(() => {
    if (trackers) {
      let multis = []
      trackers.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.key, name: element.name })
        }
      })
      setMultilinks(multis)
    }
  }, [trackers])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen(false)
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, trackers, statusFilter, typeFilter])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = trackers.filter(
      data =>
        (statusFilter === "0" ||
          (statusFilter === "1" && data.status == "idle") ||
          (statusFilter === "2" && data.status == "charging") ||
          (statusFilter === "3" && data.status == "lowbat") ||
          (statusFilter === "4" && data.status == "off")) &&
        (typeFilter === "0" ||
          (typeFilter === "1" && data.type == "MiniFinder") ||
          (typeFilter === "2" && data.type == "Farnear") ||
          (typeFilter === "3" && data.type == "MiniFinderGPRS") ||
          (typeFilter === "4" && data.type == "Navigil")) &&
        (data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.imei?.toLowerCase().includes(searchTextLowercase) ||
          data.name?.toLowerCase().includes(searchTextLowercase) ||
          data.imsi?.toLowerCase().includes(searchTextLowercase) ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.key?.toLowerCase() === searchTextLowercase ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase) ||
          data.batch?.toLowerCase().includes(searchTextLowercase) ||
          data.firmware?.toLowerCase().includes(searchTextLowercase) ||
          data.serverNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredTrackers(result)
  }

  // Get trackers
  const getTrackers = () => {
    setOngoingSearch(true)
    getTrackersTableData(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          let now = moment()
          result.entries.forEach(element => {
            let linkedNames = []
            if (element.links) {
              element.links.forEach(link => {
                linkedNames.push(link.name)
              })
            }
            element.linkedNames = linkedNames.join()

            if (element.statusTime) {
              element.lastConnectedDiff = now.diff(
                moment(element.statusTime),
                "hours"
              )
            } else {
              element.lastConnectedDiff = 99999
            }
          })
          setTrackers(result.entries)

          // Update selected row
          if (selectedRow && selectedRow.key) {
            let foundRow = result.entries.find(
              item => item.key == selectedRow.key
            )
            setSelectedRow(foundRow)
          }
        } else {
          setTrackers([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setTrackers([])
        setOngoingSearch(false)
      })
  }

  // Create validation
  const createValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: null,
      name: "",
      type: "MiniFinder",
    },
    validationSchema: Yup.object({
      group: Yup.string().required(props.t("Required field")),
      name: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingAdd(true)
      addTracker(abortRef.current, values.type, values.group, values.name)
        .then(result => {
          if (result.statusCode == 200) {
            //console.log(result)
            showToast(props.t("Success"), "success")
            createValidation.resetForm()
            setAddModal(false)
            getTrackers()
          } else {
            console.log(result)
            showToast(props.t("Error"), "error")
          }
          setOngoingAdd(false)
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setOngoingAdd(false)
        })
    },
  })

  // Import validation
  const importValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firmware: "",
      batch: "",
      json: "",
      serverNumber: "",
    },
    validationSchema: Yup.object({
      json: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingImport(true)
      importTrackers(
        abortRef.current,
        values.json,
        values.batch,
        values.firmware,
        values.serverNumber
      )
        .then(result => {
          if (result.statusCode == 200) {
            //console.log(result)
            showToast(props.t("Success"), "success")
            importValidation.resetForm()
            setImportModal(false)
            getTrackers()
          } else {
            console.log(result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingImport(false)
        })
    },
  })

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "statusTime",
      title: props.t("Status updated"),
      sorter: (a, b) => {
        return a.statusTime - b.statusTime
      },
      render: (cell, row) => {
        return (row.lastConnectedDiff == null || row.lastConnectedDiff > 120) ? (
          <span style={{ color: "#f46a6a" }}>{dateFormatter(cell)}</span>
        ) : (
          dateFormatter(cell)
        )
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "status", sortOrder)
      },
      render: cell => {
        return cell == "off" ? (
          <span style={{ color: "#f46a6a" }}>{cell}</span>
        ) : (
          cell
        )
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "battery",
      title: props.t("Battery"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["battery"], b["battery"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "group",
      title: props.t("Group"),
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "group", sortOrder)
      },
      width: "175px",
      ellipsis: true,
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "175px",
      ellipsis: true,
    },
    {
      dataIndex: "type",
      title: props.t("Device type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "type", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: linksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imei", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "imsi",
      title: props.t("IMSI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imsi", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "trackerId",
      title: props.t("Device ID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "trackerId", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "batch",
      title: props.t("Batch"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "batch", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "firmware",
      title: props.t("Firmware"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "firmware", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "serverNumber",
      title: props.t("Server number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serverNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "simStatus",
      title: props.t("SIM status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "simStatus", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b) => {
        return stringSorter(a, b, "key")
      },
      width: "120px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
          {/**props.t("Edit")*/}
        </Button>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  // Group selection changed
  const groupChanged = newGroup => {
    createValidation.setFieldValue("group", newGroup)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Status updated"),
        props.t("Status"),
        props.t("Battery"),
        props.t("Group"),
        props.t("Name"),
        props.t("Device type"),
        props.t("Order number"),
        props.t("GSM"),
        props.t("IMEI"),
        props.t("IMSI"),
        props.t("Serial"),
        props.t("Device ID"),
        props.t("Batch"),
        props.t("Firmware"),
        props.t("Server number"),
        props.t("SIM status"),
        props.t("GUID"),
      ],
    ]
    const data = filteredTrackers.map(elt => [
      elt.statusTime,
      elt.status,
      elt.battery,
      elt.group,
      elt.name,
      elt.type,
      elt.orderNumber,
      elt.gsm,
      elt.imei,
      elt.imsi,
      elt.serial,
      elt.trackerId,
      elt.batch,
      elt.firmware,
      elt.serverNumber,
      elt.simStatus,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Trackers")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen
                        ? setAlertsOpen("")
                        : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {trackers != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      value={searchText}
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                className="round-icon-button"
                                id="filter-button"
                                outline
                                onClick={() => {
                                  filtersOpen.length > 0
                                    ? setFiltersOpen("")
                                    : setFiltersOpen("1")
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="filter-button"
                                >
                                  {props.t("Filters")}
                                </UncontrolledTooltip>
                                {filtersOpen ? (
                                  <i className="mdi mdi-filter-minus"></i>
                                ) : (
                                  <i className="mdi mdi-filter-menu"></i>
                                )}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getTrackers()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="success"
                                onClick={() => {
                                  setAddModal(true)
                                }}
                              >
                                <i className="mdi mdi-plus me-2" />
                                {props.t("Create new")}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="success"
                                onClick={() => {
                                  setImportModal(true)
                                }}
                              >
                                <i className="mdi mdi-import me-2" />
                                {props.t("Import")}
                              </Button>
                              {(duplicates.length > 0 ||
                                multilinks.length > 0) && (
                                <Button
                                  color="danger"
                                  className="round-icon-button ms-3"
                                  id="alerts-button"
                                  onClick={() => {
                                    alertsOpen.length > 0
                                      ? setAlertsOpen("")
                                      : setAlertsOpen("1")
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="alerts-button"
                                  >
                                    {props.t("Notices")}
                                  </UncontrolledTooltip>
                                  {alertsOpen ? (
                                    <i className="mdi mdi-alert-minus"></i>
                                  ) : (
                                    <i className="mdi mdi-alert-plus"></i>
                                  )}
                                </Button>
                              )}
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {/* TODO: Can not choose directory file right now */}
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredTrackers.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row xs={1} sm={2} md={3} xl={4}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Status")}</Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="statusFilter"
                                        value={statusFilter}
                                        onChange={v => {
                                          setStatusFilter(
                                            v.target.value
                                          )
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("Idle")}
                                        </option>
                                        <option value="2">
                                          {props.t("Charging")}
                                        </option>
                                        <option value="3">
                                          {props.t("Low battery")}
                                        </option>
                                        <option value="4">
                                          {props.t("Offline")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Type")}</Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="typeFilter"
                                        value={typeFilter}
                                        onChange={v => {
                                          setTypeFilter(v.target.value)
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {"MiniFinder"}
                                        </option>
                                        <option value="2">
                                          {"Farnear"}
                                        </option>
                                        <option value="3">
                                          {"MiniFinderGPRS"}
                                        </option>
                                        <option value="4">
                                          {"Navigil"}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              {ongoingSearch == true ? (
                                <div className="py-5 text-center">
                                  <p className="text-primary">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                    {props.t("Loading")}
                                  </p>
                                </div>
                              ) : (
                                <AntdTable
                                  className="striped colored-header"
                                  bordered
                                  size="middle"
                                  sticky
                                  rowKey={"key"}
                                  columns={columns}
                                  dataSource={filteredTrackers}
                                  pagination={{
                                    showSizeChanger: true,
                                    defaultPageSize: 50,
                                    pageSizeOptions: defaultPageSizeOptions,
                                  }}
                                  tableLayout="auto"
                                  scroll={{
                                    x: "max-content",
                                    y: "64vh",
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("MiniFinder")}</th>
                        <td>{statistics.minifinder}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Farnear")}</th>
                        <td>{statistics.farnear}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("MiniFinder GPRS")}</th>
                        <td>{statistics.minifinderGprs}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Navigil")}</th>
                        <td>{statistics.navigil}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Yepzon")}</th>
                        <td>{statistics.yepzon}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Undefined type")}</th>
                        <td>{statistics.undefinedType}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create new")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("Device type")}</Label>
                  <Input
                    name="type"
                    type="select"
                    onChange={createValidation.handleChange}
                    onBlur={createValidation.handleBlur}
                    value={createValidation.values.type}
                  >
                    <option value="MiniFinder">{"MiniFinder"}</option>
                    <option value="MiniFinderGPRS">{"MiniFinderGPRS"}</option>
                    <option value="Farnear">{"Farnear"}</option>
                    <option value="Navigil">{"Navigil"}</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Group")}</Label>
                  <SelectGroup
                    onChange={groupChanged}
                    multiple={false}
                    clearable={false}
                    selection={createValidation.values.group}
                  />
                  {createValidation.touched.group &&
                  createValidation.errors.group ? (
                    <FormFeedback type="invalid">
                      {createValidation.errors.group}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Description")}</Label>
                  <Input
                    name="name"
                    type="text"
                    onChange={createValidation.handleChange}
                    onBlur={createValidation.handleBlur}
                    value={createValidation.values.name || ""}
                    invalid={
                      createValidation.touched.name &&
                      createValidation.errors.name
                        ? true
                        : false
                    }
                  ></Input>
                  {createValidation.touched.name &&
                  createValidation.errors.name ? (
                    <FormFeedback type="invalid">
                      {createValidation.errors.name}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    createValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={importModal}
            toggle={() => {
              setImportModal(!importModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Import")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("Batch")}</Label>
                  <Input
                    name="batch"
                    type="text"
                    placeholder="2023-01"
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    value={importValidation.values.batch}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Firmware")}</Label>
                  <Input
                    name="firmware"
                    type="text"
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    value={importValidation.values.firmware || ""}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Server number")}</Label>
                  <Input
                    name="serverNumber"
                    type="select"
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    value={importValidation.values.serverNumber || ""}
                  >
                    <option value="">{props.t("Undefined")}</option>
                    <option value="31811">31811</option>
                    <option value="+3584573950158">+3584573950158</option>
                    <option value="+358509020154">+358509020154</option>
                    <option value="+61428449770">+61428449770</option>
                    <option value="+4759447471">+4759447471</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("JSON")}</Label>
                  <Input
                    name="json"
                    type="textarea"
                    rows={8}
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    placeholder="[{'MSISDN': '358401234567', 'IMEI': '867858039519148', 'IMSI': '238208700975587'}]"
                    value={importValidation.values.json || ""}
                    invalid={
                      importValidation.touched.json &&
                      importValidation.errors.json
                        ? true
                        : false
                    }
                  ></Input>
                  {importValidation.touched.json &&
                  importValidation.errors.json ? (
                    <FormFeedback type="invalid">
                      {importValidation.errors.json}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setImportModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingImport}
                  onClick={() => {
                    importValidation.submitForm()
                  }}
                >
                  {props.t("Import")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <TrackerDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getTrackers={getTrackers}
            trackers={trackers}
          />
        </Container>
      </div>
    </>
  )
}

TrackerConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(TrackerConfigs)
