/***********************
 * SETTINGS HEADER
 (TESTING)
 ************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [checked, setChecked] = React.useState(false)

  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-cog" />
          {/* <span className="badge bg-danger rounded-pill">3</span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Asetukset")} </h6>
              </Col>
              <div className="col-auto">
                {/* <a href="#!" className="small">
                  {" "}
                  View All
                </a> */}
              </div>
            </Row>
          </div>
          <div className="p-3 ms-2 me-2 mb-2 border-top d-grid">
            <div className="" to="#">
              <h6 className="text-muted">{props.t("Kieli")}</h6>
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
              <Row>
                <Col lg="4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={false}
                      onChange={handleChange}
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      {props.t("suomi")}
                    </label>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      defaultChecked
                    />
                    <label className="" htmlFor="defaultCheck2">
                      {props.t("ruotsi")}
                    </label>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      defaultChecked
                    />
                    <label className="" htmlFor="defaultCheck2">
                      {props.t("englanti")}
                    </label>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="dropdown-divider" />
            <div className="mt-2" to="#">
              <h6 className="text-muted">{props.t("Teema")}</h6>
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
              <Row>
                <Col lg="4">p</Col>
                <Col lg="4">yu</Col>
                <Col lg="4">r</Col>
              </Row>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
