/***********************
 * GENERAL VIEW CHART
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import "../../../assets/scss/custom/charts/custom-chart.css"
import door from "../../../assets/images/small/door-orange.png"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

//Formatting date
import moment from "moment"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import { NonceProvider } from "react-select"
import GlobalState from "contexts/GlobalState"

const GeneralViewChart = props => {
  const [state, setState] = useContext(GlobalState)
  const [chartData, setChartData] = useState()
  const [activityData, setActivityData] = useState(["", 0])
  const [doorData, setDoorData] = useState(["", 0])
  const [chartDataMicro, setChartDataMicro] = useState([])
  const [chartDataBed, setChartDataBed] = useState([])
  const [language, setLanguage] = useState("fi")
  const [height, setHeight] = useState(400)

  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())

  // Loading state
  const [isLoading, setIsLoading] = useState(true)

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setLanguage("fi")
      } else if (currentLanguage.indexOf("sv") == 0) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setChartData(props.data)
  }, [props.data])

  useEffect(() => {
    setStartDate(props.startDate)
    setEndDate(props.endDate)
  }, [props.startDate, props.endDate])

  useEffect(() => {
    if (chartData != undefined) {
      let datas = chartData.entries?.map(item => [item.date, item.activity])
      // let filterEmpty = datas.filter(f => f[1] != 0)
      setActivityData(datas)
      let datas2 = chartData.entries?.map(item => [item.date, item.door])
      let filterEmpty2 = datas2.filter(f => f[1] != 0)
      setDoorData(filterEmpty2)
      let datas3 = chartData.entries?.map(item => [item.date, item.bed])
      // let filterEmpty3 = datas3.filter(f => f[1] != 0)
      setChartDataBed(datas3)
      let datas4 = chartData.entries?.map(item => [item.date, item.micro])
      let filterEmpty4 = datas4.filter(f => f[1] != 0)
      setChartDataMicro(filterEmpty4)
    }
    setIsLoading(false)
  }, [chartData])

  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("combined-activity-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("combined-activity-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("combined-activity-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Bed"),
      data: chartDataBed,
      type: "area",
      // width: 0,
    },
    {
      name: props.t("Combined activity"),
      data: activityData,
      type: "area",
      // width: 0,
    },
    {
      name: props.t("Outside door opened"),
      data: doorData,
      symbolSize: 5,
      type: "scatter",
    },

    {
      name: props.t("Micro"),
      data: chartDataMicro,
      type: "scatter",
      symbolSize: 5,
    },
  ]
  const options = {
    chart: {
      stacked: true,
      id: "combined-activity-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",

      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
      tickPlacement: "on",
    },
    legend: {
      show: true,
    },
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        format: "dd.MM.yyyy HH:mm",
      },
      custom: function (opts) {
        const desc = opts.ctx.w.config.series[opts.seriesIndex].name

        const value = opts.series[opts.seriesIndex][opts.dataPointIndex]

        if (desc == props.t("Bed")) {
          if (value > 0) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              props.t("not in use") +
              "</span>" +
              "</div>"
            )
          }
          if (value == 0) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              props.t("not in use") +
              "</span>" +
              "</div>"
            )
          }
        }
        if (desc == props.t("Combined activity")) {
          return (
            '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
            "<span>" +
            desc +
            " " +
            value +
            " " +
            "%" +
            "</span>" +
            "</div>"
          )
        }
        if (desc == props.t("Micro")) {
          return (
            '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
            "<span>" +
            desc +
            " " +
            props.t("in use") +
            "</span>" +
            "</div>"
          )
        }
        if (desc == props.t("Outside door opened")) {
          if (value == 1) {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              "</span>" +
              "</div>"
            )
          } else {
            return (
              '<div class="py-2 me-2 ms-2 text-muted font-size-12">' +
              "<span>" +
              desc +
              " " +
              value +
              " " +
              props.t("times") +
              "</span>" +
              "</div>"
            )
          }
        }
      },
    },
    colors: ["#3783cc", "#48d0f8", "#f5d663", "#f5a14a"],
    markers: {
      size: [5, 3, 6, 6],
      colors: ["#3783cc00", "#48d0f800", "#f5d663", "#f5a14a"],
      strokeColors: ["#3783cc00", "#48d0f800", "#f5d663", "#f5a14a"],
      strokeWidth: 0.1,
    },
    stroke: {
      width: [2, 2, 5, 5],
      curve: "smooth",
    },
    fill: {
      type: ["gradient", "gradient", "solid", "solid"],
      opacity: 1,
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 100, 100, 100],
      },
      image: {
        src: [door],
        width: 30,
        height: 30,
      },
    },
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={height}
        />
      )}
    </div>
  )
}

GeneralViewChart.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
}

export default withTranslation()(GeneralViewChart)
