/***********************
 * RANGE PICKER
 ************************/

import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { DatePicker, ConfigProvider } from "antd"
const { RangePicker } = DatePicker
import { getLocale } from "utils/general"

const CustomRangePicker = props => {
  const locale = getLocale()
  const rangeButtons = {
    [props.t("This day")]: [moment().startOf("day"), moment()],
    [props.t("This Week")]: [moment().startOf("week"), moment().endOf("week")],
    [props.t("This Month")]: [
      moment().startOf("month"),
      moment().endOf("month"),
    ],
    [props.t("This Year")]: [moment().startOf("year"), moment().endOf("year")],
  }

  return (
    <React.Fragment>
      <ConfigProvider locale={locale}>
        <RangePicker
          className="range-picker"
          disabled={props.disabled}
          value={props.value}
          showTime={props.showTime != null ? props.showTime : true}
          allowClear={props.allowClear != null ? props.allowClear : false}
          onChange={props.rangeChanged}
          format={props.format ? props.format : "DD.MM.YYYY HH:mm:ss"}
          ranges={{ ...rangeButtons }}
        />
      </ConfigProvider>
    </React.Fragment>
  )
}

CustomRangePicker.propTypes = {
  t: PropTypes.any,
  value: PropTypes.array,
  rangeChanged: PropTypes.func,
  disabled: PropTypes.bool,
  showTime: PropTypes.bool,
  allowClear: PropTypes.bool,
  format: PropTypes.string,
}
export default withTranslation()(CustomRangePicker)
