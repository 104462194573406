import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import { Link } from "react-router-dom"
import "./map.css"

const Map = props => {
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [showInfo, setShowInfo] = useState(false)
  const center = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng])

  useEffect(() => {
    let tempLat = props.lat
    if (!tempLat) tempLat = 0
    if (typeof tempLat === "string") tempLat = parseFloat(tempLat)

    if (tempLat != lat) {
      //console.log("Lat: ", props.lat, tempLat)
      setLat(tempLat)
    }
  }, [props.lat])

  useEffect(() => {
    let tempLng = props.lng
    if (!tempLng) tempLng = 0
    if (typeof tempLng === "string") tempLng = parseFloat(tempLng)

    if (tempLng != lng) {
      //console.log("Lng: ", props.lng, tempLng)
      setLng(tempLng)
    }
  }, [props.lng])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
  })
  if (!isLoaded)
    return (
      <div className="text-center py-5 my-3">
        <Link to="#" className="text-primary">
          <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        </Link>
      </div>
    )

  // Open selected location in new tab
  const openGoogleMap = () => {
    const win = window.open(
      "https://www.google.com/maps/place/" + lat + "," + lng,
      "_blank"
    )
    if (win != null) {
      win.focus()
    }
  }

  return (
    <GoogleMap
      zoom={props.zoom}
      center={center}
      mapContainerClassName="google-map"
      onClick={e => {
        if (props.markerEdit == true) {
          //console.log("Map clicked: ", e)
          setLat(e.latLng.lat())
          setLng(e.latLng.lng())
          if (props.markerChanged) {
            props.markerChanged(e.latLng.toJSON())
          }
        }
      }}
    >
      <Marker
        position={{ lat: lat, lng: lng }}
        draggable={props.markerEdit != undefined ? props.markerEdit : false}
        onDragEnd={e => {
          if (props.markerEdit == true) {
            //console.log("Marker dragged: ", e)
            setLat(e.latLng.lat())
            setLng(e.latLng.lng())
            if (props.markerChanged) {
              props.markerChanged(e.latLng.toJSON())
            }
          }
        }}
        onClick={() => {
          //openGoogleMap()
          setShowInfo(!showInfo)
        }}
        icon={{
          url: (require("./../../assets/images/icons/ic_marker_home_156.png")),
          scaledSize: new google.maps.Size(56, 56), // scaled size
        }}
      >
        {props.infoText && showInfo == true && (
          <InfoWindow
            position={{ lat: lat, lng: lng }}
            //options={{ maxWidth: 100 }}
            onCloseClick={() => {
              setShowInfo(false)
            }}
          >
            <div>
              {props.infoHeader && <h6>{props.infoHeader}</h6>}
              <p>{props.infoText}</p>
              <a
                href={"https://www.google.com/maps/place/" + lat + "," + lng}
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-arrow-right float-end font-size-18"></i>
                {/* {props.t("Open in Google Maps")} */}
              </a>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  )
}

Map.propTypes = {
  t: PropTypes.any,
  lat: PropTypes.any,
  lng: PropTypes.any,
  zoom: PropTypes.any,
  infoHeader: PropTypes.string,
  infoText: PropTypes.string,
  iconUrl: PropTypes.string,
  iconScale: PropTypes.number,
  markerEdit: PropTypes.bool,
  markerChanged: PropTypes.func,
}

// TODO: WithTranslation
// TODO: Antaa erroria:js?key=AIzaSyC4hut4Lnd5UKGXBDOF3HiI0t13O78FpcQ&v=weekly&callback=initMap:105 InvalidValueError: setIcon: not a string; and should be a PinView; and no url property; and no path property
export default Map
