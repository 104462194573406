import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { Select, ConfigProvider } from "antd"
import PubSub from "pubsub-js"
import { getOrganizationList } from "../../helpers/backend_helper"

const SelectOrganization = props => {
  const [options, setOptions] = useState(props.data)
  const [selectedValue, setSelectedValue] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()

    // Parent component not providing data, load here
    if (props.loadData == true) {
      getData()
    }

    // Refresh data request
    const token = PubSub.subscribe("organizations-refresh", (event, data) => {
      if (props.loadData == true) getData()
    })

    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Selection from props
  useEffect(() => {
    if (props.data) {
      setTreeData(props.data)
    }
  }, [props.data])

  // Selection from props
  useEffect(() => {
    console.log(props.selection)
    if (props.selection) {
      setSelectedValue(props.selection)
    } else if (props.multiple == true) {
      setSelectedValue([])
    } else {
      setSelectedValue(undefined)
    }
  }, [props.selection])

  // Load organizations
  const getData = () => {
    setLoading(true)
    getOrganizationList(abortRef.current)
      .then(result => {
        if (result.statusCode == 200 && result.entries) {
          setOptions(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setOptions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Selection changed
  const handleChange = newSelection => {
    setSelectedValue(newSelection)
    if (props.onChange != null) {
      props.onChange(newSelection)
    }
  }

  // Empty list render
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No organizations found")}</p>
    </div>
  )

  // Dropdown visibility
  const dropVisible = visible => {
    console.log("dropVisible: ", visible)
    if (visible && options.length == 0 && !loading) {
      getData()
    }
  }

  return (
    <div>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            showSearch
            loading={loading}
            onDropdownVisibleChange={dropVisible}
            defaultValue={props.selection}
            mode={props.multiple ? "multiple" : null}
            allowClear={props.clearable != null ? props.clearable : true}
            placeholder={props.t("Select organization")}
            style={{ width: 100 + "%" }}
            onChange={handleChange}
            options={options}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectOrganization.propTypes = {
  t: PropTypes.any,
  data: PropTypes.array,
  selection: PropTypes.any,
  onChange: PropTypes.func,
  loadData: PropTypes.bool,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
}

export default withTranslation()(SelectOrganization)
