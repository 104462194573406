import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Other public pages
import PublicReleaseNote from "../pages/Public/release-note/release-note"
import PublicDisturbanceNotice from "../pages/Public/disturbance-notice/disturbance-notice"

// LANDING PAGE
import LandingPage from "pages/LandingPage/landing-page"

// CUSTOMER PROFILE
import CustomerProfile from "pages/CustomerProfile/customer-profile"

// MONITORING
import AlarmMonitoring from "pages/Monitoring/alarm-monitoring/alarm-monitoring"
import OfflineVideoMonitoring from "pages/Monitoring/offline-videos/offline-videos"

// REPORTING
import Reporting from "pages/Reporting/reporting"

// SERVICES CHARTS
import GeneralViewChart from "pages/Services/at-home/general-view-chart"

//SUVANTO SERVICES
import GroupCallServices from "pages/Services/group-call/group-call"

// ADMIN TOOLS
import PindoraConfigs from "pages/Developer/pindora-configs/pindora-configs"
import GatewayConfigs from "pages/Developer/gateway-configs/gateway-configs"
import TrackerConfigs from "pages/Developer/tracker-configs/tracker-configs"
import VideoDevices from "pages/Developer/video-devices/video-devices"
import DoseConfigs from "pages/Developer/dose-configs/dose-configs"
import OrderManagement from "pages/Sales/order-management/order-management"
import CarephoneConfigs from "pages/Developer/carephone-configs/carephone-configs"
import MeasureDeviceConfigs from "pages/Developer/measure-device-configs/measure-device-configs"
import MessagingTools from "pages/Developer/messaging-tools/messaging-tools"
import SystemLogs from "pages/Developer/logs/system-logs/system-logs"
import ReleaseNotesPage from "pages/Developer/release-notes/release-notes"
import ErrorBulletinsPage from "pages/Developer/error-bulletins/error-bulletins"
import EmailReleasesPage from "pages/Developer/email-releases/email-releases"
// import EmailReleasesPage from "pages/Developer/email-releases/email-releases"
import ServiceMonitoring from "pages/Sales/service-monitoring/service-monitoring"
import VideoRooms from "pages/Developer/video-rooms/video-rooms"
import AdminUsersManagement from "pages/Developer/admin-users/admin-users"
import ClientsManagementTable from "pages/Developer/clients/clients"

import UserManagement from "pages/Admin/user-management/user-management"
import VideoSystemManagement from "pages/Admin/video-management/video-management"

import GroupsManagement from "pages/Developer/groups/groups"

import SmsLogs from "pages/Developer/logs/sms-logs/sms-logs"
import FeedbackLogs from "pages/Developer/logs/feedback/feedback"
import GatewayLogs from "pages/Developer/logs/gateway-logs/gateway-logs"
import VideoCallFrame from "pages/Services/video-call/video-call-frame"
import EndCallView from "pages/Services/video-call/end-call"
import AuditLogs from "pages/Developer/logs/audit-logs/audit-logs"
import SupportTicketsReport from "pages/Developer/exports/support-tickets-report/support-tickets-report"
import CallAppointments from "pages/Appointments/call-appointments"
import VideoGroupMessage from "pages/Video/VideoGroupMessage/group-message"

const authProtectedRoutes = [
  // LANDING PAGE
  { path: "/main", component: <LandingPage /> },

  // CLIENT MANAGEMENT
  // prettier-ignore
  // eslint-disable-next-line react/display-name
  { path: "/clients", exact: true, component: <Navigate to="/clients/select" replace/> },
  // prettier-ignore
  { path: "/clients/:clientId", component: <CustomerProfile/> },

  // MONITORING
  {
    path: "/monitoring/alarms",
    component: <AlarmMonitoring />,
    roles: [],
    permission: "alarm_monitoring",
  },
  {
    path: "/monitoring/offline-videos",
    component: <OfflineVideoMonitoring />,
    roles: [],
    permission: "offline_video_monitoring",
  },
  //{ path: "/status-monitoring", component: StatusMonitoring },

  // PROFILE
  { path: "/profile", component: <UserProfile /> },

  // REPORTING PAGES
  {
    path: "/reporting",
    component: <Reporting />,
    roles: [
      "system_admin",
      "salesperson",
      "order_manager",
      "organisation_admin",
    ],
  },

  // SALES PAGES
  {
    path: "/sales/service-monitoring",
    component: <ServiceMonitoring />,
    roles: ["system_admin", "salesperson", "order_manager"],
  },

  // SERVICES PAGES
  {
    path: "/group-call",
    component: <GroupCallServices />,
    roles: [
      "system_admin",
      "organisation_admin",
      "organisation_user",
      "salesperson",
    ],
  },
  {
    path: "/videocall/:clientId",
    component: <VideoCallFrame />,
    fullscreen: true,
    roles: ["system_admin"],
  },
  {
    path: "/at-home-chart-full",
    component: <GeneralViewChart />,
  },

  // ADMIN TOOLS
  // Add roles or permissions for access control
  {
    path: "/config/admins",
    component: <AdminUsersManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/admin/user-management",
    component: <UserManagement />,
    permission: "admin_management",
  },
  // TEST START
  /*{
    path: "/config/admins2",
    component: <AdminUsersManagement2 />,
    roles: ["system_admin"],
  },*/
  // TEST END
  {
    path: "/config/clients",
    component: <ClientsManagementTable />,
    roles: ["system_admin"],
  },
  {
    path: "/config/groups",
    component: <GroupsManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/config/gateways",
    component: <GatewayConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/pindoras",
    component: <PindoraConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/trackers",
    component: <TrackerConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-devices",
    component: <VideoDevices />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-numbers",
    component: <VideoRooms />,
    roles: ["system_admin"],
  },
  {
    path: "/config/doses",
    component: <DoseConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/carephones",
    component: <CarephoneConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/measuringdevices",
    component: <MeasureDeviceConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-system",
    component: <VideoSystemManagement />,
    permission: "video_system",
  },
  {
    path: "/sales/orders",
    component: <OrderManagement />,
    roles: ["system_admin", "salesperson", "order_manager"],
  },
  {
    path: "/tools/messaging",
    component: <MessagingTools />,
    roles: ["system_admin"],
  },
  {
    path: "/messaging/releases",
    component: <ReleaseNotesPage />,
    roles: ["system_admin"],
  },
  {
    path: "/messaging/email-releases",
    component: <EmailReleasesPage />,
    roles: ["system_admin"],
  },
  {
    path: "/messaging/errorbulletins",
    component: <ErrorBulletinsPage />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/system",
    component: <SystemLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/sms",
    component: <SmsLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/feedback",
    component: <FeedbackLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/gateway",
    component: <GatewayLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/audit-logs",
    component: <AuditLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/reporting/support-tickets",
    component: <SupportTicketsReport />,
    roles: ["system_admin"],
  },
  {
    path: "/appointments",
    component: <CallAppointments />,
    permission: "appointments",
  },
  {
    path: "/video/group-messages",
    component: <VideoGroupMessage />,
    permission: "groupMessages",
  },

  // this route should be at the end of all other routes
  // prettier-ignore
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: <Navigate to="/main" replace/> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/release-note/:id", component: <PublicReleaseNote /> },
  { path: "/disturbance-notice/:id", component: <PublicDisturbanceNotice /> },
  { path: "/call-end", component: <EndCallView />, fullscreen: true },
]

export { publicRoutes, authProtectedRoutes }
