// Landing page -content
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"
const { Panel } = Collapse
import { CaretRightOutlined } from "@ant-design/icons"

const InstructionsAppointments = props => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Appointments")}</h5>
        <p className="pt-2">{props.t("CallAppointments." + "0")}</p>
        <p className="pt-2">{props.t("CallAppointments." + "1")}</p>
      </div>{" "}
      <Collapse
        bordered={false}
        className="px-0 "
        accordion
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        // defaultActiveKey={[activeTab]}
        style={{ backgroundColor: "transparent" }}
      >
        <Panel key={0} header={props.t("CallAppointmentsTitle1")}>
          <p>{props.t("CallAppointments." + "CreateAppointment1")}</p>
          <p>{props.t("CallAppointments." + "CreateAppointment2")}</p>
          <ul className="">
            <li className="text-muted">
              {props.t("CallAppointments." + "CreateAppointment3")}
            </li>
            <li className="">
              {props.t("CallAppointments." + "CreateAppointment4")}
            </li>
          </ul>
          <p>{props.t("CallAppointments." + "CreateAppointment5")}</p>
          <p>{props.t("CallAppointments." + "CreateAppointment6")}</p>
        </Panel>
        <Panel key={1} header={props.t("CallAppointmentsTitle2")}>
          <p>{props.t("CallAppointments." + "FilterAppointment1")}</p>
          <p>{props.t("CallAppointments." + "FilterAppointment2")}</p>
        </Panel>
        <Panel key={2} header={props.t("CallAppointmentsTitle3")}>
          <p>{props.t("CallAppointments." + "MoveAppointment1")}</p>
          <p>{props.t("CallAppointments." + "MoveAppointment2")}</p>
        </Panel>
        <Panel key={3} header={props.t("CallAppointmentsTitle4")}>
          <p>{props.t("CallAppointments." + "DeleteAppointment1")}</p>
        </Panel>
        <Panel key={4} header={props.t("CallAppointmentsTitle5")}>
          <p className="">
            {props.t("CallAppointments." + "NavigateTimeline0")}
          </p>
          {/* AIKAJANAA VOI SÄÄTÄÄ  */}
          <h6 className="text-dark">
            {props.t("CallAppointments." + "NavigateTimelineTitle1")}
          </h6>
          <p className="">
            {props.t("CallAppointments." + "NavigateTimeline1")}
          </p>
          <ul className="">
            <li className="">
              {props.t("CallAppointments." + "NavigateTimeline3")}
            </li>
            <li className="">
              {props.t("CallAppointments." + "NavigateTimeline2")}
            </li>
          </ul>
          <p className="">
            {props.t("CallAppointments." + "NavigateTimeline4")}
          </p>
          <h6 className="text-dark">
            {props.t("CallAppointments." + "NavigateTimelineTitle2")}
          </h6>
          <p className="">
            {props.t("CallAppointments." + "NavigateTimeline5")}
          </p>
          <ul className="">
            <li className="">
              {props.t("CallAppointments." + "NavigateTimeline6")}
            </li>
            <li className="">
              {props.t("CallAppointments." + "NavigateTimeline7")}
            </li>
          </ul>
        </Panel>
      </Collapse>
    </div>
  )
}
InstructionsAppointments.propTypes = {
  t: PropTypes.any,
  activeTab: PropTypes.any,
}

export default withTranslation()(InstructionsAppointments)
