/***********************
 * Alarm monitoring
 ************************/

import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Switch } from "antd"
import alarmAudio from "../../../assets/sounds/alarm_received.mp3"
import {
  getMonitoringAlarms,
  getMonitoringHandoverAlarms,
} from "../../../helpers/backend_helper"
import MonitoringAlarmTable from "./alarms-table"
import MonitoringHandoverTable from "./handovers-table"
import AlarmMonitoringDrawer from "./monitor-drawer"
import { showToast } from "utils/general"
import GlobalState from "contexts/GlobalState"
import { useNavigate } from "react-router-dom"

const AlarmMonitoring = props => {
  const [state, setState] = useContext(GlobalState)
  const [alarms, setAlarms] = useState([])
  const [handovers, setHandovers] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [latestAlarmDate, setLatestAlarmDate] = useState(null)
  const [count, setCount] = useState(0)
  const [updateRequired, setUpdateRequired] = useState(false)
  const [soundEffect, setSoundEffect] = useState(true)
  const [showNotification, setShowNotification] = useState(true)
  const [showResolvedAlarms, setShowResolvedAlarms] = useState(false)
  const [showResolvedHandovers, setShowResolvedHandovers] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(false)

  const navigate = useNavigate()
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Alarm monitoring") + " | Suvanto Care"
    abortRef.current = new AbortController()
    // Redirect if not permitted to access this page
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      if (
        role != "system_admin" &&
        (obj.access_permissions == null ||
          obj.access_permissions.alarm_monitoring != true)
      ) {
        navigate("/main", { replace: true })
      }
    }

    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    //console.log("", count)
    if (autoRefresh && !showDrawer) {
      //console.log("STARTING")
      const interval = setInterval(() => {
        //console.log("UPDATE")
        clearInterval(interval)
        fetchAlarms()
      }, 60000)

      return () => {
        clearInterval(interval) /*console.log("CLEAR")*/
      }
    } else {
      //console.log("SKIP")
    }
  }, [count, autoRefresh])

  useEffect(() => {
    fetchAlarms()
  }, [showResolvedAlarms])

  useEffect(() => {
    fetchHandoverAlarms()
  }, [showResolvedHandovers])

  useEffect(() => {
    if (!showDrawer && updateRequired) {
      setUpdateRequired(false)
      fetchAlarms()
    }
  }, [showDrawer])

  // Fetch alarms from server
  const fetchAlarms = () => {
    if (!loading) {
      setLoading(true)
      getMonitoringAlarms(abortRef.current, showResolvedAlarms)
        .then(result => {
          console.log("Alarms: ", result)
          if (result.statusCode == 200 && result.entries) {
            result.entries.forEach(element => {
              element.description = "AlarmTitles." + element.alarm_id
            })

            result.entries.sort((a, b) =>
              a.received_at < b.received_at ? 1 : -1
            )

            for (let i = 0; i < result.entries.length; i++) {
              if (result.entries[0].status == "UNASSIGNED") {
                if (
                  latestAlarmDate != null &&
                  result.entries[0].received_at > latestAlarmDate
                ) {
                  if (soundEffect) playSound()
                  if (showNotification)
                    showToast(props.t("New alarm received"), "info", 20000)
                }
                setLatestAlarmDate(result.entries[0].received_at)
                break
              }
            }

            setAlarms(result.entries)
          } else {
            setAlarms([])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          if (abortRef.current.signal.aborted == false) {
            setLoading(false)
            setCount(value => value + 1)
          }
        })
    } else {
      setCount(value => value + 1)
    }
  }

  // Fetch handover alarms
  const fetchHandoverAlarms = () => {
    getMonitoringHandoverAlarms(abortRef.current, showResolvedHandovers)
      .then(result => {
        console.log("Handovers: ", result)
        if (result.statusCode == 200 && result.entries) {
          result.entries.forEach(element => {
            element.description = "AlarmTitles." + element.handover_alarm_id
          })
          result.entries.sort((a, b) =>
            a.received_at < b.received_at ? 1 : -1
          )
          setHandovers(result.entries)
        } else {
          setHandovers([])
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  // Play alarm sound
  const playSound = () => {
    let audioElement = document.getElementById("alarmAudio")
    if (audioElement != null) {
      audioElement.play()
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <audio id="alarmAudio" className="d-none">
            <source src={alarmAudio} type="audio/mpeg" />
          </audio>
          <Breadcrumbs
            title={props.t("Alarm monitoring")}
            className="breadcrumb m-0 justify-content-center"
            breadcrumbItem={
              <>
                <Row>
                  <Col className="col-auto">
                    <div className="d-flex mt-1">
                      <Label>{props.t("Automatic refresh") + " (60s)"}</Label>
                      <Switch
                        className="ms-2"
                        name="auto_refresh"
                        checked={autoRefresh}
                        onChange={v => setAutoRefresh(v)}
                      />
                    </div>
                  </Col>
                  <Col>
                    <Button
                      color="primary"
                      style={{
                        borderRadius: "100px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                      disabled={loading}
                      onClick={() => {
                        fetchAlarms()
                      }}
                    >
                      <i className="mdi mdi-refresh me-2" />
                      {props.t("Refresh")}
                    </Button>
                  </Col>
                </Row>
              </>
            }
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-flex flex-wrap">
                    <ul
                      className="nav nav-tabs nav-tabs-custom card-header-tabs"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1")
                          }}
                        >
                          {props.t("Alarms")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggle("2")
                          }}
                        >
                          {props.t("Transferred alarms")}
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab} className="py-2">
                    <TabPane className="show" tabId="1">
                      <Row>
                        <Col className="col-auto">
                          <FormGroup>
                            <Switch
                              className="me-2"
                              name="show_resolved"
                              checkedChildren={
                                <i className="mdi mdi-check-bold" />
                              }
                              unCheckedChildren={
                                <i className="mdi mdi-close-thick" />
                              }
                              checked={showResolvedAlarms}
                              onChange={v => setShowResolvedAlarms(v)}
                            ></Switch>
                            <Label>{props.t("Show resolved alarms")}</Label>
                          </FormGroup>
                        </Col>

                        <Col className="col-auto">
                          <FormGroup>
                            <Switch
                              style={{
                                backgroundColor: soundEffect ? "#34c38f" : "",
                              }}
                              className="me-2"
                              name="play_sound"
                              checkedChildren={
                                <i className="mdi mdi-bell-ring" />
                              }
                              unCheckedChildren={
                                <i className="mdi mdi-bell-off" />
                              }
                              checked={soundEffect}
                              onChange={v => setSoundEffect(v)}
                            ></Switch>
                            <Label>{props.t("Sound")}</Label>
                          </FormGroup>
                        </Col>

                        <Col className="col-auto">
                          <FormGroup>
                            <Switch
                              style={{
                                backgroundColor: showNotification
                                  ? "#34c38f"
                                  : "",
                              }}
                              className="me-2"
                              name="show_notification"
                              checkedChildren={
                                <i className="mdi mdi-message" />
                              }
                              unCheckedChildren={
                                <i className="mdi mdi-message-off" />
                              }
                              checked={showNotification}
                              onChange={v => setShowNotification(v)}
                            ></Switch>
                            <Label>{props.t("Notification")}</Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <MonitoringAlarmTable
                        data={alarms}
                        setShowDrawer={setShowDrawer}
                        setSelectedRow={setSelectedRow}
                        loading={loading}
                      />
                    </TabPane>

                    <TabPane className="show" tabId="2">
                      <Row>
                        <Col className="col-auto">
                          <FormGroup>
                            <Switch
                              className="me-2"
                              name="show_resolved_2"
                              checkedChildren={
                                <i className="mdi mdi-check-bold" />
                              }
                              unCheckedChildren={
                                <i className="mdi mdi-close-thick" />
                              }
                              checked={showResolvedHandovers}
                              onChange={v => setShowResolvedHandovers(v)}
                            ></Switch>
                            <Label>{props.t("Show resolved alarms")}</Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <MonitoringHandoverTable alarms={handovers} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <AlarmMonitoringDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            setUpdateRequired={setUpdateRequired}
          />
        </Container>
      </div>
    </>
  )
}

AlarmMonitoring.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
}
export default withTranslation()(AlarmMonitoring)
