/***********************
 * NODE TEMP CHART
 * (+ IMPORT PDF/XLSX)
 ************************/

import React, { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import "../../../../assets/scss/custom/charts/custom-chart.css"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

//Formatting date
import moment from "moment"

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fi from "apexcharts/dist/locales/fi.json"
import se from "apexcharts/dist/locales/se.json"
import en from "apexcharts/dist/locales/en.json"

//Translation
import { withTranslation } from "react-i18next"
import { NonceProvider } from "react-select"
import GlobalState from "contexts/GlobalState"

const NodeChartTemperature = props => {
  const [state, setState] = useContext(GlobalState)
  const [tempData, setTempData] = useState(["", 0])
  const [language, setLanguage] = useState("fi")

  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())

  // Loading state
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props.showLoading != undefined) setIsLoading(props.showLoading)
  }, [props.showLoading])

  useEffect(() => {
    // setIsLoading(true)
    if (props.data != undefined) {
      let filterEmpty = props.data.filter(f => f[1] != 0)
      setTempData(filterEmpty)
      // setTempData(props.data)
    }
    //setIsLoading(false)
  }, [props.data])

  useEffect(() => {
    setStartDate(props.startDate)
    setEndDate(props.endDate)
  }, [props.startDate, props.endDate])

  // Init language
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        setLanguage("fi")
      } else if (currentLanguage.indexOf("sv") == 0) {
        setLanguage("se")
      } else {
        setLanguage("en")
      }
    }
  }, [])
  // Language change
  useEffect(() => {
    switch (state.language) {
      case "fi":
        setLanguage("fi")
        ApexCharts.exec("node-temp-chart", "setLocale", "fi")
        break
      case "en":
        setLanguage("en")
        ApexCharts.exec("node-temp-chart", "setLocale", "en")
        break
      case "sv":
        setLanguage("se")
        ApexCharts.exec("node-temp-chart", "setLocale", "se")
        break
    }
  }, [state.language])

  const series = [
    {
      name: props.t("Temperature"),
      type: "area",
      data: tempData,
    },
  ]
  const options = {
    chart: {
      id: "node-temp-chart",
      locales: [fi, en, se],
      defaultLocale: language, //"en",
      stacked: false,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: " ",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: [2],
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      bar: {
        //columnWidth: "50%",
      },
    },
    colors: ["#ff954e"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },

    markers: {
      size: 0,
    },
    legend: {
      offsetY: 11,
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined" && y != 0 && y != 0) {
            return y.toFixed(0) + " " + props.t("°C")
          }
          return y
        },
      },
      x: {
        show: false,
        format: "HH:mm",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <div>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="300"
        />
      )}
    </div>
  )
}

NodeChartTemperature.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  showLoading: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
}

export default withTranslation()(NodeChartTemperature)
