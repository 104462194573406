import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

const EndCallView = props => {

  useEffect(() => {
    
  }, [])

  return (
    <>
      <Row className="m-4">
        <Col></Col>
        <Col className="" xs={12} md={10} lg={6}>
          <Card>
            <CardBody className="bg-primary bg-soft text-primary text-center p-5">
              <Row>
                <Col>
                  <div className="avatar-md mx-auto">
                    <div className="avatar-title bg-white rounded-circle text-primary h1">
                      <i className="mdi mdi-video-off"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <h3 className="text-primary mt-4">{props.t("The call has ended").toUpperCase()}</h3>
              <span className="text-primary">{props.t("You may now close this page or return to starting via")} </span>
              <Link to={"/main"}>{props.t("this link")}</Link>
            </CardBody>
          </Card>
        </Col>
        <Col></Col>
      </Row>
    </>
  )
}

EndCallView.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EndCallView)