import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { withTranslation } from "react-i18next"
import moment from "moment"
import withRouter from "components/Common/withRouter"
import iconVideocall from "assets/images/icons/videocall.svg"
import iconApp from "assets/images/icons/app.svg"
import iconMedicine from "assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "assets/images/icons/groupvideo.png"
import iconWebpageAlt from "assets/images/icons/webIconAlt.svg"
import Lottie from "react-lottie"
import SimpleBar from "simplebar-react"
import loadingAnim from "common/animations/loading-dots-blue"
import {
  deleteReleaseNote,
  deleteStatusReport,
  getAdminStatusReports,
  getReleaseNotes,
  updateReleaseNote,
} from "helpers/database_helper"
import {
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { Switch, Popconfirm } from "antd"
import { showToast } from "utils/general"
import EmailSendModal from "../email-send-modal"
import ErrorBulletinsList from "./error-bulletins-list"
import ErrorBulletinsPreview from "./error-bulletins-preview"

const ErrorBulletinsIndex = props => {
  const [releaseNotes, setReleaseNotes] = useState([])
  const [guid, setGuid] = useState("")
  const [modalType, setModalType] = useState(2)
  const [selection, setSelection] = useState(null)
  const [targetFilter, setTargetFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("0")
  const [rowCount, setRowCount] = useState(100)
  const [productionDatabase, setProductionDatabase] = useState(true)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [count, setCount] = useState(0)
  const [example, setExample] = useState(null)
  const [subscribers, setSubscribers] = useState([])
  const [ongoingInsert, setOngoingInsert] = useState(true)
  const [contentLink, setContentLink] = useState()
  // Setting preview
  const [preview, setPreview] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [initialValues, setInitialValues] = useState({
    id: 0,
    current_status: 1,
    target_groups: [],
    published: false,
    start_time: moment(),
    end_time: moment(0),
    header_fi: "",
    header_en: "",
    header_sv: "",
    description_fi: "",
    description_en: "",
    description_sv: "",
  })

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Email bulletins") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Should be deleted?
  useEffect(() => {
    setSubscribers(props.subscribers)
  }, [props.subscribers])

  useEffect(() => {
    // console.log(props.update)
    if (props.update > 0) {
      fetchReleases(true)
    }
  }, [props.update])

  // DB selection changed, clear everything
  useEffect(() => {
    setSelection(null)
    props.setSelection(null)
    setReleaseNotes([])
    setSelectedIndex(null)
  }, [productionDatabase])

  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      current_status: initialValues.current_status,
      target_groups: initialValues.target_groups,
      published: initialValues.published,
      start_time: initialValues.start_time,
      end_time: initialValues.end_time,
      header_fi: initialValues.header_fi,
      header_en: initialValues.header_en,
      header_sv: initialValues.header_sv,
      description_fi: initialValues.description_fi,
      description_en: initialValues.description_en,
      description_sv: initialValues.description_sv,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      setError(null)
      // console.log("Submit values: ", values)

      let copy = JSON.parse(JSON.stringify(values))
      if (copy.target_groups != null && copy.target_groups.length > 0) {
        copy.target_groups = copy.target_groups.join(",")
      } else {
        copy.target_groups = ""
      }

      const sanitizeSettings = {
        allowedTags: ["b", "i", "em", "strong", "a"],
        allowedAttributes: {
          a: ["href", "target"],
        },
        nonBooleanAttributes: [],
      }

      copy.description_fi = sanitizeHtml(copy.description_fi, sanitizeSettings)
      copy.description_en = sanitizeHtml(copy.description_en, sanitizeSettings)
      copy.description_sv = sanitizeHtml(copy.description_sv, sanitizeSettings)

      // console.log("Submit final values: ", copy)

      if (values.id < 0) {
        insertBulletin(copy)
      } else {
        updateBulletin(copy)
      }
    },
  })

  // Form values changed, update example
  useEffect(() => {
    handleExample(validation.values)
  }, [validation.values])

  // Insert bulletin
  const insertBulletin = vals => {
    setOngoingInsert(true)
    insertStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchBulletins(true)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Update bulletin
  const updateBulletin = vals => {
    setOngoingInsert(true)
    updateStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchBulletins(false)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Get release notes
  const fetchReleases = (clearSelection = true) => {
    setCount(count + 1)
    setPreview(null)
    if (clearSelection) {
      setSelectedIndex(null)
      setSelection(null)
      props.setSelection(null)
    }
    // Set filters
    let filters = new Object()
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.current_status = statusNum
    setOngoingSearch(true)
    getAdminStatusReports(
      abortRef.current,
      productionDatabase,
      rowCount,
      filters
    )
      .then(notes => {
        notes.forEach(note => {
          const temp = note.start_time
            ? moment(note.start_time).format("DD.MM.YYYY")
            : ""
          note.label = temp + " " + note.header_fi
          note.value = note.id
        })
        setReleaseNotes(notes)
        // console.log("notes:", notes)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
      })
  }

  // Update release note
  const updateRelease = vals => {
    setOngoingInsert(true)
    updateReleaseNote(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          fetchReleases(false)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Delete release note
  const deleteRelease = modalId => {
    let id = -1
    if (preview) {
      let item = releaseNotes.filter(el => el.guid == preview)
      id = item[0].id
    } else {
      id = modalId ? modalId : selection.id
    }

    if (id >= 0) {
      setOngoingDelete(true)
      deleteStatusReport(abortRef.current, productionDatabase, id)
        .then(result => {
          if (result == "OK") {
            showToast(props.t("Deleted"), "success")
            setSelection(null)
            props.setSelection(null)
            setSelectedIndex(null)
            fetchReleases(true)
          } else {
            console.log("Delete result: ", result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
        })
    } else {
      setSelection(null)
      props.setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Lottie options
  const animationLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }

  // Get matching image
  const getImage = id => {
    switch (id) {
      case 1:
        return iconApp
      case 2:
        return iconMedicine
      case 3:
        return iconVideocall
      case 4:
        return iconWebpageAlt
      case 5:
        return iconGroupvideo
      default:
        return null
    }
  }

  // handleChange from preview
  const handleChangePreview = value => {
    let item = releaseNotes.filter(el => el.guid == value)
    handleChange(item[0].id)
  }

  // Selected release changed
  const handleChange = newSelection => {
    console.log("Option selected:", newSelection)
    var index = releaseNotes.findIndex(el => el.id === newSelection)
    if (index >= 0) {
      let copy = JSON.parse(JSON.stringify(releaseNotes[index]))
      copy.start_time = moment(copy.start_time)
      copy.end_time = moment(copy.end_time)
      if (copy.target_groups != null && copy.target_groups.length > 0)
        copy.target_groups = copy.target_groups.split(",")
      else copy.target_groups = []

      setSelection(copy)
      props.setSelection(copy)
      setSelectedIndex(newSelection)
      setInitialValues(copy)
    } else {
      setSelection(null)
      props.setSelection(null)
      setSelectedIndex(null)
    }
  }

  // Handle preview
  const handleExample = bulletin => {
    let niceDate = moment(bulletin.start_time).format("DD.MM.YYYY HH:mm")
    if (bulletin.end_time) {
      var endDate = new Date(bulletin.end_time)
      if (endDate > 0) {
        let endDateNice = moment(bulletin.end_time).format("DD.MM.YYYY HH:mm")
        niceDate = niceDate + " - " + endDateNice
      }
    }

    let temp = new Object()
    temp.id = bulletin.id
    temp.date = niceDate

    temp.title = bulletin.header_fi
    temp.title_fi = bulletin.header_fi
    temp.title_en = bulletin.header_en
    temp.title_sv = bulletin.header_sv

    temp.content1_fi = bulletin.description_fi
      ? bulletin.description_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = bulletin.description_en
      ? bulletin.description_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = bulletin.description_sv
      ? bulletin.description_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1 = temp.content1_fi

    let targets = []
    bulletin.target_groups.forEach(target => {
      switch (target) {
        case "1":
          targets.push("Suvanto Care app")
          break
        case "2":
          targets.push("Video call")
          break
        case "3":
          targets.push("Group video call")
          break
        case "4":
          targets.push("Carephone")
          break
        case "5":
          targets.push("Medicine reminder")
          break
        case "6":
          targets.push("Suvanto On the Go")
          break
        case "7":
          targets.push("Suvanto Home")
          break
        case "8":
          targets.push("Smartlock")
          break
        case "9":
          targets.push("Suvanto Care webpage")
          break
      }
    })
    temp.targets = targets

    switch (bulletin.current_status) {
      case 1:
      case "1":
        temp.status = "Service outage"
        temp.color = "#f46a6a"
        break
      case 2:
      case "2":
        temp.status = "Service disruption"
        temp.color = "#f1b44c"
        break
      case 3:
      case "3":
        temp.status = "Service operational"
        temp.color = "#34c38f"
        break
    }
    setExample(temp)
  }
  return (
    <>
      <EmailSendModal
        guid={guid}
        settingSelectedGuid={setGuid}
        modalType={modalType}
        subscribers={subscribers}
        contentLink={contentLink}
      ></EmailSendModal>
      <div className="email-bulletins-col-2">
        <Row className="">
          <Col sm="3">
            <FormGroup>
              <Input
                value={statusFilter}
                type="select"
                name="statusFilter"
                onChange={v => {
                  setStatusFilter(v.target.value)
                }}
              >
                <option value="0">{props.t("All")}</option>
                <option value="1">{props.t("Service outage")}</option>
                <option value="2">{props.t("Service disruption")}</option>
                <option value="3">{props.t("Service operational")}</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup>
              <Input
                id="number_limit"
                name="limit"
                type="number"
                onChange={v => {
                  if (v.target.value.length > 0)
                    setRowCount(parseInt(v.target.value, 10))
                }}
                defaultValue={rowCount}
              ></Input>
              <UncontrolledTooltip target="number_limit" placement="right">
                {props.t("Rows")}
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="mt-2">
              <Switch
                id="productionDatabase"
                className="me-2"
                name="productionDb"
                checked={productionDatabase}
                onChange={v => {
                  setProductionDatabase(v)
                }}
              ></Switch>

              <UncontrolledTooltip
                target="productionDatabase"
                placement="right"
              >
                {props.t("Production database")}
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col sm="3"></Col>
          <Col sm="2">
            <Button
              color="primary"
              disabled={ongoingSearch}
              onClick={() => fetchReleases(true)}
              className="mb-2 float-end px-3 d-flex"
            >
              <i className="bx bx-search-alt-2" />
              <div className="d-none d-md-block ms-2">{props.t("Search")}</div>
            </Button>
          </Col>
        </Row>
        <CardBody className="py-2">
          <Row>
            {ongoingSearch && (
              <Col className="d-inline-flex" style={{ alignItems: "center" }}>
                <Lottie options={animationLoading} height={36} width={100} />
              </Col>
            )}
            {!ongoingSearch && (
              <>
                {releaseNotes?.length > 0 && preview == null ? (
                  <>
                    <h5 className="mt-0 pt-0 pb-1">
                      {props.t("Error bulletins")}{" "}
                      <span className="pb-2 font-size-14 ms-1 text-muted">
                        {releaseNotes.length}
                      </span>
                    </h5>
                    <SimpleBar className="pe-3" style={{ maxHeight: "55vh" }}>
                      <div className="">
                        {releaseNotes.map(rn => (
                          <ErrorBulletinsList
                            settingPreview={setPreview}
                            settingSelectedId={handleChange}
                            key={rn.id}
                            item={rn}
                            subscribers={subscribers}
                            settingSelectedGuid={setGuid}
                            deleteRelease={deleteRelease}
                            setDeleteConfirm={setDeleteConfirm}
                            modalType={modalType}
                            fetchReleases={fetchReleases}
                            settingContentLink={setContentLink}
                          ></ErrorBulletinsList>
                        ))}
                      </div>
                    </SimpleBar>
                  </>
                ) : (
                  <>
                    {preview !== null ? (
                      <Row>
                        <Col className="col-auto d-flex flex-column">
                          <Button
                            id="back-btn-1"
                            color="primary"
                            className="btn-rounded btn btn-soft-primary mb-3"
                            onClick={() => setPreview(null)}
                          >
                            <i className="mdi mdi-arrow-left font-size-18"></i>
                          </Button>
                          {/* TODO: set working
                          <Button
                            id="back-btn-5"
                            color="transparent"
                            className=" mb-2"
                            onClick={() => handleChangePreview(preview)}
                          >
                            <i className="bx bx-mail-send font-size-18"></i>
                          </Button> */}
                          <Button
                            id="back-btn-3"
                            color="transparent"
                            className=" mb-2"
                            onClick={() => handleChangePreview(preview)}
                          >
                            <i className="bx bx-edit-alt  font-size-18"></i>
                          </Button>
                          <a
                            id="back-btn-2"
                            type="button"
                            href={"/disturbance-notice/" + preview}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            <Button color="transparent" className="mb-2">
                              <i className="bx bx-link-external font-size-18"></i>
                            </Button>
                          </a>
                          <Popconfirm
                            title={props.t("Confirm action")}
                            open={deleteConfirm}
                            okType="danger"
                            okText={props.t("Delete")}
                            cancelText={props.t("Cancel")}
                            onConfirm={() => {
                              deleteRelease()
                              setDeleteConfirm(false)
                            }}
                            onCancel={() => {
                              setDeleteConfirm(false)
                            }}
                          >
                            <Button
                              id="back-btn-4"
                              color="transparent"
                              className=" mb-2"
                              onClick={() => {
                                setDeleteConfirm(true)
                              }}
                            >
                              <i className="bx bx-trash font-size-18"></i>
                            </Button>
                          </Popconfirm>
                          <UncontrolledTooltip
                            target="back-btn-1"
                            placement="right"
                          >
                            {props.t("Move back")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-2"
                            placement="right"
                          >
                            {props.t("Open public link")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-3"
                            placement="right"
                          >
                            {props.t("Modify disturbance notice")}
                          </UncontrolledTooltip>
                          <UncontrolledTooltip
                            target="back-btn-4"
                            placement="right"
                          >
                            {props.t("Delete disturbance notice")}
                          </UncontrolledTooltip>
                          {/* <UncontrolledTooltip
                            target="back-btn-5"
                            placement="right"
                          >
                            {props.t("Send error notice")}
                          </UncontrolledTooltip> */}
                        </Col>
                        <Col>
                          <ErrorBulletinsPreview
                            guid={preview}
                          ></ErrorBulletinsPreview>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <div className="text-center">
                          <p className="pt-5 text-muted">
                            {count == 0 ? (
                              <>{props.t("Search for error notices")}</>
                            ) : (
                              <>{props.t("No release error notices")}</>
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        </CardBody>
      </div>
    </>
  )
}

ErrorBulletinsIndex.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  releaseNotes: PropTypes.any,
  setSelection: PropTypes.any,
  fetchReleases: PropTypes.any,
  subscribers: PropTypes.any,
  handleSend: PropTypes.any,
}

export default withRouter(withTranslation()(ErrorBulletinsIndex))
