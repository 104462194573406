import React from "react"
import PropTypes from "prop-types"
import Chip from "@material-ui/core/Chip"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ReleaseListItem = props => {
  return (
    <>
      <div className="d-flex">
        <div className="me-3">
          <img
            src={props.item.img}
            alt=""
            className="avatar-xs d-none d-sm-block rounded pb-1 pt-1 "
            style={{ objectFit: "contain" }}
          />
        </div>
        <div
          //type="button"
          //onClick={() => props.onOpenModal(props.item)}
          className="align-self-center overflow-hidden me-auto"
        >
          <div>
            <h5 className="font-size-14 text-truncate mb-1">
              {/** 
              <Link to="#" className="text-dark">
                {props.t(props.item.target) + " v." + props.item.version}
              </Link>*/}
              <a
                href={"/release-note/" + props.item.guid}
                target="_blank"
                rel="noreferrer"
              >
                {props.t(props.item.target) + " v." + props.item.version}
              </a>
            </h5>
            <p className="text-muted mb-1">{props.item.date}</p>
            <Chip
              //avatar={<i className="bx bxs-hot text-danger ms-0" style={{textAlign: "end", alignSelf: "end"}}></i>}
              size="small"
              key="chip"
              className="font-size-11 me-2 my-2"
              label={props.t(props.item.type)}
            />
            <Chip
              size="small"
              key="chip2"
              className="font-size-11 me-2 my-2"
              label={props.t(props.item.status)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

ReleaseListItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  onOpenModal: PropTypes.any,
}

export default withRouter(withTranslation()(ReleaseListItem))
