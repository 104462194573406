/***********************
 * INDEX TAB4 CONTENT
 * (GROUPVIDEOCALLS)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Form, Nav, NavItem, NavLink, Row, Tooltip } from "reactstrap"

//Classnames
import classnames from "classnames"

// Locale
import { withTranslation } from "react-i18next"

// Importing components
import TableCalls from "./components/calls-table"
import CallsChart from "./components/calls-chart"
import CallsChartLinear from "./components/calls-chart-linear"

const GroupVideoCallsTabReporting = props => {
  const [callsData, setCallsData] = useState([])
  // Passing customers data to cards/stats
  const [customers, setCustomers] = useState()
  // Tooltip states
  const [TTnavcardtop, setTTnavcardtop] = useState(false)
  const [TTnavtabletop, setTTnavtabletop] = useState(false)
  // Filter view related states
  const [activeTab, setActiveTab] = useState("1")
  const [widgetVisible, setWidgetVisible] = useState(true)
  const [tableVisible, setTableVisible] = useState(false)

  useEffect(() => {
    if (props.activeTab == 4) {
      console.log("Group calls data changed: " + JSON.stringify(props.data))
      setCallsData(props.data)
      setCustomers(props.customers)
    }
  }, [props.data, props.activeTab])

  useEffect(() => {
    console.log("group calls active")
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setWidgetVisible(!widgetVisible)
      setTableVisible(!tableVisible)
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          {/* <div className=" float-end">
            <Form>
              <Nav className="product-view-nav" pills>
                <NavItem>
                  <Tooltip
                    placement="bottom"
                    isOpen={TTnavcardtop}
                    target="TTnavcardservice"
                    toggle={() => {
                      setTTnavcardtop(!TTnavcardtop)
                    }}
                  >
                    {props.t("Cards")}
                  </Tooltip>
                  <NavLink
                    id="TTnavcardservice"
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    <i
                      style={{ padding: "10px" }}
                      className="bx bx-grid-alt text-sixe-16"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <Tooltip
                    isOpen={TTnavtabletop}
                    target="TTnavtableservice"
                    placement="bottom"
                    toggle={() => {
                      setTTnavtabletop(!TTnavtabletop)
                    }}
                  >
                    {props.t("Table")}
                  </Tooltip>
                  <NavLink
                    id="TTnavtableservice"
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    <i
                      style={{ padding: "10px" }}
                      className="bx bx-list-ul text-sixe-16"
                    />
                  </NavLink>
                </NavItem>
              </Nav>
            </Form>
          </div> */}
          {activeTab == "1" && props.data !== undefined && (
            // {props.activeTab == 4 && props.data !== undefined && (
            <div className="mt-2 mb-1">
              <Row>
                <CallsChart
                  // selectedType={props.selectedType}
                  customers={customers}
                  data={callsData}
                  start={props.start}
                  end={props.end}
                ></CallsChart>
              </Row>
              <Row>
                <TableCalls
                  minRows={0}
                  selectedType={props.selectedType}
                  data={callsData}
                />
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

GroupVideoCallsTabReporting.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  customers: PropTypes.any,
  activeTab: PropTypes.any,
  selectedType: PropTypes.any,
  start: PropTypes.any,
  end: PropTypes.any,
}

export default withTranslation()(GroupVideoCallsTabReporting)
