/***********************
 * REMOTE MEASUREMENTS
 ************************/

import React, { useState, useEffect, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"

//Chart import
import ReactApexChart from "react-apexcharts"

//Translation
import { withTranslation } from "react-i18next"

const ChartRM = props => {
  const [stats, setStats] = useState({})
  const [list, setList] = useState({})

  // Filtering customer names from types
  const [oxygenSaturation, setOxygenSaturation] = useState([])
  const [total, setTotal] = useState([])
  const [spirometry, setSpirometry] = useState([])
  const [temperature, setTemperature] = useState([])
  const [pulse, setPulse] = useState([])
  const [weight, setWeight] = useState([])
  const [bloodPressure, setBloodPressure] = useState([])
  const [bloodSugar, setBloodSugar] = useState([])

  // Setting search field material
  const [findSearch, setFindSearch] = useState([])

  // Filtering customer names from types
  const [customerOS, setCustomerOS] = useState([])
  const [customerS, setCustomerS] = useState([])
  const [customerT, setCustomerT] = useState([])
  const [customerP, setCustomerP] = useState([])
  const [customerW, setCustomerW] = useState([])
  const [customerBP, setCustomerBP] = useState([])
  const [customerBS, setCustomerBS] = useState([])

  // Updating states
  useEffect(() => {
    if (
      props.data != null &&
      props.data.stats != null &&
      props.data.stats != stats
    ) {
      console.log("Stats: " + JSON.stringify(props.data))

      // set chosen groups/customers from props reporting.js
      setFindSearch(props.customers)

      //Setting stats, list
      setStats(props.data.stats)
      setList(props.data.list)

      // Setting variables for charts
      setOxygenSaturation(props.data.stats.oxygen_saturation)
      setTotal(props.data.stats.total)
      setSpirometry(props.data.stats.spirometry)
      setTemperature(props.data.stats.temperature)
      setPulse(props.data.stats.pulse)
      setWeight(props.data.stats.weight)
      setBloodPressure(props.data.stats.blood_pressure)
      setBloodSugar(props.data.stats.blood_sugar)

      //Setting names by service function
      filterCustomersByService(props.data.list)
    }
  }, [props.data])

  // Chart options
  const getChartOptions = index => {
    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }
    switch (index) {
      case 0:
        options["colors"][0] = "#0061db" // color: loading-blue-dots
        break
      case 1:
        options["colors"][0] = "#97cd00" //color: suvanto-green
        break
      case 2:
        options["colors"][0] = "#00a0d6" //color: suvanto-blue-3-1
        break
      case 3:
        options["colors"][0] = "#f46a6a" //color: danger
        break
      case 4:
        options["colors"][0] = "#34c38f" //color: success
        break
      case 5:
        options["colors"][0] = "#ff9b00" //color: suvanto-orange
        break
      case 6:
        options["colors"][0] = "#343a40" //color: secondary
        break
      default:
        break
    }
    return options
  }

  // Delete duplicates from names array
  const deleteDuplicated = array => {
    let setNew = Array.from(new Set(array))
    return setNew
  }

  // Stat card values
  const cardValues = [
    {
      id: 0,
      value: bloodPressure,
      text: props.t("Blood pressure"),
      icon: "bx bx-home",
      color: "warning",
      series: toPercentage(bloodPressure),
      customers: deleteDuplicated(customerBP),
    },
    {
      id: 1,
      value: pulse,
      text: props.t("Pulse"),
      icon: "mdi mdi-cellphone-sound",
      color: "primary",
      series: toPercentage(pulse),
      customers: deleteDuplicated(customerP),
    },
    {
      id: 2,
      value: weight,
      text: props.t("Weight"),
      icon: "mdi mdi-phone-ring-outline",
      color: "info",
      series: toPercentage(weight),
      customers: deleteDuplicated(customerW),
    },
    {
      id: 3,
      value: bloodSugar,
      text: props.t("Blood sugar"),
      color: "danger",
      icon: "mdi mdi-cellphone-link",
      series: toPercentage(bloodSugar),
      customers: deleteDuplicated(customerBS),
    },
    {
      id: 4,
      value: oxygenSaturation,
      text: props.t("Oxygen saturation"),
      icon: "bx bx-stats",
      color: "success",
      series: toPercentage(oxygenSaturation),
      customers: deleteDuplicated(customerOS),
    },
    {
      id: 5,
      value: spirometry,
      text: props.t("Spirometry"),
      icon: "mdi mdi-medical-bag",
      color: "pink",
      series: toPercentage(spirometry),
      customers: deleteDuplicated(customerS),
    },
    {
      id: 6,
      value: temperature,
      text: props.t("Temperature"),
      icon: "mdi mdi-shield-lock-outline",
      color: "secondary",
      series: toPercentage(temperature),
      customers: deleteDuplicated(customerT),
    },
  ]

  // For radial bars
  function toPercentage(value) {
    if (value != 0) {
      var result = Math.round((value / total) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  // Filtering customer names from types
  const filterCustomersByService = data => {
    data.filter(n => {
      // Finding home services users
      if (n.oxygen_saturation === true) {
        setCustomerOS(customerO => [...customerO, n.client_name])
      }
      if (n.spirometry === true) {
        setCustomerS(customerS => [...customerS, n.client_name])
      }
      if (n.temperature === true) {
        setCustomerT(customerT => [...customerT, n.client_name])
      }
      if (n.pulse === true) {
        setCustomerP(customerP => [...customerP, n.client_name])
      }
      if (n.weight === true) {
        setCustomerW(customerW => [...customerW, n.client_name])
      }
      if (n.blood_pressure === true) {
        setCustomerBP(customerBP => [...customerBP, n.client_name])
      }
      if (n.blood_sugar === true) {
        setCustomerBS(customerBS => [...customerBS, n.client_name])
      }
    })
  }

  return (
    <React.Fragment>
      <div id="services-chart">
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Remote measurements")}</h5>
            </span>
          </div>
        </Row>
        <Col className="mt-1" xl="12">
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="mt-3 mb-3 text-center" xs="12">
                      <div className="">
                        <div className="mb-4 text-primary display-6">
                          <i className="fas fa-file-medical-alt " />
                        </div>
                        <div className="mt-2 mb-0">
                          <h3>{total}</h3>
                          {total > 1 ? (
                            <p className="">{props.t("measurements")}</p>
                          ) : (
                            <p className="">{props.t("measurement")}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {cardValues.map((item, index) => {
              const options2 = getChartOptions(item.id)
              return (
                <Fragment key={index}>
                  {item.value != 0 ? (
                    <Col sm="3">
                      <Card>
                        <CardBody>
                          <CardTitle>{item.text}</CardTitle>
                          <Row>
                            <Col xs="6">
                              <div className="text-center">
                                <h2 className="mt-4">{item.value}</h2>
                              </div>
                            </Col>
                            <Col id={"col_" + item.id} xs="6">
                              <div id="wallet-balance-chart">
                                <ReactApexChart
                                  options={options2}
                                  series={item.series}
                                  type="radialBar"
                                  height={90}
                                  width={90}
                                  className="apex-charts"
                                ></ReactApexChart>
                              </div>
                            </Col>{" "}
                          </Row>
                          <Col xs="12">
                            <div className="text-muted text-truncate mt-3">
                              <span>{item.series}%</span>
                              <span> {props.t("of all measurements")}</span>
                            </div>
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : null}
                </Fragment>
              )
            })}
          </Row>
        </Col>
      </div>
    </React.Fragment>
  )
}

ChartRM.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  customers: PropTypes.any,
}

export default withTranslation()(ChartRM)
