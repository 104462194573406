import {
  getJournalEntries,
  getJournalResponses,
  addMessageToJournal,
  addJournalResponse,
  deleteJournalEntries,
  editJournalEntry,
  getJournalFileDownloadLink,
  endOngoingVisit,
  downloadJournalAttachment,
  uploadJournalAttachment,
} from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useContext } from "react"
import { getLottieLoadingOptions, formatDateFull } from "utils/general"
import {
  Alert,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Modal,
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Label,
  Tooltip,
  Collapse,
  FormGroup,
  ModalFooter,
  UncontrolledTooltip,
  Form,
  ModalHeader,
  ModalBody,
  CardTitle,
} from "reactstrap"

import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import moment from "moment"
import Lottie from "react-lottie"
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import CustomRangePicker from "components/DatePickers/RangePicker"
//Excel export
import XLSX from "xlsx"

//  antd
import { Select, TreeSelect, Switch, ConfigProvider, Popconfirm } from "antd"
import { showToast } from "utils/general"
import GlobalState from "contexts/GlobalState"
import { Socket } from "socket.io-client"

const { Option } = Select
const { SHOW_PARENT } = TreeSelect

//
// TEST Socket
import SocketContext from "contexts/SocketContext/context"
import { socket } from "sockets"
//
//

// Journal page
const JournalServices = props => {
  const abortRef = useRef(null)
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [entries, setEntries] = useState([])
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "weeks"),
    moment(),
  ])
  const [uploadMessage, setUploadMessage] = useState("")

  const [downloadLink, setDownloadLink] = useState("")

  // Export name
  const [title, setTitle] = useState(props.t("Journal entries"))

  // Filtered data
  const [filteredData, setFilteredData] = useState(entries)
  const [filteredDataTemp, setFilteredDataTemp] = useState([])

  // Search field
  const [searchInput, setSearchInput] = useState("")

  // Delete MEssages
  const [deleteMessages, setDeleteMessages] = useState(false)
  const [selectDeleteMessage, setSelectDeleteMessage] = useState([])
  //Show opened entry
  const [entryOpen, setEntryOpen] = useState({})
  //Show opened file save
  const [fileSaveOpen, setFileSaveOpen] = useState({})
  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)
  // Loading state
  const [isLoading, setIsLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [action, setAction] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  // Messages
  const [messages, setMessages] = useState([])
  const [loadingResponses, setLoadingResponses] = useState(false)
  // Send message
  const [currentMessage, setCurrentMessage] = useState("")
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Date sort
  const [descending, setDescending] = useState(true)
  // New entry modal
  const [modalMessage, setModalMessage] = useState(false)
  const [deleteLength, setDeleteLength] = useState(0)
  //New file
  const [modalFile, setModalFile] = useState(false)
  // Edit modal
  const [editModal, setEditModal] = useState(false)
  const [selectedFile, setselectedFile] = useState([])
  const [selectedDataBase64, setSelectedDataBase64] = useState("")
  const [loadingFile, setLoadingFile] = useState(false)
  // Filter
  const [typeFilter, setTypeFilter] = useState(["all"])
  // Customize tree
  const [customize, setCustomize] = useState(true)
  // Date groups
  const [dateGroups, setDateGroups] = useState()
  // Edited messages
  const [editedMessage, setEditedMessage] = useState("")
  const [editedKey, setEditedKey] = useState("")

  // TEST SOCKET
  const { positionInLine, queueLength } = useContext(SocketContext)

  const treeData = [
    {
      title: props.t("All"),
      value: "all",
      key: "all",

      children: [
        {
          title: props.t("Calls"),
          value: "call_",
          key: "call_",
          children: [
            {
              title: props.t("Video call"),
              value: "call_video",
              key: "call_video",
            },
            {
              title: props.t("Group video call"),
              value: "call_group",
              key: "call_group",
            },
            {
              title: props.t("Call to tracker"),
              value: "call_tracker",
              key: "call_tracker",
            },
            {
              title: props.t("Call to carephone"),
              value: "call_carephone",
              key: "call_carephone",
            },
            {
              title: props.t("Call to phone"),
              value: "call_phone",
              key: "call_phone",
            },
          ],
        },
        {
          title: props.t("Alarms"),
          value: "alarm_",
          key: "alarm_",
          children: [
            {
              title: props.t("Alarm received"),
              value: "alarm_new",
              key: "alarm_new",
            },
            {
              title: props.t("Alarm acknowledged"),
              value: "alarm_ack",
              key: "alarm_ack",
            },
            {
              title: props.t("Alarm closed"),
              value: "alarm_done",
              key: "alarm_done",
            },
          ],
        },
        {
          title: props.t("Dose"),
          value: "dose",
          key: "dose",
        },
        {
          title: props.t("Attachment"),
          value: "file",
          key: "file",
        },
        {
          title: props.t("Remote measurement"),
          value: "measurement",
          key: "measurement",
        },
        {
          title: props.t("Messages"),
          value: "messag",
          key: "messag",
          children: [
            {
              title: props.t("Message to client"),
              value: "message_client",
              key: "message_client",
            },
            {
              title: props.t("Message"),
              value: "message",
              key: "message",
            },
          ],
        },
        {
          title: props.t("Smartlock"),
          value: "smartlocks",
          key: "smartlocks",
        },
        {
          title: props.t("Visit"),
          value: "visit",
          key: "visit",
        },
      ],
    },
  ]

  // Filter types
  let types = [
    { checked: "true", name: "all", color: "light", type: "all" },
    { checked: "false", name: "calls", color: "primary", type: "call" },
    { checked: "false", name: "alarms", color: "danger", type: "alarm" },
    { checked: "false", name: "dose", color: "chart-color1", type: "dose" },
    { checked: "false", name: "files", color: "chart-color2", type: "file" },
    {
      checked: "false",
      name: "measurements",
      color: "chart-color3",
      type: "measurement",
    },
    {
      checked: "false",
      name: "messages",
      color: "chart-color4",
      type: "message",
    },
    {
      checked: "false",
      name: "messages to client",
      color: "chart-color5",
      type: "message_client",
    },
    {
      checked: "false",
      name: "smartlocks",
      color: "chart-color6",
      type: "smartlock",
    },
    { checked: "false", name: "visits", color: "chart-color7", type: "visit" },
  ]

  // Selected types select dropdown content
  const itemsTypeJSX = []
  types.forEach(item => {
    itemsTypeJSX.push(
      <Option checked={item.checked} value={item.type} label={item.name}>
        <div className="demo-option-label-item">
          <span aria-label={item.name}>
            <i
              className={"dripicons-dot font-size-16 me-2 text-" + item.color}
            ></i>
          </span>
          {item.name}
        </div>
      </Option>
    )
  })

  useEffect(() => {
    if (selectedFile.length == 0) {
      setUploadMessage("")
    }
  }, [selectedFile])

  // TEST ISTEK
  useEffect(() => {
    // console.log(queueLength)
  }, [queueLength])

  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      setDateRange(prevState => [prevState[0], moment()])
    })

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }

    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Reset messages when modal closed
  useEffect(() => {
    setSuccessMessage(null)
    setErrorMessage(null)
  }, [modalMessage])

  // get entries if key/date changed
  useEffect(() => {
    getEntries()
  }, [props.suvantoKey, dateRange])

  // Filter by selected types
  useEffect(() => {
    if (entries != undefined) {
      setFilteredDataTemp([])
      if (typeFilter == "all") {
        if (searchInput !== "") {
          let value = searchInput.toLowerCase()
          let result = []
          result = entries.filter(
            data =>
              data.entryTypeDescription?.toLowerCase().includes(value) ||
              data.fullName?.toLowerCase().includes(value) ||
              data.title?.toLowerCase().includes(value) ||
              data.message?.toLowerCase().includes(value)
          )
          setFilteredDataTemp(result)
        } else {
          setFilteredDataTemp(entries)
        }
      }
      if (typeFilter.length == 0) {
        setFilteredDataTemp([])
      } else {
        let tagEntries = []
        if (searchInput !== "") {
          let value = searchInput.toLowerCase()
          tagEntries = entries.filter(
            data =>
              data.entryTypeDescription?.toLowerCase().includes(value) ||
              data.fullName?.toLowerCase().includes(value) ||
              data.title?.toLowerCase().includes(value) ||
              data.message?.toLowerCase().includes(value)
          )
        } else {
          tagEntries = entries
        }
        for (let i = 0; i < typeFilter.length; i++) {
          tagEntries.filter(data => {
            if (typeFilter[i] == "message") {
              if (data.type == typeFilter[i]) {
                setFilteredDataTemp(prev => [...prev, data])
              }
            } else {
              if (data.type?.indexOf(typeFilter[i]) == 0) {
                setFilteredDataTemp(prev => [...prev, data])
              }
            }
          })
        }
      }
    }
  }, [typeFilter, entries])
  // Filter by selected types
  useEffect(() => {
    sortData(filteredDataTemp, descending)
    setFilteredData(filteredDataTemp)
  }, [filteredDataTemp])

  // get entries, TODO: set socket.io
  useEffect(() => {
    if (!isLoading) {
      const interval = setInterval(() => {
        getEntries(true)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [isLoading])

  // Get entries
  const getEntries = noLoading => {
    if (noLoading) {
      // console.log("no loading")
      if (isLoading == false) {
        // console.log("isloading", dateRange)
        if (dateRange && props.suvantoKey) {
          getJournalEntries(
            abortRef.current,
            props.suvantoKey,
            dateRange[0].valueOf(),
            dateRange[1].valueOf()
          )
            .then(data => {
              if (data.entries) {
                // console.log("Entries: ", data)
                data.entries.forEach(element => {
                  formatJournalEntry(element)
                })
                let arrange = sortData(data.entries, descending)

                if (JSON.stringify(entries) === JSON.stringify(arrange)) {
                  // console.log("same")
                } else {
                  console.log("New entries: ", arrange)
                  setEntries(arrange)
                  // console.log(groupByDate(arrange))
                  setDateGroups(groupByDate(arrange))
                  //setFilteredData(arrange) // Filtered data if set after type filter
                }
              } else {
                setEntries([])
              }
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              // setIsLoading(false)
            })
        }
      }
    } else {
      // console.log("loading")
      if (dateRange && props.suvantoKey) {
        // console.log("isNOTloading", dateRange)
        setIsLoading(true)
        getJournalEntries(
          abortRef.current,
          props.suvantoKey,
          dateRange[0].valueOf(),
          dateRange[1].valueOf()
        )
          .then(data => {
            if (data.entries) {
              console.log("Entries: ", data)
              data.entries.forEach(element => {
                formatJournalEntry(element)
              })
              let arrange = sortData(data.entries, descending)
              setEntries(arrange)
              // console.log(groupByDate(arrange))
              setDateGroups(groupByDate(arrange))
              //setFilteredData(arrange) // Filtered data if set after type filter
            } else {
              setEntries([])
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  // Get journal entry responses
  const getResponses = key => {
    setMessages([])
    setLoadingResponses(true)
    getJournalResponses(abortRef.current, key)
      .then(data => {
        if (data.entries) {
          console.log(data.entries, "entries")
          data.entries.forEach(element => {
            let date = new Date(element.date)
            element.dateNice = moment(date).format("DD.MM.YYYY HH:mm:ss")
            // console.log(element.dateNice, "formatted date")
          })
          setMessages(data.entries)
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoadingResponses(false)
      })
  }
  // Test: Sort by date to groups
  const groupByDate = notesData => {
    return notesData.reduce((groups, note) => {
      const date = new Date(note.date).toDateString()
      if (!groups.hasOwnProperty(date)) {
        groups[date] = []
      }
      groups[date].push(note)
      return groups
    }, {})
  }
  // * Test: Sort by date to groups, end

  // Delete selected entry (or entries)
  const deleteSelectedEntries = key => {
    console.log(key)
    if (props.suvantoKey && !deleting && (selectDeleteMessage || key)) {
      console.log(key)
      let messages = []
      if (key) {
        messages = [key]
      } else {
        messages = selectDeleteMessage
      }

      console.log(key, messages)
      setDeleting(true)
      // TODO: Only system admins can delete, others can delete from sight !!! ISTEK
      deleteJournalEntries(abortRef.current, props.suvantoKey, messages)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setSelectDeleteMessage([])
            setDeleteConfirm(false)
            setDeleteMessages(false)
            setDeleteLength(0)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setDeleting(false)
          getEntries()
        })
    }
  }

  // End ongoing visit
  // TODO:check if really want to end visit
  const endVisit = key => {
    setAction(true)
    endOngoingVisit(abortRef.current, key)
      .then(result => {
        if (result.statusCode == 200) {
          showToast(props.t("Ongoing visit ended"), "success")
          setDateRange(prevState => [prevState[0], moment()])
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setAction(false)
      })
  }

  const formatJournalEntry = entry => {
    switch (entry.type) {
      case "alarm_new":
        entry.entryTypeDescription = "Alarm received"
        entry.title = "AlarmTitles." + entry.title
        break
      case "alarm_ack":
        entry.entryTypeDescription = "Alarm acknowledged"
        entry.title = "AlarmTitles." + entry.title
        break
      case "alarm_done":
        entry.entryTypeDescription = "Alarm closed"
        entry.title = "AlarmTitles." + entry.title
        break

      case "call_video":
        entry.entryTypeDescription = "Video call"
        break
      case "call_group":
        entry.entryTypeDescription = "Group video call"
        break
      case "call_tracker":
        entry.entryTypeDescription = "Call to tracker"
        break
      case "call_carephone":
        entry.entryTypeDescription = "Call to carephone"
        break
      case "call_phone":
        entry.entryTypeDescription = "Call to phone"
        break

      case "dose":
        entry.entryTypeDescription = "Medicine reminder"

        if (entry.subType == "dose_taken") {
          entry.message = "<TIME> " + props.t("dose acked")
        } else if (entry.subType == "dose_untaken") {
          entry.message = "<TIME> " + props.t("dose missed")
        } else if (entry.subType == "dose_count") {
          entry.message = props.t("Dose count set to") + " <COUNT>"
        } else if (entry.subType == "dose_side_ack") {
          entry.message = "<TIME> '<EVENT>' " + props.t("acked")
        } else if (entry.subType == "dose_side_unack")
          entry.message = "<TIME> '<EVENT>' " + props.t("missed")

        if (entry.message.indexOf("<TIME>") > -1) {
          entry.message = entry.message.replace(
            "<TIME>",
            entry.additionalData.time
          )
        }
        if (entry.message.indexOf("<COUNT>") > -1) {
          entry.message = entry.message.replace(
            "<COUNT>",
            entry.additionalData.count
          )
        }
        if (entry.message.indexOf("<EVENT>") > -1) {
          entry.message = entry.message.replace(
            "<EVENT>",
            entry.additionalData.event
          )
        }
        break

      case "file":
        entry.entryTypeDescription = "Attachment"
        break

      case "measurement":
        entry.entryTypeDescription = "Measurement"
        if (entry.subType == "manual") {
          entry.entryTypeDescription = "Measurement (manual input)"
        }

        entry.message = entry.message.replace(
          "[blood_pressure]",
          props.t("Blood pressure")
        )
        entry.message = entry.message.replace("[pulse]", props.t("Pulse"))
        entry.message = entry.message.replace("[weight]", props.t("Weigth"))
        entry.message = entry.message.replace(
          "[temperature]",
          props.t("Temperature")
        )
        entry.message = entry.message.replace(
          "[saturation]",
          props.t("Oxygen saturation")
        )
        entry.message = entry.message.replace(
          "[perfusion]",
          props.t("Perfusion")
        )
        entry.message = entry.message.replace(
          "[blood_sugar]",
          props.t("Blood sugar")
        )
        break

      case "message":
        entry.entryTypeDescription = "Message"
        break
      case "message_client":
        entry.entryTypeDescription = "Message to client"
        break
      case "smartlock":
        entry.entryTypeDescription = "Smartlock"
        if (entry.subType == "open_permission")
          entry.message = "Open permission granted"
        else if (entry.subType == "door_open") entry.message = "Door opened"
        else if (entry.subType == "door_close") entry.message = "Door closed"
        else if (entry.subType == "pin_entered")
          entry.message = "Granted access with PIN code"
        break
      case "visit":
        entry.entryTypeDescription = "Visit"
        break
      case "home":
        entry.entryTypeDescription = "Home"
        if (entry.subType == "away")
          entry.entryTypeDescription = "Away from home"
        else if (entry.subType == "returned")
          entry.entryTypeDescription = "Returned to home"
        break
      default:
        entry.entryTypeDescription = entry.type
        break
    }
  }
  // Change filter type
  const onChange = newValue => {
    setTypeFilter(newValue)
  }
  // Customized empty for tree
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("Entry type not found")}</p>
    </div>
  )

  //Showing clicked mapped item
  const toggleEntry = (e, ec, event) => {
    console.log(event.target.id)
    if (event.target.id !== "downloadfile-button" && event.target.id !== "downloadfile-button-a") {
    if (ec != undefined && !entryOpen[e]) {
      getResponses(e)
    }

    if (!entryOpen[e]) {
      setEntryOpen({[e]:true})
    }
    else {
      setEntryOpen({})
    }
    }
    //prettier-ignore
    //setEntryOpen((prevState => ({ ...prevState, [e]: !prevState[e] })))
  }
  //Showing dowload link file
  const toggleFileSave = e => {
    console.log(e)
    downloadAttachment(e)
    if (!fileSaveOpen[e]) {
        setFileSaveOpen({[e]:true})
      }
      else {
        setFileSaveOpen({})
      }
    //prettier-ignore
    //setEntryOpen((prevState => ({ ...prevState, [e]: !prevState[e] })))
  }

  //  ** START OF MODAL FUNCTIONS ** //
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const openMessageModal = () => {
    removeBodyCss()
    validationMessage.resetForm()
    validationEditMessage.resetForm()
    setModalMessage(!modalMessage)
    // setEditModal(false)
  }
  const openFileModal = () => {
    removeBodyCss()
    if (modalFile == false) {
      setselectedFile([])
    }
    setModalFile(!modalFile)
  }
  // Set edited
  const addResponse = entry => {
    if (entry && currentMessage && !action) {
      setAction(true)
      addJournalResponse(abortRef.current, entry.key, currentMessage)
        .then(data => {
          if (!entry.responseCount) entry.responseCount = 1
          else entry.responseCount += 1
          setCurrentMessage("")
          getResponses(entry.key)
          // TODO: Show toast?
        })
        .catch(e => {
          console.log(e)
          // TODO: Show toast
          showToast(props.t("Error", "error"))
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Add response
  const editMessage = (key, message) => {
    setEditModal(true)
    setModalMessage(true)
    setEditedMessage(message)
    setEditedKey(key)
  }

  // File download
  const getDownloadLink = journalKey => {
    if (!action && journalKey) {
      setAction(true)
      getJournalFileDownloadLink(abortRef.current, journalKey)
        .then(result => {
          console.log("File download result: ", result)
          if (result.statusCode == 200 && result.link) {
            window.open(result.link, "_blank", "noreferrer")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // File download - blob
  const downloadAttachment = journalKey => {
    if (!action && journalKey) {
      setAction(true)
      downloadJournalAttachment(abortRef.current, journalKey)
        .then(result => {
          if (result.statusCode == 200) {
            let temp = "data:application/octet-stream;base64," + result.data // TODO: Set correct mime type?
            setDownloadLink(temp)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // File upload - base64 encoded
  const uploadAttachment = file => {
    if (!action && props.suvantoKey) {
      setAction(true)

      // File object
      // Todo: blob
      const { blob, name, type } = file

      // uploadJournalAttachment(
      //   abortRef.current,
      //   props.suvantoKey,
      //   "<BLOB>",
      //   "test.jpg",
      //   "jpg"
      // )

      console.log(abortRef.current, props.suvantoKey, blob, name, type, "send")
      //console.log("Data URL: ", selectedDataBase64)
      uploadJournalAttachment(
        abortRef.current,
        props.suvantoKey,
        selectedDataBase64,
        name,
        type
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setModalFile(false)
            setSelectedDataBase64("")
            setselectedFile([])
            setTimeout(() => {
              setDateRange(prevState => [prevState[0], moment()])
            }, 1500)
          } else {
            console.log(result)
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // New message
  const validationMessage = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      message: "",
      showAsAdmin: false,
      //newMessage: newMessage,
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(225, props.t("Message is too long")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      // set entry manual message
      addMessageToJournal(
        abortRef.current,
        props.suvantoKey,
        values.title,
        values.message,
        values.showAsAdmin
      )
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            openMessageModal()
            setDateRange(prevState => [prevState[0], moment()])
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })

  // Edit message
  // TODO: Writing text is slow
  const validationEditMessage = useFormik({
    enableReinitialize: true,
    initialValues: {
      journalKey: editedKey,
      message: editedMessage,
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(225, props.t("Message is too long")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      // set manual message
      editJournalEntry(abortRef.current, values.journalKey, values.message)
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccessMessage("Success")
            setDateRange(prevState => [prevState[0], moment()])
            openMessageModal()
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })
  //*** END OF MODAL FUNCTIONS ***//

  // Hide messages if not system_admin

  const settingMessageEmpty = (journalKey, message) => {
    let newMessage = ""
    editJournalEntry(abortRef.current, journalKey, newMessage)
      .then(result => {
        console.log(result)
        if (result.statusCode != null && result.statusCode == 200) {
          setSuccessMessage("Success")
          setDateRange(prevState => [prevState[0], moment()])
        } else {
          setErrorMessage("Error")
        }
      })
      .catch(err => {
        console.log(err)
        setErrorMessage("Error")
      })
      .finally(() => {
        setSaveDisable(false)
      })
  }

  // Handle search field function
  const handleSearch = event => {
    if (entries != undefined) {
      setSearchInput(event.target.value)
      let value = event.target.value.toLowerCase()
      let result = []
      result = filteredData.filter(
        data =>
          data.entryTypeDescription?.toLowerCase().includes(value) ||
          data.fullName?.toLowerCase().includes(value) ||
          data.title?.toLowerCase().includes(value) ||
          data.message?.toLowerCase().includes(value)
      )
      setFilteredDataTemp(result)
      // Set original data result
      if (value == "") {
        setFilteredDataTemp(entries)
      }
    }
  }
  // Sorting order of entries by date + order
  const sortData = (data, order) => {
    if (order == false) {
      let y = data.sort((a, b) => a.date - b.date)
      return y
    }
    if (order == true) {
      let n = data.sort((a, b) => b.date - a.date)
      return n
    }
  }
  // Setting order of date
  const arrange = () => {
    let value = !descending
    setDescending(value)
    sortData(filteredData, value)
  }

  // format duration seconds
  const formatSeconds = value => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - minutes * 60
    if (hours > 0) {
      hours = hours + "" + props.t("h") + " "
    }
    if (hours == 0) {
      hours = ""
    }
    if (minutes > 0) {
      minutes = minutes + "" + props.t("min") + " "
    }
    if (minutes == 0) {
      minutes = ""
    }
    if (seconds > 0) {
      seconds = seconds + "" + props.t("s")
    }
    if (seconds == 0) {
      seconds = ""
    }
    return value ? hours + minutes + seconds : ""
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    // TODO: Include comments and message ( problem with mapping undefined object )
    const Heading = [
      [
        props.t("Time"),
        props.t("Name"),
        props.t("Type"),
        props.t("Title"),
        props.t("Message"),
      ],
    ]
    const data = filteredData.map(elt => [
      moment(elt.date).format("DD.MM.YYYY HH:mm:ss"),
      elt.fullName,
      props.t(elt.entryTypeDescription),
      props.t(elt.title),
      elt.message,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }
  // Settin icon to journal
  const setIconAndColor = type => {
    // console.log(type)
    if (type == "Alarm received") {
      return (
        <i className="mdi mdi-alert-decagram-outline mdi-alert-decagram text-truncate align-middle me-2 text-danger"></i>
      )
    }
    if (type == "Alarm acknowledged") {
      return (
        <i className="mdi mdi-alert-circle-outline text-truncate align-middle me-2 text-warning"></i>
      )
    }
    if (type == "Alarm closed") {
      return (
        <i className="mdi mdi-alert-circle-check-outline text-truncate align-middle me-2 text-success"></i>
      )
    }
    if (type == "Video call") {
      return (
        <i className=" mdi mdi-message-video text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Group video call") {
      return (
        <i className=" mdi mdi-message-video text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Call to tracker") {
      return (
        <i className="bx bxs-phone-outgoing text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Call to carephone") {
      return (
        <i className="bx bxs-phone-outgoing  text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Call to phone") {
      return (
        <i className="bx bxs-phone-outgoing text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Medicine reminder") {
      return (
        <i className="dripicons-pill text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Attachment") {
      return (
        <i className="mdi mdi-attachment  text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Measurement") {
      return (
        <i className="fas fa-file-medical text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Measurement (manual input)") {
      return (
        <i className="fas fa-comment-medical text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Message") {
      return (
        <i className=" bx bx-message-rounded-dots text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Message to client") {
      return (
        <i className="mdi mdi-message-arrow-right-outline text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Smartlock") {
      return (
        <i className="mdi mdi-home-lock text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Away from home" || type == "Returned to home") {
      return (
        <i className="mdi mdi-home text-truncate text-primary align-middle me-2"></i>
      )
    }
    if (type == "Visit") {
      return (
        <i className="mdi mdi-door-open text-truncate text-primary align-middle me-2"></i>
      )
    } else {
      return (
        <i className="bx bx-right-arrow-alt text-truncate text-primary align-middle me-2"></i>
      )
    }
  }

  // Setting keys from deleted messages
  const handleChange = event => {
    var item = event.target.value
    var arr = selectDeleteMessage

    if (arr.includes(item) === true) {
      arr = arr.filter(x => x !== item)
      // console.log(arr)
    } else {
      arr.push(item)
    }
    handlingKeys(arr)
  }

  // Adding to
  const handlingKeys = keys => {
    setSelectDeleteMessage(keys)
    setDeleteLength(keys.length)
  }

  // Date changed
  const onDateSelection = value => {
    setDateRange(value)
  }

  // Dropzone
  function handleAcceptedFiles(files) {
    setUploadMessage("")
    setLoadingFile(true)
    console.log(files, "files")

    files.forEach(file => {
      const reader = new FileReader()
      let maxSizeInBytes = 0
      // console.log(file.type, "filetype")
      if (file.type.includes("video")) {
        maxSizeInBytes = 20 * 1024 * 1024 // 20 Mt
      } else {
        maxSizeInBytes = 5 * 1024 * 1024 // 5 Mt
      }
      // console.log(maxSizeInBytes)
      if (file.size > maxSizeInBytes) {
        setUploadMessage(props.t("File is too large"))
      }
      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        // Do whatever you want with the file contents
        let dataUrl = reader.result
        //console.log(dataUrl)
        let index = dataUrl.indexOf("base64,")
        if (index >= 0) {
          dataUrl = dataUrl.substring(index + 7)
        }
        setSelectedDataBase64(dataUrl)
      }
      reader.readAsDataURL(file)
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFile(files)
    setLoadingFile(false)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    console.log(
      "size ",
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    )
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const load = (
    <div className="text-center py-5 my-3">
      <Link to="#" className="text-primary">
        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
        {props.t("Loading")}
      </Link>
    </div>
  )

  return (
    <>
      {/* File modal */}
      <Modal
        centered
        isOpen={modalFile}
        toggle={() => {
          openFileModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 mb-0">
            {props.t("Add an attachment")}
          </h5>

          <button
            type="button"
            onClick={() => {
              setModalFile(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Row className="mt-2">
          {/* <Label className="col-form-label col-lg-2">Attached Files</Label> */}
          <div className="px-5 pt-2">
            <Form>
              <Dropzone
                accept={{
                  "image/png": [".png"],
                  "image/bmp": [".bmp"],
                  "image/jpeg": [".jpeg", ".jpg"],
                  "image/gif": [".gif"],
                  "text/plain": [".txt"],
                  "application/rtf": [".rtf"],
                  "application/pdf": [".pdf"],
                  "application/msword": [".doc"],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    [".docx"],
                  "application/vnd.oasis.opendocument.text": [".odt"],
                  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
                  "application/vnd.ms-excel": [".xls"],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                  "video/mpeg": [".mpeg"],
                  "video/mp4": [".mp4"],
                  "video/x-msvideo": [".avi"],
                }}
                maxFiles={1}
                multiple={false}
                maxSize={10485760} // 10MB
                onDropAccepted={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles)
                }}
                onDropRejected={fileRejections => {
                  //console.log("REJECT: ", fileRejections)
                  let errMessage = fileRejections[0].errors[0].code
                  showToast(errMessage, "error")
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    {selectedFile.length == 0 && (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h5>
                              {props.t("Drop file here or click to upload")}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Dropzone>

              {loadingFile && <div className="p-4">{load}</div>}
              <div className="dropzone-previews py-1" id="file-previews">
                {selectedFile.length > 0 && (
                  <span className="float-end p-2">
                    <Button
                      className="p-2 mt-2"
                      color="transparent"
                      onClick={() => {
                        setselectedFile([])
                      }}
                    >
                      <i className="bx bx-trash font-size-16 text-danger" />
                    </Button>
                  </span>
                )}
                {uploadMessage !== "" && (
                  <span className="text-danger h6">
                    {props.t(uploadMessage)}
                  </span>
                )}
                {selectedFile?.map((f, i) => {
                  return (
                    <Card
                      outline
                      color={uploadMessage == "" ? "" : "danger"}
                      className={
                        "py-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      }
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {/* video file */}
                            {f.type.includes("video") && (
                              <i className="mdi mdi-file-video display-7 text-muted ps-2"></i>
                            )}
                            {/* excel file */}
                            {f.type.includes("application/vnd.ms-excel") ||
                              (f.type.includes(
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ) && (
                                <i className="mdi mdi-file-excel display-7 text-muted ps-2"></i>
                              ))}
                            {/* audio file */}
                            {f.type.includes("audio") && (
                              <i className="mdi mdi-file-music display-7 text-muted ps-2"></i>
                            )}
                            {/* word file */}
                            {f.type.includes("application/msword") ||
                              (f.type.includes(
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              ) && (
                                <i className="bx bxs-file-doc display-7 text-muted ps-2"></i>
                              ))}
                            {/* img file */}
                            {f.type.includes("image") && (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            )}
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}
              </div>
              {selectedFile.length == 0 && (
                <p className="text-muted mt-0 pt-0">
                  {props.t(
                    "The maximum file size is 20Mt for video files and 5Mt for other file types"
                  )}
                  .
                </p>
              )}
              {selectedFile.length > 0 && uploadMessage !== "" && (
                <p className="text-danger mt-0 pt-0">
                  {props.t(
                    "The maximum file size is 20Mt for video files and 5Mt for other file types"
                  )}
                  .
                </p>
              )}
            </Form>
          </div>
        </Row>
        <ModalFooter className="mt-2">
          <span className="float-end">
            <button
              type="button"
              onClick={() => {
                openFileModal()
              }}
              className="btn btn-outline-primary "
            >
              {props.t("Cancel")}
            </button>

            <button
              disabled={
                selectedFile.length == 0 ||
                selectedDataBase64.length == 0 ||
                uploadMessage !== ""
              }
              className="btn btn-primary ms-2"
              onClick={() => {
                uploadAttachment(selectedFile[0])
              }}
            >
              {props.t("Save")}
            </button>
          </span>
        </ModalFooter>
      </Modal>
      {/* Add/edit entry */}
      <Modal
        isOpen={modalMessage}
        toggle={() => {
          openMessageModal()
        }}
        centered
      >
        <div className="modal-header">
          {editModal ? (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("Edit message")}
            </h5>
          ) : (
            <h5 className="modal-title mt-0" id="myModalLabel">
              {props.t("Add message")}
            </h5>
          )}
          <button
            type="button"
            onClick={() => {
              setModalMessage(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <>
          {editModal ? (
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <FormGroup>
                  <Label>{props.t("Message")}</Label>
                  <Input
                    type="textarea"
                    name="message"
                    rows="3"
                    maxLength="225"
                    onChange={validationEditMessage.handleChange}
                    onBlur={validationEditMessage.handleBlur}
                    value={validationEditMessage.values.message || ""}
                    invalid={
                      validationEditMessage.touched.message &&
                      validationEditMessage.errors.message
                        ? true
                        : false
                    }
                    placeholder={props.t("max. 225 characters")}
                  />
                  {validationEditMessage.touched.message &&
                  validationEditMessage.errors.message ? (
                    <FormFeedback type="invalid">
                      {validationEditMessage.errors.message}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <Col xs={12}>
                  <span className="float-end">
                    <button
                      type="button"
                      onClick={() => {
                        openMessageModal()
                      }}
                      className="btn btn-outline-primary "
                      data-dismiss="modal"
                    >
                      {props.t("Cancel")}
                    </button>

                    <button
                      disabled={saveDisable}
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        validationEditMessage.submitForm()
                      }}
                    >
                      {props.t("Save message")}
                    </button>
                  </span>
                </Col>
              </Form>
            </div>
          ) : (
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <FormGroup>
                  <Label>{props.t("Title")}</Label>
                  <Input
                    type="text"
                    name="title"
                    onChange={validationMessage.handleChange}
                    onBlur={validationMessage.handleBlur}
                    value={validationMessage.values.title || ""}
                    placeholder={props.t("optional")}
                  />
                </FormGroup>
                {errorMessage ? (
                  <Alert color="danger">{props.t(errorMessage)}</Alert>
                ) : null}
                {successMessage ? (
                  <Alert color="success">{props.t(successMessage)}</Alert>
                ) : null}
                <FormGroup>
                  <Label>{props.t("Message")}</Label>
                  <Input
                    type="textarea"
                    name="message"
                    rows="3"
                    maxLength="225"
                    onChange={validationMessage.handleChange}
                    onBlur={validationMessage.handleBlur}
                    value={validationMessage.values.message || ""}
                    invalid={
                      validationMessage.touched.message &&
                      validationMessage.errors.message
                        ? true
                        : false
                    }
                    placeholder={props.t("max. 225 characters")}
                  />
                  {validationMessage.touched.message &&
                  validationMessage.errors.message ? (
                    <FormFeedback type="invalid">
                      {validationMessage.errors.message}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                {userRole === "system_admin" && (
                  <FormGroup>
                    <Switch
                      className="me-2"
                      name="showAsAdmin"
                      checked={validationMessage.values.showAsAdmin}
                      onChange={v => {
                        validationMessage.setFieldValue("showAsAdmin", v)
                      }}
                    ></Switch>
                    <Label>{props.t("Add entry as Suvanto Care")}</Label>
                  </FormGroup>
                )}

                <ModalFooter className="p-2 mt-2">
                  <Col lg={12} className="mt-2 mb-0  text-truncate">
                    <Row>
                      <Col xs={12}>
                        <span className="float-end">
                          <button
                            type="button"
                            onClick={() => {
                              openMessageModal()
                            }}
                            className="btn btn-outline-primary "
                            data-dismiss="modal"
                          >
                            {props.t("Cancel")}
                          </button>

                          <button
                            disabled={saveDisable}
                            className="btn btn-primary ms-2"
                            onClick={() => {
                              validationMessage.submitForm()
                            }}
                          >
                            {props.t("Save message")}
                          </button>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </ModalFooter>
              </Form>
            </div>
          )}
        </>
      </Modal>
      <Card className="">
        <div className="p-2 ms-2">
          <Row>
            <Col lg={5} className="mt-1">
              <Label className="mt-0">{props.t("Filter")}</Label>
              <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
                <TreeSelect
                  style={{ width: "100%" }}
                  value={typeFilter}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={treeData}
                  placeholder={props.t("Filter")}
                  allowClear={true}
                  treeDefaultExpandAll={true}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  onChange={onChange}
                />
              </ConfigProvider>
            </Col>
            <Col lg={5} className="mt-1">
              <Label>{props.t("Time")}</Label>
              <CustomRangePicker
                value={dateRange}
                rangeChanged={onDateSelection}
                showTime={true}
                allowClear={false}
                format={"DD.MM.YYYY HH:mm"}
              />
            </Col>
            <Col lg={2} className="mt-2">
              <Label>{props.t("Order")}</Label>
              <FormGroup>
                <Switch
                  className="ms-2"
                  name="arrange_time"
                  checked={descending}
                  onChange={arrange}
                  checkedChildren={
                    <i className="mdi mdi-sort-clock-descending align-middle font-size-20" />
                  }
                  unCheckedChildren={
                    <i className="mdi mdi-sort-clock-ascending align-middle font-size-20" />
                  }
                ></Switch>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </Card>
      {isLoading ? (
        <Row>
          <Col xs="12">
            <div className="text-center my-3 py-4">
              <Link to="#" className="text-primary">
                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                {props.t("Loading")}
              </Link>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {/* Empty card, no entries */}
          {entries?.length == 0 ? (
            <Col lg="12">
              <Card className="p-4 text-center">
                <CardBody>
                  <span className="h5 me-5 p-4">
                    {props.t("No entries found")}
                  </span>
                  <span className="">
                    <Button
                      type="button"
                      color="success"
                      onClick={() => {
                        setEditModal(false), openMessageModal()
                      }}
                      className="btn btn-primary p-4"
                    >
                      <i className="bx bx-pen d-block mb-2 font-size-20" />
                      <span className="w-sm">{props.t("Add message")}</span>
                    </Button>
                    <Button
                      style={{ height: "97.1px" }}
                      type="button"
                      color="success"
                      onClick={() => {
                        openFileModal()
                      }}
                      className="btn btn-primary ms-2 p-2"
                    >
                      <i className="mdi mdi-attachment mdi-rotate-90 d-block mb-1 font-size-20" />
                      <span className="w-sm">
                        {props.t("Add an attachment")}
                      </span>
                    </Button>
                  </span>
                </CardBody>
              </Card>
            </Col>
          ) : (
            // Entries card
            <>
              <Row>
                <Col xs="4">
                  <div className="d-flex mt-1 flex-wrap">
                    <span>
                      {deleteMessages ? (
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => setDeleteMessages(!deleteMessages)}
                          className="mt-0 mb-2 ms-2 "
                        >
                          <span className="d-none d-sm-inline-block">
                            {props.t("Cancel")}
                          </span>{" "}
                        </Button>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            id="addEntry"
                            type="button"
                            color="success"
                            onClick={() => {
                              setEditModal(false), openMessageModal()
                            }}
                            className="btn btn-primary mt-0 mb-2 font-size-16"
                          >
                            +<i className="bx bx-pen" />
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="addEntry"
                          >
                            {props.t("Add message")}
                          </UncontrolledTooltip>
                          <Button
                            size="sm"
                            id="addAttachment"
                            type="button"
                            color="success"
                            onClick={() => {
                              openFileModal()
                            }}
                            className="btn btn-primary mt-0 mb-2 ms-2 font-size-16"
                          >
                            +
                            <i className="mdi mdi-attachment mdi-rotate-90" />
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="addAttachment"
                          >
                            {props.t("Add an attachment")}
                          </UncontrolledTooltip>

                          {userRole === "system_admin" && (
                            <>
                              <Button
                                size="sm"
                                id="deleteEntries"
                                type="button"
                                color="primary"
                                // outline
                                onClick={() =>
                                  setDeleteMessages(!deleteMessages)
                                }
                                className="mt-0 mb-2 ms-2  font-size-16"
                              >
                                <i className="bx bx-trash" />
                              </Button>{" "}
                              <UncontrolledTooltip
                                placement="bottom"
                                target="deleteEntries"
                              >
                                {props.t("Select the entries to delete")}
                                {" (admin) "}
                              </UncontrolledTooltip>
                            </>
                          )}
                        </>
                      )}
                    </span>
                    {deleteMessages == true && (
                      <Button
                        type="button"
                        color="danger"
                        onClick={e => {
                          setDeleteConfirm(true)
                        }}
                        disabled={deleteLength == 0}
                        className="mt-0 mb-2 ms-2 "
                      >
                        <i className="bx bx-trash" />
                        <span className="d-none d-sm-inline-block ms-2">
                          {props.t("Delete entries")}{" "}
                          {deleteLength !== 0 && <span>{deleteLength}</span>}
                        </span>
                      </Button>
                    )}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="float-end">
                    <div className="mt-0">
                      <Dropdown
                        id="printButtonServicesStats"
                        isOpen={menu}
                        toggle={() => setMenu(!menu)}
                        className="download-btn-bg text-center text-muted d-inline-block"
                        type="button"
                      >
                        <DropdownToggle id="downloadstatstable" tag="i">
                          <i className="mdi mdi-download" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* TODO: Can not choose directory file right now */}
                          <DropdownItem onClick={() => downloadExcel()}>
                            {props.t("Download .xlsx")}
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="printButtonServicesStats"
                      >
                        {props.t("Download statistics")}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <div className="search-box me-2 float-end">
                    <div className="position-relative">
                      <Input
                        type="text"
                        onChange={event => handleSearch(event)}
                        placeholder={props.t("Search")}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              </Row>

              {/* Journal entries */}
              <SimpleBar
                className="mt-0 ms-4"
                style={{ maxHeight: "75vh", cursor: "default" }}
              >
                <ul className="verti-timeline-journal list-unstyled">
                  <Col xl="12">
                    {filteredData.length == 0 ? (
                      <div className="ms-3 p-4">
                        {props.t("No results found with")}
                        {"  "}
                        <strong>{searchInput}</strong>
                      </div>
                    ) : (
                      <>
                        {filteredData?.map((entry, index) => (
                          <li
                            key={index}
                            className="event-list"
                            // type="button"
                            // style={{ cursor: "pointer" }}
                          >
                            {entry.deleted ? (
                              <>
                                <Collapse isOpen={deleteMessages}>
                                  {deleteMessages == true && (
                                    <>
                                      <CardTitle
                                        className={
                                          selectDeleteMessage.includes(
                                            entry.key
                                          )
                                            ? "bg-soft bg-danger py-1 px-2"
                                            : "bg-soft bg-light-gray py-1 px-2"
                                        }
                                      >
                                        <Col lg="12">
                                          <div className="form-check form-check-danger ">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id={
                                                entry.key + "_customCheckcolor5"
                                              }
                                              value={entry.key}
                                              onChange={handleChange}
                                            />
                                            <label
                                              className="form-check-label text-danger h6"
                                              htmlFor={
                                                entry.key + "_customCheckcolor5"
                                              }
                                            >
                                              {props.t("Delete entry")}
                                            </label>
                                          </div>
                                        </Col>
                                      </CardTitle>
                                    </>
                                  )}
                                </Collapse>
                                <div
                                  className="event-timeline-dot mt-1"
                                  style={{ cursor: "default" }}
                                >
                                  <div className="event-down-icon mt-2">
                                    <i className="bx bx-right-arrow-circle h2 text-gray down-arrow-icon mt-0" />
                                  </div>
                                </div>
                                <div
                                  className="py-3 mt-2 text-gray "
                                  style={{ cursor: "default" }}
                                >
                                  <span className="float-end">
                                    <i className="bx bx-time-five me-1 "></i>
                                    {formatDateFull(entry.date)}
                                    <i className="bx bx-user-circle text-gray me-1 ms-2"></i>
                                    {entry.fullName}
                                  </span>
                                  <p className="font-size-14">
                                    {props.t("Deleted entry")}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="event-timeline-dot">
                                  <div className="event-down-icon mt-4">
                                    <i className="bx bx-right-arrow-circle h2 text-primary down-arrow-icon mt-3" />
                                  </div>
                                </div>
                                <div>
                                  <Card>
                                    <Collapse isOpen={deleteMessages}>
                                      {deleteMessages == true && (
                                        <>
                                          <CardTitle
                                            className={
                                              selectDeleteMessage.includes(
                                                entry.key
                                              )
                                                ? "bg-soft bg-danger py-1 px-2"
                                                : "bg-soft bg-light-gray py-1 px-2"
                                            }
                                          >
                                            <Col lg="12">
                                              <div className="form-check form-check-danger ">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={
                                                    entry.key +
                                                    "_customCheckcolor5"
                                                  }
                                                  value={entry.key}
                                                  onChange={handleChange}
                                                />
                                                <label
                                                  className="form-check-label text-danger h6"
                                                  htmlFor={
                                                    entry.key +
                                                    "_customCheckcolor5"
                                                  }
                                                >
                                                  {props.t("Delete entry")}
                                                </label>
                                              </div>
                                            </Col>
                                          </CardTitle>
                                        </>
                                      )}
                                    </Collapse>
                                    <CardBody>
                                      <Col sm={12}>
                                        <Row>
                                          <Col sm={3}>
                                            <div className="ms-2">
                                              <div className="d-flex">
                                                <span className="text-uppercase font-size-12">
                                                  {props.t(
                                                    moment(entry.date).format(
                                                      "dddd"
                                                    )
                                                  )}
                                                </span>
                                                <span className="text-uppercase ms-2 font-size-12">
                                                  <i className="bx bx-time-five me-1 font-size-12"></i>
                                                  {moment(entry.date).format(
                                                    "HH:mm:ss"
                                                  )}
                                                </span>
                                              </div>
                                              <div className="d-flex">
                                                <h4 className="mt-2">
                                                  {moment(entry.date).format(
                                                    "D"
                                                  )}
                                                  .
                                                </h4>
                                              </div>
                                              <p className="h6">
                                                {props.t(
                                                  moment(entry.date).format(
                                                    "MMMM"
                                                  )
                                                )}{" "}
                                                {moment(entry.date).format(
                                                  "YYYY"
                                                )}
                                              </p>
                                            </div>
                                            <div className="vl d-none d-sm-inline-block"></div>
                                          </Col>
                                          <div className="hidden-media mt-3">
                                            <hr />
                                          </div>
                                          <Col sm={9}>
                                            <Col sm={12}>
                                              <Row>
                                                <Col
                                                  xs={9}
                                                  sm={9}
                                                  onClick={e => {
                                                    // if (entry.type !== "file") {
                                                    toggleEntry(
                                                      entry.key,
                                                      entry.responseCount,
                                                      e
                                                    )
                                                    // }
                                                  }}
                                                >
                                                  <div className="ms-2">
                                                    <div className="d-flex">
                                                      <span className="h5">
                                                        <span className="">
                                                          {setIconAndColor(
                                                            entry.entryTypeDescription
                                                          )}
                                                        </span>{" "}
                                                        {props.t(
                                                          entry.entryTypeDescription
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div className="mt-2">
                                                      {entry.title ? (
                                                        <h6 className="text-muted">
                                                          {props.t(entry.title)}
                                                        </h6>
                                                      ) : (
                                                        <span className="text-muted"></span>
                                                      )}

                                                      <Col xs={12}>
                                                        <>
                                                          {entry.message !=
                                                            undefined && (
                                                            <>
                                                              {entry.message}{" "}
                                                              {entry.responseCount >
                                                                0 && (
                                                                <span className="text-muted ms-3">
                                                                  <i
                                                                    className={
                                                                      "bx bx-chat font-size-12 me-1"
                                                                    }
                                                                  ></i>
                                                                  +
                                                                  {
                                                                    entry.responseCount
                                                                  }
                                                                </span>
                                                              )}
                                                            </>
                                                          )}
                                                          <span>
                                                            {entry.edited !=
                                                              undefined && (
                                                              <span className="text-muted ms-3 font-size-12">
                                                                <i className="bx bxs-user-check me-1"></i>
                                                                <span className="">
                                                                  {props.t(
                                                                    "Edited"
                                                                  )}{" "}
                                                                  {moment(
                                                                    entry.updated
                                                                  ).format(
                                                                    "D.M.YYYY HH:mm"
                                                                  )}
                                                                </span>
                                                              </span>
                                                            )}
                                                          </span>
                                                          {entry.duration ? (
                                                            <p className="text-muted mt-3 font-size-12">
                                                              {props.t(
                                                                "Duration"
                                                              )}{" "}
                                                              {formatSeconds(
                                                                entry.duration
                                                              )}
                                                            </p>
                                                          ) : (
                                                            <div></div>
                                                          )}
                                                          {entry.type ===
                                                            "visit" &&
                                                          entry.ongoing ===
                                                            true ? (
                                                            <div className="mt-3">
                                                              <span className="badge badge-soft-danger p-3 font-size-14">
                                                                {props.t(
                                                                  "Visit ongoing"
                                                                )}
                                                              </span>
                                                              <span className="float-end mt-1">
                                                                <Button
                                                                  color="danger"
                                                                  disabled={
                                                                    action
                                                                  }
                                                                  onClick={() => {
                                                                    endVisit(
                                                                      entry.key
                                                                    )
                                                                  }}
                                                                >
                                                                  <i className="dripicons-stopwatch me-2"></i>
                                                                  {props.t(
                                                                    "End visit"
                                                                  )}
                                                                </Button>
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <div></div>
                                                          )}
                                                          {entry.type ===
                                                            "file" && (
                                                            <div>
                                                              <span className="badge badge-soft-primary p-2 font-size-12">
                                                                {"" +
                                                                  entry.fileName}
                                                              </span>
                                                              <Dropdown
                                                                isOpen={
                                                                  fileSaveOpen[
                                                                    entry.key
                                                                  ]
                                                                }
                                                                toggle={() =>
                                                                  toggleFileSave(
                                                                    entry.key
                                                                  )
                                                                }
                                                                className="text-center p-2 text-muted d-inline-block"
                                                                type="button"
                                                              >
                                                                <DropdownToggle
                                                                  id="downloadfile"
                                                                  tag="i"
                                                                >
                                                                  <i
                                                                    id="downloadfile-button"
                                                                    className="bx bx-download font-size-14"
                                                                  />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                  <DropdownItem>
                                                                    <a
                                                                      download={
                                                                        entry.fileName
                                                                      }
                                                                      href={
                                                                        downloadLink
                                                                      }
                                                                    >
                                                                      <span
                                                                        id="downloadfile-button-a"
                                                                        className="text-muted"
                                                                      >
                                                                        {props.t(
                                                                          "Download file"
                                                                        )}
                                                                      </span>
                                                                    </a>
                                                                  </DropdownItem>
                                                                </DropdownMenu>
                                                              </Dropdown>
                                                              {/* <span className="ms-2">
                                                                <Button
                                                                  color="secondary"
                                                                  disabled={
                                                                    action
                                                                  }
                                                                  onClick={() => {
                                                                    downloadAttachment(
                                                                      entry.key
                                                                    )
                                                                  }}
                                                                >
                                                                  <i className="dripicons-download "></i>
                                                                  <a
                                                                    download={
                                                                      entry.fileName
                                                                    }
                                                                    href={
                                                                      downloadLink
                                                                    }
                                                                  >
                                                                    <span className="d-none d-lg-inline-block ms-2">
                                                                      {props.t(
                                                                        "Download file"
                                                                      )}
                                                                    </span>
                                                                  </a>
                                                                </Button>
                                                              </span> */}

                                                              {/** Download file */}
                                                              {/* {downloadLink?.length >
                                                                0 && ( */}
                                                              {/* <span className="float-end">
                                                                <a
                                                                  download={
                                                                    entry.fileName
                                                                  }
                                                                  href={
                                                                    downloadLink
                                                                  }
                                                                >
                                                                  {" "}
                                                                  <span className="d-none d-lg-inline-block ms-2">
                                                                    {props.t(
                                                                      "Download file"
                                                                    )}
                                                                  </span>
                                                                </a>
                                                              </span> */}
                                                              {/* )} */}
                                                            </div>
                                                          )}
                                                        </>
                                                      </Col>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col xs={3} sm={3}>
                                                  {entry.owner === true &&
                                                  // TODO: set exeptions for manual measurements
                                                  entry.type == "message" ? (
                                                    <Row>
                                                      <div>
                                                        {entry.type ===
                                                        "message" ? (
                                                          <span className="float-end ms-1">
                                                            <Popconfirm
                                                              title={
                                                                props.t(
                                                                  "Delete entry"
                                                                ) + "?"
                                                              }
                                                              okText={props.t(
                                                                "Yes"
                                                              )}
                                                              cancelText={props.t(
                                                                "No"
                                                              )}
                                                              onConfirm={() => {
                                                                settingMessageEmpty(
                                                                  entry.key,
                                                                  entry.message
                                                                )
                                                              }}
                                                            >
                                                              <Button
                                                                size="sm"
                                                                color="danger"
                                                              >
                                                                <i className="bx bx-trash" />
                                                                <span className="d-none d-md-inline-block ms-1">
                                                                  {props.t(
                                                                    "Delete"
                                                                  )}
                                                                </span>
                                                              </Button>
                                                            </Popconfirm>
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {" "}
                                                            <Popconfirm
                                                              title={
                                                                props.t(
                                                                  "Delete entry"
                                                                ) + "?"
                                                              }
                                                              okText={props.t(
                                                                "Yes"
                                                              )}
                                                              cancelText={props.t(
                                                                "No"
                                                              )}
                                                              onConfirm={() => {
                                                                settingMessageEmpty(
                                                                  entry.key
                                                                )
                                                              }}
                                                            >
                                                              <Button
                                                                className="float-end ms-1"
                                                                size="sm"
                                                                color="danger"
                                                              >
                                                                <i className="bx bx-trash" />
                                                                <span className="d-none d-md-inline-block ms-1">
                                                                  {props.t(
                                                                    "Delete"
                                                                  )}
                                                                </span>
                                                              </Button>
                                                            </Popconfirm>
                                                          </span>
                                                        )}

                                                        {entry.type ==
                                                          "message" && (
                                                          <span className="float-end mb-3 ">
                                                            <Button
                                                              color="primary"
                                                              size="sm"
                                                              onClick={() => {
                                                                editMessage(
                                                                  entry.key,
                                                                  entry.message
                                                                )
                                                              }}
                                                            >
                                                              <i className="bx bx-edit-alt"></i>
                                                              <span className="d-none d-md-inline-block ms-1">
                                                                {props.t(
                                                                  "Edit"
                                                                )}
                                                              </span>
                                                            </Button>
                                                          </span>
                                                        )}
                                                      </div>
                                                    </Row>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <Row>
                                                    <div>
                                                      <span className="float-end text-truncate">
                                                        <p>
                                                          <i className="bx bx-user-circle me-1 text-primary font-size-11"></i>
                                                          {entry.fullName}
                                                        </p>
                                                      </span>
                                                    </div>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Collapse
                                            isOpen={entryOpen[entry.key]}
                                          >
                                            <Col xs={12}>
                                              <div className="mt-4 mb-0">
                                                <div className="chat-conversation p-3 mt-0">
                                                  <ul className="list-unstyled">
                                                    {loadingResponses ==
                                                      true && (
                                                      <Lottie
                                                        options={getLottieLoadingOptions()}
                                                        height={100}
                                                        width={100}
                                                      />
                                                    )}
                                                    {loadingResponses ==
                                                      false &&
                                                      !entry.responseCount && (
                                                        <>
                                                          <div className="chat-day-title">
                                                            <span className="title h6">
                                                              {props.t(
                                                                "No comments"
                                                              )}
                                                            </span>
                                                          </div>
                                                        </>
                                                      )}
                                                    {loadingResponses ==
                                                      false &&
                                                      entry.responseCount >
                                                        0 && (
                                                        <div>
                                                          <div className="chat-day-title">
                                                            <span className="title h6">
                                                              <>
                                                                {props.t(
                                                                  "Comments"
                                                                )}
                                                              </>
                                                            </span>
                                                          </div>
                                                          {messages?.map(
                                                            (message, key) => (
                                                              <li key={key}>
                                                                <div className="left">
                                                                  <div className="conversation-list">
                                                                    <div
                                                                      className={
                                                                        message.message !==
                                                                        ""
                                                                          ? "ctext-wrap"
                                                                          : "ctext-wrap-deleted"
                                                                      }
                                                                    >
                                                                      <div className="conversation-name">
                                                                        {
                                                                          message.fullName
                                                                        }
                                                                        {message.owner ==
                                                                          true &&
                                                                          message.message !==
                                                                            "" && (
                                                                            <>
                                                                              <Popconfirm
                                                                                title={
                                                                                  props.t(
                                                                                    "Delete entry"
                                                                                  ) +
                                                                                  "?"
                                                                                }
                                                                                okText={props.t(
                                                                                  "Yes"
                                                                                )}
                                                                                cancelText={props.t(
                                                                                  "No"
                                                                                )}
                                                                                onConfirm={() => {
                                                                                  if (
                                                                                    userRole ===
                                                                                    "system_admin"
                                                                                  ) {
                                                                                    deleteSelectedEntries(
                                                                                      message.key
                                                                                    )
                                                                                  } else {
                                                                                    settingMessageEmpty(
                                                                                      message.key
                                                                                    )
                                                                                  }
                                                                                }}
                                                                              >
                                                                                <Button
                                                                                  className="float-end ms-1"
                                                                                  color="danger"
                                                                                  size="sm"
                                                                                >
                                                                                  <i className="bx bx-trash"></i>
                                                                                  <span className="d-none d-md-inline-block"></span>
                                                                                </Button>
                                                                              </Popconfirm>
                                                                              <Button
                                                                                className="float-end ms-3"
                                                                                color="primary"
                                                                                size="sm"
                                                                                onClick={() => {
                                                                                  editMessage(
                                                                                    message.key,
                                                                                    message.message
                                                                                  )
                                                                                }}
                                                                              >
                                                                                <i className="bx bx-edit-alt"></i>
                                                                                <span className="d-none d-md-inline-block"></span>
                                                                              </Button>
                                                                            </>
                                                                          )}
                                                                      </div>
                                                                      {message.message ? (
                                                                        <p>
                                                                          {props.t(
                                                                            message.message
                                                                          )}
                                                                        </p>
                                                                      ) : (
                                                                        <em className="text-gray">
                                                                          {props.t(
                                                                            "Deleted message"
                                                                          )}
                                                                        </em>
                                                                      )}
                                                                      {message.message !==
                                                                      "" ? (
                                                                        <>
                                                                          <p className="chat-time mb-0  mt-2">
                                                                            <i className="bx bx-time-five align-middle me-1" />
                                                                            {
                                                                              message.dateNice
                                                                            }
                                                                          </p>
                                                                          <span>
                                                                            {message.edited ? (
                                                                              <span className="text-muted font-size-12">
                                                                                <i className="bx bxs-user-check me-1"></i>
                                                                                <span className="">
                                                                                  {moment(
                                                                                    message.updated
                                                                                  ).format(
                                                                                    "D.M.YYYY HH:mm:ss"
                                                                                  )}
                                                                                </span>
                                                                              </span>
                                                                            ) : (
                                                                              <>

                                                                              </>
                                                                            )}
                                                                          </span>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <p className="chat-time mb-0 text-gray mt-2">
                                                                            <i className="bx bx-time-five align-middle me-1" />
                                                                            {
                                                                              message.dateNice
                                                                            }
                                                                          </p>
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            )
                                                          )}
                                                        </div>
                                                      )}
                                                  </ul>
                                                </div>
                                                <div className="px-3 py-2">
                                                  <Row>
                                                    <Col>
                                                      <div className="position-relative">
                                                        <input
                                                          type="text"
                                                          value={currentMessage}
                                                          onChange={e =>
                                                            setCurrentMessage(
                                                              e.target.value
                                                            )
                                                          }
                                                          className="form-control chat-input"
                                                          placeholder={props.t(
                                                            "Enter message"
                                                          )}
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                      <Button
                                                        type="button"
                                                        color="primary"
                                                        disabled={
                                                          !currentMessage ||
                                                          action
                                                        }
                                                        onClick={() =>
                                                          addResponse(entry)
                                                        }
                                                        className="btn btn-primary btn-rounded chat-send w-md "
                                                      >
                                                        <span className="d-none d-sm-inline-block me-2">
                                                          {props.t("Send")}
                                                        </span>{" "}
                                                        <i className="mdi mdi-send" />
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </Col>
                                          </Collapse>
                                        </Row>
                                      </Col>
                                    </CardBody>
                                  </Card>
                                </div>
                              </>
                            )}
                          </li>
                        ))}
                      </>
                    )}
                  </Col>
                </ul>
              </SimpleBar>
            </>
          )}
        </>
      )}
      {/** Delete confirmation modal */}
      <Modal
        isOpen={deleteConfirm}
        toggle={() => {
          setDeleteConfirm(!deleteConfirm)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          {deleteLength == 1 ? (
            <p>{props.t("Are you sure you want to delete selected entry?")}</p>
          ) : (
            <p>
              {props.t("Are you sure you want to delete selected entries?")}
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteConfirm(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={deleting}
            color="danger"
            onClick={() => {
              deleteSelectedEntries()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

JournalServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
}

export default withTranslation()(JournalServices)
