/***********************
 * CALENDAR COMPONENT (RANGE, CUSTOMER SETTINGS)
 ************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

//Formatting date
import moment from "moment"

//Translation
import { withTranslation } from "react-i18next"

//DatePicker ANTD
import { DatePicker, ConfigProvider } from "antd"
const { RangePicker } = DatePicker

// Locale/weekday related imports
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"
import { Col, Row, Button } from "reactstrap"

const RangeCalendarServices = props => {
  const [defaultValueStart, setDefaultValueStart] = useState(props.range)
  const [rangePicker, setRangePicker] = useState(true)
  // const [now, setNow] = useState()
  const [startTime, setStartTime] = useState()
  const [defaultValueEnd, setDefaultValueEnd] = useState(
    moment().isAfter(moment())
  )
  const [endTime, setEndTime] = useState()
  const [dateAndTime, setDateAndTime] = useState(null)
  const [chosenDate, setChosenDate] = useState(moment())
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    if (dateAndTime == null && defaultValueStart != undefined) {
      let timeStart = new Date(defaultValueStart)
      let timeEnd = new Date(defaultValueEnd)
      let unixStart = timeStart.getTime()
      let unixEnd = timeEnd.getTime()
      props.setCalendarStartDate(unixStart)
      props.setCalendarEndDate(unixEnd)
    }
    if (defaultValueStart == undefined) {
      onDateSelection(moment())
    }
  }, [])

  useEffect(() => {
    if (props.range == undefined) {
      setRangePicker(false)
    }
  }, [props.range])

  //DatePicker language update
  const getLanguage = () => {
    var language = localStorage.getItem("i18nextLng")
    if (language.indexOf("-") > -1) language = language.split("-")[0]
    switch (language) {
      case "en":
        return en
      case "fi":
        return fin
      case "sv":
        return swe
      default:
        return null
    }
  }
  const locale = getLanguage()

  const rangeButtons = {
    [props.t("This day")]: [moment().startOf("day"), moment()],
    [props.t("This Week")]: [moment().startOf("week"), moment().endOf("week")],
    [props.t("This Month")]: [
      moment().startOf("month"),
      moment().endOf("month"),
    ],
  }

  const settingDate = v => {
    let date = null
    if (v == "back") {
      date = moment(chosenDate).add(-1, "d")
      onDateSelection(date)
    }
    if (v == "forward") {
      date = moment(chosenDate).add(1, "d")
      onDateSelection(date)
    }
  }

  useEffect(() => {
    if (moment().isSame(chosenDate, "day")) {
      setButtonDisabled(true)
    }
    if (moment().isSame(chosenDate, "day") == false) {
      setButtonDisabled(false)
    }
  }, [chosenDate])

  // User can not pick a date further than end of the day
  const disabledDate = current => {
    // console.log("disabled date", current)
    const from = moment().add(-(props.startDefault - 1), "days")
    return (
      current &&
      (current > moment().endOf("day") || current < from.startOf("day"))
    )
  }

  const onDateSelectionRange = value => {
    setDateAndTime(value)
    if (value != null) {
      let start = new Date(value[0])
      let end = new Date(value[1])

      let startUnix = start.getTime()
      let endUnix = end.getTime()
      props.setCalendarStartDate(startUnix)
      props.setCalendarEndDate(endUnix)

      setStartTime(start)
      setEndTime(end)
    }
  }
  const onDateSelection = value => {
    setChosenDate(value)
    // console.log(value)
    // setDateAndTime(value)
    if (value != null) {
      let start = new Date(value.startOf("day"))
      let end = new Date(value.endOf("day"))

      let startUnix = start.getTime()
      let endUnix = end.getTime()
      props.setCalendarStartDate(startUnix)
      props.setCalendarEndDate(endUnix)

      setStartTime(start)
      setEndTime(end)
    }
  }

  return (
    <React.Fragment>
      <div>
        <Col className={` ${props.placeholder ? "placeholder" : ""}`}>
          <ConfigProvider locale={locale}>
            {rangePicker ? (
              <RangePicker
                defaultValue={[defaultValueStart, defaultValueEnd]}
                showTime
                style={{
                  width: "100%",
                }}
                // TODO: Set disabled dates from historyDays
                // disabledDate={disabledDate}
                onChange={onDateSelectionRange}
                format="DD.MM.YYYY HH:mm"
                ranges={{ ...rangeButtons }}
              />
            ) : (
              <>
                <Col className="px-0">
                  <Button
                    onClick={() => {
                      settingDate("back")
                    }}
                    size="sm"
                    // disabled={buttonDisabled}
                    color="clear"
                    className="mt-0 py-0"
                    style={{ border: "none" }}
                  >
                    <i className="bx bx-chevron-left mt-1  text-primary font-size-20"></i>
                  </Button>
                  <DatePicker
                    // size="large"
                    className="mt-0"
                    defaultValue={moment()}
                    disabledDate={disabledDate}
                    showToday
                    allowClear={false}
                    onChange={onDateSelection}
                    format="DD.MM.YYYY"
                    value={chosenDate}
                  />
                  <Button
                    onClick={() => {
                      settingDate("forward")
                    }}
                    disabled={buttonDisabled}
                    size="sm"
                    color="clear"
                    className="mt-0 py-0"
                    style={{ border: "none" }}
                  >
                    <i className="bx bx-chevron-right mt-1 text-primary font-size-20"></i>
                  </Button>
                </Col>
              </>
            )}
          </ConfigProvider>
        </Col>
      </div>
    </React.Fragment>
  )
}

RangeCalendarServices.propTypes = {
  t: PropTypes.any,
  getDate: PropTypes.any,
  setDateRange: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  placeholder: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  range: PropTypes.any,
  rangePicker: PropTypes.bool,
  startDefault: PropTypes.any,
  now: PropTypes.any,
}
export default withTranslation()(RangeCalendarServices)
