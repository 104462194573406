/***********************
 * DEVICE IDENTIFICATION TABLE
 * (TABLE + IMPORT PDF/XLSX)
 ************************/
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Label,
  CardBody,
  Tooltip,
} from "reactstrap"
import PropTypes from "prop-types"

// Toast
import toastr from "toastr"
import "toastr/build/toastr.min.css"

// Datatable related plugins
import { Table as AntdTable, Button as AntdButton } from "antd"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../datatables.scss"

// Search field
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Translation
import { withTranslation } from "react-i18next"
import { minWidth, width } from "@mui/system"
import { defaultPageSizeOptions, stringSorter } from "utils/general"

const TableDI = props => {
  //Table data state
  const [series, setSeries] = useState([])
  //Table data state filtered
  const [seriesFiltered, setSeriesFiltered] = useState([])
  // Found items lenght
  const [itemsLength, setItemsLength] = useState(0)
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false)
  // Search text
  // const [searchText, setSearchText] = useState("")

  //Show toast
  const [showEasing, setshowEasing] = useState("swing")
  const [hideEasing, sethideEasing] = useState("linear")
  const [showMethod, setshowMethod] = useState("fadeIn")
  const [hideMethod, sethideMethod] = useState("fadeOut")
  const [showDuration, setshowDuration] = useState(300)
  const [hideDuration, sethideDuration] = useState(1000)
  const [timeOut, settimeOut] = useState(5000)
  const [extendedTimeOut, setextendedTimeOut] = useState(1000)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const handleExpand = (event, record) => {
    event.preventDefault()
    const keys = [...expandedRowKeys]
    const index = keys.indexOf(record.id) // Use id as a key
    if (index >= 0) {
      keys.splice(index, 1) // Delete id/key if included
    } else {
      keys.length = 0 // Delete all id/key
      keys.push(record.id) // Add id/key
    }
    setExpandedRowKeys(keys)
  }

  let positionClass = "toast-bottom-left"

  function showToast(m) {
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    }
    toastr.success(m)
  }

  //Excel and pdf name
  const titleExport = props.t("Device identifications")

  //Setting data state
  useEffect(() => {
    if (
      props.data != null &&
      props.data.list != null &&
      props.data.list != series
    ) {
      let sorted = [...props.data.list].sort((a, b) => {
        return stringSorter(a, b, "clientName")
      })
      let parsedData = sorted.map(item => {
        if (item.carephoneAccessories) {
          return {
            ...item,
            carephoneAccessories: JSON.parse(item.carephoneAccessories),
          }
        }
        return item
      })

      console.log(parsedData, "sorted")

      setSeries(parsedData)
      setSeriesFiltered(parsedData)
    }
  }, [props.data])

  // Table structure
  const columns = [
    {
      dataIndex: "group",
      title: props.t("Group"),
      sorter: (a, b) => {
        return stringSorter(a, b, "group")
      },
      width: "140px",
      ellipsis: true,
    }, 
    {
      dataIndex: "clientName",
      title: props.t("Client"),
      // defaultSortOrder: "descend",
      sorter: (a, b) => {
        return stringSorter(a, b, "clientName")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "gateway",
      title: props.t("Gateway"),
      sorter: (a, b) => {
        return props.t(a.gateway) - props.t(b.gateway) ? 1 : -1
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "tracker",
      title: props.t("Tracker"),
      sorter: (a, b) => {
        return props.t(a.tracker) - props.t(b.tracker) ? 1 : -1
      },
      render: statusFormatter,
      width: "140px",
      ellipsis: true,
    },
    // AntdTable.EXPAND_COLUMN,
    {
      dataIndex: "carephone",
      title: props.t("Safety phone"),
      width: 240,
      ellipsis: true,
      sorter: (a, b) => {
        return props.t(a.carephone) - props.t(b.carephone) ? 1 : -1
      },

      render: (text, record) =>
        text !== undefined ? (
          <>
            <span className="float-end">
              <Button
                // outline
                className="m-0 py-0 px-1"
                size="sm"
                color="primary"
                onClick={event => handleExpand(event, record)}
              >
                {expandedRowKeys.includes(record.id)
                  ? props.t("Hide")
                  : props.t("Extentions") +
                    " (" +
                    record.carephoneAccessories.length +
                    ")"}
              </Button>
            </span>
            {text}
          </>
        ) : null,
    },
    // AntdTable.SELECTION_COLUMN,
    {
      dataIndex: "dose",
      title: props.t("Medication reminder"),
      width: 155,
      sorter: (a, b) => {
        return props.t(a.dose) - props.t(b.dose) ? 1 : -1
      },
      //render: statusFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "video",
      title: props.t("Video"),
      sorter: (a, b) => {
        return props.t(a.video) - props.t(b.video) ? 1 : -1
      },
      //render: statusFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "smartlock",
      title: props.t("Smartlocks"),
      sorter: (a, b) => {
        return props.t(a.smartlock) - props.t(b.smartlock) ? 1 : -1
      },
      //render: deviceFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "measuringDevices",
      title: props.t("Measuring devices"),
      sorter: (a, b) => {
        return props.t(a.measuringDevices) - props.t(b.measuringDevices)
          ? 1
          : -1
      },
      width: "140px",
      ellipsis: true,
    },
  ]

  const expandedRowRender = record => {
    return (
      <div className="extra-table-row-background px-3">
        {/* <div className=""> */}
        {/* TODO: translate */}
        <h6 className=" mb-3">
          {props.t("Safety phone extentions for")}{" "}
          <b className="text-muted">{record.carephone}</b>
        </h6>
        {/* TODO: SET EXTENTION ID:S */}
        {/* {record.carephoneAccessories != undefined ? (*/}
        <div style={{ width: "100vh" }}>
          <Row>
            {record.carephoneAccessories.map((e, index) => {
              return (
                <Col xs="4" key={e.radioId}>
                  <Row>
                    <Col xs="2">
                      <div className="">{index + 1}.</div>
                    </Col>
                    <Col xs="10">
                      <div>{e.equipmentName}</div>
                      <div>
                        {props.t("ID")}: {e.radioId}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )
              // )
            })}
          </Row>
        </div>
        {/* ) : null}  */}
        {/* {record.clientName} */}
      </div>
    )
  }
  function statusFormatter(cell) {
    // console.log(cell, "cell")
    if (cell == "<N/A>") {
      return (
        <span className="badge bg-tertiary font-size-12">
          {props.t("No identification")}
        </span>
      )
    }
    if (cell != "<N/A>") {
      return cell
    }
  }

  function statusFormatterExport(cell) {
    if (cell == "<N/A>") {
      return props.t("No identification")
    }
    if (cell != "<N/A>") {
      return cell
    }
  }
  // format Carephone devices
  function devicesFormatterExport(cell) {
    if (cell == undefined) {
      return ""
    }
    if (cell != undefined) {
      let formattedDevices = cell.map((ce, index) => {
        return (
          `${index + 1}. ${props.t("Device name")}: ${
            ce.equipmentName
          }, ${props.t("Device id")}: ${ce.radioId}` + " "
        )
      })
      return formattedDevices.join("\n")
    }
  }
  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "striped" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = seriesFiltered.map(elt => [
      elt.group,
      elt.clientName,
      elt.gateway,
      statusFormatterExport(elt.tracker),
      elt.carephone,
      devicesFormatterExport(elt.carephoneAccessories),
      elt.dose,
      elt.video,
      elt.smartlock,
      elt.measuringDevices,
    ])
    console.log(data, ".pdf function")

    let content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Client"),
          props.t("Gateway"),
          props.t("Tracker"),
          props.t("Safety phone"),
          props.t("Safety phone extentions"),
          props.t("Medication reminder"),
          props.t("Video"),
          props.t("Smartlocks"),
          props.t("Measuring devices"),
        ],
      ],
      body: data,
    }

    doc.text(titleExport, marginLeft, 40)
    doc.autoTable(content)
    doc.save(titleExport + ".pdf")

    //Show toast
    showToast(message)
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Group"),
        props.t("Client"),
        props.t("Gateway"),
        props.t("Tracker"),
        props.t("Safety phone"),
        props.t("Safety phone extentions"),
        props.t("Medication reminder"),
        props.t("Video"),
        props.t("Smartlocks"),
        props.t("Measuring devices"),
      ],
    ]
    const data = seriesFiltered.map(elt => [
      elt.group,
      elt.clientName,
      elt.gateway,
      statusFormatterExport(elt.tracker),
      elt.carephone,
      devicesFormatterExport(elt.carephoneAccessories),
      elt.dose,
      elt.video,
      elt.smartlock,
      elt.measuringDevices,
    ])

    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, titleExport)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, titleExport + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message)
  }

  // Handle search function
  const handleSearch = searchText => {
    let result = series.filter(
      data =>
        data.clientName?.toLowerCase().includes(searchText) ||
        data.group?.toLowerCase().includes(searchText) ||
        data.gateway?.toLowerCase().includes(searchText) ||
        data.tracker?.toLowerCase().includes(searchText) ||
        data.carephone?.toLowerCase().includes(searchText) ||
        data.dose?.toLowerCase().includes(searchText) ||
        data.smartlock?.toLowerCase().includes(searchText) ||
        data.measuringDevices?.toLowerCase().includes(searchText)
    )
    setSeriesFiltered(result)
  }

  return (
    <React.Fragment>
      <div>
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Device identifications")}</h5>
            </span>
          </div>
        </Row>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col className="col-12">
                <Col lg="12">
                  <Row className="mb-1">
                    <Col xl="4" sm="6">
                      <div className="mt-2">
                        <p>
                          {seriesFiltered.length} {props.t("found results")}
                        </p>
                      </div>
                    </Col>
                    <Col lg="8" sm="6">
                      <span className="float-end mt-1">
                        <Tooltip
                          placement="bottom"
                          isOpen={TTdownloadstatstable}
                          target="downloadstatstable"
                          toggle={() => {
                            setTTdownloadstatstable(!TTdownloadstatstable)
                          }}
                        >
                          {props.t("Download statistics")}
                        </Tooltip>
                        <Dropdown
                          id="printButtonServicesStats"
                          isOpen={menu}
                          toggle={() => setMenu(!menu)}
                          className="download-btn text-center"
                          type="button"
                        >
                          <DropdownToggle
                            id="downloadstatstable"
                            className="text-muted"
                            tag="i"
                          >
                            <i className="mdi mdi-download" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* TODO: Can not choose directory file right now */}
                            <DropdownItem onClick={() => downloadExcel()}>
                              {props.t("Download .xlsx")}
                            </DropdownItem>
                            <DropdownItem onClick={() => exportPDF()}>
                              {props.t("Download .pdf")}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </span>
                      <Form className="mt-sm-0 float-sm-end d-flex align-items-center">
                        <div className="search-box me-2 mb-2 d-inline-block float-end">
                          <div className="position-relative">
                            <Input
                              type="text"
                              onChange={event =>
                                handleSearch(event.target.value.toLowerCase())
                              }
                              placeholder={""}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
                <div className="extra-padding">
                  <AntdTable
                    // className="with-extra-row"
                    bordered={false}
                    size="middle"
                    // sticky
                    rowKey={"id"}
                    columns={columns}
                    dataSource={seriesFiltered}
                    expandable={{
                      expandedRowRender: expandedRowRender,
                      rowExpandable: record => record.carephone !== undefined,
                      expandedRowKeys: expandedRowKeys,
                      onExpand: (expanded, record) =>
                        handleExpand(event, record),
                      showExpandColumn: false,
                    }}
                    pagination={{
                      showSizeChanger: true,
                      defaultPageSize: 50,
                      pageSizeOptions: defaultPageSizeOptions,
                    }}
                    // tableLayout="auto"
                    scroll={{
                      x: "max-content",
                      y: "70vh",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

TableDI.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.any,
  searchProps: PropTypes.any,
  csvProps: PropTypes.any,
  baseProps: PropTypes.any,
  MyExportCSV: PropTypes.any,
  onExport: PropTypes.any,
}

export default withTranslation()(TableDI)
