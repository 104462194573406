import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import moment from "moment"
import withRouter from "components/Common/withRouter"
import iconVideocall from "assets/images/icons/videocall.svg"
import iconApp from "assets/images/icons/app.svg"
import iconMedicine from "assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "assets/images/icons/groupvideo.png"
import iconWebpageAlt from "assets/images/icons/webIconAlt.svg"
import { insertStatusReport, updateStatusReport } from "helpers/database_helper"
import sanitizeHtml from "sanitize-html"
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Alert,
  FormFeedback,
  Label,
  FormGroup,
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
} from "reactstrap"
import { DatePicker, Switch, Steps, TreeSelect, Select } from "antd"
import { useFormik } from "formik"
import * as Yup from "yup"
import { showToast } from "utils/general"
import StatusReportItem from "pages/LandingPage/status-report-item"
const { Option } = Select

const ErrorBulletinsModal = props => {
  const [selection, setSelection] = useState(null)
  const [selectedLang, setSelectedLang] = useState(1)
  const [ongoingInsert, setOngoingInsert] = useState(false)
  const [example, setExample] = useState(null)
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [initialValues, setInitialValues] = useState({
    id: 0,
    current_status: 1,
    target_groups: [],
    published: false,
    start_time: moment(),
    end_time: moment(0),
    header_fi: "",
    header_en: "",
    header_sv: "",
    description_fi: "",
    description_en: "",
    description_sv: "",
  })

  // Steps for publication
  const modalSteps = [
    {
      title: props.t("General"),
    },
    {
      title: props.t("Description"),
    },
  ]

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.modal) createBulletin()
  }, [props.modal])

  useEffect(() => {
    setSelection(props.selection)
  }, [props.selection])

  useEffect(() => {
    if (selection !== null) {
      setInitialValues(selection)
    }
  }, [selection])
  // Formik validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues.id,
      current_status: initialValues.current_status,
      target_groups: initialValues.target_groups,
      published: initialValues.published,
      start_time: initialValues.start_time,
      end_time: initialValues.end_time,
      header_fi: initialValues.header_fi,
      header_en: initialValues.header_en,
      header_sv: initialValues.header_sv,
      description_fi: initialValues.description_fi,
      description_en: initialValues.description_en,
      description_sv: initialValues.description_sv,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)

      let copy = JSON.parse(JSON.stringify(values))
      if (copy.target_groups != null && copy.target_groups.length > 0) {
        copy.target_groups = copy.target_groups.join(",")
      } else {
        copy.target_groups = ""
      }

      const sanitizeSettings = {
        allowedTags: ["b", "i", "em", "strong", "a"],
        allowedAttributes: {
          a: ["href", "target"],
        },
        nonBooleanAttributes: [],
      }

      copy.description_fi = sanitizeHtml(copy.description_fi, sanitizeSettings)
      copy.description_en = sanitizeHtml(copy.description_en, sanitizeSettings)
      copy.description_sv = sanitizeHtml(copy.description_sv, sanitizeSettings)

      console.log("Submit final values: ", copy)

      if (values.id < 0) {
        insertBulletin(copy)
      } else {
        updateBulletin(copy)
      }
    },
  })

  useEffect(() => {
    // console.log("changed values", validation.values)
    handleExample(validation.values)
  }, [validation.values])

  // Insert bulletin
  const insertBulletin = vals => {
    setOngoingInsert(true)
    insertStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          // props.fetchReleases

          props.setCount(1)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Update bulletin
  const updateBulletin = vals => {
    setOngoingInsert(true)
    updateStatusReport(abortRef.current, productionDatabase, vals)
      .then(result => {
        if (result == "OK") {
          showToast(props.t("Updated"), "success")
          // props.fetchReleases

          props.setCount(1)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingInsert(false)
      })
  }

  // Create new bulletin draft
  const createBulletin = () => {
    let temp = new Object()
    temp.id = -1
    temp.start_time = moment()
    temp.end_time = null
    temp.published = false
    temp.target_groups = []
    temp.current_status = 1
    temp.header_fi = ""
    temp.header_en = ""
    temp.header_sv = ""
    temp.description_fi = ""
    temp.description_en = ""
    temp.description_sv = ""

    setSelection(temp)
    setInitialValues(temp)
    // setSelectedIndex(null)
  }

  // Handle preview
  const handleExample = bulletin => {
    let niceDate = moment(bulletin.start_time).format("DD.MM.YYYY HH:mm")
    if (bulletin.end_time) {
      var endDate = new Date(bulletin.end_time)
      if (endDate > 0) {
        let endDateNice = moment(bulletin.end_time).format("DD.MM.YYYY HH:mm")
        niceDate = niceDate + " - " + endDateNice
      }
    }

    let temp = new Object()
    temp.id = bulletin.id
    temp.date = niceDate

    temp.title = bulletin.header_fi
    temp.title_fi = bulletin.header_fi
    temp.title_en = bulletin.header_en
    temp.title_sv = bulletin.header_sv

    temp.content1_fi = bulletin.description_fi
      ? bulletin.description_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_en = bulletin.description_en
      ? bulletin.description_en.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1_sv = bulletin.description_sv
      ? bulletin.description_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
      : ""
    temp.content1 = temp.content1_fi

    let targets = []
    bulletin.target_groups.forEach(target => {
      switch (target) {
        case "1":
          targets.push("Suvanto Care app")
          break
        case "2":
          targets.push("Video call")
          break
        case "3":
          targets.push("Group video call")
          break
        case "4":
          targets.push("Carephone")
          break
        case "5":
          targets.push("Medicine reminder")
          break
        case "6":
          targets.push("Suvanto On the Go")
          break
        case "7":
          targets.push("Suvanto Home")
          break
        case "8":
          targets.push("Smartlock")
          break
        case "9":
          targets.push("Suvanto Care webpage")
          break
      }
    })
    temp.targets = targets

    switch (bulletin.current_status) {
      case 1:
      case "1":
        temp.status = "Service outage"
        temp.color = "#f46a6a"
        break
      case 2:
      case "2":
        temp.status = "Service disruption"
        temp.color = "#f1b44c"
        break
      case 3:
      case "3":
        temp.status = "Service operational"
        temp.color = "#34c38f"
        break
    }
    setExample(temp)
  }
  const changeSteps = value => {
    setCurrentStep(value)
  }
  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }
  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }
  const steps = modalSteps.map(item => ({
    key: item.title,
    title: item.title,
  }))

  return (
    <>
      <Modal isOpen={initialValues !== null} centered size="lg">
        <ModalBody>
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              <Steps
                type="navigation"
                size="small"
                className="pb-3 pt-0"
                current={currentStep}
                items={steps}
                onChange={validation.values.version === "" ? null : changeSteps}
              ></Steps>
              {currentStep == 0 && (
                <div>
                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Status")}</Label>
                        <Input
                          value={validation.values.current_status + ""}
                          type="select"
                          name="current_status"
                          onChange={validation.handleChange}
                        >
                          <option value="1">{props.t("Service outage")}</option>
                          <option value="2">
                            {props.t("Service disruption")}
                          </option>
                          <option value="3">
                            {props.t("Service operational")}
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Affected services")}</Label>
                        <Select
                          className=""
                          allowClear
                          mode="multiple"
                          placeholder={props.t("Select services")}
                          style={{ width: "100%" }}
                          value={validation.values.target_groups}
                          onChange={v =>
                            validation.setFieldValue("target_groups", v)
                          }
                          listHeight={400}
                        >
                          <Option value="1">
                            {props.t("Suvanto Care app")}
                          </Option>
                          <Option value="2">{props.t("Video call")}</Option>
                          <Option value="3">
                            {props.t("Group video call")}
                          </Option>
                          <Option value="4">{props.t("Carephone")}</Option>
                          <Option value="5">
                            {props.t("Medicine reminder")}
                          </Option>
                          <Option value="6">
                            {props.t("Suvanto On the Go")}
                          </Option>
                          <Option value="7">{props.t("Suvanto Home")}</Option>
                          <Option value="8">{props.t("Smartlock")}</Option>
                          <Option value="9">
                            {props.t("Suvanto Care webpage")}
                          </Option>
                        </Select>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Start time")}</Label>
                        <DatePicker
                          style={{ width: "100%" }}
                          showTime
                          format="DD.MM.YYYY HH:mm"
                          value={validation.values.start_time}
                          onChange={v =>
                            validation.setFieldValue("start_time", v)
                          }
                        />
                      </FormGroup>
                    </Col>{" "}
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Start time")}</Label>
                        <DatePicker
                          style={{ width: "100%" }}
                          showTime
                          format="DD.MM.YYYY HH:mm"
                          value={validation.values.start_time}
                          onChange={v =>
                            validation.setFieldValue("start_time", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <FormGroup>
                      <Label>{props.t("Bulletin published")}</Label>
                      <Switch
                        className="ms-2"
                        name="published"
                        checked={validation.values.published}
                        onChange={v => validation.setFieldValue("published", v)}
                        checkedChildren={props.t("Yes")}
                        unCheckedChildren={props.t("No")}
                      ></Switch>
                      {/* <Label>{props.t("Release note published")}</Label> */}
                    </FormGroup>
                  </Row>
                  {example && (
                    <div>
                      <div>
                        {selection &&
                          selection.guid &&
                          props.productionDatabase && (
                            <span className="float-end">
                              <a
                                className=""
                                href={"/disturbance-notice/" + selection.guid}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bx bx-link-external me-1"></i>
                                {props.t("Public link")}
                              </a>
                            </span>
                          )}
                        <span className="h6">
                          {props.t("Bulletin preview")}
                        </span>
                      </div>
                      <Card className="shadow-none border mx-2 my-1">
                        <div className="">
                          <ul className="list-group list-group-flush">
                            <li key="example" className="list-group-item py-2">
                              <StatusReportItem
                                item={example}
                                // onOpenModal={() => {
                                //   setModal(!modal)
                                // }}
                              />
                            </li>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
              )}
              {currentStep == 1 && (
                <>
                  <ButtonGroup size="sm" className="mb-4 mt-3">
                    <Button
                      color="primary"
                      active={selectedLang === 1}
                      onClick={() => setSelectedLang(1)}
                    >
                      Suomi
                    </Button>
                    <Button
                      color="primary"
                      active={selectedLang === 2}
                      onClick={() => setSelectedLang(2)}
                    >
                      Englanti
                    </Button>
                    <Button
                      color="primary"
                      active={selectedLang === 3}
                      onClick={() => setSelectedLang(3)}
                    >
                      Ruotsi
                    </Button>
                  </ButtonGroup>

                  {selectedLang === 1 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Title") + " (fi)"}</Label>
                        <Input
                          name="header_fi"
                          placeholder={props.t("Title")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.header_fi || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Description") + " (fi)"}</Label>
                        <Input
                          name="description_fi"
                          placeholder={props.t("Description")}
                          type="textarea"
                          rows={12}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description_fi || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}

                  {selectedLang === 2 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Title") + " (en)"}</Label>
                        <Input
                          name="header_en"
                          placeholder={props.t("Title")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.header_en || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Description") + " (en)"}</Label>
                        <Input
                          name="description_en"
                          placeholder={props.t("Description")}
                          type="textarea"
                          rows={12}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description_en || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}

                  {selectedLang === 3 && (
                    <div>
                      <FormGroup>
                        <Label>{props.t("Title") + " (sv)"}</Label>
                        <Input
                          name="header_sv"
                          placeholder={props.t("Title")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.header_sv || ""}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>{props.t("Description") + " (sv)"}</Label>
                        <Input
                          name="description_sv"
                          placeholder={props.t("Description")}
                          type="textarea"
                          rows={12}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description_sv || ""}
                        ></Input>
                      </FormGroup>
                    </div>
                  )}
                  {example && (
                    <div>
                      <div>
                        {selection &&
                          selection.guid &&
                          props.productionDatabase && (
                            <span className="float-end">
                              <a
                                className=""
                                href={"/disturbance-notice/" + selection.guid}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="bx bx-link-external me-1"></i>
                                {props.t("Public link")}
                              </a>
                            </span>
                          )}
                        <span className="h6">
                          {props.t("Bulletin preview")}
                        </span>
                      </div>
                      <Card className="shadow-none border mx-2 my-1">
                        <div className="">
                          <ul className="list-group list-group-flush">
                            <li key="example" className="list-group-item py-2">
                              <StatusReportItem
                                item={example}
                                // onOpenModal={() => {
                                //   setModal(!modal)
                                // }}
                              />
                            </li>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  )}
                </>
              )}

              <div
                style={{
                  marginTop: 24,
                }}
              >
                {currentStep == 0 && (
                  <Button
                    color="primary"
                    disabled={ongoingInsert}
                    style={{ minWidth: "160px" }}
                    onClick={() => {
                      validation.submitForm()
                    }}
                  >
                    <i className="bx bxs-save me-1"></i>
                    {props.t("Save")}
                  </Button>
                )}
                {currentStep == 1 ? (
                  <Button
                    color="primary"
                    outline
                    className="ms-2"
                    onClick={() => {
                      props.setSelection(null)
                      props.modal(false)
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    outline
                    className="ms-2"
                    onClick={() => {
                      props.setSelection(null)
                      props.modal(false)
                    }}
                  >
                    {props.t("Cancel")}
                  </Button>
                )}

                {currentStep < modalSteps.length - 1 && (
                  <>
                    {currentStep === 1 ? (
                      <Button
                        color="primary"
                        disabled={ongoingInsert}
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          validation.submitForm()
                        }}
                      >
                        <i className="bx bxs-save me-1"></i>
                        {props.t("Save")}
                      </Button>
                    ) : (
                      <Button
                        className="float-end"
                        color="primary"
                        onClick={() => nextStep()}
                        disabled={validation.values.version == ""}
                      >
                        {props.t("Next")}
                      </Button>
                    )}
                  </>
                )}
                {currentStep > 0 && (
                  <>
                    <Button
                      className="float-end"
                      color="primary"
                      disabled={ongoingInsert}
                      style={{ minWidth: "160px" }}
                      onClick={() => {
                        validation.submitForm()
                      }}
                    >
                      <i className="bx bxs-save me-1"></i>
                      {props.t("Save")}
                    </Button>
                    <Button
                      className="float-end"
                      color="primary"
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => prevStep()}
                    >
                      {props.t("Previous")}
                    </Button>
                  </>
                )}
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

ErrorBulletinsModal.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.any,
  setSelection: PropTypes.any,
  productionDatabase: PropTypes.any,
  modal: PropTypes.any,
  fetchReleases: PropTypes.func,
  subscribers: PropTypes.any,
}

export default withRouter(withTranslation()(ErrorBulletinsModal))
