import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Switch } from "antd"
import {} from "helpers/backend_helper"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"
import { isEqual } from "lodash"

const VideoAppSettings = props => {
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [changedValues, setChangedValues] = useState({})
  const [changedValuesString, setChangedValuesString] = useState("")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Settings validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      settings_user_name: "",
      settings_user_password: "",
      webDemoUser: "",
      webDemoPass: "",

      usesDimMinutes: null,
      dimMinutes: 0,
      dimSeconds: 0,

      clockEnabled: null,
      analogClockEnabled: null,
      galleryMode: null,

      automaticPickup: null,
      answerTime: null,

      applicationPipShown: null,
      showHealthButton: null,
      showDebugStats: null,

      usesProduction: null,
      uses_assisted_restart: null,
    },
    validationSchema: Yup.object({

    }),
    onSubmit: values => {
      console.log("Submit values: ", values)

      /*const initialFormValues = JSON.parse(
        JSON.stringify(validation.initialValues)
      )

      const changedValues = {}
      for (const key in values) {
        if (!isEqual(initialFormValues[key], values[key])) {
          changedValues[key] = values[key]
        }
      }*/

      console.log("Changed values: ", changedValues)

      /*if (props.serial) {
        setUpdate(true)
        videoSendPush(abortRef.current, props.serial, "SetVideoSettings")
          .then(result => {
            console.log("Push result: ", result)
            if (result.statusCode == 200) {
              showToast(props.t("Command sent"), "success")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            showToast(props.t("Error"), "error")
            console.log(err)
          })
          .finally(() => {
            setUpdate(false)
          })
      }*/
    },
  })

  useEffect(() => {
    const initialFormValues = JSON.parse(
      JSON.stringify(validation.initialValues)
    )
    let temp = {}
    for (const key in validation.values) {
      if (!isEqual(initialFormValues[key], validation.values[key])) {
        temp[key] = validation.values[key]
      }
    }
    setChangedValues(temp)
    setChangedValuesString(JSON.stringify(temp))
  }, [validation.values])

  return (
    <>
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          return false
        }}
      >
        <Row xs={1} lg={2}>
          <Col>
            <FormGroup>
              <Label>{props.t("Username")}</Label>
              <Input
                bsSize="sm"
                name="settings_user_name"
                type="text"
                value={validation.values.settings_user_name}
                onChange={validation.handleChange}
              ></Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{props.t("Password")}</Label>
              <Input
                bsSize="sm"
                name="settings_user_password"
                type="text"
                value={validation.values.settings_user_password}
                onChange={validation.handleChange}
              ></Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{props.t("Auto answer time")}</Label>
              <Input
                bsSize="sm"
                name="answerTime"
                type="number"
                value={validation.values.answerTime}
                onChange={validation.handleChange}
              ></Input>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup className="mt-2">
          <Switch
            className="me-2"
            name="applicationPipShown"
            checked={validation.values.applicationPipShown}
            onChange={v => {
              validation.setFieldValue("applicationPipShown", v)
            }}
          ></Switch>
          <Label>{props.t("Show PiP")}</Label>
        </FormGroup>

        <FormGroup className="mt-2">
          <Switch
            className="me-2"
            name="showDebugStats"
            checked={validation.values.showDebugStats}
            onChange={v => {
              validation.setFieldValue("showDebugStats", v)
            }}
          ></Switch>
          <Label>{props.t("Show debug stats")}</Label>
        </FormGroup>

        <div className="mt-2 mb-3 d-flex justify-content-center">
          <Button
            color="primary"
            //disabled={update}
            style={{ minWidth: "150px" }}
            onClick={() => {
              validation.resetForm()
            }}
          >
            {props.t("Reset form")}
          </Button>
        </div>

        <div className="mt-2 mb-3 d-flex justify-content-center">
          <Button
            color="primary"
            //disabled={update}
            style={{ minWidth: "150px" }}
            onClick={() => {
              validation.submitForm()
            }}
          >
            {props.t("Save")}
          </Button>
        </div>
        <Alert color="info">
          {changedValuesString}
        </Alert>
      </Form>
    </>
  )
}

VideoAppSettings.propTypes = {
  t: PropTypes.any,
  serial: PropTypes.string,
}

export default withRouter(withTranslation()(VideoAppSettings))